import styled from "@emotion/styled"
import * as Toolbar from "@radix-ui/react-toolbar"
import { ComponentProps } from "react"

const Button = styled(Toolbar.Button)`
  all: unset;
  cursor: pointer;
  font-size: inherit;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
`
const IconButton = (props: ComponentProps<typeof Toolbar.Button>) => {
  return <Button {...props} />
}

export default IconButton
