import styled from "@emotion/styled"
import { PX10, PX12 } from "theme/sizes"
import Center from "ui/Center"

const LeaningLineLessonLabel = styled(Center)<{ color: string }>`
  width: 55px;
  height: 20px;
  color: white;
  background-color: ${({ color }) => color};
  font-size: ${PX10};
  border-radius: 11px;
  font-weight: 600;
`

LeaningLineLessonLabel.defaultProps = {
  children: "Leerlijn",
}

export default LeaningLineLessonLabel
