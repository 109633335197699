import styled from "@emotion/styled"
import { DARK_ORANGE_COLOR, ORANGE_COLOR } from "theme/colors"
import { css } from "@emotion/react"

type Props = {
  size?: string
  inline?: boolean
  bold?: boolean
}

const TextLink = styled("a")<Props>`
  ${(p) => p.bold && "font-weight: 600;"}
  color: ${ORANGE_COLOR};
  text-decoration: none;
  transition: 0.3s ease-out;
  cursor: pointer;
  ${(p) => p.size && `font-size: ${p.size};`}

  &:hover {
    color: ${DARK_ORANGE_COLOR};
  }

  ${(p) =>
    p.inline
      ? css`
          text-decoration: underline;
          text-decoration-color: ${ORANGE_COLOR};

          &:hover {
            text-decoration-color: ${DARK_ORANGE_COLOR};
          }
        `
      : null}
`

export default TextLink
