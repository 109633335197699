import { FaPause } from "@react-icons/all-files/fa/FaPause"
import { FaPlay } from "@react-icons/all-files/fa/FaPlay"
import Text from "ui/Text"
import { TooltipArrow } from "ui/Tooltip"
import styled from "@emotion/styled"
import { PX14, TOASTS_Z_INDEX } from "theme/consts"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import { poppins } from "theme/fonts"
import * as RadixTooltip from "@radix-ui/react-tooltip"

const ContentTooltip = styled.div`
  z-index: ${TOASTS_Z_INDEX};
  background-color: ${DIMMED_BLUE_COLOR};
  padding: 9px 19px;
  box-shadow: 0px 3px 6px #0000004d;
  border-radius: 4px;
  font-family: ${poppins.style.fontFamily};
  color: white;
`

type Props = {
  trigger: any
  text: string
}

const Tooltip = ({ trigger, text }: Props) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content sideOffset={5} asChild>
            <ContentTooltip>
              <Text size={PX14}>{text}</Text>
            </ContentTooltip>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

export default Tooltip
