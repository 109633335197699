import ContentSubjectIcon from "web/src/content-subjects/components/ContentSubjectIcon"
import getContentSubjectColor from "web/src/content-subjects/getContentSubjectColor"
import { Title, TitleWrapper } from "./LessonListItem"
import { ContentSubjectType } from "db"
import styled from "@emotion/styled"

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  title: string
  contentSubject: ContentSubjectType
}

const LessonListItemTitle = ({ title, contentSubject }: Props) => {
  return (
    <TitleWrapper>
      <IconContainer>
        <ContentSubjectIcon
          type={contentSubject}
          color={getContentSubjectColor(contentSubject)}
        />
      </IconContainer>
      <Title>{title}</Title>
    </TitleWrapper>
  )
}

export default LessonListItemTitle
