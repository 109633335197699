import { Value } from "@stringtale/react"
import styled from "@emotion/styled"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { hasKidsFeatures } from "app/organizations/utils/hasKidsFeatures"
import { useRouter } from "next/router"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
  PX18,
  PX24,
} from "theme/consts"
import Button from "ui/Button"
import { Routes } from "@blitzjs/next"

const Body = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 40px;
  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  max-width: 50%;
  align-items: center;
  box-sizing: border-box;
  margin: 60px;
  @media (max-width: 1248px) {
    max-width: 80%;
    padding: 20px;
  }
`

const ErrorTitle = styled.h2`
  font-size: ${PX24};
  font-weight: 900;
  color: ${DARK_BLUE_COLOR};
`

const ErrorMessage = styled.span`
  font-size: ${PX18};
  color: ${DARK_BLUE_COLOR};
  margin-bottom: 10px;
`

const ErrorButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 50px;
  color: white;
  height: 50px;
  width: 200px;
`

export const NoGroups = function () {
  const router = useRouter()
  const currentOrganization = useCurrentOrganization()

  return (
    <Body>
      <ErrorContainer>
        <ErrorTitle>
          <Value name="apps.web.src.errors.nogroups.title">
            Groepsfuncties niet beschikbaar
          </Value>
        </ErrorTitle>
        <ErrorMessage>
          {hasKidsFeatures(currentOrganization) ? (
            <Value name="apps.web.src.errors.nogroups.message_geen_groep">
              De Kidspagina is niet beschikbaar omdat je geen groep hebt. Vraag
              de beheerder van jullie school om jouw groep aan te maken.
            </Value>
          ) : (
            <Value name="apps.web.src.errors.nogroups.message_organisatie_geen_groepen">
              De Kidspagina is niet beschikbaar omdat jouw organisatie geen
              groepen in 123ZING heeft.
            </Value>
          )}
        </ErrorMessage>

        <ErrorButton
          onClick={async () => {
            router.push(Routes.Home())
          }}
        >
          <Value name="apps.web.src.errors.nogroups.terug_naar_home">
            Terug naar home
          </Value>
        </ErrorButton>
      </ErrorContainer>
    </Body>
  )
}
