import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import * as Dialog from "@radix-ui/react-dialog"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { TbDots } from "@react-icons/all-files/tb/TbDots"
import { TbMoodSmile } from "@react-icons/all-files/tb/TbMoodSmile"
import { Value } from "@stringtale/react/dist"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { MAX_KIDS_LIST } from "app/kids/consts"
import getLearningLineLinkProps from "app/learning-lines/utils/getLearningLineLinkProps"
import IconContainer from "app/player/components/IconContainer"
import ShareModal from "app/share/components/ShareModal"
import useToast from "app/toasts/hooks/useToast"
import {
  BulletinBoardItemType,
  PageType,
  UserListAccessLevel,
  UserListType,
} from "db"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import Link from "next/link"
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react"
import TruncateMarkup from "react-truncate-markup"
import { BORDER_GREY_COLOR, DARK_BLUE_COLOR, RED_COLOR } from "theme/colors"
import { PX12, PX13, PX14, PX28 } from "theme/sizes"
import AlertModal from "ui/AlertModal"
import Badge from "ui/Badge"
import Button from "ui/Button"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import * as Modal from "ui/Modal"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import { usePlay } from "../../radio/components/PlayerContext"
import {
  displayStateAtom,
  isPlayingAtom,
  playingStateAtom,
  playingThemeAtom,
} from "../../radio/state"
import { MoreThanOnePlaylistError } from "../errors"
import deleteUserPlaylist from "../mutations/deleteUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import getUserList, { GetUserListResult } from "../queries/getUserList"
import getIsMigrationVisible from "../utils/getIsMigrationVisible"
import getListTypeCanAdd from "../utils/getListTypeCanAdd"
import getUserPlaylistColor from "../utils/getUserPlaylistColor"
import getUserPlaylistTitle from "../utils/getUserPlaylistTitle"
import { AddListModalUncontrolled } from "./AddListModal"
import DeleteMultiplePlaylistsModal from "./DeleteMultiplePlaylistsModal"
import UpdateUserListModal from "./UpdateUserListModal"
import UserPlaylistType, { UserPlaylistTypeOwner } from "./UserPlaylistType"
import { TbSend } from "@react-icons/all-files/tb/TbSend"
import getSchoolLists from "app/schoolbeheer/queries/getSchoolLists"
import { schoolListBadge } from "./SchoolListItem"
import Avatar from "app/files/components/Avatar"
import getFullname from "utils/users/getFullName"
import Hr from "ui/Hr"
import UpdateSchoolListModal from "app/schoolbeheer/components/schoollists/UpdateSchoolListModal"
import { publishUserListIdAtom, unpublishUserListIdAtom } from "../state"
import { parseAsBoolean, useQueryState } from "nuqs"
import { MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE } from "../consts"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

export const Header = styled(Stack)<{ color: string }>`
  padding: 19px 32px 25px;
  background-color: ${(p) => p.color};
  gap: 24px;
  color: white;
  width: 100%;
  box-sizing: border-box;
`

const RoundedButton = styled(RoundedButtonBase)`
  color: ${DARK_BLUE_COLOR};
  font-size: ${PX14};
`

const ContextMenuButton = styled(RoundedButton)``

const ListType = styled(Text)`
  font-weight: 600;
  font-size: ${PX13};
`

const Description = styled.div`
  font-weight: 600;
  font-size: ${PX14};
  white-space: pre-wrap;
  max-height: 250px;
  overflow-y: auto;
`

const ShowMoreButton = styled(Button)`
  font-weight: 600;
  font-size: ${PX14};
  align-self: self-start;
  text-decoration: underline;
  padding: 0;
  margin-top: 3px;
`

const PlayButton = styled(Button)`
  width: 42px;
  height: 42px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #efefef;
  img {
    width: 21px;
    height: 21px;
    transform: translateX(3%);
  }
`

const addListType = (accessLevel: UserListAccessLevel, type: UserListType) => {
  switch (accessLevel) {
    case "PERSONAL":
      return BulletinBoardItemType.PERSONAL_LIST
    case "SCHOOL":
      return BulletinBoardItemType.SCHOOL_LIST
    case "KIDS":
      return BulletinBoardItemType.KIDS_LIST
    case "GLOBAL":
      if (type === "THEME") return BulletinBoardItemType.THEME_GROUP
      else return BulletinBoardItemType.LL_PAGE
    default:
      return BulletinBoardItemType.GROUP_LIST
  }
}

const addListId = (userPlaylist: NonNullable<GetUserListResult>) => {
  switch (userPlaylist.type) {
    case "THEME":
      if (userPlaylist.themeGroupId) return userPlaylist.themeGroupId
      throw new Error("No themeGroupId found")
    case "LL":
      if (userPlaylist.learningLineId) return userPlaylist.learningLineId
      throw new Error("No learningLineId found")
    default:
      return userPlaylist.id
  }
}

export default function PlaylistModalHeader({
  userPlaylist,
  list,
  setIsNewPlaylistOpen,
  setIsAddLessonOpen,
  addButton,
  video,
  canEdit,
  canDelete,
  isSharedWithMe,
  addedByUser,
}: {
  userPlaylist: NonNullable<GetUserListResult>
  list: NonNullable<NonNullable<GetUserListResult>["playlist"]>["lessons"]
  setIsNewPlaylistOpen: Dispatch<SetStateAction<boolean>>
  setIsAddLessonOpen: Dispatch<SetStateAction<boolean>>
  addButton: ReactNode
  video: ReactNode
  canEdit: boolean
  canDelete: boolean
  isSharedWithMe?: boolean
  addedByUser?: UserPlaylistTypeOwner
}) {
  const [openSchoolList, setOpenSchoolList] = useQueryState(
    MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE
  )
  const setUnpublishUserListId = useSetAtom(unpublishUserListIdAtom)
  const setPublishUserListId = useSetAtom(publishUserListIdAtom)
  const [isFollowModalVisible, setFollowModalVisibe] = useState(false)
  const [isListSaveModalOpen, setListSaveModalOpen] = useState(false)
  const [isUpdatePlaylistVisible, setUpdatePlaylistVisible] = useState(false)
  const [isUpdateSchoolListVisible, setUpdateSchoolListVisible] =
    useState(false)
  const [isSharePlaylistVisible, setSharePlaylistVisible] = useState(false)
  const [isDeletePlaylistVisible, setDeletePlaylistVisible] = useState(false)
  const [isDeleteMultiplePlaylistVisible, setDeleteMultiplePlaylistVisible] =
    useState(false)
  const [deletePlaylistMutation] = useMutation(deleteUserPlaylist)
  const addToast = useToast()
  const { play, pause, setList, player } = usePlay()
  const playingState = useAtomValue(playingStateAtom)
  const group = useCurrentGroup()
  const isPlaying = useAtomValue(isPlayingAtom)
  const [displayState, setDisplayState] = useAtom(displayStateAtom)
  const [playingTheme, setPlayingTheme] = useAtom(playingThemeAtom)
  const currentGroup = useCurrentGroup()

  const hidePlayButton =
    userPlaylist.themeGroup?.globalPlaylist?.playlist.hidePlayButton

  const color = getUserPlaylistColor(userPlaylist)
  const hasAddButton =
    userPlaylist &&
    getListTypeCanAdd(userPlaylist.accessLevel) &&
    !isSharedWithMe
  const hasReachedMax =
    userPlaylist.accessLevel === "KIDS" && list.length >= MAX_KIDS_LIST
  const isPlayableList =
    !hidePlayButton &&
    !!list.find((el) => !!el.lesson.radioSong || !!el.lesson?.rehearse?.video)

  const [isShowMore, setShowMore] = useState(false)
  const [isTruncated, setTruncated] = useState(false)

  const isMigrationVisible = useMemo(() => {
    return getIsMigrationVisible(userPlaylist, currentGroup)
  }, [currentGroup, userPlaylist])

  const [currentUserRole] = useQuery(getOrganizatorRole, {})

  return (
    <Header color={color}>
      <Group justify="space-between" gap="12px">
        <Stack gap="24px">
          {userPlaylist && (
            <Stack gap="6px">
              <Group gap="12px" justify="flex-start" align="center">
                <ListType>
                  <UserPlaylistType
                    {...userPlaylist}
                    isSharedWithMe={isSharedWithMe}
                    sender={addedByUser || null}
                  />
                </ListType>
                {userPlaylist.accessLevel === "SCHOOL" &&
                  (currentUserRole === "INITIATOR" ||
                    currentUserRole === "MANAGER") &&
                  userPlaylist.owner && (
                    <Group gap="6px" justify="flex-start" align="center">
                      <Avatar
                        user={userPlaylist.owner}
                        width={20}
                        height={20}
                      />
                      <Text size={PX12}>{getFullname(userPlaylist.owner)}</Text>
                    </Group>
                  )}
                {userPlaylist.accessLevel === "SCHOOL" &&
                  (currentUserRole === "INITIATOR" ||
                    currentUserRole === "MANAGER") &&
                  schoolListBadge(userPlaylist)}
                {userPlaylist.migratedFromId &&
                  userPlaylist.accessLevel !== "KIDS" && (
                    <Badge
                      color={DARK_BLUE_COLOR}
                      backgroundColor={BORDER_GREY_COLOR}
                      bold
                    >
                      <Value name="apps.web.src.playlists.components.playlistmodalheader.archief_kopie">
                        Archief kopie
                      </Value>
                    </Badge>
                  )}
              </Group>
              <Title size={PX28}>{getUserPlaylistTitle(userPlaylist)}</Title>
              {isShowMore ? (
                <>
                  <Description>
                    {userPlaylist.playlist?.description}
                  </Description>
                  {isTruncated && (
                    <ShowMoreButton onClick={() => setShowMore(false)}>
                      <Value name="apps.web.src.playlists.components.playlistmodalheader.toon_minder">
                        Toon minder
                      </Value>
                    </ShowMoreButton>
                  )}
                </>
              ) : (
                <>
                  <TruncateMarkup lines={3} onTruncate={setTruncated}>
                    <Description>
                      {userPlaylist.playlist?.description}
                    </Description>
                  </TruncateMarkup>
                  {isTruncated && (
                    <ShowMoreButton onClick={() => setShowMore(true)}>
                      <Value name="apps.web.src.playlists.components.playlistmodalheader.toon_meer">
                        Toon meer
                      </Value>
                    </ShowMoreButton>
                  )}
                </>
              )}
            </Stack>
          )}

          <Group gap="12px" justify="space-between">
            <Group gap="12px" justify="flex-start">
              {isPlayableList && (
                <PlayButton
                  onClick={async () => {
                    if (
                      isPlaying &&
                      userPlaylist.id === playingTheme?.libraryPlaylistId
                    ) {
                      await pause()
                    } else if (
                      !isPlaying &&
                      userPlaylist.id === playingTheme?.libraryPlaylistId
                    ) {
                      if (
                        displayState === "closed" ||
                        displayState === "hidden"
                      )
                        setDisplayState("small")
                      await play()
                    } else {
                      // Set playlist in global player
                      await setList(list)
                      if (
                        userPlaylist.type === "THEME" &&
                        userPlaylist.themeGroup &&
                        userPlaylist.themeGroup.theme
                      ) {
                        setPlayingTheme({
                          listName: getUserPlaylistTitle(userPlaylist),
                          route: Routes.ThemePage({
                            category:
                              userPlaylist.themeGroup.theme.category.slug,
                            theme: userPlaylist.themeGroup.theme.slug,
                            group: userPlaylist.themeGroup.slug,
                          }),
                          libraryPlaylistId: userPlaylist.id
                            ? userPlaylist.id
                            : undefined,
                        })
                      } else {
                        await setPlayingTheme({
                          listName: getUserPlaylistTitle(userPlaylist),
                          libraryPlaylistId: userPlaylist.id
                            ? userPlaylist.id
                            : undefined,
                        })
                      }

                      if (player) player.currentTime = 0

                      await play()
                      if (
                        displayState === "closed" ||
                        displayState === "hidden"
                      )
                        setDisplayState("small")
                    }
                  }}
                >
                  {isPlaying &&
                  userPlaylist.id === playingTheme?.libraryPlaylistId ? (
                    <IconContainer url="/icons/player/ico_pause_bl.svg" />
                  ) : (
                    <IconContainer url="/icons/player/ico_play_bl.svg" />
                  )}
                </PlayButton>
              )}
              {addButton}
              <ContextMenu
                trigger={
                  <ContextMenuButton left={<TbDots size={20} />}>
                    <Value name="apps.web.src.playlists.components.playlistmodalheader.opties">
                      Opties
                    </Value>
                  </ContextMenuButton>
                }
                items={
                  <>
                    {(userPlaylist.accessLevel === "GLOBAL" ||
                      userPlaylist.accessLevel === "GROUP" ||
                      userPlaylist.accessLevel === "PERSONAL" ||
                      userPlaylist.accessLevel === "KIDS") && (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={(e) => {
                            setListSaveModalOpen(true)
                          }}
                        >
                          <Value
                            name="apps.web.src.playlists.components.playlistmodalheader.lijst_toevoegen"
                            version="1"
                          >
                            Lijst opslaan
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    )}
                    {isPlayableList && (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item onClick={play}>
                          <Value name="apps.web.src.playlists.components.playlistmodalheader.afspelen">
                            Afspelen
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    )}
                    {hasAddButton ||
                    (userPlaylist.accessLevel === "SCHOOL" && canEdit) ? (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={() =>
                            !hasReachedMax ? setIsAddLessonOpen(true) : null
                          }
                        >
                          <Value name="playlists.header.add" version="112">
                            Voeg les toe
                          </Value>
                          {hasReachedMax ? (
                            <Value name="apps.web.src.playlists.components.playlistmodalcontent.max_van_max_kids_list_bereikt" format={{ MAX_KIDS_LIST }} version="1"
                            >
                                {/* HTML */ `(max. {MAX_KIDS_LIST})`}
                              </Value>
                          ) : null}
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ) : null}
                    {group && (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={(e) => {
                            setSharePlaylistVisible(true)
                          }}
                        >
                          <Value name="afspeellijsten.lijst.delen" version="1">
                            Deel lijst
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    )}

                    {hasAddButton ||
                      (userPlaylist.accessLevel === "SCHOOL" && canEdit && (
                        <Hr />
                      ))}

                    {canEdit ? (
                      <>
                        {userPlaylist.accessLevel === "SCHOOL" ? (
                          <DropdownMenu.Item asChild>
                            <ContextMenu.Item
                              onClick={() => {
                                setUpdateSchoolListVisible(true)
                              }}
                            >
                              <Value
                                name="apps.web.src.playlists.components.playlistmodalheader.schoollijst_aanpassen"
                                version="1"
                              >
                                Pas schoollijst aan
                              </Value>
                            </ContextMenu.Item>
                          </DropdownMenu.Item>
                        ) : (
                          <DropdownMenu.Item asChild>
                            <ContextMenu.Item
                              onClick={() => {
                                setUpdatePlaylistVisible(true)
                              }}
                            >
                              <Value
                                name="afspeellijsten.lijst.aanpassen"
                                version="1"
                              >
                                Aanpassen
                              </Value>
                            </ContextMenu.Item>
                          </DropdownMenu.Item>
                        )}
                      </>
                    ) : null}

                    {canEdit && userPlaylist.accessLevel === "SCHOOL" && (
                      <>
                        {userPlaylist.status !== "PUBLISHED" ? (
                          <DropdownMenu.Item asChild>
                            <ContextMenu.Item
                              onClick={() =>
                                setPublishUserListId(userPlaylist.id)
                              }
                            >
                              <Value name="apps.web.src.playlists.components.playlistmodalheader.maak_zichtbaar_voor_collegas">
                                {`Maak zichtbaar voor collega’s`}
                              </Value>
                            </ContextMenu.Item>
                          </DropdownMenu.Item>
                        ) : (
                          <DropdownMenu.Item asChild>
                            <ContextMenu.Item
                              onClick={() =>
                                setUnpublishUserListId(userPlaylist.id)
                              }
                            >
                              <Value name="apps.web.src.playlists.components.playlistmodalheader.maak_onzichtbaar_voor_collegas">
                                {`Maak onzichtbaar voor collega’s`}
                              </Value>
                            </ContextMenu.Item>
                          </DropdownMenu.Item>
                        )}
                      </>
                    )}
                    {canDelete && (
                      <>
                        <Hr />
                        <DropdownMenu.Item asChild>
                          <ContextMenu.Item
                            onClick={(e) => {
                              setDeletePlaylistVisible(true)
                            }}
                          >
                            <Text color={RED_COLOR}>
                              <Value
                                name="afspeellijsten.lijst.delete"
                                version="1"
                              >
                                Verwijder lijst
                              </Value>
                            </Text>
                          </ContextMenu.Item>
                        </DropdownMenu.Item>
                      </>
                    )}
                    {userPlaylist.accessLevel !== "KIDS" &&
                      userPlaylist.accessLevel !== "SCHOOL" && (
                        <DropdownMenu.Item asChild>
                          <ContextMenu.Item
                            onClick={(e) => {
                              setIsNewPlaylistOpen(true)
                            }}
                          >
                            <Value name="afspeellijsten.lijst.new" version="1">
                              Nieuwe lijst
                            </Value>
                          </ContextMenu.Item>
                        </DropdownMenu.Item>
                      )}
                    {/* {currentUserRole === "MANAGER" &&
                      userPlaylist.accessLevel === "SCHOOL" && (
                        <DropdownMenu.Item asChild>
                          <ContextMenu.Item>
                            <Link
                              style={{ textDecoration: "none" }}
                              {...Routes.SchoolbeheerSchoollijstenPage()}
                            >
                              <Value
                                name="afspeellijsten.lijst.nieuwe_schoollijst"
                                version="1"
                              >
                                Nieuwe schoollijst
                              </Value>
                            </Link>
                          </ContextMenu.Item>
                        </DropdownMenu.Item>
                      )} */}
                    {/* <DropdownMenu.Separator asChild>
                  <ContextMenu.Separator />
                </DropdownMenu.Separator>
                <DropdownMenu.Item asChild>
                  <ContextMenu.Item
                    onClick={(e) => {
                      setInvitePlaylistVisible(true)
                    }}
                  >
                    <Value name="afspeellijsten.lijst.delen">
                      Afspeellijst delen
                    </Value>
                  </ContextMenu.Item>
                </DropdownMenu.Item>
                <DropdownMenu.Item asChild>
                  <ContextMenu.Item
                    onClick={(e) => {
                      setDuplicatePlaylistVisible(true)
                    }}
                  >
                    <Value name="afspeellijsten.lijst.dupliceren">
                      Afspeellijst dupliceren
                    </Value>
                  </ContextMenu.Item>
                </DropdownMenu.Item> */}
                  </>
                }
              />
              {userPlaylist.accessLevel === "SCHOOL" &&
                userPlaylist.status !== "PUBLISHED" &&
                setPublishUserListId && (
                  <RoundedButtonBase
                    left={<TbSend />}
                    variant="filled"
                    color="blue"
                    onClick={() => setPublishUserListId(userPlaylist.id)}
                  >
                    <Value name="apps.web.src.playlists.components.playlistmodalheader.maak_zichtbaar_voor_collega_s">
                      {`Maak zichtbaar voor collega’s`}
                    </Value>
                  </RoundedButtonBase>
                )}
              {userPlaylist.accessLevel === "KIDS" ? (
                <ContextMenu
                  trigger={
                    <ContextMenuButton left={<TbMoodSmile size={20} />}>
                      <Value name="apps.web.src.playlists.components.playlistmodalheader.kids">
                        Kids
                      </Value>
                    </ContextMenuButton>
                  }
                  items={
                    <>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.LeerlingcodesPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.leerlingcodes">
                              Leerlingcodes
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.IngeleverdeOpdrachtenPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.ingeleverde_opdrachten">
                              Ingeleverde opdrachten
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.KidsPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.kidspagina_preview">
                              Kidspagina preview
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link
                            href="https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-kidspagina"
                            target="_blank"
                          >
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.uitleg_over_de_kidspagina">
                              Uitleg over de Kidspagina
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    </>
                  }
                />
              ) : undefined}
              {userPlaylist.type === "THEME" &&
                userPlaylist.themeGroup?.theme?.category &&
                !isMigrationVisible && (
                  <RoundedButton
                    as={Link}
                    {...Routes.ThemeIndexPage({
                      category: userPlaylist.themeGroup.theme.category.slug,
                      theme: userPlaylist.themeGroup.theme.slug,
                    })}
                  >
                    <Value name="apps.web.src.playlists.components.playlistmodalheader.thema_openen">
                      Thema openen
                    </Value>
                  </RoundedButton>
                )}
              {userPlaylist.type === "LL" &&
                userPlaylist.learningLine &&
                !isMigrationVisible && (
                  <RoundedButton
                    as={Link}
                    {...getLearningLineLinkProps(userPlaylist.learningLine)}
                  >
                    <Value name="apps.web.src.playlists.components.playlistmodalheader.leerlijn_openen">
                      Leerlijn openen
                    </Value>
                  </RoundedButton>
                )}
            </Group>
            <Group gap="12px">
              {userPlaylist.accessLevel === "KIDS" ? (
                <ContextMenuButton as={Link} {...Routes.KidsPage()}>
                  <Value name="apps.web.src.playlists.components.playlistmodalheader.kidspagina_preview">
                    Kidspagina preview
                  </Value>
                </ContextMenuButton>
              ) : undefined}
            </Group>
          </Group>
        </Stack>
        {/*{video}*/}
      </Group>
      <Dialog.Root
        open={isUpdatePlaylistVisible}
        onOpenChange={() => setUpdatePlaylistVisible(false)}
      >
        {isUpdatePlaylistVisible && (
          <UpdateUserListModal
            key={`update-${isUpdatePlaylistVisible}`}
            userList={userPlaylist}
            onClose={() => setUpdatePlaylistVisible(false)}
          />
        )}
      </Dialog.Root>
      <Dialog.Root
        open={isUpdateSchoolListVisible}
        onOpenChange={() => setUpdateSchoolListVisible(false)}
      >
        {isUpdateSchoolListVisible && (
          <UpdateSchoolListModal
            key={`update-${isUpdateSchoolListVisible}`}
            list={userPlaylist}
            onClose={() => setUpdateSchoolListVisible(false)}
          />
        )}
      </Dialog.Root>

      <AlertDialog.Root
        open={isDeletePlaylistVisible}
        onOpenChange={() => setDeletePlaylistVisible(false)}
      >
        <AlertModal
          title={
            <Stack gap="24px">
              <Modal.Title>
                <Value name="playlists.item.verwijderen.titel">
                  Afspeellijst verwijderen?
                </Value>
              </Modal.Title>
              <Text>
                <Value name="apps.web.src.playlists.components.verwijderen.body">
                  Let op! Het verwijderen van een afspeellijst kan niet ongedaan
                  gemaakt worden.
                </Value>
              </Text>
            </Stack>
          }
          cancel={
            <Modal.CancelButton>
              <Value name="playlists.verwijderen.annuleren">
                Nee, annuleer
              </Value>
            </Modal.CancelButton>
          }
          submit={
            <Modal.SubmitButton
              onClick={async () => {
                try {
                  await deletePlaylistMutation({
                    id: userPlaylist.id,
                    mode: undefined,
                  })

                  addToast({
                    content: (
                      <Value name="playlists.verwijderen.success">
                        Afspeellijst verwijderd
                      </Value>
                    ),
                  })

                  await invalidateQuery(getGroupedUserPlaylists)
                  await invalidateQuery(getSchoolLists)
                  await invalidateQuery(getBulletinBoardItems)

                  if (openSchoolList) {
                    setOpenSchoolList(null)
                  }
                } catch (e) {
                  if (e instanceof MoreThanOnePlaylistError) {
                    setDeletePlaylistVisible(false)
                    setDeleteMultiplePlaylistVisible(true)
                  }
                }
              }}
            >
              <Value name="playlists.verwijderen.bevestigen">
                Ja, verwijder
              </Value>
            </Modal.SubmitButton>
          }
        />
      </AlertDialog.Root>
      <AlertDialog.Root
        open={isDeleteMultiplePlaylistVisible}
        onOpenChange={() => setDeleteMultiplePlaylistVisible(false)}
      >
        {isDeleteMultiplePlaylistVisible && (
          <DeleteMultiplePlaylistsModal userList={userPlaylist} />
        )}
      </AlertDialog.Root>
      {userPlaylist && (
        <ShareModal.Controlled
          isOpen={isSharePlaylistVisible}
          onClose={() => setSharePlaylistVisible(false)}
          type="PLAYLIST"
          playlist={userPlaylist}
        />
      )}

      <AddListModalUncontrolled
        isOpen={isListSaveModalOpen}
        setOpen={setListSaveModalOpen}
        scopeBulletinBoard={
          userPlaylist.accessLevel === "GLOBAL"
            ? undefined
            : isSharedWithMe
              ? "NONE"
              : "CURRENT_GROUP"
        }
        playlist={{
          id: addListId(userPlaylist),
          displayTitle: getUserPlaylistTitle(userPlaylist),
        }}
        type={addListType(userPlaylist.accessLevel, userPlaylist.type)}
      />
    </Header>
  )
}
