import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { Value } from "@stringtale/react"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import { parseAsBoolean, parseAsInteger, useQueryState } from "nuqs"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import updateSchoolList from "app/schoolbeheer/mutations/updateSchoolList"
import getSchoolLists from "app/schoolbeheer/queries/getSchoolLists"
import ShareModal from "app/share/components/ShareModal"
import useToast from "app/toasts/hooks/useToast"
import { PageType } from "db"
import { useSetAtom } from "jotai"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX24 } from "theme/sizes"
import AlertModal from "ui/AlertModal"
import Button from "ui/Button"
import Group from "ui/Group"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextLinkBase from "ui/TextLink"
import { MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE } from "../consts"
import { MoreThanOnePlaylistError } from "../errors"
import deleteUserPlaylist from "../mutations/deleteUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { publishUserListIdAtom, unpublishUserListIdAtom } from "../state"
import { VisibilityFilterStates } from "../types"
import { PlaylistModalContent } from "./PlaylistModalContent"
import { Header as HeaderBase } from "./PlaylistModalHeader"
import SchoolListFilters from "./SchoolListFilters"
import SchoolListItem from "./SchoolListItem"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

const Content = styled.div`
  padding: 32px;
  padding-top: 16px;
  overflow: auto;
`

const FilterButton = styled(RoundedButton)`
  border-radius: 8px;
  height: 46px;
  /* padding-left: 11px; */
`

const Header = styled(HeaderBase)`
  font-weight: 700;
`

const BackHeader = styled.div`
  padding: 6px 32px;
  background-color: #f2f2f6;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
`

const TextLink = styled(TextLinkBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 32px 0 0;
`

const ManageSchoollistContent = ({
  grades,
  setNewSchoolListModalOpen,
  setIsAddLessonOpen,
  setIsNewPlaylistOpen,
  setMigrationModalVisibe,
}: {
  grades: GetAllGradesResult
  setNewSchoolListModalOpen: Dispatch<SetStateAction<boolean>>
  setIsAddLessonOpen: Dispatch<SetStateAction<boolean>>
  setIsNewPlaylistOpen: Dispatch<SetStateAction<boolean>>
  setMigrationModalVisibe: Dispatch<SetStateAction<boolean>>
}) => {
  const setPublishedUpdateModalOpen = useSetAtom(publishUserListIdAtom)
  const addToast = useToast()
  const unpublishModalState = usePopoverSeen(
    PageType.SCHOOL_LIST_UNPUBLISH
  ).state
  const setUnpublishUserListId = useSetAtom(unpublishUserListIdAtom)
  const [schoolLists] = useQuery(getSchoolLists, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: 30000,
  })
  const [deletePlaylistMutation] = useMutation(deleteUserPlaylist)
  const [openList, setOpenList] = useQueryState(
    MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE,
    parseAsInteger
  )

  const openedList = useMemo(() => {
    return schoolLists.find((item) => item.id === openList)
  }, [schoolLists, openList])

  const [deletePlaylistId, setDeletePlaylistId] = useState<number | null>(null)
  const [shareUserPlaylist, setShareUserPlaylist] = useState<{
    id: number
    playlist: {
      displayTitle: string | null
    } | null
  } | null>(null)
  const [filteredGrades, setFilteredGrades] = useState<number[]>([])
  const [visibility, setVisibility] = useState<VisibilityFilterStates>(
    VisibilityFilterStates.ALL
  )

  const filteredSchoolLists = useMemo(() => {
    return schoolLists.filter((list) => {
      if (filteredGrades.length !== 0) {
        if (
          !filteredGrades.every((grade) =>
            list.playlist?.grades?.find(
              (playlistGrade) => playlistGrade.id === grade
            )
          )
        ) {
          return false
        }
      }

      if (visibility === VisibilityFilterStates.ALL) {
        return true
      }
      if (visibility === VisibilityFilterStates.PUBLISHED) {
        return list.status === "PUBLISHED"
      }
      if (visibility === VisibilityFilterStates.UNPUBLISHED) {
        return list.status !== "PUBLISHED"
      }
    })
  }, [filteredGrades, schoolLists, visibility])

  if (openList && openedList?.id) {
    return (
      <>
        <BackHeader>
          <Button onClick={() => setOpenList(null)}>
            <Group gap="8px" align="center">
              <FaChevronLeft color={DARK_BLUE_COLOR} size={12} />
              <Text color={DARK_BLUE_COLOR} bold size="13px">
                <Value name="apps.web.src.playlists.components.manageschoollistcontent.terug_naar_beheer_alle_schoollijsten">
                  Terug naar beheer alle schoollijsten
                </Value>
              </Text>
            </Group>
          </Button>
        </BackHeader>
        <PlaylistModalContent
          id={openedList.id}
          grades={grades}
          onClose={() => setOpenList(null)}
          setIsNewPlaylistOpen={setIsNewPlaylistOpen}
          setIsAddLessonOpen={setIsAddLessonOpen}
          setMigrationModalVisibe={setMigrationModalVisibe}
        />
      </>
    )
  }

  return (
    <Stack grow overflow="hidden">
      <Header color="#F5F5F5">
        <Group justify="space-between">
          <Text color={DARK_BLUE_COLOR} size={PX24}>
            <Value name="apps.web.src.playlists.components.manageschoollistcontent.beheer_alle_schoollijsten">
              Beheer alle schoollijsten
            </Value>
          </Text>
          <TextLink
            color={DARK_BLUE_COLOR}
            size={PX24}
            onClick={() => setNewSchoolListModalOpen(true)}
          >
            <Value name="apps.web.src.playlists.components.manageschoollistcontent.nieuwe_schoollijst">
              Nieuwe schoollijst
            </Value>
            <TbPlus />
          </TextLink>
        </Group>
      </Header>
      <Content>
        <Stack>
          <Stack gap="16px">
            <Group gap={"8px"}>
              <SchoolListFilters
                allGrades={grades}
                filteredGrades={filteredGrades}
                setFilteredGrades={setFilteredGrades}
                visibility={visibility}
                setVisibility={setVisibility}
              />
            </Group>
            <Hr />
          </Stack>
          {filteredSchoolLists.map((list) => (
            <div>
              <SchoolListItem
                list={list}
                // onEdit={() => setUpdateModalItem(list)}
                // onAddLesson={() => setAddLesModalItem(list)}
                onDelete={async () => {
                  setDeletePlaylistId(list.id)
                }}
                onShare={() => setShareUserPlaylist(list)}
                /* onUnarchive={async () => {
                  await updateSchoolListMutation({
                    id: list.id,
                    status: "DRAFT",
                  })
                  await invalidateQuery(getSchoolLists)
                }} */
                onPublish={() => setPublishedUpdateModalOpen(list.id)}
                onUnpublish={() => setUnpublishUserListId(list.id)}
              />
              <Hr />
            </div>
          ))}
          {/* <BulletinAddSchoollistItem list={undefined} bulletinBoardItems={[]} /> */}
          {/* {items.length > 0 ? (
            <>
              <Hr />
              {items.map((item) => (
                <Stack>
                  <Item item={item} />
                </Stack>
              ))}
            </>
          ) : (
            <ContentPadding>
              <Title size={PX14}>
                <Value name="apps.web.src.playlists.components.sharecontent.empty_state_title">
                  Er is nog niets met je gedeeld...
                </Value>
              </Title>
            </ContentPadding>
          )} */}
        </Stack>
      </Content>
      {deletePlaylistId && (
        <AlertDialog.Root
          open={!!deletePlaylistId}
          onOpenChange={() => setDeletePlaylistId(null)}
        >
          <AlertModal
            title={
              <Stack gap="24px">
                <Modal.Title>
                  <Value
                    name="apps.web.src.playlists.components.manageschoollistcontent.schoollijst_verwijderen"
                    version="1"
                  >
                    Weet je deze dat je deze schoollijst wil verwijderen?
                  </Value>
                </Modal.Title>
                <Text>
                  <Value name="apps.web.src.playlists.components.manageschoollistcontent.let_op_het_verwijderen_van_een_schoollijst_kan_niet_ongedaan_gemaakt_worden">
                    Let op! Het verwijderen van een schoollijst kan niet
                    ongedaan gemaakt worden.
                  </Value>
                </Text>
              </Stack>
            }
            cancel={
              <Modal.CancelButton>
                <Value name="playlists.verwijderen.annuleren">
                  Nee, annuleer
                </Value>
              </Modal.CancelButton>
            }
            submit={
              <Modal.SubmitButton
                onClick={async () => {
                  try {
                    await deletePlaylistMutation({
                      id: deletePlaylistId,
                      mode: undefined,
                    })
                    addToast({
                      content: (
                        <Value name="playlists.verwijderen.success">
                          Afspeellijst verwijderd
                        </Value>
                      ),
                    })
                    await invalidateQuery(getGroupedUserPlaylists)
                    await invalidateQuery(getSchoolLists)
                    await invalidateQuery(getBulletinBoardItems)
                  } catch (e) {
                    if (e instanceof MoreThanOnePlaylistError) {
                      setDeletePlaylistId(null)
                    }
                  }
                }}
              >
                <Value name="playlists.verwijderen.bevestigen">
                  Ja, verwijder
                </Value>
              </Modal.SubmitButton>
            }
          />
        </AlertDialog.Root>
      )}
      {shareUserPlaylist && (
        <ShareModal.Controlled
          isOpen={!!shareUserPlaylist}
          onClose={() => setShareUserPlaylist(null)}
          type="PLAYLIST"
          playlist={shareUserPlaylist}
        />
      )}
    </Stack>
  )
}

export default ManageSchoollistContent
