import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react/dist"
import useToast from "app/toasts/hooks/useToast"
import { useState } from "react"
import { GREEN_COLOR, LIST_COLORS } from "theme/colors"
import {
  Buttons,
  CancelButton,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
} from "ui/Modal"
import Stack from "ui/Stack"
import updateSchoolPlaylist from "../../mutations/updateSchoolList"
import getSchoolLists, {
  GetSchoolListsResult,
} from "../../queries/getSchoolLists"
import createFormFactory, {
  convertNullToUndefined,
} from "utils/form/createForm"
import { UpdateSchoolListInput } from "app/schoolbeheer/validation"
import FieldArea from "app/core/components/FieldArea"
import Color, { ColorGroup } from "app/playlists/components/Color"
import Field from "app/core/components/Field"
import { Grade, Lesson, Playlist } from "db"
import styled from "@emotion/styled"
import Grades, { GradeGroup, Root as GradesCheckbox } from "../groups/Grades"
import getAllGrades from "app/grades/queries/getAllGrades"
import toggleArray from "utils/global/toggleArray"
import getUserList from "app/playlists/queries/getUserList"
import getGroupedUserPlaylists from "app/playlists/queries/getGroupedUserPlaylists"

const FormLabel = styled.label`
  font-weight: 600;
`

const form = createFormFactory({
  validationSchema: UpdateSchoolListInput,
})

const UpdatePlaylistModal = ({
  onClose,
  list,
}: {
  onClose: (open?: boolean) => void
  list: {
    id: number
    playlist: { color: Playlist["color"]; grades: Grade[] } | null
  }
}) => {
  const [updateSchoolListMutation] = useMutation(updateSchoolPlaylist)
  const [serverErrors, setServerErrors] = useState()
  const addToast = useToast()
  const [color, setColor] = useState(list.playlist?.color || GREEN_COLOR)
  const [activeGrades, setActiveGrades] = useState<number[]>(
    list.playlist && list.playlist.grades.length > 0
      ? list.playlist.grades.map((el) => el.id)
      : []
  )
  const [grades] = useQuery(getAllGrades, undefined)

  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <form.Form
            defaultValues={
              list.playlist
                ? convertNullToUndefined({
                    ...list.playlist,
                    grades: list.playlist.grades.map((grade) => grade.id),
                  })
                : {}
            }
            noValidate
            onSubmit={async (data) => {
              try {
                await updateSchoolListMutation({
                  id: list.id,
                  displayTitle: data.displayTitle as string,
                  description: data.description as string,
                  color,
                  grades: activeGrades,
                })
                onClose(true)
                addToast({
                  content: (
                    <>
                      <Value name="playlist.nieuw.aangepast.pre">
                        Afspeellijst
                      </Value>{" "}
                      &quot;{data.displayTitle}&quot;{" "}
                      <Value name="playlist.nieuw.aangepast.post">
                        aangepast
                      </Value>
                    </>
                  ),
                })
                await invalidateQuery(getUserList)
                await invalidateQuery(getSchoolLists)
                await invalidateQuery(getGroupedUserPlaylists)
              } catch (e) {
                setServerErrors(e)
              }
            }}
          >
            <Content>
              <Stack gap="24px">
                <Dialog.Title>
                  <Value name="apps.web.src.schoolbeheer.components.schoollists.updateschoollistmodal.afspeellijst_aanpassen">
                    Afspeellijst aanpassen
                  </Value>
                </Dialog.Title>
                <Stack gap={"6px"}>
                  <FormLabel>
                    <Value
                      name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.leerjaar_van_de_schoollijst"
                      version="1"
                    >
                      Leerjaren van de schoollijst
                    </Value>
                  </FormLabel>
                  <GradeGroup>
                    {grades.map((grade) => (
                      <GradesCheckbox
                        name="grades[]"
                        key={grade.id}
                        value={`${grade.id}`}
                        checked={activeGrades.includes(grade.id)}
                        onCheckedChange={() =>
                          setActiveGrades(toggleArray(grade.id))
                        }
                      >
                        {grade.number}
                      </GradesCheckbox>
                    ))}
                  </GradeGroup>
                </Stack>
                <Field
                  name="displayTitle"
                  label={
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.updateschoollistmodal.naam">
                      Naam
                    </Value>
                  }
                  form={form}
                />
                <FieldArea
                  name="description"
                  label={
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.updateschoollistmodal.omschrijving">
                      Omschrijving
                    </Value>
                  }
                  form={form}
                />
                <ColorGroup onValueChange={setColor} value={color}>
                  {LIST_COLORS.map((color) => (
                    <Color value={color} />
                  ))}
                </ColorGroup>
                <Buttons>
                  <CancelButton type="button" onClick={() => onClose()}>
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.updateschoollistmodal.annuleren">
                      Annuleren
                    </Value>
                  </CancelButton>
                  <Form.Submit asChild>
                    <SubmitButton type="submit">
                      <Value name="apps.web.src.schoolbeheer.components.schoollists.updateschoollistmodal.opslaan">
                        Opslaan
                      </Value>
                    </SubmitButton>
                  </Form.Submit>
                </Buttons>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </form.Form>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default UpdatePlaylistModal
