export type learningLineSubscriptionProps =
  | {
      isDramaHidden: boolean
      isDanceHidden: boolean
    }
  | undefined

export default function hasOnlyOneLearningLine(
  subscription: learningLineSubscriptionProps
) {
  if (!subscription) {
    return false
  }
  return subscription.isDramaHidden && subscription.isDanceHidden
}
