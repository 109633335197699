import styled from "@emotion/styled"
import Group from "./Group"


const Stack = styled(Group)`
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  ${(p) => p.grow && `overflow-y: hidden;`}
  ${(p) => p.overflow && `overflow: ${p.overflow};`}
`

export default Stack