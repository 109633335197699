import { useQuery } from "@blitzjs/rpc"
import getCurrentYear from "../queries/getCurrentYear"

export const useCurrentYear = () => {
  const [currentYear] = useQuery(getCurrentYear, {
    refetchOnReconnect: false,
  })

  return currentYear
}
