import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Layout from "app/core/layouts/Layout"
import * as Header from "app/header/components/Header"
import {
  DARK_BLUE_COLOR,
  ORANGE_COLOR,
  SYSTEM_BLUE,
  GREEN_COLOR,
  SYSTEM_YELLOW,
} from "theme/colors"
import { poppins } from "theme/fonts"
import { PX14, PX18 } from "theme/sizes"
import ContainerBase from "ui/Container"
import Group from "ui/Group"
import RoundedButton from "ui/RoundedButton"
import { GetBlueprintResult } from "./queries/getBlueprint"
import Title from "ui/Title"

const Container = styled(ContainerBase)`
  padding-top: 40px;
`
export const BlueprintLabel = styled.div<{
  blueprint: Omit<NonNullable<GetBlueprintResult>, "blueprints"> & {
    blueprints?: NonNullable<GetBlueprintResult>["blueprints"] | null
  }
}>`
  color: ${(p) =>
    p.blueprint.blueprints && p.blueprint.blueprints[0]
      ? p.blueprint.blueprints[0]?.state === "CONCEPT"
        ? DARK_BLUE_COLOR
        : "white"
      : "white"};
  font-family: ${poppins.style.fontFamily};
  font-weight: 600;
  font-size: ${PX14};
  padding: 2px 6px;
  background-color: ${(p) =>
    p.blueprint.blueprints && p.blueprint.blueprints[0]
      ? p.blueprint.blueprints[0]?.state === "CONCEPT"
        ? SYSTEM_YELLOW
        : GREEN_COLOR
      : SYSTEM_BLUE};
  border-radius: 4px;
`

export default function SchooljaarLayout({
  children,
  title,
  blueprint,
  hideCloseButton,
  onCloseButton,
}: {
  children: React.ReactNode
  title?: string
  blueprint:
    | (Omit<NonNullable<GetBlueprintResult>, "blueprints"> & {
        blueprints?: NonNullable<GetBlueprintResult>["blueprints"] | null
      })
    | null
  hideCloseButton?: Boolean
  onCloseButton?: () => void
}) {
  return (
    <Layout
      title={
        title
          ? title
          : blueprint
            ? `Schooljaarovergang ${blueprint.displayTitle}`
            : "Schooljaarovergang"
      }
    >
      <Header.Root>
        <Header.Content>
          <Group align="center" gap="44px">
            <Header.Logo />
            <Group align="center" gap="6px">
              <Title as="span" size={PX18}>
                <Value
                  name="apps.web.src.schooljaren.layout.schooljaar_overgang_header_title"
                  format={{ year: blueprint ? blueprint.displayTitle : "" }}
                >
                  {`Schooljaarovergang {year}`}
                </Value>
              </Title>
              {blueprint && blueprint.blueprints && (
                <BlueprintLabel blueprint={blueprint}>
                  {!blueprint.blueprints[0] && (
                    <Value name="apps.web.src.schooljaren.layout.label.nieuw">
                      {`Nieuw`}
                    </Value>
                  )}
                  {blueprint.blueprints[0]?.state === "CONCEPT" && (
                    <Value name="apps.web.src.schooljaren.layout.label.concept">
                      {`Concept`}
                    </Value>
                  )}
                  {blueprint.blueprints[0]?.state === "PUBLISHED" && (
                    <Value name="apps.web.src.schooljaren.layout.label.voltooid">
                      {`Voltooid`}
                    </Value>
                  )}
                </BlueprintLabel>
              )}
            </Group>
          </Group>
          {!hideCloseButton && (
            <Group align="center">
              <RoundedButton onClick={onCloseButton}>
                <Value name="apps.web.src.schooljaren.layout.sluiten">
                  Sluiten
                </Value>
              </RoundedButton>
            </Group>
          )}
        </Header.Content>
      </Header.Root>
      <Container style={{ paddingBottom: 120 }}>{children}</Container>
    </Layout>
  )
}

SchooljaarLayout.suppressFirstRenderFlicker = true
