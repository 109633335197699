
export default function getDataFromFormData(formData: FormData) {
  let data = {}

  for (const [key, value] of formData.entries()) {
    if (key.indexOf("[]") === key.length - 2) {
      const keyWithoutBrackets = key.slice(0, -2)
      if (!data[keyWithoutBrackets]) {
        data[keyWithoutBrackets] = []
      }
      data[keyWithoutBrackets].push(value)
    } else {
      data[key] = value
    }
  }

  return data
}