import { RouteUrlObject } from "blitz"

export const addQueryParams = (query: RouteUrlObject, params: Record<string, string>) => {
  const searchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value)
  })
  return {
    ...query,
    href: query.href + "?" + searchParams.toString()
  }
}
