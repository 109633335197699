import { EditorThemeClasses } from "lexical"
// import { css } from "@emotion/css"
import { ClassNames, ClassNamesContent } from "@emotion/react"
import { useMemo } from "react"
import { ORANGE_COLOR } from "theme/colors"
import { LinkStyleType } from "./types"

export const createTheme = (css, linkStyle?: LinkStyleType) => {
  const listBefore = css({
    content: '""',
    width: "16px",
    height: "16px",
    top: "2px",
    left: "0",
    cursor: "pointer",
    display: "block",
    backgroundSize: "cover",
    position: "absolute",
  })

  const theme: EditorThemeClasses = {
    indent: css({
      paddingInlineStart: "24px !important",
    }),
    paragraph: css({
      margin: "0",
      position: "relative",
    }),
    heading: {
      h1: css({
        fontSize: "36px",
        fontWeight: "900",
      }),
      h2: css({
        fontSize: "32px",
        fontWeight: "900",
      }),
      h3: css({
        fontSize: "28px",
        fontWeight: "900",
      }),
      h4: css({
        fontSize: "24px",
        fontWeight: "900",
      }),
      h5: css({
        fontSize: "20px",
        fontWeight: "700",
      }),
    },
    text: {
      bold: css({
        fontWeight: "bold",
      }),
      code: css({}),
      italic: css({
        fontStyle: "italic",
      }),
      strikethrough: css({
        textDecoration: "line-through",
      }),
      subscript: css({
        verticalAlign: "sub",
      }),
      superscript: css({
        verticalAlign: "super",
      }),
      underline: css({
        textDecoration: "underline",
      }),
      underlineStrikethrough: css({
        textDecoration: "underline line-through",
      }),
    },
    image: css`
      cursor: default;
      display: inline-block;
      position: relative;
      user-select: none;
    `,
    table: css({
      borderCollapse: "collapse",
      borderSpacing: "0",
      maxWidth: "100%",
      overflowY: "scroll",
      tableLayout: "fixed",
      width: "100%",
    }),
    tableCell: css({
      border: "1px solid black",
      padding: "6px 8px",
      minWidth: "75px",
      verticalAlign: "top",
      textAlign: "start",
    }),
    tableCellHeader: css({
      backgroundColor: "#f2f3f5",
      textAlign: "start",
    }),
    list: {
      listitem: css({
        margin: "8px",
      }),
      listitemChecked: css({
        position: "relative",
        marginLeft: "8px",
        marginRight: "8px",
        paddingLeft: "24px",
        paddingRight: "24px",
        listStyleType: "none",
        outline: "none",
        textDecoration: "line-through",

        "&:before": {
          ...listBefore,
          border: "1px solid rgb(61, 135, 245)",
          borderRadius: "2px",
          backgroundColor: "#3d87f5",
          backgroundRepeat: "no-repeat",
        },
      }),
      listitemUnchecked: css({
        position: "relative",
        marginLeft: "8px",
        marginRight: "8px",
        paddingLeft: "24px",
        paddingRight: "24px",
        listStyleType: "none",
        outline: "none",
        "&:before": {
          ...listBefore,
          border: "1px solid #999",
          borderRadius: "2px",
        },
        "&:after": {
          content: '""',
          cursor: "pointer",
          borderColor: "#fff",
          borderStyle: "solid",
          position: "absolute",
          display: "block",
          top: "6px",
          width: "3px",
          left: "7px",
          height: "6px",
          transform: "rotate(45deg)",
          borderWidth: "0 2px 2px 0",
        },
      }),
      nested: {
        listitem: css({
          listStyleType: "none",
          "&:before, &:after": {
            display: "none",
          },
        }),
      },
      olDepth: [
        css({
          padding: "0",
          margin: "0",
          marginLeft: "16px",
          // listStylePosition: "inside",
        }),
        css({
          padding: "0",
          margin: "0",
          marginLeft: "16px",
          listStyleType: "upper-alpha",
          // listStylePosition: "inside",
        }),
        css({
          padding: "0",
          margin: "0",
          marginLeft: "16px",
          listStyleType: "lower-alpha",
          // listStylePosition: "inside",
        }),
        css({
          padding: "0",
          margin: "0",
          marginLeft: "16px",
          listStyleType: "upper-roman",
          // listStylePosition: "inside",
        }),
        css({
          padding: "0",
          margin: "0",
          marginLeft: "16px",
          listStyleType: "lower-roman",
          // listStylePosition: "inside",
        }),
      ],
      ul: css({
        padding: "0",
        margin: "0",
        marginLeft: "16px",
        listStylePosition: "inside",
        listStyleType: "none",

        li: css({
          "&:before": {
            content: '">"',
            fontWeight: "bold",
            color: ORANGE_COLOR,
            position: "relative",
            marginLeft: "-0.6em",
            left: "-15px",
          },
        }),
      }),
    },
    link:
      linkStyle === "orange"
        ? css({
            color: ORANGE_COLOR,
            fontWeight: "bold",
          })
        : linkStyle === "bold" ? css({
            fontWeight: "bold",
          }) : undefined,
  }
  return theme
}

export function Theme({
  children,
  linkStyle,
}: {
  children: (theme: EditorThemeClasses) => JSX.Element
  linkStyle?: LinkStyleType
}) {
  return (
    <ClassNames>{(p) => children(createTheme(p.css, linkStyle))}</ClassNames>
  )
}
