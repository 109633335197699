import styled from "@emotion/styled"
import * as Checkbox from "@radix-ui/react-checkbox"
import { Grade } from "db"
import { DARK_BLUE_COLOR, LIGHT_GREY_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"

export const GradeGroup = styled.div`
  display: flex;
  gap: 8px;
`

export const Root = styled(Checkbox.Root)`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid ${DARK_BLUE_COLOR};
  padding: 4px;
  box-sizing: border-box;
  font-size: ${PX14};

  &[data-state="checked"] {
    border: 1px solid ${ORANGE_COLOR};
    background-color: ${ORANGE_COLOR};
    color: white;
    font-weight: 600;
  }
`

const Grades = ({
  grades,
  activeGrades,
}: {
  grades: Grade[]
  activeGrades?: Grade[]
}) => {
  return (
    <GradeGroup>
      {grades.map((grade) => (
        <Root
          name="grades[]"
          key={grade.id}
          value={`${grade.id}`}
          defaultChecked={activeGrades?.some((g) => g.id === grade.id)}
        >
          {grade.number}
        </Root>
      ))}
    </GradeGroup>
  )
}

export default Grades
