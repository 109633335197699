import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import FollowPlaylistToGroupModal from "app/playlists/components/FollowPlaylistToGroupModal"
import toggleFollowLearningLinePlaylist from "../mutations/followLearningLinePlaylist"
import getLearningLine from "../queries/getLearningLine"

type Props = {
  learningLineId: number
  onClose: () => void
}

export default function FollowLearningLineToGroupModal({
  learningLineId,
  onClose,
}: Props) {
  const [toggleFollowThemePlaylistMutation] = useMutation(
    toggleFollowLearningLinePlaylist
  )
  return (
    <FollowPlaylistToGroupModal
      learningLineId={learningLineId}
      onClose={onClose}
      onSubmit={async (groupIds) => {
        await toggleFollowThemePlaylistMutation({
          learningLinePageId: learningLineId,
          groupIds,
        })
        await invalidateQuery(getLearningLine)
      }}
    />
  )
}
