import { keyframes } from "@emotion/css"
import styled from "@emotion/styled"
import { GREEN_COLOR } from "theme/colors"

const Loader = styled.div<{ color?: string, size?: string }>`
  border: 4px solid ${p => p.color || GREEN_COLOR}30;
  border-top-color: ${p => p.color || GREEN_COLOR};
  border-radius: 50%;
  width: ${p => p.size || "24px"};
  height: ${p => p.size || "24px"};
  animation: ${keyframes`
  to {
    transform: rotate(360deg);
  }
  `} 500ms linear infinite;
`

export default Loader
