import { UserListAccessLevel, UserListType } from "db"
import { Value } from "@stringtale/react"

export default function getListTypeDisplayTitle(
  accessLevel: UserListAccessLevel,
  type: UserListType
) {
  switch (accessLevel) {
    case "PERSONAL":
      return (
        <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.priv_lijst" version="1">
          Mijn lijst
        </Value>
      )
    case "GROUP":
      return (
        <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.groepslijst">
          Groepslijst
        </Value>
      )
    case "KIDS":
      return (
        <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.kidspagina_lijst" version="1">
          Kidslijst
        </Value>
      )
    case "SCHOOL":
      return (
        <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.schoollijst" version="1">
          {/* HTML */ `Schoollijst (zichtbaar voor collega's))`}
        </Value>
      )
    case "GLOBAL":
      if (type === "LL") {
        return (
          <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.leerlijn_lijst">
            Leerlijn lijst
          </Value>
        )
      }
      return (
        <Value name="apps.web.src.playlists.utils.getlisttypedisplaytitle.123zing_lijst" version="1">
          Thema lijst
        </Value>
      )
  }
}
