import { Grade } from "db"

let userGroups = {
  userGroup0: false,
  userGroup1: false,
  userGroup2: false,
  userGroup3: false,
  userGroup4: false,
  userGroup5: false,
  userGroup6: false,
  userGroup7: false,
  userGroup8: false,
} as const

// Define the type of the userGroups
export type UserGroupsObject = {
  [K in keyof typeof userGroups]: boolean
}

export default function getAnalyticsUserGroups<UserGroupsObject>(
  grades?: Grade[] | null
) {
  if (!grades) return userGroups
  let userUserGroups = {}

  grades.forEach((grade) => {
    const groupKey = `userGroup${grade.number}`
    userUserGroups[groupKey] = true
  })

  for (const uG in userGroups) {
    if (!userUserGroups[uG]) {
      userUserGroups[uG] = false
    }
  }

  return userUserGroups
}
