import styled from "@emotion/styled"
import { Value } from "@stringtale/react/dist"
import Input from "ui/Input"
import { Form } from "utils/form/createForm"
import { Slot } from "@radix-ui/react-slot"
import Stack from "ui/Stack"
import { ComponentProps, ReactNode } from "react"
import Text from "ui/Text"
import { RED_COLOR } from "theme/colors"

const FormLabel = styled.label`
  font-weight: 600;
`
const FormMessage = styled(Text)`
  color: ${RED_COLOR};
`

export type FieldProps<TForm extends Form<any>> = {
  name: keyof TForm["validationSchema"]["_output"]
  label?: ReactNode
  form: TForm
  asChild?: boolean
  // children?: React.ReactNode
} & Omit<ComponentProps<typeof Input>, "form">

const Field = <TForm extends Form<any>>({
  label,
  name,
  form,
  asChild,
  ...props
}: FieldProps<TForm>) => {
  const Comp = asChild ? Slot : Input
  return (
    <form.Field name={name}>
      <Stack gap="5px">
        {label ? (
          <form.Label asChild>
            <FormLabel>
              {label}
            </FormLabel>
          </form.Label>
        ) : null}
        <form.Control>
          <Comp {...props}></Comp>
        </form.Control>
        <form.Message
          render={(issue) => {
            return (
              <FormMessage>
                {/* <Value
                  name={`${translatePrefix}.errors.${name as string}.${
                    issue.code
                  }`}
                > */}
                  {issue.message}
                {/* </Value> */}
              </FormMessage>
            )
          }}
        />
      </Stack>
    </form.Field>
  )
}

export default Field
