import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import { PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetBulletinAddGroupListItemsResult } from "../queries/getBulletinAddGroupListItems"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import BulletinAddListItem from "./BulletinAddListItem"

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const BulletinAddGroupListItem = ({
  groupPlaylist,
  bulletinBoardItems,
}: {
  groupPlaylist: GetBulletinAddGroupListItemsResult[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const activeGrades = useMemo(() => {
    const grades: GetAllGradesResult = []
    groupPlaylist.playlist?.lessons.map((lesson) =>
      lesson.lesson.grades.map((grade) =>
        grades.includes(grade) ? null : grades.push(grade)
      )
    )
    return grades
  }, [groupPlaylist])

  const currentBulletinBoardList = bulletinBoardItems.find(
    (item) =>
      (item.type === BulletinBoardItemType.GROUP_LIST ||
        item.type === BulletinBoardItemType.PERSONAL_LIST ||
        item.type === BulletinBoardItemType.KIDS_LIST) &&
      item.playlistId === groupPlaylist.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          groupPlaylist.accessLevel === "GROUP" ? (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.groepslijst">
              Groepslijst
            </Value>
          ) : groupPlaylist.accessLevel === "KIDS" ? (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.kidslijst">
              Kidslijst
            </Value>
          ) : groupPlaylist.accessLevel === "PERSONAL" ? (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.mijn_lijst">
              Mijn lijst
            </Value>
          ) : (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.lijst">
              Lijst
            </Value>
          )
        }
        title={groupPlaylist.playlist?.displayTitle}
        // description={groupPlaylist.description}
        activeGrades={activeGrades}
        image={
          <>
            {groupPlaylist.playlist?.lessons ? (
              <ImageGrid>
                {groupPlaylist.playlist?.lessons.map((lesson) =>
                  lesson.lesson.cardImage ? (
                    <Image
                      alt=""
                      file={lesson.lesson.cardImage}
                      key={lesson.lesson.id}
                      width={115}
                      height={65}
                    />
                  ) : (
                    <EmptyContainer>
                      <EmptyImage />
                    </EmptyContainer>
                  )
                )}
                {groupPlaylist.playlist?.lessons &&
                  [...Array(4 - groupPlaylist.playlist?.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {groupPlaylist.owner && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.gemaakt_door">
                        Gemaakt door
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Avatar
                          user={groupPlaylist.owner}
                          width={20}
                          height={20}
                        />
                        <Text size={PX12}>{groupPlaylist.owner.firstName}</Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <BulletinAddButton
                  addProps={{
                    playlistId: groupPlaylist.id,
                    type:
                      groupPlaylist.accessLevel === "PERSONAL"
                        ? BulletinBoardItemType.PERSONAL_LIST
                        : groupPlaylist.accessLevel === "KIDS"
                          ? BulletinBoardItemType.KIDS_LIST
                          : BulletinBoardItemType.GROUP_LIST,
                  }}
                  deleteProps={
                    currentBulletinBoardList && {
                      id: currentBulletinBoardList.id,
                    }
                  }
                  currentBulletinBoardItem={currentBulletinBoardList}
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddGroupListItem
