import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FormattedTimeType } from "../types"
import useDuration from "../hooks/useDuration"
import useCurrentTime from "../hooks/useCurrentTime"

const Time = styled.div`
  font-size: 18px;
  display: flex;
`

const Text = styled.div`
  font-size: 18px;
`

export const Segment = styled.div<{ side: "left" | "middle" | "right" }>`
  display: flex;
  ${(props) => {
    switch (props.side) {
      case "left":
        return css`
          justify-content: flex-end;
        `
      case "middle":
        return css`
          justify-content: center;
        `
      case "right":
        return css`
          justify-content: flex-start;
        `
    }
  }};
`

const TimeRoot = styled.div<{ color?: string }>`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  color: ${(props) => (props.color ? props.color : "white")};
`

type Props = {
  color?: string
}

export default function TimeDisplay({
  ...props
}: Props) {
  const duration = useDuration()
  const currentTime = useCurrentTime()

  return (
    <TimeRoot color={props.color}>
      <Segment side="left">
        <Time>
          <Segment side="left">{currentTime?.minutes}</Segment>
          <Segment side="middle">:</Segment>
          <Segment side="right">
            {Math.floor(currentTime?.seconds).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Segment>
        </Time>
      </Segment>
      <Segment side="middle">
        <Text>/</Text>
      </Segment>
      <Segment side="right">
        <Time>
          <Segment side="left">{duration?.minutes}</Segment>
          <Segment side="middle">:</Segment>
          <Segment side="right">
            {Math.floor(duration?.seconds).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Segment>
        </Time>
      </Segment>
    </TimeRoot>
  )
}
