import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { Value, htmlFormatTags } from "@stringtale/react"
import { GetOrganizationUsersResult } from "app/organizations/queries/getOrganizationUsers"
import useToast from "app/toasts/hooks/useToast"
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useMemo,
  useRef,
  useState,
} from "react"
import Group from "ui/Group"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextAreaBase from "ui/TextArea"
import share from "../mutations/share"
import getShareUsers from "../queries/getShareUsers"
import { GroupItem } from "./GroupItem"
import Tab from "./Tab"
import { UserItem } from "./UserItem"
import getShareTitle from "app/playlists/utils/getShareTitle"
import {
  Content,
  Header,
  Input,
  InputWrapper,
  Root,
  SelectAllWrapper,
} from "ui/FilterSelect"
import Checkbox from "ui/Checkbox"
import getFullname from "utils/users/getFullName"

export type Groups = GetOrganizationUsersResult[number]["groups"]

const TextArea = styled(TextAreaBase)`
  height: 63px;
`

const ModalRoot = styled(Modal.Root)`
  overflow-y: auto;
`

const ModalContent = styled(Modal.Content)`
  height: clamp(545px, calc(100vh - 40px), 800px);
  min-height: 545px;
`

const ScrollArea = styled(Stack)`
  overflow-y: auto;
  flex: 1;
`

// const form = createFormFactory({ validationSchema: Share })

const emptyArray: any[] = []

type Props =
  | {
      type: "LESSON"
      lesson: {
        id: number
        displayTitle: string | null
      }
    }
  | {
      type: "PLAYLIST"
      playlist: {
        id: number
        playlist: {
          displayTitle: string | null
        } | null
      }
    }
  | {
      type: "THEME"
      themeGroup: {
        id: number
        globalPlaylist: {
          playlist: {
            displayTitle: string | null
          } | null
        } | null
      }
    }
  | {
      type: "LL"
      learningLine: {
        id: number
        displayTitle: string | null
      }
    }

const getShareProps = (props: Props) => {
  switch (props.type) {
    case "LESSON":
      return {
        type: "LESSON",
        lessonId: props.lesson.id,
      } as const
    case "PLAYLIST":
      return {
        type: "PLAYLIST",
        playlistId: props.playlist.id,
      } as const
    case "THEME":
      return {
        type: "THEME",
        themeGroupId: props.themeGroup.id,
      } as const
    case "LL":
      return {
        type: "LL",
        learningLineId: props.learningLine.id,
      } as const
  }
}

export default function ShareModal(props: Props) {
  const [users] = useQuery(getShareUsers, getShareProps(props))
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [state, setState] = useState<"users" | "groups">("users")
  const [shareMutation] = useMutation(share)
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const addToast = useToast()
  const { groups, usersPerGroup } = useMemo(() => {
    const groups: Groups = []
    const usersPerGroup: Map<number, GetOrganizationUsersResult> = new Map()
    users.forEach((user) => {
      user.groups.forEach((group) => {
        if (!groups.find((g) => g.id === group.id)) {
          groups.push(group)
        }
        const groupArray = usersPerGroup.get(group.id)
        if (!groupArray) {
          usersPerGroup.set(group.id, [user])
        } else {
          groupArray.push(user)
        }
      })
    })
    groups.sort((a, b) => a.displayTitle.localeCompare(b.displayTitle))
    return { groups, usersPerGroup }
  }, [users])

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [search, setSearch] = useState("")

  return (
    <AlertDialog.Portal>
      <AlertDialog.Overlay asChild>
        <Modal.Overlay isAlert />
      </AlertDialog.Overlay>
      <AlertDialog.Content asChild>
        <ModalRoot isAlert width="646px">
          <ModalContent>
            {/* <form.Form
              defaultValues={{
                type: props.type,
                playlistId:
                  props.type === "PLAYLIST" ? props.playlist.id : undefined,
                lessonId: props.type === "LESSON" ? props.lesson.id : undefined,
                users: selectedUsers,
              }}
              onSubmit={async (data) => {
                
              }}
            > */}
            <Stack gap="24px" grow>
              <Stack gap="10px" grow>
                <Modal.Title>
                  <Value name="apps.web.src.share.components.sharemodal.titel">
                    Delen
                  </Value>
                </Modal.Title>
                <Text>
                  <Value name="apps.web.src.share.components.sharemodal.body" version="13"
                  >
                    Deel met een (of meerdere) collega(‘s).
                  </Value>
                </Text>
                <Group gap="2px">
                  <Tab
                    isActive={state === "users"}
                    onClick={() => setState("users")}
                  >
                    <Value name="apps.web.src.share.components.sharemodal.gebruikers">
                      Gebruikers
                    </Value>
                  </Tab>
                  <Tab
                    isActive={state === "groups"}
                    onClick={() => setState("groups")}
                  >
                    <Value name="apps.web.src.share.components.sharemodal.groepen">
                      Groepen
                    </Value>
                  </Tab>
                </Group>
                <Root>
                  <Header align="center">
                    <SelectAllWrapper>
                      <Checkbox
                        label={
                          <Value name="apps.web.src.themes.components.selectgroupmodal.selecteer_alles" version="1">
                            Alles
                          </Value>
                        }
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedUsers(users.map((g) => g.user.id))
                          } else {
                            setSelectedUsers([])
                          }
                        }}
                      />
                    </SelectAllWrapper>
                    <InputWrapper>
                      <Input
                        // autoFocus
                        inputRef={inputRef}
                        placeholder={
                          state === "users"
                            ? "Zoek een gebruiker"
                            : "Zoek een groep"
                        }
                        defaultValue={search || ""}
                        onChange={(e) => {
                          setSearch(e.currentTarget.value)
                        }}
                      />
                    </InputWrapper>
                  </Header>
                  <ScrollArea grow>
                    <Content>
                      <Stack>
                        {state === "users"
                          ? users.map((user) => {
                              if (
                                getFullname(user.user)
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) === false
                              ) {
                                return null
                              }
                              return (
                                <UserItem
                                  key={user.id}
                                  user={user}
                                  setSelectedUsers={setSelectedUsers}
                                  selectedUsers={selectedUsers}
                                />
                              )
                            })
                          : groups.map((group) => {
                              if (
                                group.displayTitle
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) === false
                              ) {
                                return null
                              }
                              return (
                                <GroupItem
                                  key={group.id}
                                  group={group}
                                  users={
                                    usersPerGroup.get(group.id) ?? emptyArray
                                  }
                                  setSelectedUsers={setSelectedUsers}
                                  selectedUsers={selectedUsers}
                                />
                              )
                            })}
                      </Stack>
                    </Content>
                  </ScrollArea>
                </Root>
                <Text bold>
                  <Value name="apps.web.src.share.components.sharemodal.bericht">
                    Bericht (optioneel)
                  </Value>
                </Text>
                <TextArea ref={textAreaRef} />
              </Stack>
              <Modal.Buttons>
                <AlertDialog.Cancel asChild>
                  <Modal.CancelButton>
                    <Value name="apps.web.src.share.components.sharemodal.annuleren">
                      Annuleren
                    </Value>
                  </Modal.CancelButton>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <Modal.SubmitButton
                    disabled={selectedUsers.length === 0}
                    type="button"
                    onClick={async () => {
                      await shareMutation({
                        type: props.type,
                        playlistId:
                          props.type === "PLAYLIST"
                            ? props.playlist.id
                            : undefined,
                        lessonId:
                          props.type === "LESSON" ? props.lesson.id : undefined,
                        themeGroupId:
                          props.type === "THEME"
                            ? props.themeGroup.id
                            : undefined,
                        learningLineId:
                          props.type === "LL"
                            ? props.learningLine.id
                            : undefined,
                        users: selectedUsers,
                        message: textAreaRef.current?.value,
                      })
                      await invalidateQuery(getShareUsers)
                      addToast({
                        type: "success",
                        content: (
                          <Text>
                            <Value
                              name="apps.web.src.share.components.sharemodal.afspeellijst_is_gedeeld"
                              format={{
                                name: getShareTitle(props),
                                ...htmlFormatTags,
                              }}
                            >
                              {`<b>{name}</b> is gedeeld`}
                            </Value>
                          </Text>
                        ),
                      })
                    }}
                  >
                    <Value name="apps.web.src.share.components.sharemodal.delen">
                      Delen
                    </Value>
                  </Modal.SubmitButton>
                </AlertDialog.Action>
              </Modal.Buttons>
            </Stack>
            {/* </form.Form> */}
          </ModalContent>
          <AlertDialog.Cancel asChild>
            <Modal.Close />
          </AlertDialog.Cancel>
        </ModalRoot>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  )
}

ShareModal.Controlled = function ControlledShareModal({
  isOpen,
  onClose,
  trigger,
  ...props
}: Props & {
  isOpen: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  trigger?: React.ReactNode
}) {
  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onClose}>
      {isOpen && (
        <Suspense fallback="Loading...">
          <ShareModal {...props} />
        </Suspense>
      )}
      {trigger ? (
        <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
      ) : null}
    </AlertDialog.Root>
  )
}

ShareModal.UnControlled = function UnControlledShareModal({
  trigger,
  ...props
}: Props & {
  trigger?: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <ShareModal.Controlled
      isOpen={isOpen}
      onClose={setIsOpen}
      trigger={trigger}
      {...props}
    />
  )
}
