import { Value } from "@stringtale/react"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import LessonSubTitle from "app/lessons/components/LessonSubTitle"
import getAllGrades from "app/grades/queries/getAllGrades"
import { PX12 } from "theme/sizes"
import GradeIcons from "ui/Grades"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import {
  Content,
  RootWithBorder as RootBase,
} from "web/src/lessons/components/LessonListItem"
import LessonListItemTitle from "web/src/lessons/components/LessonListItemTitle"
import LessonPreview from "web/src/lessons/components/LessonPreview"
import ParticipationIcons from "web/src/lessons/components/ParticipationIcons"
import LeaningLineLessonLabel from "../../lessons/components/LearningLineLessonLabel"
import { SearchElement } from "app/search/types"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import LessonDescriptionPreview from "app/lessons/components/LessonDescriptionPreview"
import { Dispatch, SetStateAction, useEffect } from "react"
import { css } from "@emotion/react"
import {
  DARK_GREEN_COLOR,
  DARKER_GREY_COLOR,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
} from "theme/colors"
import { motion } from "framer-motion"

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 175px;
  gap: 40px;
  @media screen and (max-width: 1420px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

const ParticipationIconsContainer = styled.div`
  @media screen and (max-width: 1420px) {
    display: none;
  }
`

const RoundedButton = styled(RoundedButtonBase)<{
  isActive: boolean
  disabled: boolean
}>`
  font-size: ${PX12};
  ${(p) =>
    p.isActive
      ? css`
          background-color: ${GREEN_COLOR};
          border-color: ${DARK_GREEN_COLOR};
          color: white;
        `
      : null}
  ${(p) =>
    p.disabled
      ? css`
          opacity: 1 !important;
          border-color: ${LIGHT_GREY_COLOR};
          background-color: ${LIGHT_GREY_COLOR};
          color: ${DARKER_GREY_COLOR};
        `
      : null}
`

const Root = styled(RootBase)`
  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 0;
    gap: 0;
  }
`.withComponent(motion.div)

const AddLessonListItem = ({
  lesson,
  selectedLessons,
  setSelectedLessons,
  isInPlaylist,
}: {
  lesson: SearchElement
  selectedLessons: string[] | null
  setSelectedLessons: Dispatch<SetStateAction<string[] | null>>
  isInPlaylist: boolean
}) => {
  const [grades] = useQuery(getAllGrades, undefined)
  const isLessonSelected = selectedLessons?.includes(lesson.uuid)

  return (
    <Root
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.1,
        delay: 0.2,
      }}
    >
      <Grid>
        <Group gap="40px">
          {lesson.cardImage && (
            <LessonPreview
              previewId={`addlesson-${lesson.uuid}`}
              image={lesson.cardImage}
            />
          )}
          <Content>
            <Stack gap="8px">
              {lesson.displayTitle && (
                <LessonListItemTitle
                  title={lesson.displayTitle}
                  contentSubject={lesson.contentSubject}
                />
              )}
              <LessonSubTitle size={PX12} lesson={lesson} />
              <Group gap="4px" justify="left">
                <GradeIcons
                  allGrades={grades}
                  activeGrades={lesson.grades}
                  contentSubjectType={lesson.contentSubject}
                />
                {lesson.isLearningLineLesson ? (
                  <LeaningLineLessonLabel
                    color={getContentSubjectColor(lesson.contentSubject)}
                  />
                ) : null}
              </Group>
              <LessonDescriptionPreview
                description={lesson.searchDescription}
              />
            </Stack>
            <Group gap="8px" justify="left">
              <RoundedButton
                disabled={isInPlaylist}
                isActive={isLessonSelected ? isLessonSelected : false}
                left={
                  isLessonSelected || isInPlaylist ? <FaCheck /> : <FaPlus />
                }
                size="small"
                onClick={() => {
                  if (isLessonSelected) {
                    setSelectedLessons((prevState) => {
                      if (prevState && prevState?.length > 0) {
                        return prevState.filter((e, i) => e !== lesson.uuid)
                      } else return prevState
                    })
                  } else {
                    setSelectedLessons((prevState) => {
                      if (prevState && prevState?.length > 0) {
                        return [...prevState, lesson.uuid]
                      } else {
                        return [lesson.uuid]
                      }
                    })
                  }
                }}
              >
                {isInPlaylist ? (
                  <Value name="apps.web.src.playlists.components.addlessonlistitem.aanwezig_op_afspeellijst">
                    Aanwezig op afspeellijst
                  </Value>
                ) : (
                  <Value name="apps.web.src.playlists.components.addlessonlistitem.toevoegen_aan_afspeellijst">
                    Toevoegen aan afspeellijst
                  </Value>
                )}
              </RoundedButton>
            </Group>
          </Content>
        </Group>
        <ParticipationIconsContainer>
          <ParticipationIcons lesson={lesson} />
        </ParticipationIconsContainer>
      </Grid>
    </Root>
  )
}

export default AddLessonListItem
