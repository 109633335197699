import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import ContentSubjectIcon from "app/content-subjects/components/ContentSubjectIcon"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import Image from "app/files/components/Image"
import { BulletinBoardItemType } from "db"
import { PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import { GetBulletinThemeGroupsResult } from "../queries/getBulletinThemeGroups"
import { GetCategoryThemesResult } from "../queries/getCategoryThemes"
import BulletinAddButton from "./BulletinAddButton"
import BulletinAddListItem from "./BulletinAddListItem"
import SquareImage from "./SquareImage"

const SubjectIconContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  width: 20px;
  height: 20px;

  svg {
    width: 14px;
    height: 14px;
  }
`

const BulletinAddThemeItem = ({
  themeGroup,
  selectedTheme,
  bulletinBoardItems,
}: {
  themeGroup: NonNullable<GetBulletinThemeGroupsResult>[number]
  selectedTheme?: NonNullable<GetCategoryThemesResult>[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const currentBulletinBoardTheme = bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.THEME_GROUP &&
      item.themeGroupId === themeGroup.id
  )

  const playlist = themeGroup.globalPlaylist?.playlist

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddthemeitem.thema">
            Thema
          </Value>
        }
        title={
          themeGroup.globalPlaylist?.playlist.displayTitle ||
          selectedTheme?.displayTitle ||
          themeGroup.displayTitle
        }
        activeGrades={themeGroup.grades}
        image={
          <>
            {selectedTheme &&
              (selectedTheme.cardImage ? (
                <Image
                  alt=""
                  file={selectedTheme.cardImage}
                  width={230}
                  height={130}
                />
              ) : selectedTheme.listImage ? (
                <SquareImage image={selectedTheme.listImage} />
              ) : null)}
          </>
        }
        right={
          <>
            <Stack justify="space-between" gap={"16px"}>
              <Stack gap="8px">
                <Text bold size={PX12}>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddthemeitem.methode">
                    Methode
                  </Value>
                </Text>
                <Stack gap="4px">
                  {selectedTheme?.contentSubjects.map((subject) => {
                    return (
                      <Group
                        gap="8px"
                        align="center"
                        justify="flex-start"
                        key={subject.id}
                      >
                        <SubjectIconContainer
                          color={getContentSubjectColor(subject.type)}
                        >
                          <ContentSubjectIcon type={subject.type} />
                        </SubjectIconContainer>
                        <Text size={PX12}>{subject.displayTitle}</Text>
                      </Group>
                    )
                  })}
                </Stack>
              </Stack>
              <ButtonGroup>
                <BulletinAddButton
                  addProps={{
                    type: BulletinBoardItemType.THEME_GROUP,
                    themeGroupId: themeGroup.id,
                  }}
                  deleteProps={
                    currentBulletinBoardTheme && {
                      id: currentBulletinBoardTheme.id,
                    }
                  }
                  currentBulletinBoardItem={currentBulletinBoardTheme}
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddThemeItem
