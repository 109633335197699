import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { isSelectedAtom } from "app/playlists/state"
import { useAtom } from "jotai"
import { Dragger } from "../components/TileEdit"
import { TbArrowsMove } from "@react-icons/all-files/tb/TbArrowsMove"

export default function useSortableTile(id: number, isEditMode: boolean) {
  const [isChecked, setChecked] = useAtom(isSelectedAtom(id))

  const {
    attributes,
    listeners,
    setNodeRef,
    setDraggableNodeRef,
    transform,
    transition,
    isSorting,
    active,
  } = useSortable({
    id,
  })

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform.x,
          y: transform.y,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition,
  }

  const dragger = isEditMode && !isChecked && (
    <Dragger ref={setDraggableNodeRef} {...attributes} {...listeners}>
      <TbArrowsMove size={50} />
    </Dragger>
  )

  return {
    ref: setNodeRef,
    style,
    dragger,
    isDragging: active?.id === id,
    onClick: <E extends React.MouseEvent>(e: E) => {
      if (isEditMode) {
        e.preventDefault()
      }
    },
  }
}
