import * as Dialog from "@radix-ui/react-dialog"
import { MouseEvent, ReactNode } from "react"
import * as Modal from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Group from "./Group"
import Text from "./Text"
import Hr from "./Hr"
import { PX14 } from "theme/sizes"

type ConfirmModalProps = {
  title: ReactNode
  description?: ReactNode
  confirmTitle: ReactNode
  declineTitle: ReactNode
  onConfirm: (e: MouseEvent<HTMLButtonElement>) => void
  onDecline?: (e: MouseEvent<HTMLButtonElement>) => void
}

const ConfirmModal = ({
  title,
  description,
  confirmTitle,
  declineTitle,
  onConfirm,
  onDecline,
}: ConfirmModalProps) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay>
        <Modal.Overlay />
      </Dialog.Overlay>
      <Dialog.Content>
        <Modal.Root width={description ? "660px" : "378px"}>
          <Modal.Content>
            <Dialog.Close asChild>
              <Modal.Close />
            </Dialog.Close>

            <Stack gap="24px">
              <Modal.Title>{title}</Modal.Title>
              {description && (
                <>
                  <Hr />
                  <Text size={PX14}>{description}</Text>
                </>
              )}
              <Group justify="flex-end" gap="12px">
                <Dialog.Close asChild>
                  <RoundedButton variant="bordered" onClick={onDecline}>
                    {declineTitle}
                  </RoundedButton>
                </Dialog.Close>
                <Dialog.Close asChild>
                  <RoundedButton variant="filled" onClick={onConfirm}>
                    {confirmTitle}
                  </RoundedButton>
                </Dialog.Close>
              </Group>
            </Stack>
          </Modal.Content>
        </Modal.Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default ConfirmModal
