import { useQuery } from "@blitzjs/rpc";
import getCurrentSchool from "app/users/queries/getCurrentOrganization"
import { useMemo } from "react"

export const useCurrentLicenseIds = () => {
  const [school] = useQuery(getCurrentSchool, null)
  return useMemo(() => {
    if(!school) return []
    return school.licenses.map(license => license.id)
  }, [school])
}
