import { Routes } from "@blitzjs/next"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Value } from "@stringtale/react"
import { parseAsBoolean, useQueryState } from "nuqs"
import { MODAL_QUERY_KEY } from "app/playlists/consts"
import Link from "next/link"
import { useState } from "react"
import Group from "ui/Group"
import * as Menu from "./Menu"

export default function LessonMenu({ trigger }: { trigger: React.ReactNode }) {
  const [_, setBiblitheek] = useQueryState(MODAL_QUERY_KEY)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  return (
    <DropdownMenu.Root modal open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content asChild align="start" alignOffset={-70}>
        <Menu.Root>
          <DropdownMenu.Arrow
            style={{ fill: "white" }}
            width={30}
            height={15}
          />
          <Menu.Content>
            <Menu.Title>
              <Group align="center" gap="17px">
                <Value name="apps.web.src.header.components.kidsmenu.kids">
                  Kids
                </Value>
              </Group>
            </Menu.Title>
            <Menu.Item onClick={() => {
              setBiblitheek("kids")
              setMenuOpen(false)
            }}>
              <Value name="apps.web.src.header.components.kidsmenu.kidslijst">
                Kidslijst
              </Value>
            </Menu.Item>
            <Menu.Item as={Link} {...Routes.LeerlingcodesPage()}>
              <Value name="apps.web.src.header.components.kidsmenu.leerlingcodes">
                Leerlingcodes
              </Value>
            </Menu.Item>
            <Menu.Item as={Link} {...Routes.IngeleverdeOpdrachtenPage()}>
              <Value name="apps.web.src.header.components.kidsmenu.ingeleverde_opdrachten">
                Ingeleverde opdrachten
              </Value>
            </Menu.Item>
            {/* <Menu.Item as={Link} {...Routes.KidsPage()}>
              Kidspagina preview
            </Menu.Item> */}
            <Menu.Item
              as="a"
              // @ts-ignore
              href="https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-kidspagina"
              target="_blank"
            >
              <Value name="apps.web.src.header.components.kidsmenu.uitleg_over_de_kidspagina">
                Uitleg over de Kidspagina
              </Value>
            </Menu.Item>
          </Menu.Content>
        </Menu.Root>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
