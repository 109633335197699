import { GetGroupsInSchoolResult } from "app/auth/queries/getGroupsInSchool"
import { PX12, PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import { Item } from "ui/FilterSelect"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getFullname from "utils/users/getFullName"

export const GroupSelectItem = ({
  group,
  isActive,
  onChange,
  disabled,
}: {
  group: GetGroupsInSchoolResult[number]
  isActive: boolean
  disabled?: boolean
  onChange: () => void
}) => {
  return (
    <Item isActive={isActive && !disabled}>
      <Checkbox
        checked={isActive || !!disabled}
        disabled={!!disabled}
        onCheckedChange={onChange}
        label={
          <Stack>
            <Group align="center" gap="5px">
              <Text size={PX14} bold>
                {group.displayTitle}
              </Text>
            </Group>
            <Text size={PX12}>
              {group.organizationUsers
                .map((u) => getFullname(u.user))
                .join(", ")}
            </Text>
          </Stack>
        }
      />
    </Item>
  )
}
