import { Organization, Subscription } from "db"

type OrganizationWithSubscription = Organization & {
    subscription: Subscription
}

export function hasKidsFeatures(organization: OrganizationWithSubscription | null) {
    if (organization === null) {
        return false
    }

    if (!organization.subscription.hasAccessToKidsPage) {
        return false
    }

    if (
        typeof organization.overrideGroupCountLimit === "number" &&
        organization.overrideGroupCountLimit <= 0
    ) {
        return false
    }

    if (organization.subscription.defaultGroupCountLimit <= 0) {
        return false
    }

    if (
        typeof organization.overrideKidsLoginLimit === "number" &&
        organization.overrideKidsLoginLimit <= 0
    ) {
        return false
    }

    if (organization.subscription.defaultKidsLoginLimit <= 0) {
        return false
    }

    return true
}