
// Sizes
export const PX10 = "0.625rem"
export const PX11 = "0.6875rem"
export const PX12 = "0.75rem"
export const PX13 = "0.8125rem"
export const PX14 = "0.875rem"
export const PX16 = "1rem"
export const PX18 = "1.125rem"
export const PX20 = "1.25rem"
export const PX22 = "1.375rem"
export const PX24 = "1.5rem"
export const PX26 = "1.625rem"
export const PX28 = "1.75rem"
export const PX32 = "2rem"
export const PX34 = "2.125rem"
export const PX36 = "2.25rem"
export const PX40 = "2.5rem"
export const PX42 = "2.625rem"
export const PX48 = "3rem"
export const PX54 = "3.375rem"
export const PX60 = "3.75rem"
export const PX64 = "4rem"

export const HEADER_SIZES = [PX60, PX54, PX48, PX42, PX36, PX32, PX28, PX20]