import { Routes } from "@blitzjs/next"
import { useMutation } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { TbHome } from "@react-icons/all-files/tb/TbHome"
import { Value } from "@stringtale/react"
import logout from "app/auth/mutations/logout"
import useLinks from "app/core/hooks/useLinks"
import Layout from "app/core/layouts/Layout"
import Image from "next/image"
import Link from "next/link"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
} from "theme/colors"
import { PX18, PX22, PX24 } from "theme/sizes"
import Button from "ui/Button"
import Center from "ui/Center"
import Container from "ui/Container"
import Group from "ui/Group"
import Stack from "ui/Stack"
import LogoAsset from "../assets/123ZINGkids_logow.svg"
import { RoundedButton } from "./RoundedButton"

const Root = styled(Stack)`
  gap: 60px;
  padding-bottom: 20px;
`

const Header = styled(Container)`
  height: 100px;
  background: ${DARK_BLUE_COLOR};
  border-radius: 25px;
  box-shadow: 0px 2px 20px #0000004d;
  margin-top: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  img {
    margin-top: -40px;
    margin-left: -30px;
  }
`

const SignOutButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 16px;
  color: white;
  box-sizing: border-box;
  font-size: ${PX22};
  font-weight: 600;
  height: 100%;
  padding: 0 24px;
  display: grid;
  place-items: center;
`

const MenuButton = styled(Button)<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: ${PX22};
  padding: 12px 40px;
  border-radius: 24px;
  border: 3px solid transparent;
  ${(p) =>
    p.isActive &&
    css`
      border: 3px solid white;
      background: #ffffff80 0% 0% no-repeat padding-box;
    `}
`

const ErrorContainer = styled.div`
  padding: 40px;
  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
  max-width: 50%;
  align-items: center;
  box-sizing: border-box;
  margin: 60px;
  @media (max-width: 768px) {
    max-width: 80%;
    padding: 20px;
  }
`

const ErrorTitle = styled.h2`
  font-size: ${PX24};
  font-weight: 900;
  color: ${DARK_BLUE_COLOR};
`

const ErrorMessage = styled.span`
  font-size: ${PX18};
  color: ${DARK_BLUE_COLOR};
`

export default function KidsErrorLayout() {
  const getLink = useLinks()
  const [logoutMutation] = useMutation(logout)

  return (
    <Layout title="123ZING Kids">
      <style jsx global>{`
        body {
          background-color: ${LIGHT_GREEN_COLOR};
        }
      `}</style>
      <Root>
        <Header>
          <Image src={LogoAsset} width={158} height={93} alt="Logo" />
          <Group>
            <MenuButton
              as={Link}
              {...getLink(Routes.KidsPage())}
              isActive={true}
            >
              <TbHome size={30} />
              <Value name="apps.web.src.kids.components.kidslayout.home">
                Home
              </Value>
            </MenuButton>
          </Group>

          <SignOutButton
            onClick={async () => {
              await logoutMutation()
              sessionStorage.clear()
            }}
          >
            <Value name="apps.web.src.kids.components.kidserrorlayout.signout">
              Uitloggen
            </Value>
          </SignOutButton>
        </Header>
        <Center>
          <ErrorContainer>
            <ErrorTitle>
              <Value name="apps.web.src.kids.components.kidserrorlayout.title">
                Er is iets misgegaan
              </Value>
            </ErrorTitle>
            <ErrorMessage>
              <Value
                name="apps.web.src.kids.components.kidserrorlayout.message"
                format={{
                  li: (c) => <li>{c}</li>,
                  ul: (c) => <ul>{c}</ul>,
                }}
              >{`
              Je moet het volgende doen:
              <ul>
                <li>
                  Controleer of je de login gegevens van de juiste groep gebruikt.
                </li>
                 <li>
                  Vraag aan je leerkracht of jullie nog 123ZING gebruiken.
                </li>
              </ul>
              `}</Value>
            </ErrorMessage>
          </ErrorContainer>
        </Center>
        <Center>
          <Group gap="12px">
            <RoundedButton
              as="a"
              // @ts-ignore
              href="https://www.123zing.nl/kind-ouder-verzorger/"
              target="_blank"
            >
              <Value name="apps.web.src.kids.components.kidslayout.uitleg_voor_ouders_en_verzorgers">
                Uitleg voor ouders en verzorgers
              </Value>
            </RoundedButton>
            <RoundedButton
              as="a"
              // @ts-ignore
              href="https://123zing.nl/privacyverklaring/"
              target="_blank"
            >
              <Value name="apps.web.src.kids.components.kidslayout.privacy">
                Privacy
              </Value>
            </RoundedButton>
          </Group>
        </Center>
      </Root>
    </Layout>
  )
}
