import styled from "@emotion/styled"
import { Form } from "utils/form/createForm"
import Field, { FieldProps } from "./Field"
import TextArea from "ui/TextArea"

const FieldArea = <TForm extends Form<any>>(props: FieldProps<TForm>) => {
  return (
    <Field {...props} asChild>
      <TextArea />
    </Field>
  )
}

export default FieldArea
