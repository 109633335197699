import styled from "@emotion/styled"
import {
  DARK_ORANGE_COLOR,
  LIGHT_ORANGE_COLOR,
  ORANGE_COLOR,
} from "theme/colors"
import ButtonBase from "ui/Button"
import Group from "ui/Group"

const Button = styled(ButtonBase)`
  border: 1px solid ${DARK_ORANGE_COLOR};
  color: ${ORANGE_COLOR};
  box-shadow: 0px 1px 10px #00000080;

  &:hover {
    background-color: ${LIGHT_ORANGE_COLOR};
  }
  &:active {
    background-color: ${DARK_ORANGE_COLOR};
    color: #fff;
  }
`

export const Dragger = styled(Button)`
  cursor: grab;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffffff;
  // Center
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 3;
  touch-action: none;
`

export const EditButton = styled(Button)`
  /* cursor: grab; */
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  color: ${ORANGE_COLOR};
`

const ButtonGroup = styled(Group)`
  z-index: 2;
  gap: 4px;
`

export const Left = styled(ButtonGroup)`
  position: absolute;
  top: 10px;
  left: 10px;
`

export const Right = styled(ButtonGroup)`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const ItemOverlay = styled.div<{ isChecked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(p) =>
    p.isChecked ? "#F1663680" : "rgba(255, 255, 255, 0.5)"};
  z-index: 1;
  transition: background 0.3s ease-in-out;
`
