import useGlobalVolume from "app/core/hooks/useGlobalVolume"
import useFilePathCallback from "app/files/hooks/useFilePathCallback"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useAtomCallback } from "jotai/utils"
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect
} from "react"
import shuffleArray from "utils/global/shuffleArray"
import { LessonWithCard, PlayingState } from "../../playlists/types"
import {
  audioCurrentTimeAtom,
  audioDurationAtom,
  audioElementRefAtom,
  audioLoopAtom,
  audioShuffleAtom,
  indexAtom,
  isLoadingAtom,
  isMutedAtom,
  isPlayingAtom,
  nextTrackAtom,
  playingStateAtom,
  playlistAtom
} from "../state"
import Audio from "./Audio"

export function useWindowEvent<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) {
  useEffect(() => {
    window.addEventListener(type, listener, options)
    return () => window.removeEventListener(type, listener, options)
  }, [listener, options, type])
}

const PlayerContext = createContext<HTMLAudioElement | null>(null)

export const useTogglePlaySpace = () => {
  const { togglePlay } = usePlay()
  const player = useContext(PlayerContext)
  const playingState = useAtomValue(playingStateAtom)

  useEffect(() => {
    if (!player || !playingState) return
    const onKeyPress = (e: KeyboardEvent) => {
      if (
        e.key === " " &&
        (!(e.target instanceof HTMLElement) ||
          (e.target?.tagName !== "INPUT" && e.target?.tagName !== "TEXTAREA"))
      ) {
        e.preventDefault()
        togglePlay()
      }
    }

    window.addEventListener("keypress", onKeyPress)

    return () => {
      window.removeEventListener("keypress", onKeyPress)
    }
  }, [player, playingState, togglePlay])

  return null
}

type Props = PropsWithChildren<{}>

export const PlayerProvider = ({ ...props }: Props) => {
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom)
  const setIsLoading = useSetAtom(isLoadingAtom)
  const playingState = useAtomValue(playingStateAtom)
  const getFilePath = useFilePathCallback()
  const ref = useAtomValue(audioElementRefAtom)
  const [isMuted, setMuted] = useAtom(isMutedAtom)
  const setCurrentTime = useSetAtom(audioCurrentTimeAtom)
  const setDuration = useSetAtom(audioDurationAtom)
  const [loop, setLoop] = useAtom(audioLoopAtom)

  useEffect(() => {
    if (!ref) return
    if (!playingState) return
    if (ref.paused) return
    const url = playingState?.lesson?.radioSong
      ? getFilePath(playingState?.lesson?.radioSong)
      : null
    if (url && ref.src !== url) {
      ref.src = url
      ref.play()
    }
  }, [ref, playingState, getFilePath])

  return (
    <PlayerContext.Provider {...props} value={ref}>
      <Audio
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadStart={() => setIsLoading(true)}
        onCanPlay={() => setIsLoading(false)}
        muted={isMuted}
        onTimeUpdate={(e) => setCurrentTime(e.currentTarget.currentTime)}
        onDurationChange={(e) => setDuration(e.currentTarget.duration)}
        onLoadedMetadata={(e) => {
          setDuration(e.currentTarget.duration)
        }}
        loop={loop}
      />
      {props.children}
    </PlayerContext.Provider>
  )
}

export const usePlay = () => {
  const ref = useContext(PlayerContext)
  const setIsPlaying = useSetAtom(isPlayingAtom)
  const playingState = useAtomValue(playingStateAtom)
  const [volume] = useGlobalVolume()
  const getFilePath = useFilePathCallback()

  const getUrl = useCallback(
    (lesson: LessonWithCard) => {
      if (lesson?.radioSong) {
        return getFilePath(lesson?.radioSong)
      }
      if (lesson.rehearse?.video) {
        return getFilePath(lesson.rehearse?.video)
      }
      if (lesson.rehearse?.audio) {
        return getFilePath(lesson.rehearse?.audio)
      }
      return null
    },
    [getFilePath]
  )

  const play = useCallback(() => {
    if (!ref) return
    // ref.src = ref.src
    ref
      .play()
      .catch((e) => {
        console.log("e", e)
        setIsPlaying(false)
      })
      .then(() => {})
  }, [ref, setIsPlaying])

  const pause = useCallback(() => {
    if (!ref) return
    ref.pause()
  }, [ref])

  const setList = useAtomCallback(
    useCallback(
      (get, set, lessons?: { lesson: LessonWithCard }[] | null) => {
        const list = lessons?.filter(({ lesson }) => !!getUrl(lesson)) || null
        set(playlistAtom, list)
        const index = get(indexAtom)
        if (index === -1) {
          set(playingStateAtom, list?.[0] || null)
          pause()
        }
      },
      [getUrl, pause]
    )
  )

  const toggleShuffle = useAtomCallback(
    useCallback(
      (get, set) => {
        const shuffleActive = get(audioShuffleAtom)
        const currentPlaylist = get(playlistAtom)
        if (!currentPlaylist) return

        if (shuffleActive) {
          const sortedArray = [...currentPlaylist].sort(
            (
              a: { lesson: LessonWithCard; sort: number },
              b: { lesson: LessonWithCard; sort: number }
            ) => b.sort - a.sort
          )
          set(audioShuffleAtom, false)
          set(playlistAtom, sortedArray)
          return
        } else {
          const playingState = get(playingStateAtom)
          if (!playingState) return
          const shuffledArray = shuffleArray([...currentPlaylist])
          if (!shuffledArray || !playingState) return -1

          // Get index for current song in newly shuffled playlist
          const index = shuffledArray.findIndex(
            (lesson) => lesson.lesson.id === playingState.lesson.id
          )

          if (index !== 0) {
            // Swapping song at index ${index} with index 0
            ;[shuffledArray[index], shuffledArray[0]] = [
              shuffledArray[0],
              shuffledArray[index],
            ]
          }
          set(audioShuffleAtom, true)
          set(playlistAtom, shuffledArray)
          return
        }
      },
      []
    )
  )

  const set = useAtomCallback(
    useCallback(
      (
        get,
        set,
        state: PlayingState,
        lessons?: { lesson: LessonWithCard }[] | null
      ) => {
        if (!state) {
          set(playingStateAtom, state)
          pause()
          return
        }
        if (!ref) return
        const url = getUrl(state.lesson)
        if (!url) return

        ref.src = url
        set(playingStateAtom, state)
        if (lessons) {
          set(
            playlistAtom,
            lessons.filter(({ lesson }) => !!getUrl(lesson))
          )
        }
        play()
      },
      [getUrl, pause, play, ref]
    )
  )

  const onFinish = useAtomCallback((get) => {
    const next = get(nextTrackAtom)
    if (next) {
      set(next)
    }
  })

  // Set url
  useEffect(() => {
    if (!playingState || !ref) return
    const url = getUrl(playingState.lesson)
    if (!url || ref.src === url) return
    ref.src = url
  }, [getUrl, playingState, ref])

  // Update volume when atom updates
  useEffect(() => {
    if (!ref) return
    ref.volume = volume
  }, [ref, volume])

  return {
    player: ref,
    set,
    setList,
    play,
    getUrl,
    pause,
    toggleShuffle,
    togglePlay: () => {
      if (!ref) return
      if (ref.paused) {
        play()
      } else {
        pause()
      }
    },
    onFinish
  }
}
