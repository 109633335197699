import { SafeUser } from "app/auth/utils/safeUserInclude"
import { File, Lesson, UserPlaylist, ContentSubjectType, SongText, Rehearse, Playlist } from "db"
import { Dispatch, SetStateAction } from "react"
import { RouteUrlObject } from "blitz"

// export type SelectPlaylistAccessLevels = "CURRENT_GROUP" | "SELECT_GROUP" | "PERSONAL"

export type UserWithAvatar = SafeUser

export type UserInfo = {
  user: UserWithAvatar
  access: "READ_ONLY" | "READ_WRITE"
}

export type ContentProps = {
  userListId: number
  selectedUsers: UserInfo[]
  users: UserWithAvatar[]
  setSelectedUsers: Dispatch<SetStateAction<UserInfo[]>>
  setMode: Dispatch<SetStateAction<"choose" | "search">>
  onClose: () => void
}

export type LessonWithCard = Lesson & { cardImage: File | null, listImage: File | null, radioSong: File | null, rehearse: { audio: File | null, video: File | null } | null }

export type UserPlaylistWithPlaylist = UserPlaylist & {
  playlist: {
    displayTitle: string
    color: string | null
    description: string | null
  } | null
}

export type UserPlaylistWithSubject = UserPlaylist & {
  learningLine?: {
    headerBackgroundColor: string | null
    playlistLabel: string | null
    displayTitle: string
    learningLineTheme?: {
      displayTitle: string
      learningLine?: {
        contentSubject?: ContentSubjectType
        displayTitle: string
      }
    }
  } | null
  themeGroup: {
    globalPlaylist: {
      playlist: {
        displayTitle: string
        color: string | null
      } | null
    } | null
  } | null
  playlist: {
    displayTitle: string
    color: string | null
  } | null
}

export type PlayingState = {
  lesson: LessonWithCard
} | null

export type PlaylistInfoState = {
  listName: string,
  route?: RouteUrlObject,
  libraryPlaylistId?: number
} | null

export enum VisibilityFilterStates { ALL = "Alles", PUBLISHED = "Gepubliceerd", UNPUBLISHED = "Niet gepubliceerd" }
