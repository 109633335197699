import { Routes } from "@blitzjs/next"
import { getSlugFromContentSubject } from "app/content-subjects/slug"
import { ContentSubjectType } from "db"

export type LearningLinePageLinkProps = {
  learningLineTheme: {
    learningLine: {
      slug: string
      contentSubject: ContentSubjectType
    } | null
    slug: string
  } | null
  slug: string
}

export default function getLearningLineLinkProps(learningLine: LearningLinePageLinkProps) {
  if (!learningLine?.learningLineTheme?.learningLine) {
    return {
      href: "#"
    }
  }
  return Routes.LearningLinePage({
    learningLine: learningLine.learningLineTheme.learningLine.slug,
    vak: getSlugFromContentSubject(learningLine.learningLineTheme.learningLine.contentSubject),
    theme: learningLine.learningLineTheme.slug,
    name: learningLine.slug,
  })
}