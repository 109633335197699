import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import { BulletinBoardItem } from "db"
import { useEffect, useMemo, useState } from "react"
import { GREEN_COLOR, RED_COLOR } from "theme/colors"
import { PX12 } from "theme/sizes"
import Group from "ui/Group"
import Loader from "ui/Loader"
import RoundedButtonBase from "ui/RoundedButton"
import { z } from "zod"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import deleteBulletinBoardItem, {
  DeleteBulletinBoardItemProps,
} from "../mutations/deleteBulletinBoardItem"
import getBulletinBoardItems from "../queries/getBulletinBoardItems"
import { AddBulletinBoardItemProps } from "../utils/addbulletinBoardItem"

type ButtonState = "ADDED" | "REMOVE" | "AVAILABLE"

const RoundedButton = styled(RoundedButtonBase)<{ state: ButtonState }>`
  font-size: ${PX12};
  padding: 0;
  width: 100%;
  max-width: 200px;

  &:disabled {
    opacity: 1;
  }

  ${({ state }) => {
    switch (state) {
      case "ADDED":
        return css`
          opacity: 1;
          background-color: ${GREEN_COLOR};
        `
      case "REMOVE":
        return css`
          opacity: 1;
          background-color: ${RED_COLOR};
        `
      default:
        return css`
          opacity: 1;
        `
    }
  }}
`

type Props = {
  addProps?: z.infer<typeof AddBulletinBoardItemProps>
  deleteProps?: z.infer<typeof DeleteBulletinBoardItemProps>
  currentBulletinBoardItem?: BulletinBoardItem | null
}

const BulletinAddButton = ({
  addProps,
  deleteProps,
  currentBulletinBoardItem,
}: Props) => {
  const hasItem = useMemo(
    () => !!currentBulletinBoardItem,
    [currentBulletinBoardItem]
  )
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)
  const [deleteBulletinBoardItemMutation, deleteBulletinBoardItemRes] =
    useMutation(deleteBulletinBoardItem)
  const isLoading =
    addBulletinBoardItemRes.isLoading || deleteBulletinBoardItemRes.isLoading

  const [buttonState, setButtonState] = useState<ButtonState>(
    hasItem ? "REMOVE" : "AVAILABLE"
  )

  useEffect(() => {
    if (buttonState === "ADDED") {
      setTimeout(() => {
        setButtonState("REMOVE")
      }, 3000)
    }
  }, [buttonState])

  const buttonText = useMemo(() => {
    if (buttonState === "ADDED") {
      return (
        <Value name="apps.web.src.bulletinboard.components.bulletinaddlessonitem.toegevoegd">
          Toegevoegd!
        </Value>
      )
    } else if (buttonState === "REMOVE") {
      return (
        <Value name="apps.web.src.bulletinboard.components.bulletinaddlessonitem.toegevoegd_aan_prikbord">
          Verwijder van prikbord
        </Value>
      )
    } else if (buttonState === "AVAILABLE") {
      return (
        <Value name="app.bulletinboard.components.bulletinaddlessonitem.toevoegen_aan_prikbord">
          Toevoegen aan prikbord
        </Value>
      )
    }
  }, [buttonState])

  return (
    <RoundedButton
      size="small"
      variant="filled"
      state={buttonState}
      disabled={buttonState === "ADDED" || isLoading}
      onClick={async () => {
        if (isLoading) return
        if (buttonState === "REMOVE") {
          if (!deleteProps) return
          await deleteBulletinBoardItemMutation(deleteProps)
          await invalidateQuery(getBulletinBoardItems)
          setButtonState("AVAILABLE")
        } else {
          if (!addProps) return
          await addBulletinBoardItemMutation(addProps)
          await invalidateQuery(getBulletinBoardItems)
          setButtonState("ADDED")
        }
      }}
    >
      <Group justify="center" align="center" gap="4px">
        {isLoading ? <Loader size="18px" color="#FFFFFF" /> : buttonText}
      </Group>
    </RoundedButton>
  )
}

export default BulletinAddButton
