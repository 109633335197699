import { Value } from "@stringtale/react"
import styled from "@emotion/styled"
import { useRouter } from "next/router"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
  PX18,
  PX24,
} from "theme/consts"
import Button from "ui/Button"
import { Routes } from "@blitzjs/next"

const Body = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 40px;
  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  max-width: 50%;
  align-items: center;
  box-sizing: border-box;
  margin: 60px;
  @media (max-width: 1248px) {
    max-width: 80%;
    padding: 20px;
  }
`

const ErrorTitle = styled.h2`
  font-size: ${PX24};
  font-weight: 900;
  color: ${DARK_BLUE_COLOR};
`

const ErrorMessage = styled.span`
  font-size: ${PX18};
  color: ${DARK_BLUE_COLOR};
  margin-bottom: 10px;
`

const ErrorButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 50px;
  color: white;
  height: 50px;
  width: 200px;
`

export const ContentNotAvailable = function () {
  const router = useRouter()

  return (
    <>
      <Body>
        <ErrorContainer>
          <ErrorTitle>
            <Value name="apps.web.src.errors.contentnotavailable.title">
              Pagina niet beschikbaar
            </Value>
          </ErrorTitle>
          <ErrorMessage>
            <Value name="apps.web.src.errors.contentnotavailable.message">
              De pagina die je probeert te bekijken is helaas niet beschikbaar.
            </Value>
            <br />
            <Value name="apps.web.src.errors.contentnotavailable.probeer_het_later_opnieuw_of_bekijk_een_andere_les">
              Probeer het later opnieuw of bekijk een andere les.
            </Value>
          </ErrorMessage>
          <ErrorButton
            onClick={async () => {
              router.push(Routes.Home())
            }}
          >
            <Value name="apps.web.src.errors.contentnotavailable.button">
              Terug naar home
            </Value>
          </ErrorButton>
        </ErrorContainer>
      </Body>
    </>
  )
}
