import {
  ComponentProps,
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  SetStateAction,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react"
import {
  ZodObject,
  ZodEffects,
  ZodError,
  ZodIssue,
  ZodType,
  ZodString,
} from "zod"
import { Slot } from "@radix-ui/react-slot"
import getDataFromFormData from "./getDataFromFormData"

// export class FieldError extends Error {
//   name = "FieldError"
//   fields: string[]
//   code: string

//   constructor(fields: string[], message: string, code: string = "") {
//     super()
//     this.code = code
//     this.fields = fields
//     this.message = message
//   }
// }

type Issue = {
  code: string
  message: string
  field: string
}

const getShape = (schema: ZodType<any>) => {
  if (schema instanceof ZodEffects) {
    return schema._def.schema
  }
  if (schema instanceof ZodObject) {
    return schema.shape
  }
  throw new Error("Invalid schema")
}

export default function createFormFactory<T extends ZodType<any>>({
  validationSchema,
}: {
  validationSchema: T
}) {
  type OverrideData =
    | Partial<T["_output"]>
    | ((data: T["_output"]) => T["_output"])

  const Context = createContext<{
    issues: Issue[] | null
    setIssues: Dispatch<SetStateAction<Issue[] | null>>
    defaultValues?: Partial<T["_output"]>
    submit: (overideData?: OverrideData) => Promise<void>
  } | null>(null)

  const FieldContext = createContext<keyof T["_output"] | null>(null)

  return {
    validationSchema,
    Form: ({
      onSubmit,
      defaultValues,
      ...props
    }: Omit<ComponentProps<"form">, "onSubmit"> & {
      onSubmit: (
        date: T["_output"],
        setIssue: (
          name: keyof T["_output"],
          code: string,
          message: ReactNode
        ) => void
      ) => void
      defaultValues?: Partial<T["_output"]>
    }) => {
      const [issues, setIssues] = useState<Issue[] | null>(null)
      const ref = useRef<HTMLFormElement | null>(null)

      const submit = useCallback(
        async (overideData?: OverrideData) => {
          if (!ref.current) return
          const formData = new FormData(ref.current)
          let data = {
            ...defaultValues,
            ...getDataFromFormData(formData),
          }
          if (typeof overideData === "function") {
            data = overideData(data)
          } else {
            data = {
              ...data,
              ...overideData,
            }
          }
          const v = validationSchema.safeParse(data)
          if (v.success) {
            console.log("success!!")
            await onSubmit(v.data, (field, code, message) => {
              setIssues(
                issues => ([...issues ?? [], { field, code, message }] as Issue[])
              )
            })
          } else if (v.success === false) {
            setIssues(
              v.error.issues.map((issue) => ({
                code: issue.code,
                message: issue.message,
                field: issue.path.join("."),
              }))
            )
          }
        },
        [defaultValues, onSubmit]
      )

      return (
        <Context.Provider value={{ defaultValues, issues, setIssues, submit }}>
          <form
            ref={ref}
            onSubmit={async (e) => {
              e.preventDefault()
              await submit()
            }}
            {...props}
          />
        </Context.Provider>
      )
    },

    Field: ({
      children,
      name,
    }: {
      children: React.ReactNode
      name: keyof T["_output"]
    }) => {
      return (
        <FieldContext.Provider value={name}>
          <>{children}</>
        </FieldContext.Provider>
      )
    },

    Label: ({
      children,
      asChild,
    }: {
      children: React.ReactNode
      asChild?: boolean
    }) => {
      const name = useContext(FieldContext)
      if (!name) {
        throw new Error("Control must be used inside a Field")
      }
      const Comp = asChild ? Slot : "label"
      return <Comp>{children}</Comp>
    },

    Control: ({
      children,
    }: {
      children: ReactElement<any, string | JSXElementConstructor<any>>
    }) => {
      const name = useContext(FieldContext)
      const val = useContext(Context)
      if (!name || !val) {
        throw new Error("Control must be used inside a Field")
      }
      const { defaultValues, setIssues } = val
      const shape = getShape(validationSchema)
      let object: any = {}
      if (shape instanceof ZodType) {
        object.required = !shape.isOptional()
      }
      if (shape instanceof ZodString) {
        if (shape.isEmail) {
          object.type = "email"
        }
      }
      return (
        <>
          {cloneElement(children, {
            name,
            defaultValue: defaultValues?.[name],
            onChange: () => {
              setIssues(null)
            },
            ...object,
          })}
        </>
      )
    },

    Message: ({
      children,
      render = (m) => m.message,
    }: {
      children?: React.ReactNode
      render?: (issue: Issue) => React.ReactNode
    }) => {
      const name = useContext(FieldContext)
      const val = useContext(Context)
      if (!name || !val) {
        throw new Error("Message must be used inside a Field")
      }
      const { issues } = val
      const issue = issues?.find((issue) => issue.field === name)
      if (issue) {
        return <>{children ?? render(issue)}</>
      }
      return null
    },

    SubmitButton: ({
      children,
      asChild,
      overrideData,
      onClick,
    }: {
      children: React.ReactNode
      asChild?: boolean
      overrideData?: OverrideData
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    }) => {
      const ctx = useContext(Context)
      if (!ctx) {
        throw new Error("SubmitButton must be used inside a Form")
      }
      const Comp = asChild ? Slot : "button"
      return (
        <Comp
          onClick={(e) => {
            onClick?.(e)
            if (!e.isDefaultPrevented()) {
              overrideData && e.preventDefault()
              ctx.submit(overrideData)
            }
          }}
        >
          {children}
        </Comp>
      )
    },

    useSubmit: () => {
      const ctx = useContext(Context)
      if (!ctx) {
        throw new Error("useSubmit must be used inside a Form")
      }
      return ctx.submit
    },
  }
}

export type Form<T extends ZodObject<any>> = ReturnType<
  typeof createFormFactory<T>
>

export function convertNullToUndefined(object: Record<string, any>) {
  const newObject: Record<string, any> = {}
  for (const key in object) {
    if (object[key] === null) {
      newObject[key] = undefined
    } else {
      newObject[key] = object[key]
    }
  }
  return newObject
}
