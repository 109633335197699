import { useQuery } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Tabs from "@radix-ui/react-tabs"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import { Value } from "@stringtale/react"
import getContentSubjectTitle from "app/content-subjects/getContentSubjectTitle"
import { Root as BreadcrumbRoot } from "app/core/components/BreadcrumbItem"
import { ContentSubjectType } from "db"
import { useEffect, useMemo, useState } from "react"
import { DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX14, PX16, PX24 } from "theme/sizes"
import Button from "ui/Button"
import Group from "ui/Group"
import { Separator } from "ui/Separator"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import { LL_SUB_TABS } from "../consts"
import getBulletinAddLearningLineItems, {
  GetBulletinAddLearningLineItemsResult,
} from "../queries/getBulletinAddLearningLineItems"
import BulletinAddLearningLineItem from "./BulletinAddLearningLineItem"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"

const ContentPages = styled(Stack)`
  margin-bottom: 200px;
`

const List = styled.div`
  width: 100%;
  margin-bottom: 104px;
`

const ListItem = styled(Button)`
  height: 55px;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
  gap: 4px;
`

const Breadcrumbs = styled(Group)`
  font-size: ${PX14};
`

const ContentPage = styled(Stack)`
  margin-top: 40px;
`

const BreadcrumbItem = styled(BreadcrumbRoot)<{ isActive: boolean }>`
  text-decoration: none;
  opacity: 1;
  ${({ isActive }) =>
    !isActive &&
    css`
      font-weight: 600;
      color: ${ORANGE_COLOR};
      cursor: pointer;
    `}
`

const BulletinAddLearningLineTab = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [learningLines] = useQuery(getBulletinAddLearningLineItems, undefined)

  const [currentTab, setTab] = useState<LL_SUB_TABS>(
    LL_SUB_TABS.SUBJECT_SELECTION
  )
  const [selectedSubject, setSelectedSubject] =
    useState<ContentSubjectType | null>()
  const [selectedLearningLine, setSelectedLearningLine] = useState<
    GetBulletinAddLearningLineItemsResult[ContentSubjectType][number] | null
  >()

  const subjectContent = useMemo(() => {
    if (!learningLines || !selectedSubject) {
      return null
    }

    return (
      <>
        {learningLines[selectedSubject].map((learningLine) => {
          return (
            <span key={learningLine.id}>
              <ListItem
                onClick={() => {
                  setSelectedLearningLine(learningLine)
                }}
              >
                <Text color={DARK_BLUE_COLOR} bold size={PX16}>
                  {learningLine.displayTitle}
                </Text>
                <FaChevronRight size={14} color={ORANGE_COLOR} />
              </ListItem>
              <Separator />
            </span>
          )
        })}
      </>
    )
  }, [learningLines, selectedSubject])

  useEffect(() => {
    if (!selectedLearningLine && !selectedSubject) {
      setTab(LL_SUB_TABS.SUBJECT_SELECTION)
    } else if (!selectedLearningLine && selectedSubject) {
      setTab(LL_SUB_TABS.SUBJECT)
    } else if (selectedLearningLine && selectedSubject) {
      setTab(LL_SUB_TABS.THEME)
    }
  }, [selectedLearningLine, selectedSubject])

  if (!learningLines) {
    return (
      <Text>
        <Value name="app.bulletinboard.components.bulletinaddmodalcontent.geen_leerlijnen">
          Geen leerlijnen beschikbaar.
        </Value>
      </Text>
    )
  }

  return (
    <Stack>
      <Stack gap="14px">
        <Breadcrumbs gap={"6px"}>
          <BreadcrumbItem
            isActive={currentTab === LL_SUB_TABS.SUBJECT_SELECTION}
            onClick={() => {
              if (currentTab !== LL_SUB_TABS.SUBJECT_SELECTION) {
                setSelectedSubject(null)
                setSelectedLearningLine(null)
              }
            }}
          >
            <Value name="apps.web.src.bulletinboard.components.bulletinaddlearninglinetab.leerlijnen">
              Leerlijnen
            </Value>
          </BreadcrumbItem>
          {currentTab !== LL_SUB_TABS.SUBJECT_SELECTION && (
            <>
              <span style={{ opacity: 0.5 }}>&#62;</span>
              <BreadcrumbItem
                isActive={currentTab === LL_SUB_TABS.SUBJECT}
                onClick={() => {
                  if (currentTab !== LL_SUB_TABS.SUBJECT) {
                    setSelectedLearningLine(null)
                  }
                }}
              >
                <Value
                  name="apps.web.src.bulletinboard.components.bulletinaddlearninglinetab.leerlijn_naam"
                  format={{
                    name: getContentSubjectTitle(selectedSubject),
                  }}
                >
                  {`Leerlijn {name}`}
                </Value>
              </BreadcrumbItem>
            </>
          )}
          {currentTab === LL_SUB_TABS.THEME && (
            <>
              <span style={{ opacity: 0.5 }}>&#62;</span>
              <BreadcrumbItem isActive={currentTab === LL_SUB_TABS.THEME}>
                {selectedLearningLine?.displayTitle}
              </BreadcrumbItem>
            </>
          )}
        </Breadcrumbs>
        <Separator />
      </Stack>
      <Tabs.Root
        value={currentTab}
        onValueChange={(value: LL_SUB_TABS) => setTab(value)}
      >
        <Tabs.Content value={LL_SUB_TABS.SUBJECT_SELECTION}>
          <List>
            {Object.keys(ContentSubjectType).map(
              (subject: ContentSubjectType) => {
                return (
                  <span key={subject}>
                    <ListItem onClick={() => setSelectedSubject(subject)}>
                      <Text color={DARK_BLUE_COLOR} bold size={PX16}>
                        <Value
                          name="apps.web.src.bulletinboard.components.bulletinaddlearninglinetab.leerlijn_naam"
                          format={{
                            name: getContentSubjectTitle(subject),
                          }}
                        >
                          {`Leerlijn {name}`}
                        </Value>
                      </Text>
                      <FaChevronRight size={14} color={ORANGE_COLOR} />
                    </ListItem>
                    <Separator />
                  </span>
                )
              }
            )}
          </List>
        </Tabs.Content>
        <Tabs.Content value={LL_SUB_TABS.SUBJECT}>
          {subjectContent}
        </Tabs.Content>

        <Tabs.Content value={LL_SUB_TABS.THEME}>
          <ContentPages>
            {selectedLearningLine?.learningLineThemes.map((theme) => {
              return (
                <ContentPage key={theme.id}>
                  <Stack gap="16px">
                    <Title size={PX24} weight={"900"}>
                      {theme.displayTitle}
                    </Title>
                    <Separator />
                  </Stack>
                  <Stack>
                    {theme.learningLineContentPages.map((contentPage) => {
                      return (
                        <Stack key={contentPage.id}>
                          <BulletinAddLearningLineItem
                            contentPage={contentPage}
                            bulletinBoardItems={bulletinBoardItems}
                          />
                          <Separator />
                        </Stack>
                      )
                    })}
                  </Stack>
                </ContentPage>
              )
            })}
          </ContentPages>
        </Tabs.Content>
      </Tabs.Root>
    </Stack>
  )
}

export default BulletinAddLearningLineTab
