import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { Value } from "@stringtale/react/dist"
import getLearningLineLinkProps from "app/learning-lines/utils/getLearningLineLinkProps"
import IconContainer from "app/player/components/IconContainer"
import { GetUserListResult } from "app/playlists/queries/getUserList"
import getUserPlaylistColor from "app/playlists/utils/getUserPlaylistColor"
import getUserPlaylistTitle from "app/playlists/utils/getUserPlaylistTitle"
import { usePlay } from "app/radio/components/PlayerContext"
import getListDisplayTitle from "app/schooljaren/utils/getListDisplayTitle"
import ShareModal from "app/share/components/ShareModal"
import { useAtom, useAtomValue } from "jotai"
import Link from "next/link"
import { ReactNode, useState } from "react"
import TruncateMarkup from "react-truncate-markup"
import { PX13, PX14, PX28 } from "theme/sizes"
import Button from "ui/Button"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import {
  displayStateAtom,
  isPlayingAtom,
  playingThemeAtom,
} from "../../radio/state"
import { GetArchiveListsResult } from "../queries/getArchiveLists"
import MigratePlaylistModal from "./MigratePlaylistModal"

export const Header = styled(Stack)<{ color: string }>`
  padding: 19px 32px 25px;
  background-color: ${(p) => p.color};
  gap: 24px;
  color: white;
  width: 100%;
  box-sizing: border-box;
`

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX14};
`

const ContextMenuButton = styled(RoundedButton)``

const ListType = styled(Text)`
  font-size: ${PX13};
`

const Description = styled.div`
  font-weight: 600;
  font-size: ${PX14};
  white-space: pre-wrap;
  max-height: 250px;
  overflow-y: auto;
`

const ShowMoreButton = styled(Button)`
  font-weight: 600;
  font-size: ${PX14};
  align-self: self-start;
  text-decoration: underline;
  padding: 0;
  margin-top: 3px;
`

const PlayButton = styled(Button)`
  width: 42px;
  height: 42px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #efefef;
  img {
    width: 21px;
    height: 21px;
    transform: translateX(3%);
  }
`

export default function ArchiveModalHeader({
  userList,
  list,
  video,
  canEdit,
  canDelete,
  selectedGroup,
}: {
  userList: NonNullable<GetUserListResult>
  list: NonNullable<NonNullable<GetUserListResult>["playlist"]>["lessons"]
  video: ReactNode
  canEdit: boolean
  canDelete: boolean
  selectedGroup?: NonNullable<GetArchiveListsResult>[0]["groups"][0]
}) {
  const [isSharePlaylistVisible, setSharePlaylistVisible] = useState(false)
  const { play, pause, setList, player } = usePlay()
  const isPlaying = useAtomValue(isPlayingAtom)
  const [displayState, setDisplayState] = useAtom(displayStateAtom)
  const [playingTheme, setPlayingTheme] = useAtom(playingThemeAtom)

  const hidePlayButton =
    userList.themeGroup?.globalPlaylist?.playlist.hidePlayButton

  const color = getUserPlaylistColor(userList)

  const isPlayableList =
    !hidePlayButton &&
    !!list.find((el) => !!el.lesson.radioSong || !!el.lesson?.rehearse?.video)

  const [isShowMore, setShowMore] = useState(false)
  const [isTruncated, setTruncated] = useState(false)

  return (
    <Header color={color}>
      <Group justify="space-between" gap="12px">
        <Stack gap="24px">
          {userList ? (
            <Stack>
              <ListType>
                {getListDisplayTitle(
                  userList.accessLevel,
                  userList.type,
                  userList.owner,
                  selectedGroup?.displayTitle
                )}
              </ListType>
              <Title size={PX28}>{getUserPlaylistTitle(userList)}</Title>
              {isShowMore ? (
                <>
                  <Description>{userList.playlist?.description}</Description>
                  {isTruncated ? (
                    <ShowMoreButton onClick={() => setShowMore(false)}>
                      <Value name="apps.web.src.schooljaren.components.playlistmodalheader.toon_minder">
                        Toon minder
                      </Value>
                    </ShowMoreButton>
                  ) : null}
                </>
              ) : (
                <>
                  <TruncateMarkup lines={3} onTruncate={setTruncated}>
                    <Description>{userList.playlist?.description}</Description>
                  </TruncateMarkup>
                  {isTruncated ? (
                    <ShowMoreButton onClick={() => setShowMore(true)}>
                      <Value name="apps.web.src.schooljaren.components.playlistmodalheader.toon_meer">
                        Toon meer
                      </Value>
                    </ShowMoreButton>
                  ) : null}
                </>
              )}
            </Stack>
          ) : null}

          <Group gap="12px" justify="space-between">
            <Group gap="12px" justify="flex-start">
              {isPlayableList && (
                <PlayButton
                  onClick={async () => {
                    if (
                      isPlaying &&
                      userList.id === playingTheme?.libraryPlaylistId
                    ) {
                      await pause()
                    } else if (
                      !isPlaying &&
                      userList.id === playingTheme?.libraryPlaylistId
                    ) {
                      if (
                        displayState === "closed" ||
                        displayState === "hidden"
                      )
                        setDisplayState("small")
                      await play()
                    } else {
                      // Set playlist in global player
                      await setList(list)
                      if (
                        userList.type === "THEME" &&
                        userList.themeGroup &&
                        userList.themeGroup.theme
                      ) {
                        setPlayingTheme({
                          listName: getUserPlaylistTitle(userList),
                          route: Routes.ThemePage({
                            category: userList.themeGroup.theme.category.slug,
                            theme: userList.themeGroup.theme.slug,
                            group: userList.themeGroup.slug,
                          }),
                          libraryPlaylistId: userList.id
                            ? userList.id
                            : undefined,
                        })
                      } else {
                        await setPlayingTheme({
                          listName: getUserPlaylistTitle(userList),
                          libraryPlaylistId: userList.id
                            ? userList.id
                            : undefined,
                        })
                      }

                      if (player) player.currentTime = 0

                      await play()
                      if (
                        displayState === "closed" ||
                        displayState === "hidden"
                      )
                        setDisplayState("small")
                    }
                  }}
                >
                  {isPlaying &&
                  userList.id === playingTheme?.libraryPlaylistId ? (
                    <IconContainer url="/icons/player/ico_pause_bl.svg" />
                  ) : (
                    <IconContainer url="/icons/player/ico_play_bl.svg" />
                  )}
                </PlayButton>
              )}
              <MigratePlaylistModal
                playlistId={userList.id}
                accessLevel={userList.accessLevel}
              />
              {/* <ContextMenu
                trigger={
                  <ContextMenuButton left={<TbDots size={20} />}>
                    <Value name="apps.web.src.playlists.components.playlistmodalheader.opties">
                      Opties
                    </Value>
                  </ContextMenuButton>
                }
                items={
                  <>
                    {playingState ? (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item onClick={play}>
                          <Value name="apps.web.src.playlists.components.playlistmodalheader.liedjes_afspelen">
                            Liedjes afspelen
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ) : null}
                    <DropdownMenu.Item asChild>
                      <ContextMenu.Item
                        onClick={(e) => {
                          setIsNewPlaylistOpen(true)
                        }}
                      >
                        <Value name="afspeellijsten.lijst.aanpassen">
                          Nieuwe Afspeellijst
                        </Value>
                      </ContextMenu.Item>
                    </DropdownMenu.Item>
                    {userList.accessLevel !== "SCHOOL" && canEdit ? (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={(e) => {
                            setUpdatePlaylistVisible(true)
                          }}
                        >
                          <Value name="afspeellijsten.lijst.aanpassen">
                            Afspeellijst aanpassen
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ) : null}
                    {group ? (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={(e) => {
                            setSharePlaylistVisible(true)
                          }}
                        >
                          <Value name="afspeellijsten.lijst.delen">
                            Afspeellijst delen
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ) : null}
                    {canDelete ? (
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item
                          onClick={(e) => {
                            setDeletePlaylistVisible(true)
                          }}
                        >
                          <Value name="afspeellijsten.lijst.delete">
                            Afspeellijst verwijderen
                          </Value>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    ) : null}
                  </>
                }
              /> */}
              {/* {userList.accessLevel === "KIDS" ? (
                <ContextMenu
                  trigger={
                    <ContextMenuButton left={<TbMoodSmile size={20} />}>
                      <Value name="apps.web.src.playlists.components.playlistmodalheader.kids">
                        Kids
                      </Value>
                    </ContextMenuButton>
                  }
                  items={
                    <>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.LeerlingcodesPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.leerlingcodes">
                              Leerlingcodes
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.IngeleverdeOpdrachtenPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.ingeleverde_opdrachten">
                              Ingeleverde opdrachten
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link {...Routes.KidsPage()}>
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.kidspagina_preview">
                              Kidspagina preview
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item asChild>
                        <ContextMenu.Item asChild>
                          <Link
                            href="https://123zing.my.site.com/KnowlegdeBase/s/article/hoe-gebruik-ik-de-kidspagina"
                            target="_blank"
                          >
                            <Value name="apps.web.src.playlists.components.playlistmodalheader.uitleg_over_de_kidspagina">
                              Uitleg over de Kidspagina
                            </Value>
                          </Link>
                        </ContextMenu.Item>
                      </DropdownMenu.Item>
                    </>
                  }
                />
              ) : undefined} */}
              {userList.type === "THEME" &&
              userList.themeGroup?.theme?.category ? (
                <RoundedButton
                  as={Link}
                  {...Routes.ThemeIndexPage({
                    category: userList.themeGroup.theme.category.slug,
                    theme: userList.themeGroup.theme.slug,
                  })}
                >
                  <Value name="apps.web.src.schooljaren.components.playlistmodalheader.thema_openen">
                    Thema openen
                  </Value>
                </RoundedButton>
              ) : null}
              {userList.type === "LL" && userList.learningLine ? (
                <RoundedButton
                  as={Link}
                  {...getLearningLineLinkProps(userList.learningLine)}
                >
                  <Value name="apps.web.src.schooljaren.components.playlistmodalheader.leerlijn_openen">
                    Leerlijn openen
                  </Value>
                </RoundedButton>
              ) : null}
            </Group>
            {/* <Group gap="12px">
              {userList.accessLevel === "KIDS" ? (
                <ContextMenuButton as={Link} {...Routes.KidsPage()}>
                  <Value name="apps.web.src.playlists.components.playlistmodalheader.kidspagina_preview">
                    Kidspagina preview
                  </Value>
                </ContextMenuButton>
              ) : undefined}
            </Group> */}
          </Group>
        </Stack>
        {/*{video}*/}
      </Group>
      {/* <Dialog.Root
        open={isUpdatePlaylistVisible}
        onOpenChange={() => setUpdatePlaylistVisible(false)}
      >
        {isUpdatePlaylistVisible ? (
          <UpdateUserListModal
            key={`update-${isUpdatePlaylistVisible}`}
            userList={userList}
            onClose={() => setUpdatePlaylistVisible(false)}
          />
        ) : null}
      </Dialog.Root> */}

      {/* <AlertDialog.Root
        open={isDeletePlaylistVisible}
        onOpenChange={() => setDeletePlaylistVisible(false)}
      >
        <AlertModal
          title={
            <Stack gap="24px">
              <Modal.Title>
                <Value name="playlists.item.verwijderen.titel">
                  Afspeellijst verwijderen?
                </Value>
              </Modal.Title>
              <Text>
                <Value name="apps.web.src.playlists.components.playlistmodalheader.let_op_het_verwijderen_van_een_afspeellijst_kan_niet_ongedaan_gemaakt_worden">
                  Let op! Het verwijderen van een afspeellijst kan niet ongedaan
                  gemaakt worden.
                </Value>
              </Text>
            </Stack>
          }
          cancel={
            <Modal.CancelButton>
              <Value name="playlists.verwijderen.annuleren">
                Nee, annuleer
              </Value>
            </Modal.CancelButton>
          }
          submit={
            <Modal.SubmitButton
              onClick={async () => {
                await deletePlaylistMutation({
                  id: userList.id,
                })
                await invalidateQuery(getGroupedUserPlaylists)
                addToast({
                  content: (
                    <Value name="playlists.verwijderen.success">
                      Afspeellijst verwijderd
                    </Value>
                  ),
                })
              }}
            >
              <Value name="playlists.verwijderen.bevestigen">
                Ja, verwijder
              </Value>
            </Modal.SubmitButton>
          }
        />
      </AlertDialog.Root> */}
      <ShareModal.Controlled
        isOpen={isSharePlaylistVisible}
        onClose={() => setSharePlaylistVisible(false)}
        type="PLAYLIST"
        playlist={userList}
      />
    </Header>
  )
}
