import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { ORANGE_COLOR } from "theme/colors"
import Button from "./Button"

const TextButton = styled(Button)<{ color?: string }>`
  color: ${(p) => p.color || ORANGE_COLOR};
  padding: 0;
  font-weight: 700;
  display: inline-flex;
  gap: 5px;
  line-height: inherit;
  align-items: center;
  text-decoration: none;
`
TextButton.defaultProps = {
  type: "button",
}

export default TextButton
