import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Image from "app/files/components/Image"
import { BulletinBoardItemType } from "db"
import { PX12, PX16 } from "theme/sizes"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getBulletinAddKidsList from "../queries/getBulletinAddKidsList"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import { Content, DescriptionWrapper, Root } from "./BulletinAddListItem"

const PreviewSize = styled.div`
  width: 200px;
  height: 200px;
  pointer-events: none;
  border-radius: 8px;
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  flex: 0 0 240px;
  box-shadow: 2px 2px 10px #0000001a;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  width: inherit;
  height: inherit;
  overflow: hidden;
`

const BulletinAddModalKidsItem = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [kidsList] = useQuery(getBulletinAddKidsList, undefined)
  const currentBulletinBoardKidsWidget = bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.KIDS_LIST &&
      item.playlistId === kidsList[0]?.id
  )
  return (
    <Root>
      <Group gap="20px">
        <PreviewSize>
          <ImageGrid>
            {kidsList[0]?.playlist?.lessons.map((lesson) =>
              lesson.lesson?.listImage ? (
                <Image
                  key={lesson.id}
                  alt=""
                  file={lesson.lesson?.listImage}
                  width={150}
                  height={150}
                />
              ) : (
                <EmptyContainer>
                  <EmptyImage />
                </EmptyContainer>
              )
            )}
            {kidsList[0]?.playlist?.lessons &&
              [...Array(4 - kidsList[0].playlist?.lessons.length)].map(
                function () {
                  return (
                    <EmptyContainer>
                      <EmptyImage />
                    </EmptyContainer>
                  )
                }
              )}
          </ImageGrid>
        </PreviewSize>
        <Content>
          <Stack gap="24px">
            <Stack gap="4px">
              <Text size={PX12}>
                <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalkidsitem.widget">
                  Widget
                </Value>
              </Text>
              <Stack gap="12px">
                <Text size={PX16} bold>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalkidsitem.kidslijst">
                    Kidslijst
                  </Value>
                </Text>
              </Stack>
              <DescriptionWrapper>
                <Text size={PX12}>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalkidsitem.gebruik_je_de_kidspagina">
                    Gebruik je de Kidspagina? Met deze widget ga je direct naar
                    de Kidslijst van je groep. Op de Kidslijst zet de leerkracht
                    liedjes en lessen klaar. Leerlingen kunnen deze zelf doen.
                  </Value>
                </Text>
              </DescriptionWrapper>
            </Stack>
            <BulletinAddButton
              addProps={{
                type: BulletinBoardItemType.KIDS_LIST,
                playlistId: kidsList[0]?.id,
              }}
              deleteProps={
                currentBulletinBoardKidsWidget && {
                  id: currentBulletinBoardKidsWidget.id,
                }
              }
              currentBulletinBoardItem={currentBulletinBoardKidsWidget}
            />
          </Stack>
        </Content>
      </Group>
    </Root>
  )
}

export default BulletinAddModalKidsItem
