
export type GetHasSubmittableLesson = {
  subjects: {
    subjects: {
      subjects: {
        isSubmittable: boolean,
      }[]
    }[]
  }[]
}

export default function getHasSubmittable(les: GetHasSubmittableLesson) {
  return les.subjects.some((headgroup) =>
    headgroup.subjects.some((head) =>
      head.subjects.some((sub) => sub.isSubmittable)
    )
  )
}