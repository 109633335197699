import * as Header from "app/header/components/Header"
import { PropsWithChildren, useMemo } from "react"
import Layout from "./Layout"
import Image from "next/image"
import { GetLessonResult } from "app/slides/queries/getLesson"
import Group from "ui/Group"
import RoundedButton from "ui/RoundedButton"
import getLinkProps from "../utils/getLinkProps"
import { AnalyticsObject } from "app/analytics/utils/generateAnalyticsObject"

export default function PublicLayout(
  props: PropsWithChildren<{
    title?: string | null
    lesson?: GetLessonResult
    analyticsObject?: Partial<AnalyticsObject>
  }>
) {
  const { lesson } = props

  const analyticsObject = useMemo<Partial<AnalyticsObject>>(() => {
    return {
      ...props.analyticsObject,
      isPublicPage: true,
      userType: "PUBLIC",
    }
  }, [props.analyticsObject])

  return (
    <Layout {...props} analyticsObject={analyticsObject}>
      {/* {props.type ? <HelpButton type={props.type} /> : null} */}
      <Header.Root>
        <Header.Content>
          <Group gap="40px" grow>
            <Header.LogoRoot>
              <a
                href="https://www.123zing.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={"/123Zing_logo.svg"}
                  width={72}
                  height={55}
                  alt="Logo"
                />
              </a>
            </Header.LogoRoot>
            <Group grow align="center" justify="space-between" gap="20px">
              {(lesson?.publicButton1Label && lesson?.publicButton1Link) ||
              (lesson?.publicButton2Label && lesson?.publicButton2Link) ? (
                <Group align="center" gap="8px">
                  {lesson.publicButton1Label && lesson.publicButton1Link ? (
                    <RoundedButton
                      {...getLinkProps(lesson.publicButton1Link, "BLANK")}
                    >
                      {lesson.publicButton1Label}
                    </RoundedButton>
                  ) : null}
                  {lesson.publicButton2Label && lesson.publicButton2Link ? (
                    <RoundedButton
                      {...getLinkProps(lesson.publicButton2Link, "BLANK")}
                    >
                      {lesson.publicButton2Label}
                    </RoundedButton>
                  ) : null}
                </Group>
              ) : null}
              {lesson?.publicButtons.map((group) => (
                <Group align="center" gap="8px">
                  {group.buttons.map(
                    ({ label, id, target, link, ...button }) => (
                      <RoundedButton
                        {...button}
                        key={id}
                        {...getLinkProps(link, target)}
                      >
                        {label}
                      </RoundedButton>
                    )
                  )}
                </Group>
              ))}
            </Group>
          </Group>
        </Header.Content>
      </Header.Root>
      {props.children}
    </Layout>
  )
}
