import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Tabs from "@radix-ui/react-tabs"
import { Value } from "@stringtale/react"
import getBulletinThemeGroups from "app/bulletinboard/queries/getBulletinThemeGroups"
import { Root as BreadcrumbRoot } from "app/core/components/BreadcrumbItem"
import useFilePath from "app/files/hooks/useFilePath"
import Image from "next/image"
import { useEffect, useMemo, useState } from "react"
import { DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX16 } from "theme/sizes"
import { BOX_SHADOW } from "theme/styles"
import Group from "ui/Group"
import { Separator } from "ui/Separator"
import Stack from "ui/Stack"
import { THEME_SUB_TABS } from "../consts"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import getBulletinCategories, {
  GetBulletinCategoriesResult,
} from "../queries/getBulletinCategories"
import getCategoryThemes, {
  GetCategoryThemesResult,
} from "../queries/getCategoryThemes"
import BulletinAddThemeItem from "./BulletinAddThemeItem"
import ToggleButtons from "app/content-subjects/components/ToggleButtons"
import { ContentSubjectType } from "db"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"

const Root = styled.div`
  width: 100%;
`

const ListItems = styled(Stack)`
  margin-bottom: 200px;
`

const Grid = styled.div`
  display: grid;
  scroll-behavior: smooth;
  grid-template-columns: repeat(5, 1fr);
  gap: 64px 24px;
  grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  margin-bottom: 200px;
  align-items: flex-start;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemRoot = styled.div`
  text-decoration: none;
  color: ${DARK_BLUE_COLOR};
  width: 168px;
  display: grid;
  gap: 12px;
  cursor: pointer;
`

const ItemImage = styled.div<{ shape: "square" | "circle" }>`
  ${({ shape }) =>
    shape === "circle" ? `border-radius: 100px;` : `border-radius: 8px;`}
  width: 100%;
  overflow: hidden;
  width: 168px;
  height: 168px;
  ${BOX_SHADOW};
`

const ItemTitle = styled.div`
  font-size: ${PX16};
  text-align: center;
`

const BreadcrumbItem = styled(BreadcrumbRoot)<{ isActive: boolean }>`
  text-decoration: none;
  opacity: 1;
  ${({ isActive }) =>
    !isActive &&
    css`
      font-weight: 600;
      color: ${ORANGE_COLOR};
      cursor: pointer;
    `}
`

const CategoryItem = ({
  category,
}: {
  category: NonNullable<GetBulletinCategoriesResult>[number]
}) => {
  const image = useFilePath(category.listImage, {
    height: 168 * 2,
    width: 168 * 2,
    fit: "cover",
  })
  let linkArgs =
    category.isLastAdded && category.themes && category.themes.length > 0
      ? Routes.ThemeIndexPage({
          category: category.slug,
          theme: category.themes[0].slug,
        })
      : Routes.CategoryPage({
          category: category.slug,
        })
  // if (category.url) {
  //   linkArgs = {
  //     href: category.url,
  //     pathname: category.url,
  //   }
  // }

  return (
    <ItemRoot {...linkArgs}>
      <ItemImage shape={"circle"}>
        {image && (
          <Image
            src={image}
            width={168}
            height={168}
            alt={category.displayTitle}
            draggable={"false"}
          />
        )}
      </ItemImage>
      <ItemTitle>{category.displayTitle}</ItemTitle>
    </ItemRoot>
  )
}

const ThemeItem = ({
  theme,
}: {
  theme: NonNullable<GetCategoryThemesResult>[number]
}) => {
  const image = useFilePath(theme.listImage, {
    height: 168 * 2,
    width: 168 * 2,
    fit: "cover",
  })
  return (
    <ItemRoot>
      <ItemImage shape={"square"}>
        {image && (
          <Image
            src={image}
            width={168}
            height={168}
            alt={theme.displayTitle}
            draggable={"false"}
          />
        )}
      </ItemImage>
      <ItemTitle>{theme.displayTitle}</ItemTitle>
    </ItemRoot>
  )
}

const BulletinAddThemeTab = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [categories] = useQuery(getBulletinCategories, undefined)
  const [selectedCategorySlug, setSelectedCategorySlug] = useState<string>()
  const [selectedThemeSlug, setSelectedThemeSlug] = useState<string>()

  const [currentTab, setTab] = useState<THEME_SUB_TABS>(THEME_SUB_TABS.CATEGORY)

  const [subjects, setSubjects] = useState<ContentSubjectType[]>([])
  const currentSchool = useCurrentOrganization()

  const [categoryThemes] = useQuery(getCategoryThemes, {
    subjects,
    selectedCategorySlug,
  })
  const [themeGroups] = useQuery(getBulletinThemeGroups, {
    category: selectedCategorySlug,
    theme: selectedThemeSlug,
  })

  const selectedCategory = useMemo(
    () =>
      categories?.find((category) => category.slug === selectedCategorySlug),
    [categories, selectedCategorySlug]
  )

  const selectedTheme = useMemo(
    () => categoryThemes?.find((theme) => theme.slug === selectedThemeSlug),
    [categoryThemes, selectedThemeSlug]
  )

  useEffect(() => {
    if (!selectedCategorySlug && !selectedThemeSlug) {
      setTab(THEME_SUB_TABS.CATEGORY)
    } else if (selectedCategorySlug && !selectedThemeSlug) {
      setTab(THEME_SUB_TABS.THEME)
    } else if (selectedThemeSlug) {
      setTab(THEME_SUB_TABS.THEME_GROUP)
    }
  }, [selectedCategorySlug, selectedThemeSlug])

  return (
    <Root>
      <Stack gap={"32px"}>
        <Stack gap={"16px"}>
          <Group gap={"6px"} align="center">
            <BreadcrumbItem
              isActive={currentTab === THEME_SUB_TABS.CATEGORY}
              onClick={() => {
                if (currentTab !== THEME_SUB_TABS.CATEGORY) {
                  setSelectedCategorySlug(undefined)
                  setSelectedThemeSlug(undefined)
                }
              }}
            >
              <Value name="apps.web.src.bulletinboard.components.bulletinaddthemetab.categorie_n">
                Categorieën
              </Value>
            </BreadcrumbItem>
            {currentTab !== THEME_SUB_TABS.CATEGORY &&
              !selectedCategory?.isLastAdded && (
                <>
                  <span style={{ opacity: 0.5 }}>&#62;</span>
                  <BreadcrumbItem
                    isActive={currentTab === THEME_SUB_TABS.THEME}
                    onClick={() => {
                      if (currentTab !== THEME_SUB_TABS.THEME) {
                        setSelectedThemeSlug(undefined)
                      }
                    }}
                  >
                    {selectedCategory?.displayTitle}
                  </BreadcrumbItem>
                </>
              )}
            {currentTab === THEME_SUB_TABS.THEME_GROUP && (
              <>
                <span style={{ opacity: 0.5 }}>&#62;</span>
                <BreadcrumbItem
                  isActive={currentTab === THEME_SUB_TABS.THEME_GROUP}
                >
                  {selectedTheme?.displayTitle}
                </BreadcrumbItem>
              </>
            )}
          </Group>
          <Separator />
        </Stack>
        <Tabs.Root
          value={currentTab}
          onValueChange={(value: THEME_SUB_TABS) => setTab(value)}
        >
          <Tabs.Content value={THEME_SUB_TABS.CATEGORY}>
            <Grid>
              {categories.map((category) => (
                <ItemWrapper
                  key={category.id}
                  onClick={() => {
                    if (
                      category.isLastAdded &&
                      category.themes &&
                      category.themes.length > 0
                    ) {
                      setSelectedCategorySlug(category.slug)
                      setSelectedThemeSlug(category.themes[0].slug)
                    } else {
                      setSelectedCategorySlug(category.slug)
                    }
                  }}
                >
                  <CategoryItem category={category} />
                </ItemWrapper>
              ))}
            </Grid>
          </Tabs.Content>
          <Tabs.Content value={THEME_SUB_TABS.THEME}>
            <Stack gap={"24px"}>
              <Group gap={"8px"} justify="center" align="center">
                <ToggleButtons
                  {...{
                    vakken: subjects,
                    setVakken: setSubjects,
                    subscription: currentSchool?.subscription,
                  }}
                />
              </Group>
              <Grid>
                {categoryThemes?.map((theme) => (
                  <ItemWrapper
                    key={theme.id}
                    onClick={() => setSelectedThemeSlug(theme.slug)}
                  >
                    <ThemeItem theme={theme} />
                  </ItemWrapper>
                ))}
              </Grid>
            </Stack>
          </Tabs.Content>
          <Tabs.Content value={THEME_SUB_TABS.THEME_GROUP}>
            <ListItems gap="16px">
              {themeGroups?.map((themeGroup) => (
                <span key={themeGroup.id}>
                  <BulletinAddThemeItem
                    themeGroup={themeGroup}
                    selectedTheme={selectedTheme}
                    bulletinBoardItems={bulletinBoardItems}
                  />
                  <Separator />
                </span>
              ))}
            </ListItems>
          </Tabs.Content>
        </Tabs.Root>
      </Stack>
    </Root>
  )
}

export default BulletinAddThemeTab
