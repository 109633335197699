
import styled from "@emotion/styled"
import { DARK_BLUE_COLOR } from "theme/colors"
import { CONTEXT_MENU_Z_INDEX } from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import Button from "./Button"


export const Root = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: 12px;
  width: ${p => p.width || "284px"};
  ${MODAL_BOX_SHADOW};
  z-index: ${CONTEXT_MENU_Z_INDEX};
  font-family: ${poppins.style.fontFamily};
  position: relative;
`


export const Arrow = styled.div`
  fill: white;
`

export const Close = styled(Button)`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DARK_BLUE_COLOR};
  position: absolute;
  right: 0;
  top: 0;
`