import { useQuery } from "@blitzjs/rpc"

import getArchiveYears from "../queries/getArchiveYears"

export const useHasArchiveYears = () => {
  const [organizationArchiveYears] = useQuery(getArchiveYears, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  if (!organizationArchiveYears) {
    return false
  }

  return organizationArchiveYears.archiveYears.length > 0
}
