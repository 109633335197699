import NextImage from "next/image";
import { ComponentProps, useCallback } from "react";
import useFilePathContext from "utils/files/useFilePathContext";
import { File, Options } from "utils/files/types";

type ImageProps = { file: File; options?: Omit<Options, "width"> | null, ratio?: number } & Omit<
  ComponentProps<typeof NextImage>,
  "src"
>;

const Image = ({ file, options, ratio, ...props }: ImageProps) => {
  const getFilePath = useFilePathContext();
  return (
    <NextImage
      {...props}
      src={file.url}
      loader={useCallback(
        ({ src, width, quality }) => {
          let height: number | undefined = undefined
          if (ratio) {
            height = width * ratio
          }
          return getFilePath(file, {
            width,
            height,
            ...options
          });
        },
        [ratio, getFilePath, file, options]
      )}
    />
  );
};

export default Image
