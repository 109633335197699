type ContentSubjectType = "MUSIC" | "DRAMA" | "DANCE"

export const NAMES = {
  MUSIC: "Muziek",
  DRAMA: "Drama",
  DANCE: "Dans",
}

const getContentSubjectTitle = (contentSubject?: ContentSubjectType | null) => {
  if (!contentSubject) return null
  return NAMES[contentSubject]
}

export const getSubjectType = (input: string): ContentSubjectType => {
  switch (input) {
    case NAMES["DANCE"].toLowerCase():
      return "DANCE"
    case NAMES["MUSIC"].toLowerCase():
      return "MUSIC"
    case NAMES["DRAMA"].toLowerCase():
      return "DRAMA"
    default:
      return "MUSIC"
  }
}

export default getContentSubjectTitle