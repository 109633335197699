import styled from "@emotion/styled"
import LinkBase from "next/link"

const Link = styled(LinkBase)<{
  underline?: boolean
  bold?: boolean
}>`
  color: inherit;
  text-decoration: ${(p) => (p.underline ? "underline" : "none")};
  ${(p) => p.bold && "font-weight: 600;"}
  // Hide when it all children are hidden
  &:empty, &:not(:has(*:not(:hidden))) {
    display: none;
  }
`

export default Link
