import styled from "@emotion/styled"
import { poppins } from "theme/fonts"
import { ComponentProps, forwardRef } from "react"
import {
  DARK_BLUE_COLOR,
  DARK_ORANGE_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
  PX12,
  PX14,
  PX16,
} from "theme/consts"
import Button from "ui/Button"

type Colors = {
  background?: string
  border?: string
  text?: string
}

const Root = styled(Button)<{ isActive?: boolean; colors?: Colors, size?: "large" | "small" }>`
  background: ${(p) =>
    p.colors?.background ?? (p.isActive ? ORANGE_COLOR : "white")};
  border-radius: 25px;
  opacity: 1;
  padding: ${p => p.size === "small" ? "4px 12px" : "9px 22px"};
  color: ${(p) => (p.colors?.text ?? (p.isActive ? "white" : DARK_BLUE_COLOR))};
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: ${p => p.size === "small" ? PX12 : PX16};
  text-decoration: none;
  text-align: center;
  /* border: none; */
  border: 2px solid
    ${(p) =>
      p.colors?.border ?? (p.isActive
        ? ORANGE_COLOR
        : LIGHT_GREY_COLOR)};
  cursor: pointer;

  svg {
    transition: transform 150ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const Content = styled.div`
  font-size: ${PX14};
  font-weight: 600;
`

type Props = ComponentProps<typeof Root> & {
  left?: React.ReactNode
  right?: React.ReactNode
}

export default forwardRef<HTMLButtonElement, Props>(function RoundedButton(
  { left, right, children, ...props },
  ref
) {
  return (
    <Root {...props} ref={ref}>
      {left}
      <Content>{children}</Content>
      {right}
    </Root>
  )
})
