import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { ContentSubjectType, Grade } from "db"
import { DARK_BLUE_COLOR, GREEN_COLOR } from "theme/colors"
import { PX10 } from "theme/sizes"
import getContentSubjectColor from "web/src/content-subjects/getContentSubjectColor"

export const Root = styled.div`
  display: flex;
  gap: 4px;
`

export const Item = styled.div<{
  isActive?: boolean
  contentSubjectType?: ContentSubjectType
}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: ${PX10};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => (p.isActive ? 600 : 400)};
  background-color: ${(p) =>
    p.isActive
      ? p.contentSubjectType
        ? getContentSubjectColor(p.contentSubjectType)
        : GREEN_COLOR
      : "#EEEEEE"};
  color: ${(p) => (p.isActive ? "white" : `${DARK_BLUE_COLOR}80`)};
`

type Props = {
  activeGrades: Grade[]
  allGrades: Grade[]
  contentSubjectType?: ContentSubjectType
  onChange?: (grade: Grade) => void
}

const GradeIcons = ({ activeGrades, allGrades, contentSubjectType, onChange }: Props) => {
  return (
    <Root>
      {allGrades.map((grade) => (
        <Item
          isActive={!!activeGrades.find((g) => g.id === grade.id)}
          contentSubjectType={contentSubjectType}
          key={grade.id}
          onClick={() => {
            onChange?.(grade)
          }}
        >
          {grade.number}
        </Item>
      ))}
    </Root>
  )
}

export default GradeIcons
