import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { ReactNode } from "react"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import Stack from "ui/Stack"

const AlertModal = ({
  title,
  cancel,
  submit,
  body,
}: {
  title: ReactNode
  body?: ReactNode
  cancel?: ReactNode
  submit?: ReactNode
}) => {
  return (
    <AlertDialog.Portal>
      <AlertDialog.Overlay asChild>
        <Modal.Overlay isAlert />
      </AlertDialog.Overlay>
      <AlertDialog.Content asChild>
        <Modal.Root isAlert>
          <Modal.Content>
            <Stack gap="24px">
              <Stack gap="10px">
                {title}
                <Hr />
              </Stack>
              {body && <div>{body}</div>}
              <Modal.Buttons>
                {cancel && <AlertDialog.Cancel asChild>{cancel}</AlertDialog.Cancel>}
                {submit && <AlertDialog.Action asChild>{submit}</AlertDialog.Action>}
              </Modal.Buttons>
            </Stack>
          </Modal.Content>
          <AlertDialog.Cancel asChild>
            <Modal.Close />
          </AlertDialog.Cancel>
        </Modal.Root>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  )
}
export default AlertModal
