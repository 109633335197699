import styled from "@emotion/styled"
import { motion } from "framer-motion"
import {
  LIGHT_ORANGE_COLOR
} from "theme/consts"
import Group from "ui/Group"
import InputBase from "ui/Input"
import Stack from "./Stack"

const RADIUS = "16px"

export const Root = styled(Stack)`
  border-radius: ${RADIUS};
  border: 1px solid #b2b1c9;
  flex: 1;
  overflow: hidden;
`.withComponent(motion.div)

export const Header = styled(Group)`
  width: 100%;
  border-bottom: 1px solid #b2b1c9;
`

export const Content = styled.div`
  padding: 8px 16px 8px 8px;
`

export const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  padding: 0 16px;
`

export const InputWrapper = styled(Group)`
  width: 100%;
  align-items: stretch;
`

export const Input = styled(InputBase)`
  background: white;
  flex: 1;
  border-radius: 0px 16px 0px 0px;
  width: 100%;
  border: none;
  border-left: 1px solid #b2b1c9;
`

export const Item = styled.label<{ isActive?: boolean }>`
  gap: 16px;
  padding: 12px 12px;
  align-items: center;
  background-color: ${(p) => (p.isActive ? LIGHT_ORANGE_COLOR : "white")};
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
`
