import styled from "@emotion/styled"
import { Value, htmlFormatTags } from "@stringtale/react"
import Image from "next/image"
import { PX14 } from "theme/sizes"
import ContainerBase, { Content } from "ui/Container"
import Group from "ui/Group"
import { FormatValue } from "./FormatValue"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import Button from "ui/Button"
import { useSessionStorage } from "@mantine/hooks"

const Root = styled.div`
  background-color: #e0673c;
  color: white;
  font-size: ${PX14};
`

const Container = styled(ContainerBase)`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export default function MaintenanceBar({ message }: { message?: string }) {
  const [isHidden, setHidden] = useSessionStorage({
    key: "maintenance",
    defaultValue: "false",
  })
  if (!message || isHidden === "true") {
    return null
  }
  return (
    <Root>
      <Container>
        <Group gap="8px" justify="flex-start">
          <Image src="/icons/ico_megph_wh.svg" width={24} height={24} alt="" />
          <div>
            {message === "true" ? (
              <Value name="apps.web.src.core.components.maintenancebar.maintenance" format={htmlFormatTags} version="1"
              >
                {`<b>
                Technisch onderhoud maandag 29 april.</b>
                Tussen 10.00-16.00 uur kan 123ZING tijdelijk niet bereikbaar zijn.`}
              </Value>
            ) : (
              <FormatValue format={htmlFormatTags}>{message}</FormatValue>
            )}
          </div>
        </Group>
        <Button onClick={() => setHidden("true")}>
          <FaTimes size={16} />
        </Button>
      </Container>
    </Root>
  )
}
