import { ContentSubjectType } from "db"

const MAP = {
  "MUSIC": "muziek",
  "DRAMA": "drama",
  "DANCE": "dans"
}

export const getContentSubjectFromSlug = (subject: string): ContentSubjectType => {
  switch (subject) {
    case "muziek":
      return "MUSIC"
    case "drama":
      return "DRAMA"
    case "dans":
      return "DANCE"
    default:
      return "MUSIC"
  }
}


export const getSlugFromContentSubject = (contentSubject: ContentSubjectType) => {
  return MAP[contentSubject]
}