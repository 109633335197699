import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { RichText } from "app/admin/components/RichText"
import getAllGrades from "app/grades/queries/getAllGrades"
import {
  Content as ContentBase,
  ImageRoot,
  Root as RootBase,
} from "app/lessons/components/LessonListItem"
import { Grade, Prisma } from "db"
import React from "react"
import { ReactNode } from "react"
import { PX12, PX16 } from "theme/consts"
import Grades from "ui/Grades"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"

export const Root = styled(RootBase)`
  flex-grow: 1;
  padding: 16px 0;
  box-sizing: border-box;
  min-height: 170px;
`

const ImageWrapper = styled(ImageRoot)`
  flex: 0 0 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const TitleGroup = styled(Group)`
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

const HeavyText = styled(Text)`
  font-weight: 700;
`

export const DescriptionWrapper = styled.div`
  line-height: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const Content = styled(ContentBase)`
  width: 100%;
`

const BulletinAddListItem = ({
  activeGrades,
  image,
  right,
  heading,
  title,
  description,
}: {
  activeGrades?: Grade[]
  image?: ReactNode
  right?: ReactNode
  heading?: ReactNode | string
  title?: ReactNode | string
  description?: Prisma.JsonValue | string | ReactNode
}) => {
  const [allGrades] = useQuery(getAllGrades, undefined)
  return (
    <Root>
      <Group gap="20px">
        {image && <ImageWrapper>{image}</ImageWrapper>}
        <Content>
          <Stack gap="4px">
            {heading && <Text size={PX12}>{heading}</Text>}
            <Stack gap="12px">
              <TitleGroup>
                <HeavyText size={PX16} bold>
                  {title}
                </HeavyText>
              </TitleGroup>
              {activeGrades && (
                <Grades
                  allGrades={allGrades}
                  activeGrades={activeGrades}
                  // contentSubjectType={theme.contentSubjects[0].type}
                />
              )}
            </Stack>
            {description ? (
              <DescriptionWrapper>
                {React.isValidElement(description) ? (
                  <>{description}</>
                ) : typeof description === "string" ? (
                  <Text size={PX12}>{description}</Text>
                ) : (
                  <RichText body={description} />
                )}
              </DescriptionWrapper>
            ) : null}
          </Stack>
        </Content>
      </Group>
      {right}
    </Root>
  )
}

export default BulletinAddListItem
