import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addSeenPopover from "app/onboarding/mutations/addSeenPopover"
import getCurrentUser from "app/users/queries/getCurrentUser"
import { PageType } from "db"
import { useCurrentUser } from "./useCurrentUser"



export default function usePopoverSeen(pageType: PageType) {
  const [seenPopoverMutation] = useMutation(addSeenPopover)
  const currentUser = useCurrentUser(pageType)
  if (!currentUser) return { state: "CLOSED", toggle: (state: "MINIMIZED" | "CLOSED" | "OPENED") => { } } as const

  const toggle = async (state: "MINIMIZED" | "CLOSED" | "OPENED") => {
    // Update the state of the popover
    await seenPopoverMutation({ pageType, state })
    await invalidateQuery(getCurrentUser)
  }

  if (currentUser.seenPopovers[0]?.state) {
    return { state: currentUser.seenPopovers[0].state, toggle } as const
  }

  return { state: "OPENED", toggle } as const
}