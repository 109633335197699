import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { GREEN_COLOR } from "theme/colors"
import { PX12 } from "theme/sizes"

const Badge = styled.div<{
  hasShadow?: boolean
  bold?: boolean
  backgroundColor?: string
  color?: string
}>`
  border-radius: 4px;
  ${(p) =>
    p.hasShadow &&
    css`
      box-shadow: 0px 3px 6px #00000029;
    `}
  color: #ffffff;
  font-size: ${PX12};
  padding: 1px 6px;
  font-weight: 600;
  background-color: ${(p) => p.backgroundColor || GREEN_COLOR};
  color: ${(p) => p.color || "white"};
  ${(p) =>
    p.bold &&
    css`
      font-weight: bold;
    `}
`

export default Badge
