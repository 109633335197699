// import { RouteUrlObject } from "blitz"
import { useRouter } from "next/router"
// import { ParsedUrlQuery } from "querystring"
import { useCallback } from "react"
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'node:querystring'
import { getIsPublic } from "./useIsPublic"

type RouteUrlObject = {
  pathname: string
  href?: string | undefined
  query?: string | null | ParsedUrlQueryInput | undefined
}

/**
 * Redirect links to public of kids page when needed
 */

export default function useLinks() {
  const router = useRouter()
  return useCallback(<T extends RouteUrlObject>(route: T, type?: "lesson-page") => {
    return getLink(router.query, route, type)
  }, [router.query])
}

const prefixUrl = (prefix: string) => <T extends RouteUrlObject>(id: string | number, route?: T) => {
  if (!route) return { pathname: `/${prefix}/${id}`, href: `/${prefix}/${id}`, }
  if (route.href) {
    route.href = `/${prefix}/${id}${route.href}`
  }
  route.pathname = `/${prefix}/${id}${route.pathname}`
  return route
}

export const prefixSubmitted = prefixUrl("ingeleverd")
export const prefixExamine = prefixUrl("nakijken")
export const prefixReopened = prefixUrl("heropened")


export function getLink<T extends RouteUrlObject>(query: ParsedUrlQuery, oldRoute: T, type?: "lesson-page") {
  const isPublic = getIsPublic(query)
  const route = { ...oldRoute }
  if (isPublic) {
    if (route.href) {
      route.href = `/publiek${route.href}`
    }
    route.pathname = `/publiek${route.pathname}`
  }
  const subdomain = query.__subdomain
  if (subdomain === "kids") {
    if (route.href) {
      route.href = route.href.replace('/kids/preview', '') || "/"
    }
    route.pathname = route.pathname.replace('/kids/preview', '') || "/"
  } else if (query.__kidsPreview === "true" && !route.href?.includes("/kids/preview")) {
    if (route.href) {
      route.href = '/kids/preview' + route.href
    }
    route.pathname = '/kids/preview' + route.pathname
  }
  if (query.__submitted && type !== "lesson-page") {
    return prefixSubmitted(query.__submitted as string, route)
  }
  if (query.__examine && type !== "lesson-page") {
    return prefixExamine(query.__examine as string, route)
  }
  if (query.__reopened && type !== "lesson-page") {
    return prefixReopened(query.__reopened as string, route)
  }
  return route

}