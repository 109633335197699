import styled from "@emotion/styled"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { Dispatch, SetStateAction, useState } from "react"
import { PX16 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import RoundedButton from "ui/RoundedButton"
import { Separator } from "ui/Separator"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Title from "ui/Title"
import toggleArray from "utils/global/toggleArray"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
} from "../../core/components/Drawer"
import { AllowedBulletinGroupListTypes } from "./BulletinAddModalContent"
import { useUserGroups } from "app/core/hooks/useUserGroups"

const FilterButton = styled(RoundedButton)`
  border-radius: 8px;
  height: 46px;
`

export default function FilterButtons({
  filteredTypes,
  setFilteredTypes,
}: {
  filteredTypes: AllowedBulletinGroupListTypes[]
  setFilteredTypes: Dispatch<SetStateAction<AllowedBulletinGroupListTypes[]>>
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const userGroups = useUserGroups()
  const typeFilter = (
    <>
      <Title size={PX16}>
        <Value name="apps.web.src.bulletinboard.components.filterbuttons.lijst_type">
          Lijst type
        </Value>
      </Title>
      <Stack gap="8px">
        {userGroups && userGroups.length > 0 ? (
          <Group wrap gap="8px" align="center" justify="flex-start">
            <Checkbox
              name={`${AllowedBulletinGroupListTypes.GROUP_LISTS}`}
              value={AllowedBulletinGroupListTypes.GROUP_LISTS}
              defaultChecked={filteredTypes?.some(
                (type) => type === AllowedBulletinGroupListTypes.GROUP_LISTS
              )}
              onCheckedChange={() => {
                setFilteredTypes(
                  toggleArray(AllowedBulletinGroupListTypes.GROUP_LISTS)
                )
              }}
              label={AllowedBulletinGroupListTypes.GROUP_LISTS}
            />
          </Group>
        ) : (
          <Group wrap gap="8px" align="center" justify="flex-start">
            <Checkbox
              name={`${AllowedBulletinGroupListTypes.PERSONAL_LISTS}`}
              value={AllowedBulletinGroupListTypes.PERSONAL_LISTS}
              defaultChecked={filteredTypes?.some(
                (type) => type === AllowedBulletinGroupListTypes.PERSONAL_LISTS
              )}
              onCheckedChange={() => {
                setFilteredTypes(
                  toggleArray(AllowedBulletinGroupListTypes.PERSONAL_LISTS)
                )
              }}
              label={AllowedBulletinGroupListTypes.PERSONAL_LISTS}
            />
          </Group>
        )}
        <Group wrap gap="8px" align="center" justify="flex-start">
          <Checkbox
            name={`${AllowedBulletinGroupListTypes.THEME_LISTS}`}
            value={AllowedBulletinGroupListTypes.THEME_LISTS}
            defaultChecked={filteredTypes?.some(
              (type) => type === AllowedBulletinGroupListTypes.THEME_LISTS
            )}
            onCheckedChange={() => {
              setFilteredTypes(
                toggleArray(AllowedBulletinGroupListTypes.THEME_LISTS)
              )
            }}
            label={AllowedBulletinGroupListTypes.THEME_LISTS}
          />
        </Group>
        <Group wrap gap="8px" align="center" justify="flex-start">
          <Checkbox
            name={`${AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS}`}
            value={AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS}
            defaultChecked={filteredTypes?.some(
              (type) =>
                type === AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS
            )}
            onCheckedChange={() => {
              setFilteredTypes(
                toggleArray(AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS)
              )
            }}
            label={AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS}
          />
        </Group>
      </Stack>
    </>
  )

  return (
    <Group gap="6px">
      <FilterButton
        onClick={() => setIsDrawerOpen(true)}
        variant="filled"
        left={<FaSlidersH />}
      >
        <Value name="apps.web.src.studio.components.studiothemegroup.filter">
          Filter
        </Value>
      </FilterButton>
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>
              <Stack gap="12px">
                <Text bold>
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter">
                    Filter
                  </Value>
                </Text>
                <Separator />
                <Stack gap="16px">{typeFilter}</Stack>
                <Separator />
              </Stack>
            </DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </Group>
  )
}
