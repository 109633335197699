import { ContentSubjectType } from "db"
import { GREEN_COLOR, LIGHT_BLUE_COLOR, PURPLE_COLOR } from "theme/colors"

const COLORS = {
  MUSIC: {
    dark: "#0F725D",
    medium: GREEN_COLOR,
    light: "#F0F9F7",
    border: "#CEE5E0",
  },
  DANCE: {
    dark: "#19608D",
    medium: LIGHT_BLUE_COLOR,
    light: "#EFF9FF",
    border: "#CFE2EE",
  },
  DRAMA: {
    dark: "#540F72",
    medium: PURPLE_COLOR,
    light: "#F5F0F7",
    border: "#ECE2F0",
  },
}

export default function getContentSubjectColor(
  contentSubject: ContentSubjectType,
  type: "dark" | "medium" | "light" | "border" = "dark"
) {
  return COLORS[contentSubject][type]
}
