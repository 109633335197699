import styled from "@emotion/styled"
import Image from "next/image"
import { css } from "@emotion/react"

const IconRoot = styled(Image)<{ sizeWidth?: string; sizeHeight?: string }>`
  ${(props) =>
    props.sizeWidth
      ? css`
          width: ${props.sizeWidth};
        `
      : css`
          width: 26px;
        `}
  ${(props) =>
    props.sizeHeight
      ? css`
          height: ${props.sizeHeight};
        `
      : css`
          height: 26px;
        `}
`

type Props = {
  url: string
  width?: string
  height?: string
}

const IconContainer = ({ url, width, height }: Props) => {
  return (
    <IconRoot
      src={url}
      alt=""
      width={26}
      height={26}
      sizeWidth={width}
      sizeHeight={height}
    />
  )
}

export default IconContainer
