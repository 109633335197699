import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown"
import ContextMenu from "ui/ContextMenu"
import TextButton from "ui/TextButton"


export const SORT_OPTIONS_GLOBAL = [
  "CUSTOM",
  "DATE_DESC",
  "DATE_ASC",
  "TITLE_ASC",
  "TITLE_DESC",
] as const

export const SORT_OPTIONS_GLOBAL_DISPLAY = {
  CUSTOM: "123ZING sortering",
  DATE_ASC: "Oud - Nieuw",
  DATE_DESC: "Nieuw - Oud",
  TITLE_ASC: "Titel (A-Z)",
  TITLE_DESC: "Titel (Z-A)",
}

export type SortOptionGlobal = typeof SORT_OPTIONS_GLOBAL[number]


const PlaylistSortField = ({
  sort,
  onSort,
}: {
  sort: SortOptionGlobal
  onSort: (sort: SortOptionGlobal) => void
}) => {
  return (
    <ContextMenu
      trigger={
        <TextButton>
          {SORT_OPTIONS_GLOBAL_DISPLAY[sort]}{" "}
          <FaCaretDown />
        </TextButton>
      }
      items={
        <>
          {SORT_OPTIONS_GLOBAL.map((option) => (
            <DropdownMenu.Item
              asChild
              onSelect={async () => {
                onSort(option)
              }}
            >
              <ContextMenu.Item
                isActive={option === sort}
              >
                {SORT_OPTIONS_GLOBAL_DISPLAY[option]}
              </ContextMenu.Item>
            </DropdownMenu.Item>
          ))}
        </>
      }
    />
  )
}

export default PlaylistSortField
