import SuperJson from "superjson"

export class NoOrganizationSelectedError extends Error {
  name = "NoOrganizationSelectedError"
}

SuperJson.registerClass(NoOrganizationSelectedError, {
  identifier: "NoOrganizationSelectedError",
})

export class NoGroupsError extends Error {
  name = "NoGroupsError"
}

SuperJson.registerClass(NoGroupsError, { identifier: "NoGroupsError" })

export class ShowGroupMigrationError extends Error {
  name = "ShowGroupMigrationError"
}

SuperJson.registerClass(ShowGroupMigrationError, {
  identifier: "ShowGroupMigrationError",
})

export class NoGroupSelectedError extends Error {
  name = "NoGroupSelectedError"
}

SuperJson.registerClass(NoGroupSelectedError, {
  identifier: "NoGroupSelectedError",
})

export class SchoolNoLongerAvailable extends Error {
  name = "SchoolNoLongerAvailable"
}

SuperJson.registerClass(SchoolNoLongerAvailable, {
  identifier: "SchoolNoLongerAvailable",
})

export class MainSchoolNotActiveError extends Error {
  name = "MainSchoolNotActiveError"
}

SuperJson.registerClass(MainSchoolNotActiveError, {
  identifier: "MainSchoolNotActiveError",
})

export class UserNotActiveError extends Error {
  name = "UserNotActiveError"
}

SuperJson.registerClass(UserNotActiveError, {
  identifier: "UserNotActiveError",
})

export class IncompleteFederatedCredentialsError extends Error {
  name = "IncompleteFederatedCredentialsError"
}

SuperJson.registerClass(IncompleteFederatedCredentialsError, {
  identifier: "IncompleteFederatedCredentialsError",
})

export class DuplicateCredentialsError extends Error {
  name = "DuplicateCredentialsError"
}

SuperJson.registerClass(DuplicateCredentialsError, {
  identifier: "DuplicateCredentialsError",
})

export class ExceedGroupCountError extends Error {
  name = "ExceedGroupCountError"
}

SuperJson.registerClass(ExceedGroupCountError, {
  identifier: "ExceedGroupCountError",
})

export class ExceedUserCountError extends Error {
  name = "ExceedUserCountError"
}

SuperJson.registerClass(ExceedUserCountError, {
  identifier: "ExceedUserCountError",
})

export class ContentNotAvailableError extends Error {
  name = "ContentNotAvailableError"
}

SuperJson.registerClass(ContentNotAvailableError, {
  identifier: "ContentNotAvailableError",
})

export class CantAddManagerDuringTrialError extends Error {
  name = "CantAddManagerDuringTrialError"
}

SuperJson.registerClass(CantAddManagerDuringTrialError, {
  identifier: "CantAddManagerDuringTrialError",
})

export class CantRemoveLastManager extends Error {
  name = "CantRemoveLastManager"
}

SuperJson.registerClass(CantRemoveLastManager, {
  identifier: "CantRemoveLastManager",
})

export class KidOrganizationNotActiveError extends Error {
  name = "KidOrganizationNotActiveError"
}

SuperJson.registerClass(KidOrganizationNotActiveError, {
  identifier: "KidOrganizationNotActiveError",
})

export class KidGroupIsArchivedError extends Error {
  name = "KidGroupIsArchivedError"
}

SuperJson.registerClass(KidGroupIsArchivedError, {
  identifier: "KidGroupIsArchivedError",
})

export class MultipleSessionsError extends Error {
  name = "MultipleSessionsError"
}

SuperJson.registerClass(MultipleSessionsError, {
  identifier: "MultipleSessionsError",
})
