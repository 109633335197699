import { Value } from "@stringtale/react"
import Avatar from "app/files/components/Avatar"
import getFullname from "utils/users/getFullName"
import { Dispatch, SetStateAction, useId } from "react"
import { PX12, PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import { GetShareUsersResult } from "../queries/getShareUsers"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import { Item as ItemRoot } from "ui/FilterSelect"

export const UserItem = ({
  user: { user, groups }, selectedUsers, setSelectedUsers,
}: {
  user: GetShareUsersResult[number]
  selectedUsers: number[]
  setSelectedUsers: Dispatch<SetStateAction<number[]>>
}) => {
  return (
    <ItemRoot
      key={user.id}
      isActive={selectedUsers.includes(user.id)}
    >
      <Checkbox
        checked={selectedUsers.includes(user.id)}
        onCheckedChange={(checked) => {
          if (checked !== "indeterminate") {
            setSelectedUsers(toggleArray(user.id, checked))
          }
        }} />
      <Group gap="8px">
        <Avatar user={user} />
        <Stack align="flex-start">
          <Text size={PX14}>
            <Text bold size={PX14}>
              {getFullname(user)}
            </Text>
            {user.shareGuests.length > 0 ? (
              <Text size={PX12} color={DIMMED_BLUE_COLOR}>
                {" • "}
                <Value
                  name="apps.web.src.share.components.sharemodal.gedeeld_op"
                  format={{ date: user.shareGuests[0].createdAt }}
                  locales="nl"
                >
                  {`Gedeeld op: {date, date}`}
                </Value>
              </Text>
            ) : null}
          </Text>
          <Text size={PX12}>
            {groups.map((group) => group.displayTitle).join(", ")}
          </Text>
        </Stack>
      </Group>
    </ItemRoot>
  )
}
