import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR } from "theme/colors"
import styled from "@emotion/styled"
import { RADIO_PLAYER_Z_INDEX } from "theme/consts"
import { useAtomValue, useAtom } from "jotai"
import {
  isPlayingAtom,
  playingStateAtom,
  displayStateAtom,
} from "app/radio/state"
import { useEffect, useState } from "react"
import { css } from "@emotion/react"
import { useRouter } from "next/router"
import { useElementSize } from "@mantine/hooks"
import { AnimatePresence, motion } from "framer-motion"
import IconContainer from "app/player/components/IconContainer"
import { DisplayStateType } from "app/radio/types"
import RadioBoxContent from "app/radio/components/RadioBoxContent"
import { TRANSITION } from "app/radio/consts"
import { usePlay } from "app/radio/components/PlayerContext"
import { parseAsBoolean, parseAsString, useQueryState } from "nuqs"
import { MODAL_QUERY_ARCHIVE_KEY, MODAL_QUERY_KEY } from "app/playlists/consts"

const PADDING_OFFSET = 12

const Wrapper = styled.div`
  position: fixed;
  left: -${PADDING_OFFSET}px;
  bottom: 20px;
  height: 90px;
  z-index: ${RADIO_PLAYER_Z_INDEX};
  pointer-events: none !important;
`

const RadioWrapper = styled.div<{
  displayState: DisplayStateType
  libraryOpen: boolean
}>`
  border-radius: 8px;
  ${(p) => !p.libraryOpen && `padding: 0 0 0 ${PADDING_OFFSET}px`};
  background-color: ${DARK_BLUE_COLOR};
  width: ${(p) =>
    p.displayState === "large" || p.libraryOpen
      ? "840px"
      : p.displayState === "small"
        ? "420px"
        : null};
  max-width: 960px;
  pointer-events: all !important;
`

export const Content = styled.div`
  height: 90px;
  display: grid;
  align-items: center;
  gap: 8px;
`

const Root = styled(motion.div)<{
  displayState: DisplayStateType
  libraryOpen: boolean
}>`
  box-sizing: border-box;
  /*border-radius: 0 8px 8px 0;*/
  background-color: ${DARK_BLUE_COLOR};
  color: white;
  /*padding: 0 16px 0 8px;*/
  container-type: inline-size;
  position: absolute;
  z-index: ${RADIO_PLAYER_Z_INDEX};
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);

  ${Content} {
    ${(p) =>
      p.libraryOpen
        ? css`
            grid-template-columns: 290px 270px 1fr;
          `
        : p.displayState === "small"
          ? css`
              grid-template-columns: 32px 1fr auto;
            `
          : css`
              grid-template-columns: 32px 290px 270px 1fr;
              @container (width < calc(840px - 24px)) {
                grid-template-columns: 32px 290px 155px 1fr;
              }
              @container (width < calc(770px - 24px)) {
                grid-template-columns: 32px auto auto auto;
              }
            `}
  }
`

const CollapseButton = styled(motion.div)`
  ${TRANSITION};
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${DIMMED_BLUE_COLOR};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  height: 58px;
  border-radius: 0 10px 10px 0;
  color: white;
  padding: 0 8px 0 0;
  z-index: 1000;
  pointer-events: all !important;

  &:hover {
    background-color: #5d5c77;
    width: 44px;
  }
`

const ExpandButton = styled(CollapseButton)`
  background-color: ${DIMMED_BLUE_COLOR};
  width: 40px;
  height: 90px;
  transform: unset;
  &:hover {
    width: 40px;
  }
`

const RadioBox = () => {
  const router = useRouter()
  const { ref, width, height } = useElementSize()
  const playingState = useAtomValue(playingStateAtom)
  const [displayState, setDisplayState] = useAtom(displayStateAtom)

  const { pause } = usePlay()

  const [queryParam, setOpen] = useQueryState(MODAL_QUERY_KEY)
  const [archiveQueryParam, setArchiveOpen] = useQueryState(
    MODAL_QUERY_ARCHIVE_KEY
  )
  const open = queryParam !== null || archiveQueryParam !== null

  //Check whether radio should be collapsed or expanded when routed to a different page.
  /* useEffect(() => {
    if (
      open &&
      (displayState === "closed" || displayState === "hidden") &&
      playingState
    ) {
      setDisplayState("small")
    }
  }, [setDisplayState, open, displayState, playingState]) */

  //Hide radio on admin, kids, and lesson/rehearsal pages.
  if (
    router.pathname.startsWith("/admin/") ||
    // router.pathname.startsWith("/kids/") ||
    router.pathname.startsWith("/les/[lesson]/[head]")
  ) {
    pause()
    return null
  }

  return (
    <Wrapper id="radiobox">
      <AnimatePresence mode="popLayout">
        {displayState !== "closed" && displayState !== "hidden" ? (
          <motion.div
            key="collapse"
            initial={
              open
                ? {
                    x: `calc((50vw - 50%) + ${PADDING_OFFSET / 2}px + 138px)`,
                    y: 180,
                  }
                : displayState === "large"
                  ? { x: -880 }
                  : { x: -460 }
            }
            animate={
              open
                ? {
                    x: `calc((50vw - 50%) + ${PADDING_OFFSET / 2}px + 138px)`,
                    y: -25,
                  }
                : { x: 0, y: 0 }
            }
            exit={
              open
                ? { y: 180 }
                : displayState === "large"
                  ? { x: -880 }
                  : { x: -500 }
            }
            transition={{
              type: "spring",
              bounce: open ? 0.35 : 0.065,
              duration: open ? 0.8 : 0.6,
              delay: open ? 0.1 : 0,
            }}
          >
            <RadioWrapper displayState={displayState} libraryOpen={open}>
              {!open && (
                <CollapseButton
                  animate={{
                    y: "25%",
                    x:
                      displayState === "small"
                        ? 420 - 6
                        : displayState === "large"
                          ? 840 - 6
                          : 1,
                  }}
                  transition={{
                    type: "spring",
                    bounce: 0.2,
                    duration: displayState === "small" ? 0 : 0.1,
                  }}
                  onClick={() => {
                    if (displayState === "small")
                      return setDisplayState("large")
                    else return setDisplayState("small")
                  }}
                >
                  {displayState === "large" ? (
                    <IconContainer
                      url="/icons/player/ico_chev_left_w.svg"
                      width="20px"
                      height="25px"
                    />
                  ) : (
                    <IconContainer
                      url="/icons/player/ico_chev_right_w.svg"
                      width="25px"
                      height="25px"
                    />
                  )}
                </CollapseButton>
              )}
              <Root
                ref={ref}
                displayState={displayState}
                libraryOpen={open}
                initial={false}
                animate={{
                  width:
                    displayState === "large" || open
                      ? 840
                      : displayState === "small"
                        ? 420
                        : 1,
                  padding: open ? "0 16px 0 16px" : "0 16px 0 8px",
                  borderRadius: open ? "8px" : "0 8px 8px 0",
                }}
                transition={{
                  type: "spring",
                  bounce: 0.2,
                  duration: 0.4,
                }}
              >
                <RadioBoxContent containerRef={ref} libraryOpen={open} />
              </Root>
            </RadioWrapper>
          </motion.div>
        ) : (
          <motion.div
            key="extend"
            initial={{ x: -40 }}
            animate={{ x: PADDING_OFFSET }}
            exit={{ x: -40 }}
            transition={{ ease: "easeInOut", duration: 0.1, delay: 0.3 }}
          >
            {displayState === "closed" && !open && (
              <ExpandButton onClick={() => setDisplayState("small")}>
                <IconContainer
                  url="/icons/player/ico_chev_right_w.svg"
                  width="25px"
                  height="25px"
                />
              </ExpandButton>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default RadioBox
