import { Playlist } from "db"
import { atom } from "jotai"
import { LessonWithCard, PlayingState, PlaylistInfoState } from "../playlists/types"
import { atomFamily } from "jotai/utils"
import toggleArray from "utils/global/toggleArray"
import { DisplayStateType } from "app/radio/types";

export const playlistAtom = atom<{ lesson: LessonWithCard }[] | null>(null)
export const playingStateAtom = atom<PlayingState>(null)
export const playingThemeAtom = atom<PlaylistInfoState>(null)
export const isPlayingAtom = atom(false)
export const isMutedAtom = atom(false)
export const isLoadingAtom = atom(false)
export const displayStateAtom = atom<DisplayStateType>("hidden")
export const audioElementRefAtom = atom<HTMLAudioElement | null>(null)
// export const audioVolumeAtom = atom<number>(0.5)
export const audioCurrentTimeAtom = atom<number>(0)
export const audioDurationAtom = atom<number>(0)
export const audioLoopAtom = atom<boolean>(false)
export const audioShuffleAtom = atom<boolean>(false)

export const indexAtom = atom((get) => {
  const lessons = get(playlistAtom)
  const playingState = get(playingStateAtom)
  if (!lessons || !playingState) return -1
  return lessons.findIndex((lesson) => lesson.lesson.id === playingState.lesson.id)
})

export const nextTrackAtom = atom((get) => {
  const index = get(indexAtom)
  if (index === -1) return null
  const lessons = get(playlistAtom)
  if (!lessons) return null
  const nextIndex = index + 1
  if (nextIndex >= lessons.length) return null
  return lessons[nextIndex]
})

export const previousTrackAtom = atom((get) => {
  const index = get(indexAtom)
  if (index === -1) return null
  const lessons = get(playlistAtom)
  if (!lessons) return null
  const previousIndex = index - 1
  if (previousIndex < 0) return null
  return lessons[previousIndex]
})