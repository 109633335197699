import { PX12 } from "theme/sizes"
import { RichText } from "web/src/admin/components/RichText"
import TextButton from "ui/TextButton"
import Text from "ui/Text"
import styled from "@emotion/styled"
import { JsonValue } from "type-fest"

const TextOverflow = styled.div`
  div {
    p {
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
`

const TextCustom = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const LessonDescriptionPreview = ({
  description,
  size,
  hideLink,
}: {
  description: any
  size?: string
  hideLink?: boolean
}) => {
  return (
    <Text size={size ? size : PX12}>
      {typeof description === "string" ? (
        <TextCustom>{description}</TextCustom>
      ) : (
        <TextOverflow>
          <RichText body={description} />{" "}
        </TextOverflow>
      )}
      {/* {!hideLink && (
        <>
          <TextButton>Bekijk les</TextButton>{" "}
        </>
      )} */}
    </Text>
  )
}

export default LessonDescriptionPreview
