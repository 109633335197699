import { type ContentSubjectType } from "db"

const ContentSubjectIcon = ({
  type,
  color = "#fff",
  ...props
}: {
  type: ContentSubjectType
  color?: string
}) => {
  switch (type) {
    case "MUSIC":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g transform="translate(14488 11752)">
            <path
              d="M17.1,6.967h0V3.633a.787.787,0,0,0-.833-.833H7.933a.787.787,0,0,0-.833.833v8a3.881,3.881,0,0,0-1.667-.5,3.333,3.333,0,1,0,3.333,3.333h0V7.8h6.667v3.833a3.881,3.881,0,0,0-1.667-.5A3.333,3.333,0,1,0,17.1,14.467h0ZM5.433,16.133A1.667,1.667,0,1,1,7.1,14.467h0A1.672,1.672,0,0,1,5.433,16.133Zm3.333-10V4.467h6.667V6.133Zm5,10a1.667,1.667,0,1,1,1.667-1.667h0A1.672,1.672,0,0,1,13.767,16.133Z"
              transform="translate(-14488.1 -11751.8)"
              fill={color}
            />
          </g>
        </svg>
      )
    case "DANCE":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="20"
          viewBox="0 0 17 20"
        >
          <g transform="translate(0 2.892)">
            <path
              d="M-2.249,10.927c-2.688,0-3.108-2.94-3.276-3.948a6.158,6.158,0,0,1,.84-4.788A2.7,2.7,0,0,1-2.753,1.1C-.821.931.607,3.619,1.027,6.223h0a4.469,4.469,0,0,1-.5,3.444,3.111,3.111,0,0,1-2.1,1.176A2.088,2.088,0,0,1-2.249,10.927Zm-.336-8.232h0a1.161,1.161,0,0,0-.924.5A5.132,5.132,0,0,0-4.1,6.811c.42,2.6,1.26,2.688,2.184,2.6A1.4,1.4,0,0,0-.821,8.827,3.012,3.012,0,0,0-.569,6.559h0C-.821,4.459-1.913,2.695-2.585,2.695Z"
              transform="translate(5.653 -1.091)"
              fill={color}
            />
          </g>
          <g transform="translate(2 12.696)">
            <path
              d="M-.366,17.89a2.568,2.568,0,0,1-1.68-.588,3.272,3.272,0,0,1-1.26-1.932,2.243,2.243,0,0,1,.252-1.512,3.012,3.012,0,0,1,1.932-.924l.252-.084c.168,0,.336-.084.5-.084a2.219,2.219,0,0,1,1.932.084,1.579,1.579,0,0,1,.672,1.428h0A3.087,3.087,0,0,1,.054,17.722.462.462,0,0,1-.366,17.89Zm-1.428-3.108v.336a1.672,1.672,0,0,0,.672,1.008,1.053,1.053,0,0,0,.924.252C.39,16.294.81,15.454.726,14.53h0c0-.168-.084-.252-.084-.336a1.977,1.977,0,0,0-.672.168c-.168.084-.42.084-.588.168l-.252.084a2.248,2.248,0,0,0-.924.168Z"
              transform="translate(3.337 -12.586)"
              fill={color}
            />
          </g>
          <g transform="translate(10.292)">
            <path
              d="M8.855,6.628a1.429,1.429,0,0,1-.588-.084,2.934,2.934,0,0,1-2.1-1.176,3.867,3.867,0,0,1-.588-3.36h0C5.915-.6,7.427-3.284,9.275-3.2a2.7,2.7,0,0,1,1.932,1.092,6.158,6.158,0,0,1,.84,4.788C11.963,3.688,11.543,6.628,8.855,6.628ZM7.091,2.26h0a3.7,3.7,0,0,0,.168,2.268,1.92,1.92,0,0,0,1.176.5c.924.168,1.764,0,2.184-2.6a4.725,4.725,0,0,0-.588-3.612,1.022,1.022,0,0,0-.84-.5C8.519-1.688,7.343-.008,7.091,2.26Z"
              transform="translate(-5.468 3.202)"
              fill={color}
            />
          </g>
          <g transform="translate(9.292 9.768)">
            <path
              d="M7.184,13.606a1.065,1.065,0,0,1-.5-.084A3.012,3.012,0,0,1,4.5,10.078h0A1.79,1.79,0,0,1,5.168,8.65a2.406,2.406,0,0,1,2.016-.084c.168,0,.336.084.5.084l.252.084c.756.168,1.512.252,1.932.924a2.153,2.153,0,0,1,.252,1.512,2.958,2.958,0,0,1-2.94,2.436Zm-1.176-3.36a1.62,1.62,0,0,0,1.008,1.848,1,1,0,0,0,.924-.252,1.672,1.672,0,0,0,.672-1.008c.084-.252,0-.336,0-.336a6.814,6.814,0,0,0-.924-.252l-.252-.084a1.557,1.557,0,0,1-.588-.168,3.779,3.779,0,0,0-.672-.168c-.168.084-.168.252-.168.42Z"
              transform="translate(-4.477 -8.386)"
              fill={color}
            />
          </g>
        </svg>
      )
    case "DRAMA":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g transform="translate(14418 11751)">
            <g transform="translate(0.05 0.609)">
              <path
                d="M16.563,1.8h0a2.581,2.581,0,0,0-.774.167h0a15.651,15.651,0,0,1-5.161.917h0a13.469,13.469,0,0,1-4.9-.917,3.143,3.143,0,0,0-.86-.167A2.046,2.046,0,0,0,2.8,3.967V10.8a8.075,8.075,0,0,0,8,8,8.133,8.133,0,0,0,8-8V3.967A2.243,2.243,0,0,0,16.563,1.8Zm.688,2.167V10.8a6.65,6.65,0,0,1-6.538,6.583A6.65,6.65,0,0,1,4.176,10.8V3.967c0-.5.258-.75.6-.75a.53.53,0,0,1,.344.083,14.75,14.75,0,0,0,5.333,1h0a16.266,16.266,0,0,0,5.677-1c.086,0,.258-.083.344-.083A.71.71,0,0,1,17.251,3.967Z"
                transform="translate(-14418.85 -11751.408)"
                fill={color}
              />
              <path
                d="M14.194,15.194a.642.642,0,0,0-.42.168h0a3.226,3.226,0,0,1-2.436.924A3.226,3.226,0,0,1,8.9,15.362a.6.6,0,1,0-.924.756,4.55,4.55,0,0,0,3.36,1.344,4.55,4.55,0,0,0,3.36-1.344.609.609,0,0,0,0-.84C14.53,15.278,14.362,15.194,14.194,15.194Z"
                transform="translate(-14419.474 -11753.648)"
                fill={color}
              />
              <path
                d="M17.464,9.9a3.9,3.9,0,0,0-1.932-.5,2.79,2.79,0,0,0-1.764.588A.588.588,0,0,0,14.188,11h0a.642.642,0,0,0,.42-.168s.756-.672,2.268.084a.553.553,0,0,0,.756-.252A.448.448,0,0,0,17.464,9.9Z"
                transform="translate(-14420.28 -11752.68)"
                fill={color}
              />
              <path
                d="M8.6,10.828a.561.561,0,0,0,.84,0,.609.609,0,0,0,0-.84A2.568,2.568,0,0,0,7.761,9.4a3.9,3.9,0,0,0-1.932.5.656.656,0,0,0-.336.336c-.084.168,0,.336,0,.42A.656.656,0,0,0,5.829,11H6a.309.309,0,0,0,.252-.084C7.845,10.156,8.517,10.828,8.6,10.828Z"
                transform="translate(-14419.274 -11752.68)"
                fill={color}
              />
            </g>
          </g>
        </svg>
      )
  }
  return null
}

export default ContentSubjectIcon
