import { useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import useToast from "app/toasts/hooks/useToast"
import { useAtom } from "jotai"
import { Suspense, useEffect } from "react"
import AlertModal from "ui/AlertModal"
import Button from "ui/Button"
import Group from "ui/Group"
import * as Modal from "ui/Modal"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import batchDeleteUserPlaylist from "../mutations/batchDeleteUserPlaylist"
import { multiSelectAtom } from "../state"
import { Grid as GridBase, Root } from "./AddLessonSelection"
import AddLessonToListModal from "./AddLessonToListModal"

const Grid = styled(GridBase)`
  grid-template-columns: 150px auto;
`

const RoundedButton = styled(RoundedButtonBase)`
  background: transparent;
  border: 1px solid white;
  color: white;
`

export default function PlaylistBatchUpdateModal({
  selectedLessons,
  onDelete,
}: {
  selectedLessons
  onDelete: () => void
}) {
  const [multiSelect, setMultiSelect] = useAtom(multiSelectAtom)

  useEffect(() => {
    setMultiSelect([])
  }, [setMultiSelect])

  const addToast = useToast()
  if (multiSelect.length === 0) return null
  return (
    <Root
      initial={{ translateX: "-50%", y: 180 }}
      animate={{ translateX: "-50%", y: 0 }}
      exit={{ translateX: "-50%", y: 180 }}
      transition={{
        type: "spring",
        bounce: 0.05,
        duration: 0.6,
      }}
    >
      <Group gap="12px" justify="space-between">
        {multiSelect.length}{" "}
        <Value name="apps.web.src.playlists.components.playlistbatchupdatemodal.geselecteerd">
          geselecteerd
        </Value>
        <Group gap="12px">
          <Suspense>
            <AddLessonToListModal
              trigger={
                <RoundedButton
                  variant="bordered"
                  type="button"
                  disabled={selectedLessons.length === 0}
                >
                  <Value name="apps.web.src.playlists.components.playlistbatchupdatemodal.toevoegen">
                    Toevoegen
                  </Value>
                </RoundedButton>
              }
              lessons={selectedLessons}
            />
          </Suspense>
          <AlertDialog.Root>
            <AlertDialog.Trigger asChild>
              <RoundedButton variant="bordered">
                <Value name="apps.web.src.playlists.components.playlistbatchupdatemodal.verwijderen">
                  Verwijderen
                </Value>
              </RoundedButton>
            </AlertDialog.Trigger>
            <AlertModal
              title={
                <Stack gap="24px">
                  <Modal.Title>
                    <Value name="playlists.item.verwijderen.titel">
                      Weet je zeker dat je deze selectie wilt verwijderen?
                    </Value>
                  </Modal.Title>
                  <Text>
                    <Value name="apps.web.src.playlists.components.playlistbatchupdatemodal.je_kunt_lessen_altijd_terugzetten_in_een_afspeellijst">
                      Je kunt lessen altijd terugzetten in een afspeellijst.
                    </Value>
                  </Text>
                </Stack>
              }
              cancel={
                <Modal.CancelButton>
                  <Value name="playlists.item.verwijderen.annuleren">
                    Nee, annuleer
                  </Value>
                </Modal.CancelButton>
              }
              submit={
                <Modal.SubmitButton onClick={onDelete}>
                  <Value name="playlists.item.verwijderen.bevestigen">
                    Ja, verwijder
                  </Value>
                </Modal.SubmitButton>
              }
            />
          </AlertDialog.Root>
          <Button onClick={() => setMultiSelect([])}>
            <FaTimes />
          </Button>
        </Group>
      </Group>
    </Root>
  )
}
