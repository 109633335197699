import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { PX16 } from "theme/sizes"

type Props = {
  size?: string,
  center?: boolean,
  block?: boolean,
  bold?: boolean,
  underline?: boolean,
  noUnderline?: boolean,
  italic?: boolean,
  pointer?: boolean,
  color?: string,
  opacity?: number,
  lineHeight?: string
}

const Text = styled.span<Props>`
  font-weight: inherit;
  font-family: inherit;
  letter-spacing: 0.20;

  ul {
    margin: 0;
  }
  ${p => p.center && css`
    text-align: center;
    display: block;
    width: 100%;
  `}
  ${p => p.block && css`display: block;`}
  ${p => p.lineHeight && "line-height: 1.4; display: block;"};
  ${p => p.size && `font-size: ${p.size};`}
  ${p => p.bold && "font-weight: 600;"}
  ${p => p.italic && "font-style: italic;"}
  ${p => p.color && `color: ${p.color};`}
  ${p => p.opacity !== undefined && `opacity: ${p.opacity};`}
  ${p => p.underline && `text-decoration: underline;`}
  ${p => p.noUnderline && `text-decoration: none;`}
  ${p => p.pointer && `cursor: pointer;`}
`

export default Text