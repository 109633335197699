import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react/dist"
import Field from "app/core/components/Field"
import useToast from "app/toasts/hooks/useToast"
import { UserPlaylist } from "db"
import { useState } from "react"
import { GREEN_COLOR, LIST_COLORS } from "theme/colors"
import {
  Buttons,
  CancelButton,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
} from "ui/Modal"
import Stack from "ui/Stack"
import updateUserPlaylist from "../mutations/updateUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import getUserList from "../queries/getUserList"
import Color, { ColorGroup } from "./Color"
import createFormFactory, {
  convertNullToUndefined,
} from "utils/form/createForm"
import { UpdateUserListInput } from "../validations"
import FieldArea from "app/core/components/FieldArea"
import { UserPlaylistWithPlaylist } from "../types"

const form = createFormFactory({
  validationSchema: UpdateUserListInput,
})

const UpdatePlaylistModal = ({
  onClose,
  userList,
}: {
  onClose: (open?: boolean) => void
  userList: UserPlaylistWithPlaylist
}) => {
  const [updatePlaylistMutation] = useMutation(updateUserPlaylist)
  const [serverErrors, setServerErrors] = useState()
  const addToast = useToast()
  const [color, setColor] = useState(userList.playlist?.color || GREEN_COLOR)
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <form.Form
            defaultValues={{
              id: userList.id,
              ...(userList.playlist ? convertNullToUndefined(userList.playlist) : {})
            }}
            noValidate
            onSubmit={async (data) => {
              try {
                await updatePlaylistMutation({
                  id: userList.id,
                  displayTitle: data.displayTitle as string,
                  description: data.description as string,
                  color,
                })
                await invalidateQuery(getUserList)
                await invalidateQuery(getGroupedUserPlaylists)
                onClose(true)
                addToast({
                  content: (
                    <>
                      <Value name="playlist.nieuw.aangepast.pre">
                        Afspeellijst
                      </Value>{" "}
                      &quot;{data.displayTitle}&quot;{" "}
                      <Value name="playlist.nieuw.aangepast.post">
                        aangepast
                      </Value>
                    </>
                  ),
                })
              } catch (e) {
                setServerErrors(e)
              }
            }}
          >
            <Content>
              <Stack gap="24px">
                <Dialog.Title>
                  <Value name="apps.web.src.playlists.components.updateuserlistmodal.afspeellijst_aanpassen">
                    Afspeellijst aanpassen
                  </Value>
                </Dialog.Title>
                <Field
                  name="displayTitle"
                  label={
                    <Value name="apps.web.src.playlists.components.updateuserlistmodal.naam">
                      Naam
                    </Value>
                  }
                  form={form}
                />
                <FieldArea
                  name="description"
                  label={
                    <Value name="apps.web.src.playlists.components.updateuserlistmodal.omschrijving">
                      Omschrijving
                    </Value>
                  }
                  form={form}
                />
                <ColorGroup onValueChange={setColor} value={color}>
                  {LIST_COLORS.map((color) => (
                    <Color value={color} />
                  ))}
                </ColorGroup>
                <Buttons>
                  <CancelButton type="button" onClick={() => onClose()}>
                    <Value name="apps.web.src.playlists.components.updateuserlistmodal.annuleren">
                      Annuleren
                    </Value>
                  </CancelButton>
                  <Form.Submit asChild>
                    <SubmitButton type="submit">
                      <Value name="apps.web.src.playlists.components.updateuserlistmodal.opslaan">
                        Opslaan
                      </Value>
                    </SubmitButton>
                  </Form.Submit>
                </Buttons>
              </Stack>
            </Content>
          </form.Form>
          <Dialog.Close asChild>
            <Close>
              <FaTimes />
            </Close>
          </Dialog.Close>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default UpdatePlaylistModal
