import { atom } from "jotai"

export const isControlsVisibleAtom = atom(true)
export const currentOpenPopoverAtom = atom<null | "speed" | "quality" | "volume">(null)
export const isPreventedAtom = atom(false)
export const isPlayingAtom = atom(false)
export const isLoopEnabledAtom = atom(false)


// export const volumeAtom = atom(1)
export const currentTimeAtom = atom(0)
export const currentDurationAtom = atom<number | null>(null)
export const beginAtom = atom<number | null>(null)
export const endAtom = atom<number | null>(null)
export const playbackSpeedAtom = atom<"NORMAL" | "SLOW" | "SLOWER">("NORMAL")

export const timeAtom = atom((get) => {
  const begin = get(beginAtom)
  const currentTime = get(currentTimeAtom)
  if (begin) {
    return currentTime - begin
  }
  return currentTime
})

export const durationAtom = atom((get) => {
  const begin = get(beginAtom)
  const end = get(endAtom) || get(currentDurationAtom)
  if (end) {
    return end - (begin || 0)
  }
  return null
})

export const isFinishedAtom = atom((get) => {
  if (get(currentDurationAtom) === null) {
    return false
  }
  return Math.round(get(timeAtom)) === Math.round(get(durationAtom) || 0)
})

export const isLoadingAtom = atom((get) => get(currentDurationAtom) === null, (get, set, value: boolean) => {
  if (value) {
    set(currentDurationAtom, null)
  }
})