import { useMediaQuery } from "@mantine/hooks"
import { useAtomValue, useSetAtom } from "jotai"
import { ComponentProps } from "react"
import { isFinishedAtom, isPlayingAtom, isPreventedAtom } from "../state"
import IconButton from "./IconButton"
import IconContainer from "./IconContainer"

export const isPlayButton = (element: EventTarget) => {
  return element instanceof Element && element.classList.contains("playingButton")
}

type Props = {
  onReplayClick?: () => void
} & ComponentProps<typeof IconButton>

export default function TogglePlayButton({ onReplayClick, ...props }: Props) {
  const setPrevented = useSetAtom(isPreventedAtom)
  const isPlaying = useAtomValue(isPlayingAtom)
  const isTouch = useMediaQuery("(pointer: coarse)")
  const isFinished = useAtomValue(isFinishedAtom)
  if (isFinished) {
    return (
      <IconButton
        {...props}
        onClick={onReplayClick ? onReplayClick : props.onClick}
      >
        <IconContainer url="/icons/player/ico_replay_bl.svg" />
      </IconButton>
    )
  }
  return (
    <IconButton
      {...props}
      className="playingButton"
      onMouseDown={(e) => {
        if (!isTouch) {
          setPrevented(true)
        }
        props.onMouseDown?.(e)
      }}
      onMouseLeave={(e) => {
        setPrevented(false)
        props.onMouseLeave?.(e)
      }}
    >
      {isPlaying ? (
        <IconContainer url="/icons/player/ico_pause_bl.svg" />
      ) : (
        <IconContainer url="/icons/player/ico_play_bl.svg" />
      )}
    </IconButton>
  )
}
