import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbArrowRight } from "@react-icons/all-files/tb/TbArrowRight"
import { TbFolder } from "@react-icons/all-files/tb/TbFolder"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import {
  parseAsBoolean,
  parseAsInteger,
  parseAsString,
  useQueryState,
} from "nuqs"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import {
  Content,
  ImageRoot,
  Root as RootBase,
} from "app/lessons/components/LessonListItem"
import { BulletinBoardItemType, File, ParticipationIcon } from "db"
import { useState } from "react"
import { DIMMED_BLUE_COLOR, GREEN_COLOR, PX12, PX16 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getFullname from "utils/users/getFullName"
import { MODAL_QUERY_KEY, MODAL_QUERY_SHARE_LIST_KEY } from "../consts"
import { GetSharedWithMeResult } from "../queries/getSharedWithMe"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import PlaylistMenuCircle from "./PlaylistMenuCircle"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addBulletinBoardItem from "app/bulletinboard/mutations/addBulletinBoardItem"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { AddListModalUncontrolled } from "./AddListModal"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"

export type TParticipantsIcons = ParticipationIcon & {
  image: File | null
}

const Root = styled(RootBase)`
  flex-grow: 1;
`

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const ImageWrapper = styled(ImageRoot)`
  background-color: ${GREEN_COLOR};
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  flex: 0 0 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const TitleGroup = styled(Group)`
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

type Props = {
  item: NonNullable<GetSharedWithMeResult[number]>
  onDelete: () => void
}

const ShareLessonItem = ({ item, onDelete, ...props }: Props) => {
  const [isFollowModalVisible, setFollowModalVisibe] = useState(false)
  const [isListSaveModalOpen, setListSaveModalOpen] = useState(false)
  const [openShareList, setOpenShareList] = useQueryState(
    MODAL_QUERY_SHARE_LIST_KEY,
    parseAsInteger
  )
  const [openList, setOpenList] = useQueryState(MODAL_QUERY_KEY, parseAsInteger)
  const [addBulletinBoardItemMutation] = useMutation(addBulletinBoardItem)
  const currentGroup = useCurrentGroup()
  const { playlist, sender, id } = item
  if (!playlist?.playlist) return null
  const open = () => {
    if (playlist.accessLevel === "SCHOOL") {
      setOpenList(playlist.id)
    } else {
      setOpenShareList(id)
    }
  }
  if (!playlist.playlistId) return null
  return (
    <Root {...props}>
      <Group gap="20px">
        <ImageWrapper>
          {playlist.playlist?.lessons.map((lesson) =>
            lesson.lesson.listImage ? (
              <div>
                <Image
                  alt=""
                  file={lesson.lesson.listImage}
                  key={lesson.id}
                  width={115}
                  height={65}
                />
              </div>
            ) : null
          )}
        </ImageWrapper>
        <Content>
          <Stack>
            <Text size={PX12}>
              {getListTypeDisplayTitle(playlist.accessLevel, playlist.type)}
            </Text>
            <TitleGroup onClick={open}>
              <PlaylistMenuCircle
                color={playlist.playlist.color || GREEN_COLOR}
                accessLevel={playlist.accessLevel}
                type={playlist.type}
              />
              <Text size={PX16} bold>
                {playlist.playlist.displayTitle}
              </Text>
            </TitleGroup>
            {playlist.playlist.description ? (
              <Text>{playlist.playlist.description}</Text>
            ) : null}
          </Stack>
        </Content>
      </Group>
      <Stack gap="24px">
        {playlist.owner ? (
          <Stack gap="6px">
            <Text size={PX12} bold>
              <Value name="apps.web.src.playlists.components.sharethemeitem.gemaakt_door">
                Gemaakt door
              </Value>
            </Text>
            <Group align="center" gap="6px">
              <Avatar user={playlist.owner} width={20} height={20} />{" "}
              <Text size={PX12}>{getFullname(playlist.owner)}</Text>
            </Group>
          </Stack>
        ) : null}
        <ButtonGroup>
          <RoundedButton size="small" variant="filled" onClick={open}>
            <Value name="apps.web.src.playlists.components.sharelessonitem.bekijk_lijst">
              Bekijk lijst
            </Value>
          </RoundedButton>
          <ContextMenu
            trigger={
              <RoundedButton size="small" variant="filled">
                <FaEllipsisH />
              </RoundedButton>
            }
            items={
              <>
                <ContextMenu.Item onClick={open}>
                  <Group align="center" gap="8px">
                    <TbArrowRight color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.context.bekijk_lijst">
                      Bekijk lijst
                    </Value>
                  </Group>
                </ContextMenu.Item>
                {(playlist.accessLevel === "GLOBAL" ||
                  playlist.accessLevel === "GROUP" ||
                  playlist.accessLevel === "PERSONAL" ||
                  playlist.accessLevel === "KIDS") &&
                  currentGroup && (
                    <ContextMenu.Item
                      onClick={() => {
                        setListSaveModalOpen(true)
                      }}
                    >
                      <Group align="center" gap="8px">
                        <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                        <Value
                          name="apps.web.src.playlists.components.sharelessonitem.context.lijst_opslaan"
                          version="1"
                        >
                          Lijst opslaan
                        </Value>
                      </Group>
                    </ContextMenu.Item>
                  )}

                <ContextMenu.Separator />
                <ContextMenu.Item onClick={onDelete}>
                  <Group align="center" gap="8px">
                    <TbTrash color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.context.verwijder_lijst">
                      Verwijder lijst
                    </Value>
                  </Group>
                </ContextMenu.Item>
              </>
            }
          />
        </ButtonGroup>
      </Stack>
      {/* <DuplicatePlaylistModal.Controlled
        isOpen={isDuplicateModalOpen}
        onClose={() => setIsDuplicateModalOpen(false)}
        userList={playlist}
        title={<Value name="apps.web.src.playlists.components.shareplaylistitem.lijst_toevoegen">Lijst toevoegen</Value>}
      /> */}
      <AddListModalUncontrolled
        isOpen={isListSaveModalOpen}
        setOpen={setListSaveModalOpen}
        scopeBulletinBoard={
          playlist.accessLevel === "GLOBAL" ? undefined : "NONE"
        }
        playlist={{
          id: playlist.id,
          displayTitle: playlist.playlist.displayTitle,
        }}
        type={
          playlist.accessLevel === "SCHOOL"
            ? BulletinBoardItemType.SCHOOL_LIST
            : playlist.accessLevel === "KIDS"
              ? BulletinBoardItemType.KIDS_LIST
              : BulletinBoardItemType.GROUP_LIST
        }
      />
    </Root>
  )
}

export default ShareLessonItem
