import { useQuery } from "@blitzjs/rpc"
import getCurrentUser from "app/users/queries/getCurrentUser"
import { PageType } from "db"

export const useCurrentUser = (pageType?: PageType) => {
  const [user] = useQuery(
    getCurrentUser,
    { pageType: pageType },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: false,
      staleTime: 10000,
      suspense: true,
    }
  )
  return user
}
