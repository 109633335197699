import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { Theme } from "admin-lexical/theme"
import { useEffect } from "react"
import { NODES } from "./lexical/consts"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { LinkStyleType } from "admin-lexical/types"

function InnerRichText({ body }: { body: any }) {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    if (!body) return
    try {
      const state = editor.parseEditorState(
        typeof body === "string" ? body : JSON.stringify(body)
      )
      editor.setEditorState(state)
    } catch (e) {
      console.log("error", e, body)
    }
  }, [body, editor])
  return (
    <RichTextPlugin
      ErrorBoundary={LexicalErrorBoundary}
      // The empty placeholder is required for Safari, otherwise it will not scale txt correctly. It just needs some actual element to be present, the older <></> wasn't enough.
      // Hence an empty span with a hidden character has been chosen.
      placeholder={<span style={{ visibility: "hidden" }}>&nbsp;</span>}
      contentEditable={<ContentEditable />}
    />
  )
}

export function RichText({
  body,
  linkStyle,
}: {
  body: any
  linkStyle?: LinkStyleType
}) {
  return (
    <Theme linkStyle={linkStyle}>
      {(theme) => (
        <LexicalComposer
          initialConfig={{
            editable: false,
            theme: theme,
            // editorState: typeof body === "string" ? body : JSON.stringify(body),
            nodes: NODES,
            namespace: "",
            onError: () => {
              console.log("error")
            },
          }}
        >
          <InnerRichText body={body} />
        </LexicalComposer>
      )}
    </Theme>
  )
}
