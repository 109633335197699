export const MODAL_QUERY_KEY = "bibliotheek"
export const MODAL_QUERY_SHARE_LIST_KEY = "list"
export const MODAL_QUERY_KIDS_TYPE_VALUE = "kids"
export const MODAL_QUERY_SHARE_TYPE_VALUE = "gedeeld"
export const MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE = "prikbord"
export const MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE = "schoollijstbeheer"
export const MODAL_QUERY_ARCHIVE_KEY = "archief"

export const RADIUS = "16px"

export const ACCESS_LEVELS = {
  PERSONAL: "Mijn lijst",
  GROUP: "Groepslijst",
} as const

export const SORT_OPTIONS = [
  "CUSTOM",
  "DATE_DESC",
  "DATE_ASC",
  "TITLE_ASC",
  "TITLE_DESC",
] as const

export const SORT_OPTIONS_DISPLAY = {
  CUSTOM: "Mijn volgorde",
  DATE_ASC: "Oud - Nieuw",
  DATE_DESC: "Nieuw - Oud",
  TITLE_ASC: "Titel (A-Z)",
  TITLE_DESC: "Titel (Z-A)",
}

export enum ADD_TO_PLAYLIST_TYPES {
  BULLETINBOARDS = "bulletinboards",
  KIDS_PLAYLISTS = "kidsPlaylists",
  GROUP_PLAYLISTS = "groupPlaylists",
  SCHOOL_PLAYLISTS = "schoolPlaylists",
}

export enum ADD_LIST_TYPES {
  BULLETINBOARDS = "bulletinboards",
  LIBRARY = "library",
}

export type SortOption = (typeof SORT_OPTIONS)[number]
