import { useQuery } from "@blitzjs/rpc"

import getCurrentSchool from "app/users/queries/getCurrentOrganization"

import { organizationHasSchoolyears } from "utils/schoolyears/organizationHasSchoolyears"

export const useHasSchoolyears = () => {
  const [organization] = useQuery(getCurrentSchool, undefined, {
    refetchOnWindowFocus: false,
  })
  return organizationHasSchoolyears(organization)
}
