import { Value } from "@stringtale/react"
import { PX12, PX14, PX24 } from "theme/sizes"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR, GREEN_COLOR } from "theme/colors"
import Stack from "ui/Stack"
import Group from "ui/Group"
import Image from "web/src/files/components/Image"
import Text from "ui/Text"
import styled from "@emotion/styled"
import { useMemo } from "react"
import { TParticipantsIcons } from "web/src/learning-lines/types"
import { ContentSubjectType } from "db"
import getContentSubjectColor from "web/src/content-subjects/getContentSubjectColor"

export const Title = styled.h2<{ contentSubjectType: ContentSubjectType }>`
  font-size: ${PX14};
  font-weight: 600;
  color: ${(p) => getContentSubjectColor(p.contentSubjectType)};
  letter-spacing: 0.28px;
`

export type LessonType = {
  contentSubject: ContentSubjectType
  subjects: {
    subjects: {
      participationIcons: TParticipantsIcons[]
    }[]
  }[]
}

const ParticipationIcons = ({ lesson }: { lesson: LessonType }) => {
  const participantsIcons = useMemo(() => {
    if (!lesson || !lesson.subjects) return
    const map = new Map<number, TParticipantsIcons>()
    lesson.subjects.forEach((headSubjectGroup) => {
      headSubjectGroup.subjects.forEach((headSubject) => {
        headSubject.participationIcons.forEach((icon) => {
          map.set(icon.id, icon)
        })
      })
    })
    return [...map.values()].sort((a, b) => a.order - b.order)
  }, [lesson])
  if (!participantsIcons) return <></>
  return (
    <Stack gap="8px">
      <Title contentSubjectType={lesson.contentSubject}>
        <Value name="apps.web.src.lessons.components.participationicons.meedoen">
          Meedoen
        </Value>
      </Title>
      <Stack gap="6px">
        {participantsIcons.map((icon) => (
          <Group gap="12px" align="center" justify="flex-start">
            {icon.image ? (
              <Image
                file={icon.image}
                width={20}
                height={20}
                options={{ fit: "contain" }}
                alt=""
              />
            ) : null}
            <Text color={DIMMED_BLUE_COLOR} size={PX12}>
              {icon.displayTitle}
            </Text>
          </Group>
        ))}
      </Stack>
    </Stack>
  )
}

export default ParticipationIcons
