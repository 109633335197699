import styled from "@emotion/styled"
import dynamic from "next/dynamic"
import { Suspense } from "react"
import type { YouTubeProps } from "react-youtube"

const Youtube = dynamic(() => import("react-youtube"))

export default function YoutubePlayer(props: YouTubeProps) {
  return (
    <Suspense fallback={null}>
      <Youtube {...props} />
    </Suspense>
  )
}

const VideoContainer = styled.div`
  width: 100%;
  min-height: 205px;
  max-height: 360px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`

const Player = styled(YoutubePlayer)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export function LandscapeYoutubePlayer({ videoId }: { videoId: string }) {
  return (
    <VideoContainer>
      <Player
        videoId={videoId}
        opts={{
          width: "100%",
        }}
      />
    </VideoContainer>
  )
}
