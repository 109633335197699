import styled from "@emotion/styled"
import * as ToastBase from "@radix-ui/react-toast"
import { LayoutGroup } from "framer-motion"
import { TOASTS_Z_INDEX } from "theme/consts"
import { useToasts } from "../hooks/useToast"
import Toast from "./Toast"

const Root = styled(ToastBase.Viewport)`
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: ${TOASTS_Z_INDEX};
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default function Toasts() {
  const [toasts] = useToasts()
  return (
    <ToastBase.Provider swipeDirection="left">
      <Root>
        <LayoutGroup>
          {toasts.map((toast) => (
            toast.type !== "slide" ? <Toast key={toast.id} info={toast} /> : null
          ))}
        </LayoutGroup>
      </Root>
    </ToastBase.Provider>
  )
}
