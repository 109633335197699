import styled from "@emotion/styled"
import { LIGHT_GREY_COLOR } from "theme/colors"

const Hr = styled.hr`
  border: 0;
  height: 2px;
  width: 100%;
  background: ${LIGHT_GREY_COLOR};
  margin: 0;
  /* margin-top: 12px; */
`

export default Hr