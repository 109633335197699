import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react/dist"
import useToast from "app/toasts/hooks/useToast"
import { Playlist, UserPlaylist } from "db"
import { Dispatch, SetStateAction, useState } from "react"
import {
  Buttons,
  CancelButton,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
} from "ui/Modal"
import Stack from "ui/Stack"
import duplicateUserPlaylist from "../mutations/duplicateUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { GREEN_COLOR, LIST_COLORS } from "theme/colors"
import { DuplicateUserPlaylistInput } from "../validations"
import createFormFactory from "utils/form/createForm"
import Field from "app/core/components/Field"
import Color, { ColorGroup } from "./Color"
import FieldArea from "app/core/components/FieldArea"
import { UserPlaylistWithPlaylist } from "../types"

const form = createFormFactory({
  validationSchema: DuplicateUserPlaylistInput,
})

type Props = {
  title?: React.ReactNode
  userList: UserPlaylist & { playlist: Playlist | null }
  onComplete: () => void
}

const DuplicatePlaylistModal = (props: Props) => {
  const { onComplete, userList, title } = props
  const [duplicateUserList] = useMutation(duplicateUserPlaylist)
  const [serverErrors, setServerErrors] = useState()
  const [color, setColor] = useState(userList.playlist?.color || GREEN_COLOR)

  const addToast = useToast()
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <form.Form
            defaultValues={{
              id: userList.id,
              displayTitle: `Kopie van ${userList.playlist?.displayTitle}`,
              description: userList.playlist?.description || undefined,
              color,
            }}
            onSubmit={async (data) => {
              try {
                await duplicateUserList(data)
                await invalidateQuery(getGroupedUserPlaylists)
                onComplete?.()
                addToast({
                  content: (
                    <Value
                      name="apps.web.src.playlists.components.duplicateplaylistmodal.afspeellijst_toegevoegd"
                      format={{ lesson: data.displayTitle }}
                    >
                      {`Afspeellijst "{lesson}" toegevoegd`}
                    </Value>
                  ),
                })
              } catch (e) {
                console.log("error", e)
                setServerErrors(e)
              }
            }}
          >
            <Content>
              <Stack gap="24px">
                <Dialog.Title>
                  {title || (
                    <Value name="apps.web.src.playlists.components.duplicateplaylistmodal.dupliceer_afspeellijst">
                      Dupliceer afspeellijst
                    </Value>
                  )}
                </Dialog.Title>
                <Field
                  name="displayTitle"
                  label={
                    <Value name="apps.web.src.playlists.components.duplicateplaylistmodal.naam">
                      Naam
                    </Value>
                  }
                  form={form}
                />
                <FieldArea
                  name="description"
                  label={
                    <Value name="apps.web.src.playlists.components.duplicateplaylistmodal.omschrijving">
                      Omschrijving
                    </Value>
                  }
                  form={form}
                />
                <ColorGroup onValueChange={setColor} value={color}>
                  {LIST_COLORS.map((color) => (
                    <Color value={color} />
                  ))}
                </ColorGroup>
                <Buttons>
                  <Dialog.Close asChild>
                    <CancelButton type="button">
                      <Value name="apps.web.src.playlists.components.duplicateplaylistmodal.annuleren">
                        Annuleren
                      </Value>
                    </CancelButton>
                  </Dialog.Close>
                  <SubmitButton type="submit">
                    <Value name="apps.web.src.playlists.components.duplicateplaylistmodal.opslaan">
                      Opslaan
                    </Value>
                  </SubmitButton>
                </Buttons>
              </Stack>
            </Content>
          </form.Form>
          <Close>
            <FaTimes />
          </Close>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default DuplicatePlaylistModal

export function Controlled({
  isOpen,
  onClose,
  trigger,
  onComplete,
  ...props
}: Props & {
  isOpen: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  trigger?: React.ReactNode
}) {
  console.log("Controlled Playlist Modal")
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      {isOpen && (
        <DuplicatePlaylistModal
          {...props}
          onComplete={() => {
            onComplete?.()
            onClose(false)
          }}
        />
      )}
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
    </Dialog.Root>
  )
}

DuplicatePlaylistModal.Controlled = Controlled

DuplicatePlaylistModal.UnControlled =
  function UnControlledDuplicatePlaylistModal({
    trigger,
    ...props
  }: Props & {
    trigger?: React.ReactNode
  }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
      <DuplicatePlaylistModal.Controlled
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        trigger={trigger}
        {...props}
      />
    )
  }
