import { BulletinBoardItemType } from "db"

export default function getIsAddableBulletinBoardItem(
  type: BulletinBoardItemType
) {
  switch (type) {
    case "GROUP_LIST":
    case "KIDS_LIST":
    case "LL_PAGE":
    case "THEME_GROUP":
      return true
    default:
      return false
  }
}
