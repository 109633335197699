import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { TbLayoutGrid } from "@react-icons/all-files/tb/TbLayoutGrid"
import { TbLayoutList } from "@react-icons/all-files/tb/TbLayoutList"
import { Value } from "@stringtale/react/dist"
import { RichText } from "app/admin/components/RichText"
import ToggleButtons from "app/content-subjects/components/ToggleButtons"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import useSortLessons from "app/lessons/hooks/useSortLessons"
import { AddLessonToListModalUncontrolled } from "app/playlists/components/AddLessonToListModal"
import DuplicatePlaylistModal from "app/playlists/components/DuplicatePlaylistModal"
import Grades from "app/playlists/components/Grades"
import PlaylistItem from "app/playlists/components/PlaylistItem"
import PlaylistModalEmptyState from "app/playlists/components/PlaylistModalEmptyState"
import PlaylistTileLesson from "app/playlists/components/PlaylistTileLesson"
import { RADIUS } from "app/playlists/consts"
import updateUserPlaylistDisplayType from "app/playlists/mutations/updateUserPlaylistDisplayType"
import updateUserPlaylistOrder from "app/playlists/mutations/updateUserPlaylistOrder"
import getCanWriteUserPlaylist from "app/playlists/queries/getCanWriteUserPlaylist"
import { GetUserListResult } from "app/playlists/queries/getUserList"
import ShareModal from "app/share/components/ShareModal"
import { ContentSubjectType } from "db"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  DARK_BLUE_COLOR,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
} from "theme/colors"
import { PX14, PX28 } from "theme/sizes"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import Hr from "ui/Hr"
import RoundedButtonBase from "ui/RoundedButton"
import * as ScrollArea from "ui/ScrollArea"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextButton from "ui/TextButton"
import Title from "ui/Title"
import toggleArray from "utils/global/toggleArray"
import { usePlay } from "../../radio/components/PlayerContext"
import { GetArchiveListsResult } from "../queries/getArchiveLists"
import getArchivedUserList from "../queries/getArchivedUserList"
import ArchiveModalHeader from "./ArchiveModalHeader"

const Content = styled.div`
  border-bottom-right-radius: ${RADIUS};
  border-top-right-radius: ${RADIUS};
  padding: 0 32px;
  overflow: auto;
  flex: 1;
`

const ContentPadding = styled.div`
  padding: 40px 0;
`

const UserListPadding = styled.div`
  padding: 0 0 120px 0;
`

const Filter = styled.div`
  padding: 16px 0;
  margin: 0 32px;
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  font-size: ${PX14};
`

const Separator = styled.div`
  background: ${LIGHT_GREY_COLOR};
  width: 1px;
  height: 16px;
`

const RoundedButton = styled(RoundedButtonBase)`
  color: ${DARK_BLUE_COLOR};
  font-size: ${PX14};
`
const AddButton = styled(RoundedButton)``

const TileLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  width: 100%;
`

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
`

const ScrollWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  border-radius: 0 0 20px 0;
`

const getSort = (index: number, length: number) => {
  return length - index
}

type UserPlaylistItems = NonNullable<
  NonNullable<GetUserListResult>["playlist"]
>["lessons"]
type UserPlaylistItem = UserPlaylistItems[number]

export const ArchiveModalContent = ({
  id,
  grades,
  onClose,

  isSharedWithMe,
  selectedGroup,
}: {
  id: number
  grades: GetAllGradesResult
  onClose: () => void
  isSharedWithMe?: boolean
  selectedGroup?: NonNullable<GetArchiveListsResult>[0]["groups"][0]
}) => {
  const router = useRouter()
  const currentSchool = useCurrentOrganization()
  const [updateUserPlaylistOrderMutation] = useMutation(updateUserPlaylistOrder)
  const [updateUserPlaylistDisplayTypeMutation] = useMutation(
    updateUserPlaylistDisplayType
  )
  const [openShareModalLesson, setOpenShareModalLesson] = useState<null | {
    id: number
    displayTitle: string | null
  }>(null)
  const [isLikeModalOpen, setLikeModalOpen] = useState<boolean>(false)
  const [likeModalLesson, setLikeModalLesson] = useState<null | {
    id: number
    displayTitle: string | null
  }>(null)
  const [userList] = useQuery(getArchivedUserList, { id })
  const [{ canEdit, canDelete }] = useQuery(getCanWriteUserPlaylist, {
    playlistId: id,
    type: "items",
  })
  const [vakken, setVakken] = useState<ContentSubjectType[]>([])
  const [activeGrades, setActiveGrades] = useState<number[]>([])
  const [isDuplicatePlaylistVisible, setDuplicatePlaylistVisible] =
    useState(false)
  // const [view, setView] = useState<"list" | "tile">("list")
  const view = userList.displayType[0]?.displayType || "LIST"

  const { set, getUrl, pause } = usePlay()
  const [list, setList] = useState<
    NonNullable<NonNullable<GetUserListResult>["playlist"]>["lessons"]
  >([])

  const canOrderItem = !!(
    canEdit &&
    userList.type === "PLAYLIST" &&
    userList.sortItemsBy === "CUSTOM" &&
    vakken.length === 0 &&
    activeGrades.length === 0
  )

  const sortItems = useSortLessons()

  // Fill in list with correct order
  useEffect(() => {
    if (userList.type === "THEME") {
      setList(
        sortItems(
          userList.themeGroup?.globalPlaylist?.playlist?.lessons,
          userList.sortItemsBy
        ) || []
      )
      return
    }
    if (userList.type === "LL") {
      // setList([])
      return
    }
    setList(sortItems(userList.playlist?.lessons, userList.sortItemsBy) || [])
    return
  }, [userList, sortItems])

  const color = GREEN_COLOR

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  )

  const updatePosition = useCallback(
    (oldIndex: number, newIndex: number) => {
      const newList = arrayMove(list, oldIndex, newIndex)
      setList(newList)
      updateUserPlaylistOrderMutation({
        id: userList.id,
        items: newList.map((item, index) => ({
          id: item.id,
          sort: getSort(index, newList.length),
        })),
      })
    },
    [list, updateUserPlaylistOrderMutation, userList.id]
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      const oldIndex = list.findIndex((item) => item.id === active.id)
      const newIndex = list.findIndex((item) => item.id === over.id)
      updatePosition(oldIndex, newIndex)
    }
  }

  const filteredList = useMemo(() => {
    if (vakken.length === 0 && activeGrades.length === 0) {
      return list
    }
    return list.filter((item) => {
      return (
        (vakken.length === 0 || vakken.includes(item.lesson.contentSubject)) &&
        (activeGrades.length === 0 ||
          item.lesson.grades.filter((grade) => activeGrades.includes(grade.id))
            .length === activeGrades.length)
      )
    })
  }, [activeGrades, list, vakken])

  const enabledGrades = useMemo(() => {
    const set = new Set<number>()
    filteredList.forEach((item) => {
      item.lesson.grades.forEach((grade) => {
        set.add(grade.id)
      })
    })
    return Array.from(set)
  }, [filteredList])

  const getItemContextMenu = (item: UserPlaylistItem) => {
    const _canEdit = userList.type === "PLAYLIST" && canEdit
    const url = getUrl(item.lesson)

    return (
      <>
        <DropdownMenu.Item asChild>
          <ContextMenu.Item
            onClick={() => {
              router.push(
                Routes.LessonPage({
                  lesson: item.lesson.slug,
                })
              )
            }}
          >
            <Value name="apps.web.src.schooljaren.components.archivemodalcontent.open_les">
              Open les
            </Value>
          </ContextMenu.Item>
        </DropdownMenu.Item>

        {url ? (
          <DropdownMenu.Item asChild>
            <ContextMenu.Item
              onClick={() => {
                set(item)
              }}
            >
              <Value name="apps.web.src.playlists.components.archivemodalcontent.speel_af_in_radioplayer">
                Speel af in radioplayer
              </Value>
            </ContextMenu.Item>
          </DropdownMenu.Item>
        ) : null}

        <DropdownMenu.Item asChild>
          <ContextMenu.Item
            onClick={(e) => {
              // e.preventDefault()
              setLikeModalLesson(item.lesson)
              setLikeModalOpen(true)
            }}
          >
            <Value name="apps.web.src.schooljaren.components.archivemodalcontent.voeg_toe_aan_lijst">
              Voeg toe aan lijst
            </Value>
          </ContextMenu.Item>
        </DropdownMenu.Item>
      </>
    )
  }

  return (
    <Stack grow overflow={"hidden"}>
      <ArchiveModalHeader
        canEdit={canEdit}
        canDelete={canDelete}
        userList={userList}
        list={list}
        video={
          list.length > 0 ? (
            <Stack style={{ width: 240, marginRight: 15 }}>
              <video
                src={
                  userList.accessLevel === "KIDS"
                    ? "https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-kidspagina.mp4?v=1652269774"
                    : "https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-bibliotheek.mp4?v=1652269773"
                }
                controls
              />
            </Stack>
          ) : null
        }
        selectedGroup={selectedGroup}
      />
      {!(userList.type === "LL") && list.length > 0 && (
        <Filter>
          <Group gap={"8px"} justify="center" align="center">
            <Text bold>
              <Value name="apps.web.src.schooljaren.components.archivemodalcontent.filters">
                Filters:
              </Value>
            </Text>
            <Group gap={"4px"} justify="center" align="center">
              <ToggleButtons
                {...{
                  vakken,
                  setVakken,
                  subscription: currentSchool?.subscription,
                }}
                size="small"
              />
            </Group>
            <Separator />
            <Group gap={"4px"} justify="center" align="center">
              <Grades
                grades={grades}
                activeGrades={activeGrades}
                enabledGrades={enabledGrades}
                onChange={(grade) => {
                  setActiveGrades(toggleArray(grade.id))
                }}
              />
            </Group>
            <Separator />
            <Group>
              <Text>
                {filteredList.length}{" "}
                <Value name="apps.web.src.schooljaren.components.archivemodalcontent.items">
                  items
                </Value>
              </Text>
            </Group>
          </Group>
          <Group gap="12px">
            <TextButton
              onClick={async () => {
                // setView((prevState) => (prevState === "tile" ? "list" : "tile"))
                await updateUserPlaylistDisplayTypeMutation({
                  id: userList.id,
                  displayType: view === "LIST" ? "GRID" : "LIST",
                })
                await invalidateQuery(getArchivedUserList, {
                  id: userList.id,
                })
              }}
            >
              {view === "LIST" ? (
                <TbLayoutGrid color={ORANGE_COLOR} size={20} />
              ) : (
                <TbLayoutList color={ORANGE_COLOR} size={20} />
              )}
            </TextButton>
          </Group>
        </Filter>
      )}
      <ScrollWrapper>
        <ScrollAreaRoot type="auto">
          <ScrollArea.Viewport>
            <Content>
              <Stack gap="8px">
                {userList.type === "LL" ? (
                  userList.learningLine?.planningLessonGroups.map((group) => {
                    return (
                      <ContentPadding>
                        <Stack gap="8px">
                          <Title size={PX28}>{group.displayTitle}</Title>
                          <Text>
                            <RichText body={group.description} />
                          </Text>
                          <Hr />
                          {group.lessons.map((item, index) => (
                            <PlaylistItem
                              key={item.id.toString()}
                              color={color}
                              {...Routes.LessonPage({
                                lesson: item.lesson.slug,
                              })}
                              canOrderItem={false}
                              isEditMode={false}
                              item={item}
                              index={index}
                              lessons={group.lessons}
                              grades={grades}
                              updatePosition={updatePosition}
                              contextMenuItems={
                                getUrl(item.lesson) ? (
                                  <DropdownMenu.Item asChild>
                                    <ContextMenu.Item
                                      onClick={() => {
                                        set(item)
                                      }}
                                    >
                                      <Value name="apps.web.src.schooljaren.components.archivemodalcontent.lied_afspelen">
                                        Lied afspelen
                                      </Value>
                                    </ContextMenu.Item>
                                  </DropdownMenu.Item>
                                ) : null
                              }
                            />
                          ))}
                        </Stack>
                      </ContentPadding>
                    )
                  })
                ) : list.length > 0 ? (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <UserListPadding>
                      {view === "LIST" ? (
                        <>
                          <SortableContext
                            items={filteredList}
                            strategy={verticalListSortingStrategy}
                          >
                            {filteredList.map((item, index) => {
                              const _canEdit =
                                userList.type === "PLAYLIST" && canEdit
                              const url = getUrl(item.lesson)
                              return (
                                <PlaylistItem
                                  color={color}
                                  key={item.id.toString()}
                                  {...Routes.LessonPage({
                                    lesson: item.lesson.slug,
                                  })}
                                  canOrderItem={canOrderItem}
                                  isEditMode={false}
                                  item={item}
                                  index={index}
                                  lessons={list}
                                  grades={grades}
                                  updatePosition={updatePosition}
                                  contextMenuItems={getItemContextMenu(item)}
                                />
                              )
                            })}
                          </SortableContext>
                        </>
                      ) : (
                        <SortableContext
                          items={filteredList}
                          strategy={rectSortingStrategy}
                        >
                          <TileLayout>
                            {filteredList.map((item) => {
                              return (
                                <PlaylistTileLesson
                                  key={item.id.toString()}
                                  item={item}
                                  grades={grades}
                                  isEditMode={false}
                                  canOrderItem={canOrderItem}
                                  contextMenuItems={getItemContextMenu(item)}
                                />
                              )
                            })}
                          </TileLayout>
                        </SortableContext>
                      )}
                    </UserListPadding>
                  </DndContext>
                ) : (
                  <PlaylistModalEmptyState
                    addButton={<></>}
                    userList={userList}
                  />
                )}

                <Dialog.Root
                  open={isDuplicatePlaylistVisible}
                  onOpenChange={() => setDuplicatePlaylistVisible(false)}
                >
                  {isDuplicatePlaylistVisible ? (
                    <DuplicatePlaylistModal
                      key={`duplicate-${isDuplicatePlaylistVisible}`}
                      userList={userList}
                      onComplete={() => setDuplicatePlaylistVisible(false)}
                    />
                  ) : null}
                </Dialog.Root>
              </Stack>
            </Content>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar>
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollAreaRoot>
        {openShareModalLesson ? (
          <ShareModal.Controlled
            type="LESSON"
            lesson={openShareModalLesson}
            isOpen={true}
            onClose={() => setOpenShareModalLesson(null)}
          />
        ) : null}
        {likeModalLesson && (
          <AddLessonToListModalUncontrolled
            isOpen={isLikeModalOpen}
            setOpen={setLikeModalOpen}
            lessons={[likeModalLesson]}
          />
        )}
      </ScrollWrapper>
      {/*<UserListPlayback />*/}
    </Stack>
  )
}
