import styled from "@emotion/styled"
import * as RadioGroup from "@radix-ui/react-radio-group"
import { TbCheck } from "@react-icons/all-files/tb/TbCheck"
import { ComponentProps } from "react"
import { LIGHT_GREY_COLOR, ORANGE_COLOR } from "theme/colors"

export const ColorGroup = styled(RadioGroup.Root)`
  display: flex;
  gap: 8px;
`

const Root = styled(RadioGroup.Item)`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${LIGHT_GREY_COLOR};
  padding: 4px;
  box-sizing: border-box;
  &[data-state="checked"] {
    border: 2px solid ${ORANGE_COLOR};
  }
`

const InnerCircle = styled.div<{ value: string }>`
  border: 2px solid #00000033;
  background-color: ${({ value }) => value};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Color = ({ value, ...props }: Omit<ComponentProps<typeof InnerCircle>, "key">) => (
  <Root value={value}>
    <InnerCircle value={value} {...props}>
      <RadioGroup.Indicator asChild>
        <TbCheck />
      </RadioGroup.Indicator>
    </InnerCircle>
  </Root>
)
// Color.Group = Group

export default Color
