import styled from "@emotion/styled"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX18 } from "theme/sizes"
import Button from "ui/Button"

export const RoundedButton = styled(Button)`
  height: 60px;
  background: white;
  border: 3px solid ${DARK_BLUE_COLOR};
  background: #ffffff;
  border: 3px solid #03004e;
  border-radius: 30px;
  font-weight: 600;
  font-size: ${PX18};
  padding: 0 35px;
`
