import styled from "@emotion/styled"
import ImageBase from "app/files/components/Image"
import { LIGHT_GREY_COLOR } from "theme/colors"

export const Root = styled.div`
  box-sizing: border-box;
`

export const Content = styled.div`
  padding: 2px;
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 2px solid #ffffff99;
  border-radius: 8px;
  box-sizing: border-box;
`

export const Image = styled(ImageBase)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const WrapBorder = styled.div`
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
  padding: 24px 12px 24px 12px;
`
