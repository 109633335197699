import Tooltip from "app/radio/components/Tooltip"
import Center from "ui/Center"
import { TbArrowsShuffle } from "@react-icons/all-files/tb/TbArrowsShuffle"
import { TbRepeat } from "@react-icons/all-files/tb/TbRepeat"
import Group from "ui/Group"
import { TbVolume } from "@react-icons/all-files/tb/TbVolume"
import { TbVolume2 } from "@react-icons/all-files/tb/TbVolume2"
import { TbVolumeOff } from "@react-icons/all-files/tb/TbVolumeOff"
import * as Popover from "@radix-ui/react-popover"
import VolumeSlider from "app/radio/components/VolumeSlider"
import ButtonBase from "ui/Button"
import { MutableRefObject, ReactNode, useRef, useState } from "react"
import { useAtom, useAtomValue } from "jotai"
import {
  audioLoopAtom,
  audioShuffleAtom,
} from "app/radio/state"
import styled from "@emotion/styled"
import * as PopoverUI from "ui/Popover"
import { DARK_BLUE_COLOR, RADIO_PLAYER_Z_INDEX, ORANGE_COLOR } from "theme/consts"
import { usePlay } from "app/radio/components/PlayerContext"
import { css } from "@emotion/react"
import { TRANSITION } from "app/radio/consts"
import useGlobalVolume from "app/core/hooks/useGlobalVolume"

const VolumeModal = styled(PopoverUI.Root)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  width: 56px;
  z-index: ${RADIO_PLAYER_Z_INDEX + 1};
`

type ButtonProps = {
  isActive?: boolean
  theme?: "light" | "dark"
}

const Button = styled(ButtonBase)<ButtonProps>`
  ${TRANSITION};
  width: 48px;
  height: 48px;
  border-radius: 6px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.theme === "light"
      ? css`
          background-color: #fff;
          color: ${p.isActive ? ORANGE_COLOR : DARK_BLUE_COLOR};
        `
      : css`
          background-color: ${DARK_BLUE_COLOR};
          color: ${p.isActive ? ORANGE_COLOR : "white"};
        `}

  ${(p) =>
    p.isActive &&
    css`
      &:hover {
        color: #c8320d;
      }
    `}
`

const RadioOptions = ({
  containerRef,
  contextMenu,
  volumeContainerRef,
  theme,
}: {
  containerRef: MutableRefObject<any>
  contextMenu?: ReactNode
  volumeContainerRef?: MutableRefObject<any>
  theme?: "light" | "dark"
}) => {
  const shuffle = useAtomValue(audioShuffleAtom)
  const [loop, setLoop] = useAtom(audioLoopAtom)
  const volumeButtonRef = useRef(null)
  const [volumeOpen, setVolumeOpen] = useState<boolean>(false)
  const [volume, setVolume] = useGlobalVolume()
  const { toggleShuffle } = usePlay()

  return (
    <Group gap="2px" align="center" justify="flex-end">
      {containerRef.current && containerRef.current.offsetWidth > 600 ? (
        <Tooltip
          text={shuffle ? "Shuffle uit" : "Shuffle aan"}
          trigger={
            <Button
              isActive={shuffle}
              onClick={() => toggleShuffle()}
              theme={theme}
            >
              <Center>
                <TbArrowsShuffle strokeWidth={2.5} />
              </Center>
            </Button>
          }
        />
      ) : null}
      {containerRef.current && containerRef.current.offsetWidth > 500 ? (
        <Tooltip
          text={loop ? "Herhaal uit" : "Herhaal aan"}
          trigger={
            <Button
              isActive={loop}
              theme={theme}
              onClick={() => (loop ? setLoop(false) : setLoop(true))}
            >
              <Center>
                <TbRepeat strokeWidth={2.5} />
              </Center>
            </Button>
          }
        />
      ) : null}
      <Group>
        <Tooltip
          text="Volume"
          trigger={
            <Button
              ref={volumeButtonRef}
              theme={theme}
              onPointerDown={(e) => {
                if (
                  containerRef.current &&
                  containerRef.current.offsetWidth < 880
                ) {
                  e.preventDefault()
                  if (!volumeOpen) {
                    setVolumeOpen(true)
                  }
                } else {
                  setVolumeOpen((prevState) => !prevState)
                }
              }}
            >
              <Center>
                {volume >= 0.5 ? (
                  <TbVolume strokeWidth={2.5} />
                ) : volume > 0 ? (
                  <TbVolume2 strokeWidth={2.5} />
                ) : (
                  <TbVolumeOff strokeWidth={2.5} />
                )}
              </Center>
            </Button>
          }
        />
        {volumeOpen && (
          <>
            {containerRef.current && containerRef.current.offsetWidth < 880 ? (
              <Popover.Root>
                <Popover.Anchor virtualRef={volumeButtonRef} />
                <Popover.Portal
                  forceMount
                  container={
                    volumeContainerRef ? volumeContainerRef.current : undefined
                  }
                >
                  <Popover.Content
                    asChild
                    forceMount
                    side={"top"}
                    onInteractOutside={() => setVolumeOpen(false)}
                    id="radioboxVolume"
                  >
                    <VolumeModal>
                      <VolumeSlider
                        orientation="vertical"
                        volume={volume}
                        onChange={setVolume}
                      />
                      <Popover.Arrow
                        style={{ fill: "white" }}
                        width={16}
                        height={9}
                      />
                    </VolumeModal>
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            ) : (
              <VolumeSlider
                orientation="horizontal"
                volume={volume}
                onChange={setVolume}
                theme={theme}
              />
            )}
          </>
        )}
      </Group>
      {contextMenu}
    </Group>
  )
}

export default RadioOptions
