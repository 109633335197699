import { useId } from "react"

import { useQuery } from "@blitzjs/rpc"
import getContentSubjectTitle from "app/content-subjects/getContentSubjectTitle"
import Image from "app/files/components/Image"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import getParticipationIcons, {
  GetParticipationIcons,
} from "../queries/getParticipationIcons"
import AccordionItem from "./AccordionItem"
import { useSearchContext } from "./SearchProvider"
import Hr from "ui/Hr"

export type ContentSubjectType = "MUSIC" | "DRAMA" | "DANCE"

export default function ParticipationIconFilter({
  contentSubjectType,
}: {
  contentSubjectType: ContentSubjectType
}) {
  const [participationIcons] = useQuery(getParticipationIcons, {
    contentSubjectType,
  })

  return (
    <>
      <AccordionItem
        value="participants"
        title={`Meedoen ${getContentSubjectTitle(contentSubjectType)}`}
      >
        {participationIcons.map((icon) => {
          return <Item icon={icon} />
        })}
      </AccordionItem>
      <Hr />
    </>
  )
}

function Item({ icon }: { icon: GetParticipationIcons[0] }): JSX.Element {
  const { participants, setParticipants, facetDistribution } =
    useSearchContext()
  const id = useId()
  const isActive = participants.includes(icon.id)
  const amount =
    facetDistribution?.["subjects.subjects.participationIcons.id"]?.[
      `${icon.id}`
    ] ?? 0
  return (
    <Group align="center" gap="12px">
      <Checkbox
        checked={isActive}
        id={id}
        onCheckedChange={() => {
          setParticipants(toggleArray(icon.id))
        }}
      />
      {icon.image ? (
        <label htmlFor={id}>
          <Image
            style={{ opacity: isActive ? 1 : 0.5 }}
            file={icon.image}
            alt=""
            width={28}
            height={28}
          />
        </label>
      ) : null}
      <Text size={PX14} lineHeight="1">
        <label htmlFor={id}>
          <Text bold={isActive}>{icon.displayTitle}</Text>{" "}
          <Text color={DIMMED_BLUE_COLOR}>({amount})</Text>
        </label>
      </Text>
    </Group>
  )
}
