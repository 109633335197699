import styled from "@emotion/styled"
import { ComponentProps } from "react"
import { CONTAINER_WIDTH } from "theme/consts"

type Props = {
  width?: string
  pt?: string
  pb?: string
  mt?: string
  mb?: string
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* padding: 0 25px; */
  box-sizing: border-box;
  /* overflow: hidden; */
`

export const Content = styled.div<Props>`
  width: calc(100% - 50px);
  box-sizing: border-box;
  max-width: ${(p) => p.width ?? CONTAINER_WIDTH};
  position: relative;
  margin: 0 25px;
  padding-top: ${(p) => p.pt ?? "0"};
  padding-bottom: ${(p) => p.pb ?? "0"};
  margin-top: ${(p) => p.mt ?? "0"};
  margin-bottom: ${(p) => p.mb ?? "0"};
`

export default function Container(props: ComponentProps<typeof Content>) {
  return (
    <Root>
      <Content {...props} />
    </Root>
  )
}

Container.Root = Root
Container.Content = Content