import { Value } from "@stringtale/react"
import * as Dialog from "@radix-ui/react-dialog"
import Hr from "ui/Hr"
import {
  Buttons,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
  Title,
} from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"

export const AddSubmittableToPlaylistWarning = ({
  lessons,
}: {
  lessons: Array<{ displayTitle: string | null }>
}) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <Content>
            <Dialog.Close asChild>
              <Close />
            </Dialog.Close>
            <Stack gap="24px">
              <Stack gap="24px">
                <Title>
                  {lessons.length === 1 ? (
                    <Value name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.deze_les_heeft_een_inleveropdracht">
                      Deze les heeft een inleveropdracht
                    </Value>
                  ) : (
                    <Value name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.let_op_inleveropdrachten">
                      Let op: inleveropdrachten
                    </Value>
                  )}
                </Title>
                <Text>
                  {lessons.length === 1 ? (
                    <>
                      <Value
                        name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.1les"
                        format={{
                          b: (c) => <Text bold>{c}</Text>,
                          les: lessons[0].displayTitle,
                        }}
                      >{`Door het toevoegen van de les <b>{les}</b> aan de Kidslijst, 
                        wordt de inleveropdracht beschikbaar voor de leerlingen via de Kidspagina.
                      `}</Value>
                    </>
                  ) : (
                    <>
                      <Value name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.meerdere_lessen">
                        Je hebt één of meerdere lessen met een inleveropdracht
                        toegevoegd aan de Kidslijst. Deze lessen zijn
                        beschikbaar voor leerlingen via de Kidspagina.
                      </Value>
                    </>
                  )}
                  {/* <TextButton
                      as={Link}
                      {...Routes.IngeleverdeOpdrachtenPage()}
                      onClick={() => {
                        setSubmittableToKidsListWarningOpen(false)
                      }}
                    >
                      hier bekijken
                    </TextButton> */}
                </Text>
                <Hr />
              </Stack>
              <Buttons>
                <RoundedButton
                  as="a"
                  // @ts-ignore
                  target="_blank"
                  href="https://123zing.my.site.com/knowledgebase/s/article/wat-zijn-inleveropdrachten-en-hoe-werk-ik-daarmee"
                >
                  <Value name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.uitleg_over_inleveropdrachten">
                    Uitleg over inleveropdrachten
                  </Value>
                </RoundedButton>
                <Dialog.Close asChild>
                  <SubmitButton type="button">
                    <Value name="apps.web.src.playlists.components.addsubmittabletoplaylistwarning.sluit">
                      Sluit
                    </Value>
                  </SubmitButton>
                </Dialog.Close>
              </Buttons>
            </Stack>
          </Content>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}
