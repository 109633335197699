import { useRouter } from "next/router"
import { ParsedUrlQuery } from "querystring"
import { useEffect, useState } from "react"

export const getIsPublic = (query: ParsedUrlQuery) => {
  return query.__public === "true"
}

export const useIsPublic = () => {
  const [isLocation, setIsLocation] = useState(false)
  useEffect(() => {
    setIsLocation(location.pathname.indexOf("/publiek") === 0)
  }, [])
  const router = useRouter()
  return getIsPublic(router.query) || isLocation
  // return useContext(Context)
}