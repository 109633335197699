import { Value } from "@stringtale/react"
import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import Group from "ui/Group"
import Stack from "ui/Stack"
import getActiveOrganizationsForUser from "app/auth/queries/getActiveOrganizationsForUser"
import setOrganizationForSession from "app/auth/mutations/setOrganizationForSession"
import { DARK_BLUE_COLOR, LIGHT_GREEN_COLOR, PX28, PX16 } from "theme/consts"
import useFilePathCallback from "app/files/hooks/useFilePathCallback"
import { useMutation } from "@blitzjs/rpc"
import { useRouter } from "next/router"
import { useEffect } from "react"

const Header = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 50px 0 50px;
  width: 100%;
  z-index: 4;
`

const Body = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
`

const OrganizationsContainer = styled(Group)<{ itemCount: number }>`
  max-width: 1416px;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: stretch;
  margin: auto;
  z-index: 1;
  position: relative;

  padding: 150px 8px;
  gap: 16px;

  justify-content: ${(p) => (p.itemCount < 2 ? "center" : "flex-start")};

  @media (min-width: 900px) {
    padding: 150px 42px;
    gap: 24px;
    justify-content: ${(p) => (p.itemCount < 3 ? "center" : "flex-start")};
  }
  @media (min-width: 1376px) {
    gap: 48px;
    justify-content: ${(p) => (p.itemCount <= 5 ? "center" : "flex-start")};
  }
`

const Organization = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  align-items: center;
  :active,
  :hover {
    background-color: transparent;
  }
  margin-bottom: 4px;

  width: calc(50% - 8px);

  @media (min-width: 900px) {
    width: calc(33% - 12px);
  }
  @media (min-width: 1376px) {
    width: calc(20% - 24px);
  }
`

const OrganizationImage = styled.img`
  width: 180px;
  height: 180px;
  box-shadow: 0px 2px 10px #0000001f;
  border-radius: 100px;
`

const OrganizationImagePlacholder = styled.div`
  background: #707070;
  min-width: 180px;
  min-height: 180px;
  box-shadow: 0px 2px 10px #0000001f;
  border-radius: 100px;
`

const Title = styled.h1`
  font-size: ${PX28};
  font-weight: 600;
  color: ${DARK_BLUE_COLOR};
  text-align: center;
`

const OrganizationName = styled.span`
  font-size: ${PX16};
  font-weight: 600;
  color: ${DARK_BLUE_COLOR};
`
const UserRole = styled.span`
  font-size: ${PX16};
  color: ${DARK_BLUE_COLOR};
  opacity: 0.5;
`

export const OrganizationPickerContent = ({
  onChange,
}: {
  onChange: () => Promise<void>
}) => {
  const getFilePath = useFilePathCallback()
  const [organizationUsers] = useQuery(getActiveOrganizationsForUser, {})
  const [setOrganizationForSessionMutation] = useMutation(
    setOrganizationForSession
  )

  const setOrganizationForSessionClickHandler = async (organizationUserId) => {
    let result = await setOrganizationForSessionMutation({ organizationUserId })
    if (result) {
      await onChange()
    } else {
      // Do stuff
    }
  }

  return (
    <>
      <Header>
        <Group align="center">
          <Title>
            <Value name="apps.web.src.organizations.components.organizationpickercontent.op_welke_school_sta_je">
              Op welke school sta je?
            </Value>
          </Title>
        </Group>
      </Header>
      <Body>
        <OrganizationsContainer itemCount={organizationUsers.length}>
          {organizationUsers.map((organizationUser) => {
            return (
              <Organization
                key={organizationUser.id}
                onClick={() =>
                  setOrganizationForSessionClickHandler(organizationUser.id)
                }
              >
                {organizationUser.organization.image ? (
                  <OrganizationImage
                    src={
                      organizationUser.organization.image
                        ? getFilePath(organizationUser.organization.image, {
                            width: 180,
                            height: 180,
                            fit: "cover",
                          })
                        : undefined
                    }
                    alt={`${organizationUser.organization.displayTitle}`}
                  />
                ) : (
                  <OrganizationImagePlacholder />
                )}
                <Stack gap="0">
                  <OrganizationName>
                    {organizationUser.organization.displayTitle}
                  </OrganizationName>
                  <UserRole>{organizationUser.role}</UserRole>
                </Stack>
              </Organization>
            )
          })}
        </OrganizationsContainer>
      </Body>
    </>
  )
}
