import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import getAlreadyFollowing from "app/playlists/queries/getAlreadyFollowing"
import migratePlaylistToGroup from "../mutations/migratePlaylistToGroup"
import getUserList from "../queries/getUserList"
import { GroupSelectItem } from "./FollowPlaylistToGroupModal"
import { Value } from "@stringtale/react"
import useToast from "app/toasts/hooks/useToast"

type Props = {
  playlistId: number
  onClose: () => void
}

export default function MigratePlaylistToGroupsModal({
  playlistId,
  onClose,
  ...props
}: Props) {
  const [migratePlaylistToGroupMutation] = useMutation(migratePlaylistToGroup)
  const [alreadyFollowing] = useQuery(getAlreadyFollowing, {
    id: playlistId,
  })

  const addToast = useToast()

  return (
    <SelectGroupModal
      {...props}
      onClose={onClose}
      onSubmit={async (groupIds) => {
        await migratePlaylistToGroupMutation({
          playlistId,
          groupIds,
        })
        addToast({
          content: (
            <Value name="apps.web.src.themes.components.migrateplaylisttogroupsmodal.lijst_is_gekoppeld_aan_groepen">
              {`Lijst is gekoppeld aan groep(en).`}
            </Value>
          ),
        })
        await invalidateQuery(getUserList)
        onClose()
      }}
      onRenderItem={({ group, isActive, onChange }) => (
        <GroupSelectItem
          isActive={isActive}
          group={group}
          onChange={onChange}
          alreadyFollowing={alreadyFollowing.find(
            (playlist) => playlist.groupId === group.id
          )}
        />
      )}
    />
  )
}
