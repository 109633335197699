import styled from "@emotion/styled"
import * as ScrollArea from "@radix-ui/react-scroll-area"

export const Root = styled(ScrollArea.Root)`
  overflow: hidden;
`

export const Viewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Thumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.75rem;
  transition: background-color 150ms ease;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`

export const Scrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
  padding: calc(0.75rem / 5);
  width: 0.75rem;
  /*background: rgba(0, 0, 0, 0.1);*/
  border-radius: 0 0 0.75rem 0.75rem;
  transition: background-color 150ms ease, opacity 150ms ease;

  &:hover {
    background-color: #f8f9fa;
    ${Thumb} {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`
