import { Routes } from "@blitzjs/next"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { TbChevronRight } from "@react-icons/all-files/tb/TbChevronRight"
import { TbMusic } from "@react-icons/all-files/tb/TbMusic"
import { Value } from "@stringtale/react"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import NextImage from "next/image"
import { ComponentProps, forwardRef } from "react"
import { PX14, PX16, PX20 } from "theme/consts"
import Button from "ui/Button"
import Grow from "ui/Grow"
import Link from "ui/Link"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { useCurrentOrganization } from "../../core/hooks/useCurrentOrganization"
import getAnalyticsDataAttributes from "../../core/utils/getAnalyticsDataAttributes"
import hasOnlyOneLearningLine from "../../subscriptions/hasOnlyOneLearningLine"
import { Root } from "./BulletinBoardCard"
import Title from "ui/Title"

const CardTitle = styled(Title)`
  text-align: center;
`

const GrowRoundedButton = styled(Button)<{ hoverColor?: string }>`
  border: none;
  text-decoration: none;
  border-radius: 100px;
  color: #ffffff;
  background-color: #ffffff4d;
  font-size: ${PX14};
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  padding: 5px 14px;
  flex-grow: 1;
  transition: 0.075s;
  max-height: 53px;

  &:hover {
    background: ${({ hoverColor }) => hoverColor && hoverColor};
  }
`

const Content = styled.div<{ preview: boolean }>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${({ preview }) =>
    preview &&
    css`
      padding: 16px;
    `}

  container-type: inline-size;
  @container (min-width: calc(262px - 48px)) {
    ${GrowRoundedButton} {
      padding: 5px 20px;
      font-size: ${PX16};
    }
  }
`

type Props = { preview?: boolean } & ComponentProps<typeof Root>

const BulletinBoardLLCard = forwardRef<HTMLDivElement, Props>(
  ({ preview, children, ...props }, ref) => {
    const currentSchool = useCurrentOrganization()
    return (
      <Root {...props} ref={ref}>
        {children}
        <Content preview={!!preview}>
          <Stack gap={preview ? "8px" : "13px"} grow>
            <CardTitle size={PX20} color="white">
              {hasOnlyOneLearningLine(currentSchool?.subscription) ? (
                <>
                  <Value name="apps.web.pages.index.leerlijn">Leerlijn</Value>
                </>
              ) : (
                <Value name="home.welkom.kaart1.titel">Leerlijnen</Value>
              )}
            </CardTitle>
            <Stack gap="8px" grow>
              <GrowRoundedButton
                as={Link}
                {...Routes.LearningLineIndexOverview({
                  vak: "muziek",
                })}
                {...getAnalyticsDataAttributes({
                  eventAction: "click",
                  elementLocation: "musicLearningLineHome",
                  elementText: "Muziek",
                })}
                hoverColor={getContentSubjectColor("MUSIC")}
              >
                <TbMusic size={20} />
                <Grow>
                  <Text bold>
                    <Value name="home.welkom.kaart1.muziek">Muziek</Value>
                  </Text>
                </Grow>
                <TbChevronRight size={20} />
              </GrowRoundedButton>
              {!currentSchool?.subscription.isDanceHidden && (
                <GrowRoundedButton
                  as={Link}
                  {...Routes.LearningLineIndexOverview({
                    vak: "dans",
                  })}
                  {...getAnalyticsDataAttributes({
                    eventAction: "click",
                    elementLocation: "danceLearningLineHome",
                    elementText: "Dans",
                  })}
                  hoverColor={getContentSubjectColor("DANCE")}
                >
                  <NextImage
                    src="/icons/dans.svg"
                    width={20}
                    height={20}
                    alt="Dans icon"
                  />
                  <Grow>
                    <Text bold>
                      <Value name="home.welkom.kaart1.dans">Dans</Value>
                    </Text>
                  </Grow>
                  <TbChevronRight size={20} />
                </GrowRoundedButton>
              )}
              {!currentSchool?.subscription.isDramaHidden && (
                <GrowRoundedButton
                  as={Link}
                  {...Routes.LearningLineIndexOverview({
                    vak: "drama",
                  })}
                  {...getAnalyticsDataAttributes({
                    eventAction: "click",
                    elementLocation: "dramaLearningLineHome",
                    elementText: "Drama",
                  })}
                  hoverColor={getContentSubjectColor("DRAMA")}
                >
                  <NextImage
                    src="/icons/drama.svg"
                    width={20}
                    height={20}
                    alt="Drama icon"
                  />
                  <Grow>
                    <Text bold>
                      <Value name="home.welkom.kaart1.drama">Drama</Value>
                    </Text>
                  </Grow>
                  <TbChevronRight size={20} />
                </GrowRoundedButton>
              )}
            </Stack>
          </Stack>
        </Content>
      </Root>
    )
  }
)

BulletinBoardLLCard.displayName = "BulletinBoardLLCard"

export default BulletinBoardLLCard
