import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { UserListAccessLevel, UserListType } from "db"
import Image from "next/image"
import { ComponentProps } from "react"

type Props = {
  color: string
  accessLevel: UserListAccessLevel
  type: UserListType
}

const Root = styled.div<Props>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  color: white;
  position: relative;
  overflow: hidden;
  flex: 0 0 20px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
  }
  ${(p) =>
    p.accessLevel === "PERSONAL"
      ? css`
          :after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 50%;
            width: 100%;
            background-color: black;
            opacity: 0.5;
            transform-origin: bottom center;
            transform: rotate(45deg);
          }
        `
      : ""}
`

export default function PlaylistMenuCircle(props: ComponentProps<typeof Root>) {
  const { accessLevel, type } = props
  return (
    <Root {...props}>
      {(() => {
        switch (accessLevel) {
          case "GLOBAL":
            if (type === "LL") {
              return "LL"
            }
            return (
              <Image
                alt=""
                src="/icons/small-123zing-logo-rect.png"
                width={20}
                height={20}
              />
            )
          case "GROUP":
            return "G"
        }
      })()}
    </Root>
  )
}
