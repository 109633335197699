import { GetSharedWithMeResult } from "../queries/getSharedWithMe"

type ItemWithDisplayTitle = {
  displayTitle: string | null
}

type Props = {
  type: "LESSON" | "PLAYLIST" | "THEME" | "LL"
  lesson?: ItemWithDisplayTitle | null
  playlist?: {
    playlist: ItemWithDisplayTitle | null
  } | null
  themeGroup?: {
    globalPlaylist: {
      playlist: ItemWithDisplayTitle | null
    } | null
  } | null
  learningLine?: ItemWithDisplayTitle | null
}

const getShareTitle = (share: Props) => {
  if (share.type === "LESSON") {
    return share.lesson?.displayTitle
  }
  if (share.type === "PLAYLIST" && share.playlist) {
    return share.playlist?.playlist?.displayTitle
  }
  if (share.type === "THEME") {
    return share.themeGroup?.globalPlaylist?.playlist?.displayTitle
  }
  if (share.type === "LL") {
    return share.learningLine?.displayTitle
  }
  return ""
}

export default getShareTitle