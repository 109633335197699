import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Slider from "@radix-ui/react-slider"
import { useAtomValue } from "jotai"
import { ComponentProps } from "react"
import { ORANGE_COLOR } from "theme/colors"
import {
  beginAtom,
  currentDurationAtom,
  currentTimeAtom,
  endAtom
} from "../state"

export const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
`

type SlideTrackProps = {
  color?: string
  bufferColor?: string
  bufferedPercentage?: number
}

const SliderTrack = styled(Slider.Track)<SlideTrackProps>`
  position: relative;
  flex-grow: 1;
  border-radius: 4px;
  height: 12px;
  ${(props) => {
    const baseColor = props.color ? props.color : "rgba(255, 255, 255, 0.3)"
    const bufferColor = props.bufferColor
      ? props.bufferColor
      : "rgba(255, 255, 255, 0.5)"
    if (!!props.bufferedPercentage) {
      return css`
        background: rgb(255, 119, 0);
        background: linear-gradient(
          90deg,
          ${bufferColor} ${props.bufferedPercentage}%,
          ${baseColor} ${props.bufferedPercentage}%
        );
      `
    } else
      return css`
        background-color: ${baseColor};
      `
  }}
`

const SliderRange = styled(Slider.Range)<{ color?: string }>`
  position: absolute;
  background-color: ${(props) => (props.color ? props.color : ORANGE_COLOR)};
  border-radius: 999px;
  height: 100%;
`

const SliderThumb = styled(Slider.Thumb)<{ color?: string }>`
  display: block;
  background-color: ${(props) => (props.color ? props.color : "white")};
  border-radius: 32px;
  width: 32px;
  height: 32px;
  box-shadow: 0 3px 6px black;
  &:focus {
    outline: none;
  }
`

type Props = {
  thumbColor?: string
  trackColor?: string
  rangeColor?: string
  bufferedAmount?: TimeRanges
  bufferColor?: string
} & ComponentProps<typeof SliderRoot>

const ProgressBar = ({
  bufferedAmount,
  ...props
}: Props) => {
  const begin = useAtomValue(beginAtom)
  const end = useAtomValue(endAtom)
  const currentDuration = useAtomValue(currentDurationAtom)
  const currentTime = useAtomValue(currentTimeAtom)

  const bufferedPercentage = () => {
    if (!bufferedAmount || !currentDuration) return undefined
    return (bufferedAmount?.end(bufferedAmount?.length - 1) / currentDuration) * 100
  }

  return (
    <SliderRoot
      min={begin || 0}
      max={end || currentDuration || 0}
      value={[currentTime]}
      {...props}
    >
      <SliderTrack
        color={props.trackColor}
        bufferColor={props.bufferColor}
        bufferedPercentage={
          !!bufferedAmount?.length ? bufferedPercentage() : undefined
        }
      >
        <SliderRange color={props.rangeColor} />
      </SliderTrack>
      <SliderThumb color={props.thumbColor} />
    </SliderRoot>
  )
}

export default ProgressBar
