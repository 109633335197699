import { Routes } from "@blitzjs/next"
import { useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Stack } from "@mantine/core"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import logout from "app/auth/mutations/logout"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader as DrawerHeaderBase,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
} from "app/core/components/Drawer"
import { SUPPORT_LINKS } from "app/core/consts"
import { addQueryParams } from "app/core/utils/addQueryParams"
import { MODAL_QUERY_ARCHIVE_KEY, MODAL_QUERY_KEY } from "app/playlists/consts"
import { useHasArchiveYears } from "app/schooljaren/hooks/useHasArchiveYears"
import { useHasSchoolyears } from "app/schooljaren/hooks/useHasSchoolyears"
import { Group } from "db"
import { parseAsString, useQueryState } from "nuqs"
import { ORANGE_COLOR } from "theme/colors"
import { PX18 } from "theme/sizes"
import Link from "ui/Link"
import { Separator } from "ui/Separator"
import { Drawer as DrawerPrimitive } from "vaul"

const DrawerHeader = styled(DrawerHeaderBase)`
  height: 30px;
  border-bottom: 1px solid #e0e0e0;
`

const Button = styled.a<{ bold?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: ${PX18};
  cursor: pointer;
`

const TextLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

type Props = {
  trigger?: React.ReactNode
  groups: Group[] | null
}

export default function MobileMenuDrawer({ trigger, groups }: Props) {
  const [logoutMutation] = useMutation(logout)
  const [organizatorRole] = useQuery(getOrganizatorRole, {})
  const archiveYearsPresent = useHasArchiveYears()
  const hasSchoolyears = useHasSchoolyears()
  const [open, setOpen] = useQueryState(MODAL_QUERY_KEY)
  const [archiveOpen, setArchiveOpen] = useQueryState(MODAL_QUERY_ARCHIVE_KEY)
  const [next] = useQueryState("next", parseAsString)
  return (
    <Drawer>
      {trigger ? <DrawerTrigger asChild>{trigger}</DrawerTrigger> : null}
      <DrawerOverlay />
      <DrawerPortal>
        <DrawerContent>
          <DrawerHeader>
            <DrawerIconClose>
              <FaTimes size={20} />
            </DrawerIconClose>
          </DrawerHeader>
          <DrawerDescription>
            <Stack>
              <Button as={Link} {...Routes.Home()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.home">
                  Home
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button
                as={Link}
                {...Routes.LearningLineIndexOverview({
                  vak: "muziek",
                })}
              >
                <Value name="apps.web.src.header.components.mobilemenudrawer.leerlijnen">
                  Leerlijnen
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button as={Link} {...Routes.CategoriesPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.categorie_n">
                  Categorieën
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button as={Link} {...Routes.CalendarPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.kalender">
                  Kalender
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button as={Link} {...Routes.LeerlingcodesPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.kids">
                  Kids
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button as={Link} {...Routes.StudioPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.studio">
                  Studio
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <Button as={Link} {...Routes.LeerkrachtIndexPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.leerkracht">
                  Leerkracht
                </Value>
                <FaChevronRight color={ORANGE_COLOR} />
              </Button>
              <DrawerPrimitive.Close asChild>
                <Button onClick={() => setOpen("")}>
                  <Value name="apps.web.src.header.components.mobilemenudrawer.bibliotheek">
                    Bibliotheek
                  </Value>
                  <FaChevronRight color={ORANGE_COLOR} />
                </Button>
              </DrawerPrimitive.Close>
              <Separator />
              <TextLink
                href="https://123zing.my.site.com/knowledgebase/s/contactsupport"
                target="_blank"
                rel="noreferrer"
              >
                <Value name="apps.web.src.header.components.headeruser.klantenservice_contact">
                  Klantenservice & contact
                </Value>
              </TextLink>
              <TextLink
                href={SUPPORT_LINKS["GENERAL_SUPPORT"]}
                target="_blank"
                rel="noreferrer"
              >
                <Value name="apps.web.src.header.components.headeruser.hulp_en_aan_de_slag" version="1">
                  Hulp & Aan de slag
                </Value>
              </TextLink>
              <TextLink {...Routes.SettingsPage()}>
                <Value name="apps.web.src.header.components.mobilemenudrawer.profiel_en_instellingen">
                  Profiel en instellingen
                </Value>
              </TextLink>
              <Separator />
              {organizatorRole && organizatorRole !== "MEMBER" ? (
                <TextLink as={Link} {...Routes.SchoolbeheerPage()}>
                  <Value name="apps.web.src.header.components.headeruser.schoolbeheer">
                    Schoolbeheer
                  </Value>
                </TextLink>
              ) : null}
              {organizatorRole && organizatorRole !== "MEMBER" ? (
                <>
                  <TextLink as={Link} {...Routes.SchoolbeheerPlanPage()}>
                    <Value name="apps.web.src.header.components.headeruser.123zing_plan">
                      123ZING Plan
                    </Value>
                  </TextLink>
                </>
              ) : null}
              {organizatorRole && organizatorRole === "MEMBER" ? (
                <TextLink as={Link} {...Routes.MijnSchoolPage()}>
                  <Value name="apps.web.src.header.components.headeruser.mijn_school">
                    Mijn school
                  </Value>
                </TextLink>
              ) : null}
              {hasSchoolyears && archiveYearsPresent ? (
                <DrawerPrimitive.Close asChild>
                  <TextLink onClick={() => setArchiveOpen("")}>
                    <Value name="apps.web.src.header.components.headeruser.archief">
                      Archief
                    </Value>
                  </TextLink>
                </DrawerPrimitive.Close>
              ) : null}
              {groups && groups.length > 1 ? (
                <TextLink
                  as={Link}
                  {...addQueryParams(
                    Routes.GroupPicker(),
                    next ? { next } : {}
                  )}
                >
                  <Value name="apps.web.src.header.components.headeruser.wissel_groep">
                    Wissel groep
                  </Value>
                </TextLink>
              ) : null}
              {organizatorRole ? <Separator /> : null}
              <TextLink
                onClick={async () => {
                  await logoutMutation()
                  sessionStorage.clear()
                }}
              >
                <Value name="apps.web.src.header.components.headeruser.uitloggen">
                  Uitloggen
                </Value>
              </TextLink>
            </Stack>
          </DrawerDescription>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  )
}
