import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import { GREEN_COLOR } from "theme/colors"
import { UserPlaylistWithSubject } from "../types"


export default function getUserPlaylistColor(playlist: UserPlaylistWithSubject) {
  if (playlist.type === "LL") {
    if (playlist.learningLine?.headerBackgroundColor) {
      return playlist.learningLine.headerBackgroundColor
    }
    if (playlist.learningLine?.learningLineTheme?.learningLine?.contentSubject) {
      return getContentSubjectColor(playlist.learningLine.learningLineTheme.learningLine.contentSubject)
    }
  }
  if (playlist.type === "THEME" && playlist.themeGroup?.globalPlaylist?.playlist?.color) {
    return playlist.themeGroup.globalPlaylist.playlist.color
  }
  if (playlist.playlist?.color) {
    return playlist.playlist?.color
  }
  // if (playlist.oldColor) {
  //   return playlist.oldColor
  // }
  return GREEN_COLOR
}