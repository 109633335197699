import { css } from "@emotion/css"
import styled from "@emotion/styled"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { ReactNode } from "react"
import {
  DARK_BLUE_COLOR,
  DARK_ORANGE_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
} from "theme/colors"
import { CONTEXT_MENU_Z_INDEX, PX14, PX16 } from "theme/consts"
import { Root as RootBase } from "ui/Popover"

export const Root = styled(RootBase)`
  padding: 8px 0;
  /* padding: 12px 0; */
  overflow-y: auto;
  max-height: var(--radix-dropdown-menu-content-available-height);
  z-index: auto;
`

export const Label = styled.div`
  padding: 12px 24px;
  color: ${DARK_BLUE_COLOR};
  font-weight: 600;
  font-size: ${PX14};
`

type ItemProps = {
  type?: "critical" | "default"
  isActive?: boolean
}

export const Item = styled(DropdownMenu.Item)<ItemProps>`
  background: none;
  border: none;
  padding: 12px 24px;
  text-align: left;
  color: ${(p) =>
    p.type === "critical" ? DARK_ORANGE_COLOR : DARK_BLUE_COLOR};
  background-color: ${(p) => (p.isActive ? "#FFF0E9" : "transparent")};
  /* border-radius: 8px; */
  font-size: ${PX14};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: unset;
    text-decoration: unset;
  }
  &::before {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    background-color: ${(p) => (p.isActive ? ORANGE_COLOR : "transparent")};
  }
`

export const Separator = styled.div`
  background: ${LIGHT_GREY_COLOR};
  height: 1px;
  margin: 4px 0;
  width: 100%;
`

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
`

function ContextMenu({
  trigger,
  items,
  ...props
}: {
  trigger: ReactNode
  items: ReactNode
} & DropdownMenu.DropdownMenuProps) {
  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{ zIndex: CONTEXT_MENU_Z_INDEX }}
        >
          <Root>{items}</Root>
          <Arrow width={30} height={15} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

ContextMenu.Root = Root
ContextMenu.Label = Label
ContextMenu.Item = Item
ContextMenu.Separator = Separator
ContextMenu.Arrow = Arrow

export default ContextMenu
