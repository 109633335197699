import { usePathname, useSearchParams } from "next/navigation"
import { useCallback, useEffect, useRef, useState } from "react"

import generateAnalyticsObject, {
  AnalyticsObject,
} from "app/analytics/utils/generateAnalyticsObject"

function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

interface NavigationEvents {
  routeChanged?: ({
    pathname,
    searchParams,
  }: {
    pathname: string | null
    searchParams: URLSearchParams | null
  }) => void
}

// const useNavigation = ({ on }: { on?: NavigationEvents }) => {
//   const pathname = usePathname()
//   const prevPathname = usePrevious(pathname)

//   const searchParams = useSearchParams()
//   const prevSearchParams = usePrevious(searchParams)

//   const { routeChanged } = on || {}
//   const [route, setRoute] = useState({ pathname, searchParams })

//   useEffect(() => {
//     if (
//       // Don't do anything on searchParam changes for now
//       // searchParams?.toString() !== prevSearchParams?.toString() ||
//       pathname !== prevPathname
//     ) {
//       setRoute({ pathname, searchParams })
//       routeChanged?.({ pathname, searchParams })
//       // console.log(
//       //   "route changed, logging changes",
//       //   searchParams?.toString(),
//       //   prevSearchParams?.toString(),
//       //   pathname,
//       //   prevPathname
//       // )
//     }
//   }, [pathname, prevPathname, prevSearchParams, routeChanged, searchParams])

//   useEffect(() => {
//     console.log("pathName", pathname)
//   }, [pathname])

//   useEffect(() => {
//     console.log("pathName", prevPathname)
//   }, [prevPathname])

//   useEffect(() => {
//     console.log("searchParams", searchParams)
//   }, [searchParams])

//   useEffect(() => {
//     console.log("Searchparams", prevSearchParams)
//   }, [prevSearchParams])

//   useEffect(() => {
//     console.log("Routechanged")
//   }, [routeChanged])

//   return { route }
// }

export default function Analytics({
  analyticsObject,
}: {
  analyticsObject: Partial<AnalyticsObject>
}) {
  const pathname = usePathname()
  const prevPathname = usePrevious(pathname)

  const searchParams = useSearchParams()
  const prevSearchParams = usePrevious(searchParams)

  const sendObject = useCallback(
    (analyticsObject: Partial<AnalyticsObject>) =>
      sendAnalyticsObject(analyticsObject),
    []
  )

  const generateObject = useCallback(
    (analyticsObject: Partial<AnalyticsObject>) =>
      generateAnalyticsObject(analyticsObject),
    []
  )
  useEffect(() => {
    if (
      // Don't do anything on searchParam changes for now
      // searchParams?.toString() !== prevSearchParams?.toString() ||
      pathname !== prevPathname
    ) {
      const finalAnalyticsObject = generateObject(analyticsObject)
      sendObject(finalAnalyticsObject)
      // console.log(
      //   "route changed, logging changes",
      //   searchParams?.toString(),
      //   prevSearchParams?.toString(),
      //   pathname,
      //   prevPathname
      // )
    }
  }, [
    analyticsObject,
    pathname,
    prevPathname,
    searchParams,
    sendObject,
    generateObject,
  ])

  return <></>
}

export function sendAnalyticsObject(analyticsObject: Partial<AnalyticsObject>) {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(analyticsObject)
  }
}
