import { atom, useAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"

const volumeAtom = atom(1)

export default function useGlobalVolume() {
  const [volume, setVolume] = useAtom(volumeAtom)
  useEffect(() => {
    const volume = localStorage.getItem("volume")
    if (volume) {
      setVolume(parseInt(volume) / 100)
    }
  }, [setVolume])

  return [volume, useCallback((newVolume: number) => {
    localStorage.setItem("volume", `${newVolume * 100}`)
    setVolume(newVolume)
  }, [setVolume])] as const
}