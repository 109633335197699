import styled from "@emotion/styled"
import { HEADER_Z_INDEX } from "theme/consts"
import { SUPPORT_LINKS } from "../consts"

const Icon = styled.img`
  width: 46px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 42px;
  background-color: transparent;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
  z-index: ${HEADER_Z_INDEX};
`

const HelpButton = ({ type }: { type: keyof typeof SUPPORT_LINKS }) => {
  return (
    <a href={SUPPORT_LINKS[type]} target="_blank" rel="noreferrer">
      <Icon src={"/icons/supp_icon_bl.svg"} />
    </a>
  )
}

export default HelpButton
