import styled from "@emotion/styled"
import { ComponentProps } from "react"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"

type WrapperProps = { position?: "relative" | "absolute" }

export const Wrapper = styled.div<{ position?: "relative" | "absolute" }>`
  overflow: hidden;
  position: ${p => p.position || "absolute"};
  left: -5px;
  top: 5px;
  z-index: 3;
`

export const Label = styled.div<{ color?: string; size?: string }>`
  font-size: ${p => p.size || PX14};
  font-weight: 700;
  color: white;
  background: ${p => p.color || DARK_BLUE_COLOR};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transform-origin: left;
  transform: rotate(-5deg);
  display: inline-block;
  padding: 5px;
  padding-right: 15px;
  padding-left: 25px;
  margin-top: 15px;
  left: -10px;
  position: relative;
`

const CardLabel = ({ position, ...props}: ComponentProps<typeof Label> & WrapperProps) => {
  return (
    <Wrapper position={position}>
      <Label {...props} />
    </Wrapper>
  )
}

export default CardLabel