import styled from "@emotion/styled"
import * as Accordion from "@radix-ui/react-accordion"
import { ComponentProps, ReactNode } from "react"
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown"
import { PX16 } from "theme/sizes"
import Button from "ui/Button"
import Stack from "ui/Stack"
import Title from "ui/Title"

type Props = {
  title: string
  value: string
  children: ReactNode
} & ComponentProps<typeof Accordion.Item>

const Chevron = styled(FaChevronDown)`
  transition: transform 200ms cubic-bezier(0.22, 1, 0.36, 1);
`

const Root = styled(Stack)`
  &[data-state="open"] ${Chevron} {
    transform: rotate(180deg);
  }
  gap: 16px;
`

const AccordionTrigger = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export default function AccordionItem({ title, children, ...props }: Props) {
  return (
    <Accordion.Item asChild {...props}>
      <Root>
        <Accordion.Trigger asChild>
          <AccordionTrigger>
            <Title size={PX16}>{title}</Title>
            <Chevron />
          </AccordionTrigger>
        </Accordion.Trigger>
        <Accordion.Content>
          <Stack gap="4px">{children}</Stack>
        </Accordion.Content>
      </Root>
    </Accordion.Item>
  )
}
