import styled from "@emotion/styled"
import * as Slider from "@radix-ui/react-slider"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { css } from "@emotion/react"

type Props = { orientation: "horizontal" | "vertical"; onChange; volume; theme?: "light" | "dark" }

const BAR_HEIGHT = 3
const BAR_WIDTH = 100

const SliderRoot = styled(Slider.Root)`
  position: absolute;

  &[data-orientation="horizontal"] {
    height: ${BAR_HEIGHT}px;
    width: ${BAR_WIDTH}px;
  }
  &[data-orientation="vertical"] {
    width: ${BAR_HEIGHT}px;
    height: ${BAR_WIDTH}px;
  }
`

const SliderRange = styled(Slider.Range)`
  position: absolute;
  border-radius: 8px;
  transition: background-color 0.1s ease-in-out;
  background-color: ${ORANGE_COLOR};

  &[data-orientation="horizontal"] {
    height: ${BAR_HEIGHT}px;
  }
  &[data-orientation="vertical"] {
    width: ${BAR_HEIGHT}px;
  }
`

const SliderThumb = styled(Slider.Thumb)<{theme?: "light" | "dark"}>`
  opacity: 0;
  display: block;
  border-radius: 32px;
  width: 15px;
  height: 15px;
  box-shadow: 0 0 6px black;
  &:focus {
    outline: none;
  }

  &[data-orientation="horizontal"] {
    ${p => p.theme === "light" ? css`background-color: ${DARK_BLUE_COLOR};` : css`background-color: white;` }
    ${p => p.theme === "light" ? css`box-shadow: 0 3px 6px #00000029;` : css`box-shadow: 0 0 6px black;` }
    transform: translate(0, -8.5px);
  }
  &[data-orientation="vertical"] {
    opacity: 1;
    background-color: ${DARK_BLUE_COLOR};
    box-shadow: 0 3px 6px #00000029;
    transform: translateX(-6px);
  }
`

const Root = styled.div<{
  orientation?: "horizontal" | "vertical"
}>`
  display: grid;
  grid-template-columns: ${BAR_WIDTH}px;
  align-content: center;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.orientation === "horizontal"
      ? css`
          height: ${BAR_HEIGHT}px;
          width: ${BAR_WIDTH}px;
          margin-right: 8px;
        `
      : css`
          width: ${BAR_HEIGHT}px;
          height: ${BAR_WIDTH}px;
        `}

  &:hover {
    ${SliderThumb} {
      opacity: 1;
    }
  }
`

const SliderTrack = styled(Slider.Track)<{
  orientation?: "horizontal" | "vertical"
  theme?: "light" | "dark"
}>`
  display: block;
  border-radius: 8px;

  &[data-orientation="horizontal"] {
    ${p => p.theme === "light" ? css`background-color: #FFF0E9;` : css`background-color: ${DIMMED_BLUE_COLOR};` }
    height: ${BAR_HEIGHT}px;
    width: ${BAR_WIDTH}px;
    flex-grow: 1;
  }
  &[data-orientation="vertical"] {
    background-color: #fff0e9;
    width: ${BAR_HEIGHT}px;
    height: ${BAR_WIDTH}px;
  }
`

const VolumeSlider = ({ orientation, onChange, volume, theme }: Props) => {
  return (
    <Root orientation={orientation}>
      <SliderRoot
        onValueChange={(values) => onChange(values[0])}
        value={[volume]}
        max={1}
        min={0}
        step={0.01}
        aria-label="Radio volume"
        orientation={orientation ? orientation : "horizontal"}
      >
        <SliderTrack theme={theme}>
          <SliderRange />
        </SliderTrack>
        <SliderThumb theme={theme} />
      </SliderRoot>
    </Root>
  )
}

export default VolumeSlider
