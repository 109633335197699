import { Grade } from "db"

export default function formatGrades(grades: Grade[], allGrades: Grade[]) {
  const sortedGrades = grades.map((grade) => grade.number).sort()

  if (sortedGrades.length === allGrades.length || sortedGrades.length === 0)
    return "Alles"
  if (sortedGrades.length === 1) return `${sortedGrades[0]}`
  return `${sortedGrades[0]}-${sortedGrades[sortedGrades.length - 1]}`
}
