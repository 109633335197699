"use client"

import styled from "@emotion/styled"
import * as React from "react"
import { DRAWER_OVERLAY_Z_INDEX, DRAWER_Z_INDEX } from "theme/consts"
import { poppins } from "theme/fonts"
import { Drawer as DrawerPrimitive } from "vaul"

const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    direction="left"
    {...props}
  />
)
Drawer.displayName = "Drawer"

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerIconClose = styled(DrawerPrimitive.Close)`
  position: absolute;
  top: 10px;
  right: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: inherit;
`

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = styled(DrawerPrimitive.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${DRAWER_OVERLAY_Z_INDEX};
`

const DrawerContent = styled(DrawerPrimitive.Content)`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 300px;
  z-index: ${DRAWER_Z_INDEX};
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: ${poppins.style.fontFamily};
`

const DrawerHeader = styled.div`
  position: relative;
  display: grid;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  text-align: center;
`

const DrawerFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  margin-top: auto;
`
const DrawerTitle = styled(DrawerPrimitive.Title)`

`

const DrawerDescription = styled(DrawerPrimitive.Description)`
  overflow-y: auto;
  padding: 20px;
`


export {
  Drawer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerIconClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
}
