import { RefObject, useCallback } from "react"

export default function useProgressValueChange(
  elementRef: RefObject<HTMLVideoElement | HTMLAudioElement | null>
) {
  return useCallback(
    (value: number[]) => {
      if (!elementRef || !elementRef.current) return
      elementRef.current.currentTime = value[0] // + (get(beginAtom) || 0)
    },
    [elementRef]
  )
}
