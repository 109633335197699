import styled from "@emotion/styled"

type Align =
  | "center"
  | "flex-start"
  | "flex-end"
  | "stretch"
  | "inherit"
  | "initial"
  | "unset"
type Justify =
  | "center"
  | "left"
  | "right"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "stretch"
  | "inherit"
  | "initial"
  | "unset"
  | "flex-start"
  | "flex-end"

type GroupProps = {
  gap?: string
  align?: Align
  justify?: Justify
  grow?: boolean
  wrap?: boolean
  overflow?: "hidden" | "auto" | "clip"
  inline?: boolean
}

const Group = styled.div<GroupProps>`
  display: ${p => p.inline ? "inline-flex" : "flex"};
  align-items: ${(p) => p.align || "inherit"};
  justify-content: ${(p) => p.justify || "inherit"};
  ${(p) => p.gap && `gap: ${p.gap};`}
  ${(p) => p.grow && `flex-grow: 1;`}
  ${(p) => p.wrap && `flex-wrap: wrap;`}
  ${(p) => p.overflow && `overflow: ${p.overflow};`}
`

export default Group
