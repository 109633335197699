import { UserListAccessLevel, OrganizationUserRole } from "db"

export default function getListTypeCanAdd(
  accessLevel: UserListAccessLevel,
  role?: OrganizationUserRole
) {
  switch (accessLevel) {
    case "PERSONAL":
      return true
    case "GROUP":
    case "KIDS":
      if (role) {
        return true
      }
      return false
    case "GLOBAL":
      return false
    case "SCHOOL":
      if (role === "MANAGER" || role === "INITIATOR") {
        return true
      }
      return false
  }
}
