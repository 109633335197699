import * as Slider from "@radix-ui/react-slider"
import styled from "@emotion/styled"
import { SliderRoot as SliderRootBase } from "app/player/components/ProgressBar"
import { useAtom, useAtomValue } from "jotai"
import { PX10 } from "theme/sizes"
import TextBase from "ui/Text"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import getFormattedTime from "../../player/utils/getFormattedTime"
import { Segment } from "../../player/components/TimeDisplay"
import {
  audioCurrentTimeAtom,
  audioDurationAtom,
  audioElementRefAtom,
} from "app/radio/state"
import useProgressValueChange from "app/player/hooks/useProgressValueChange"
import { css } from "@emotion/react"

const BAR_HEIGHT = 3

const SliderRange = styled(Slider.Range)`
  background-color: white;
  position: absolute;
  /*height: 100%;*/
  height: ${BAR_HEIGHT}px;
  border-radius: 8px;
  transition: background-color 0.1s ease-in-out;
`

const Text = styled(TextBase)`
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  transition: color 0.1s ease-in-out;
`

const SliderThumb = styled(Slider.Thumb)<{ theme?: "light" | "dark" }>`
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  display: block;
  border-radius: 32px;
  width: 15px;
  height: 15px;
  &:focus {
    outline: none;
  }

  ${(p) =>
    p.theme === "light"
      ? css`
          background-color: ${DARK_BLUE_COLOR};
          box-shadow: 0 3px 6px #00000029;
        `
      : css`
          background-color: white;
          box-shadow: 0 0 6px black;
        `}
`

const Root = styled.div<{ disabled?: boolean; theme?: "light" | "dark" }>`
  height: 15px;
  display: grid;
  grid-template-columns: 35px 200px 35px;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.theme === "light" &&
    css`
      ${SliderRange} {
        background-color: ${ORANGE_COLOR};
      }
      ${Text} {
        color: #b2b1c9;
      }
    `}

  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        ${SliderRange} {
          background-color: ${ORANGE_COLOR};
        }
        ${Text} {
          ${p.theme === "light"
            ? css`
                color: ${DARK_BLUE_COLOR};
              `
            : css`
                color: rgba(255, 255, 255, 1);
              `}
        }
        ${SliderThumb} {
          opacity: 1;
        }
      }
    `}
`

const SliderRoot = styled(SliderRootBase)`
  height: 100%;
  /*background-color: ${DARK_BLUE_COLOR};*/
`

const SliderTrack = styled(Slider.Track)<{ theme?: "light" | "dark" }>`
  border-radius: 8px;
  height: ${BAR_HEIGHT}px;
  flex-grow: 1;
  ${(p) =>
    p.theme === "light"
      ? css`
          background-color: #fff0e9;
        `
      : css`
          background-color: ${DIMMED_BLUE_COLOR};
        `}
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ProgressBar = ({
  disabled,
  theme,
}: {
  disabled?: boolean
  theme?: "light" | "dark"
}) => {
  const audioElementRef = useAtomValue(audioElementRefAtom)
  const [currentTime, setCurrentTime] = useAtom(audioCurrentTimeAtom)
  const formattedTime = getFormattedTime(currentTime)
  const duration = useAtomValue(audioDurationAtom)
  const formattedDuration = getFormattedTime(duration)

  const onProgressValueChange = useProgressValueChange({
    current: audioElementRef,
  })

  return (
    <Root disabled={disabled} theme={theme}>
      <Text size={PX10}>
        <Segment side="left">{formattedTime?.minutes}</Segment>
        <Segment side="middle">:</Segment>
        <Segment side="right">
          {Math.floor(formattedTime?.seconds).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </Segment>
      </Text>
      <ProgressBarWrapper>
        <SliderRoot
          min={0}
          max={duration}
          value={[currentTime]}
          onValueChange={onProgressValueChange}
          disabled={disabled}
        >
          <SliderTrack theme={theme}>
            <SliderRange theme={theme} />
          </SliderTrack>
          <SliderThumb theme={theme} />
        </SliderRoot>
      </ProgressBarWrapper>
      <Text size={PX10}>
        <Segment side="left">{formattedDuration?.minutes}</Segment>
        <Segment side="middle">:</Segment>
        <Segment side="right">
          {Math.floor(formattedDuration?.seconds).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </Segment>
      </Text>
    </Root>
  )
}

export default ProgressBar
