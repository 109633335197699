import { useRouter } from "next/router"
import { ParsedUrlQuery } from "querystring"
import { useEffect, useState } from "react"

export const useIsKids = () => {
  const [isLocation, setIsLocation] = useState(false)
  useEffect(() => {
    setIsLocation(location.host.split('.')[0] === "kids")
  }, [])
  return getIsKids(useRouter().query) || isLocation
}

export const useIsKidsPreview = () => {
  const [isLocation, setIsLocation] = useState(false)
  useEffect(() => {
    setIsLocation(location.pathname.indexOf("/kids/preview") === 0)
  }, [])
  return getIsKidsPreview(useRouter().query) || isLocation
}



export function getIsKids(query: ParsedUrlQuery) {
  return query.__subdomain === "kids" || query.__kidsPreview === "true"
}

export function getIsKidsPreview(query: ParsedUrlQuery) {
  return query.__kidsPreview === "true"
}
