
type UserPlaylistWithSubject = {
  type: "PLAYLIST" | "LL" | "THEME"
  learningLine?: {
    playlistLabel: string | null
    displayTitle: string
  } | null
  themeGroup: {
    globalPlaylist: {
      playlist: {
        displayTitle: string
        color: string | null
      } | null
    } | null
  } | null
  playlist: {
    displayTitle: string
    color: string | null
  } | null
}

export default function getUserPlaylistTitle(playlist: UserPlaylistWithSubject) {
  if (playlist.type === "LL" && playlist.learningLine) {
    return playlist.learningLine.playlistLabel || playlist.learningLine.displayTitle
  }
  if (playlist.type === "THEME" && playlist?.themeGroup?.globalPlaylist?.playlist) {
    return playlist.themeGroup.globalPlaylist.playlist.displayTitle
  }
  if (playlist.type === "PLAYLIST" && playlist?.playlist) {
    return playlist.playlist.displayTitle
  }
  return "" 
}