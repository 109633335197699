import { Dispatch, SetStateAction, useState } from "react"
import ContentSubjectFilter from "../../content-subjects/components/ContentSubjectFilter"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
} from "../../core/components/Drawer"
import GroupFilter from "../../groups/components/GroupFilter"
import { useCurrentOrganization } from "../../core/hooks/useCurrentOrganization"
import { OpenTabState } from "app/studio/components/StudioModal"
import Group from "ui/Group"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { Value } from "@stringtale/react"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { Separator } from "ui/Separator"
import styled from "@emotion/styled"
import RoundedButton from "ui/RoundedButton"
import hasOnlyOneLearningLine from "app/subscriptions/hasOnlyOneLearningLine"
import { ContentSubjectType, Grade } from "db"
import Title from "ui/Title"
import { PX16 } from "theme/sizes"
import * as Grades from "app/schoolbeheer/components/groups/Grades"
import toggleArray from "utils/global/toggleArray"
import { VisibilityFilterStates } from "../types"
import Radio from "ui/Radio"

const FilterButton = styled(RoundedButton)`
  border-radius: 8px;
  height: 46px;
  /* padding-left: 11px; */
`

export default function SchoolListFilter({
  allGrades,
  filteredGrades,
  setFilteredGrades,
  visibility,
  setVisibility,
}: {
  allGrades: Grade[]
  filteredGrades: number[]
  setFilteredGrades: Dispatch<SetStateAction<number[]>>
  visibility: VisibilityFilterStates
  setVisibility: Dispatch<SetStateAction<VisibilityFilterStates>>
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const currentSchool = useCurrentOrganization()
  const [openFilterDrawer, setOpenFilterDrawer] = useState<OpenTabState | null>(
    null
  )

  const gradeFilter = (
    <Stack gap={"8px"}>
      <Title size={PX16}>
        <Value name="apps.web.src.playlists.components.schoollistfilters.leerjaar">
          Leerjaar
        </Value>
      </Title>
      <Group wrap gap="6px">
        {allGrades.map((grade) => (
          <Grades.Root
            checked={filteredGrades.includes(grade.id)}
            onCheckedChange={() => setFilteredGrades(toggleArray(grade.id))}
          >
            {grade.number}
          </Grades.Root>
        ))}
      </Group>
    </Stack>
  )
  const visibilityFilter = (
    <Stack gap={"8px"}>
      <Title size={PX16}>
        <Value name="apps.web.src.playlists.components.schoollistfilters.zichtbaarheid">
          Zichtbaarheid
        </Value>
      </Title>
      <Group wrap gap="6px">
        <Radio.Group
          value={visibility}
          onValueChange={(value: VisibilityFilterStates) => {
            setVisibility(value)
          }}
        >
          <Radio
            label={`Alles`}
            value={VisibilityFilterStates.ALL}
            defaultChecked
          />
          <Radio
            label={`Zichtbaar voor collega’s`}
            value={VisibilityFilterStates.PUBLISHED}
          />
          <Radio
            label={`Onzichtbaar voor collega’s`}
            value={VisibilityFilterStates.UNPUBLISHED}
          />
        </Radio.Group>
      </Group>
    </Stack>
  )

  return (
    <Group gap="6px">
      <FilterButton
        onClick={() => setIsDrawerOpen(true)}
        variant="filled"
        left={<FaSlidersH />}
      >
        <Value name="apps.web.src.studio.components.studiothemegroup.filter">
          Filter
        </Value>
      </FilterButton>
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>
              <Stack gap="18px">
                <Text bold>
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter">
                    Filter
                  </Value>
                </Text>
                <Separator />
                {gradeFilter}
                <Separator />
                {visibilityFilter}
                <Separator />
              </Stack>
            </DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>

      <Drawer>
        <DrawerTrigger asChild>
          <FilterButton onClick={() => setOpenFilterDrawer("new")} color="blue">
            <Value name="apps.web.src.playlists.components.schoollistfilters.leerjaar">
              Leerjaar
            </Value>
          </FilterButton>
        </DrawerTrigger>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>{gradeFilter}</DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>

      <Drawer>
        <DrawerTrigger asChild>
          <FilterButton onClick={() => setOpenFilterDrawer("new")} color="blue">
            <Value name="apps.web.src.playlists.components.schoollistfilters.zichtbaarheid">
              Zichtbaarheid
            </Value>
          </FilterButton>
        </DrawerTrigger>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>{visibilityFilter}</DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </Group>
  )
}
