import { Value } from "@stringtale/react"
import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import LessonSubTitle from "app/lessons/components/LessonSubTitle"
import {
  File,
  Grade,
  HeadSubject,
  HeadSubjectGroup,
  Lesson,
  ParticipationIcon,
  SubSubject,
} from "db"
import { useAtom, useAtomValue } from "jotai"
import { ComponentProps, ReactNode } from "react"
import { TbDots } from "@react-icons/all-files/tb/TbDots"
import { PX12 } from "theme/sizes"
import ContextMenu from "ui/ContextMenu"
import GradeIcons from "ui/Grades"
import Group from "ui/Group"
import Link from "ui/Link"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import {
  Content,
  HIDE_IMAGE_BREAKPOINT,
  RootWithBorder as RootBase,
  SWAP_BUTTON_BREAKPOINT,
} from "web/src/lessons/components/LessonListItem"
import LessonListItemTitle from "web/src/lessons/components/LessonListItemTitle"
import LessonPreview, {
  PreviewLink,
} from "web/src/lessons/components/LessonPreview"
import { ImageRoot } from "web/src/lessons/components/LessonListItem"
import LeaningLineLessonLabel from "../../lessons/components/LearningLineLessonLabel"
import {
  isPlayingAtom,
  playingStateAtom,
} from "../../radio/state"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import ButtonBase from "ui/Button"
import { FaSort } from "@react-icons/all-files/fa/FaSort"
import { ORANGE_COLOR } from "theme/colors"
import { css } from "@emotion/react"
import CheckboxBase from "./Checkbox"
import InputBase from "ui/Input"
import parseStringTime from "app/rehearsal/utils/parseStringTime"
import { FileWithVariants } from "utils/files/types"
import { isSelectedAtom } from "../state"

export type TParticipantsIcons = ParticipationIcon & {
  image: File | null
}

export type TLesson = Lesson & {
  grades: Grade[]
  cardImage: File | null
  radioSong: File | null
  previewVideo: FileWithVariants | null
  previewAudio: File | null
  rehearse: {
    video: FileWithVariants | null
    audio: File | null
  } | null
  subjects: (HeadSubjectGroup & {
    subjects: (HeadSubject & {
      subjects: SubSubject[]
      participationIcons: TParticipantsIcons[]
    })[]
  })[]
}

const Root = styled(RootBase)<{ color: string; isDragging: boolean }>`
  padding: 24px 24px 24px 8px;
  background: ${({ color }) => color};

  ${({ isDragging }) =>
    isDragging
      ? css`
          z-index: 1;
          box-shadow: 0px 3px 10px #000000e6;
          border-radius: 4px;
        `
      : ""}
`

const RoundedButton = styled(RoundedButtonBase)`
  width: 100px;
`

const Checkbox = styled(CheckboxBase)`
  position: absolute;
  right: 10px;
  top: 10px;
`

const CheckableLessonPreview = styled(LessonPreview)<{ checked: boolean }>`
  ${ImageRoot} {
    border: 2px solid #ffffff99;

    ${({ checked }) =>
      checked
        ? css`
            border: 2px solid ${ORANGE_COLOR};
            border-radius: 8px;

            ::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              background: ${ORANGE_COLOR}80;
              pointer-events: none;
            }
          `
        : css`
            img {
              opacity: 0.5;
            }
          `}
  }
`

const Input = styled(InputBase)`
  width: 36px;
  height: 32px;
  input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0%;
    padding-right: 0%;
    text-align: center;
  }
`

const Button = styled(ButtonBase)`
  flex-grow: 1;
`

const ButtonGroup = styled(Stack)`
  gap: 8px;
  justify-content: center;
  align-items: flex-end;

  @container (max-width: ${SWAP_BUTTON_BREAKPOINT}) {
    justify-content: center;
    align-items: flex-start;
  }
`

type Props = {
  item: { id: number; lesson: TLesson }
  index: number
  lessons: { lesson: TLesson }[]
  grades: Grade[]
  contextMenuItems?: ReactNode
  color: string
  isEditMode: boolean
  canOrderItem: boolean
  updatePosition: (oldIndex: number, newIndex: number) => void
} & Omit<ComponentProps<typeof Root>, "isDragging">

const PlaylistListItem = ({
  item,
  lessons,
  grades,
  contextMenuItems,
  color,
  canOrderItem,
  isEditMode,
  index,
  updatePosition,
  ...props
}: Props) => {
  const { lesson } = item
  const {
    attributes,
    listeners,
    setNodeRef,
    setDraggableNodeRef,
    transform,
    transition,
    active,
  } = useSortable({
    id: item.id,
  })

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform.x,
          y: transform.y,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition,
  }
  const playingState = useAtomValue(playingStateAtom)
  const isPlaying =
    useAtomValue(isPlayingAtom) && playingState?.lesson.id === lesson.id

  const [isChecked, setChecked] = useAtom(isSelectedAtom(item.id))

  return (
    <Root
      {...props}
      color={isPlaying ? `${color}33` : "#ffffff"}
      ref={setNodeRef}
      style={style}
      isDragging={active?.id === item.id}
    >
      <Group gap="10px" grow>
        {isEditMode && canOrderItem ? (
          <Stack style={{ width: 36 }}>
            <Button
              style={{ cursor: "grabbing" }}
              ref={setDraggableNodeRef}
              {...attributes}
              {...listeners}
            >
              <FaSort color={ORANGE_COLOR} />
            </Button>
            <Input
              key={index}
              defaultValue={index + 1}
              onBlur={(e) => {
                const val = parseInt(e.target.value)
                if (Number.isNaN(val)) {
                  e.currentTarget.value = `${index + 1}`
                  return
                }
                updatePosition(index, parseInt(e.target.value) - 1)
              }}
            />
          </Stack>
        ) : null}
        <Group gap="40px" grow>
          {lesson.cardImage ? (
            isEditMode ? (
              <CheckableLessonPreview
                previewId={`checkableLesson-${lesson.id}`}
                image={lesson.cardImage}
                checked={isChecked}
              >
                <Checkbox checked={isChecked} onCheckedChange={setChecked} />
              </CheckableLessonPreview>
            ) : (
              <PreviewLink
                {...Routes.LessonPage({
                  lesson: lesson.slug,
                })}
              >
                <LessonPreview
                  previewId={`playlist-${lesson.id}`}
                  isPlayVisible={
                    !!lesson?.previewVideo ||
                    !!lesson?.previewAudio ||
                    !!lesson?.rehearse?.video ||
                    !!lesson?.radioSong
                  }
                  image={lesson.cardImage}
                  previewBegin={
                    lesson?.previewBegin
                      ? parseStringTime(lesson.previewBegin)
                      : undefined
                  }
                  previewEnd={
                    lesson?.previewEnd
                      ? parseStringTime(lesson.previewEnd)
                      : undefined
                  }
                  file={
                    lesson?.previewVideo ||
                    lesson?.previewAudio ||
                    lesson?.rehearse?.video ||
                    lesson?.radioSong
                  }
                />
              </PreviewLink>
            )
          ) : null}
          <Content>
            <Stack gap="8px">
              {lesson.displayTitle && (
                <Link
                  {...Routes.LessonPage({
                    lesson: lesson.slug,
                  })}
                >
                  <LessonListItemTitle
                    title={lesson.displayTitle}
                    contentSubject={lesson.contentSubject}
                  />
                </Link>
              )}
              <LessonSubTitle size={PX12} lesson={lesson} />
              <Group gap="4px" justify="left">
                <GradeIcons
                  allGrades={grades}
                  activeGrades={lesson.grades}
                  contentSubjectType={lesson.contentSubject}
                />
                {lesson.isLearningLineLesson ? (
                  <LeaningLineLessonLabel
                    color={getContentSubjectColor(lesson.contentSubject)}
                  />
                ) : null}
              </Group>
              <Text size={PX12}>{lesson.searchDescription}</Text>
            </Stack>
          </Content>
        </Group>
      </Group>
      <ButtonGroup>
        <RoundedButton
          size="small"
          as={Link}
          {...Routes.LessonPage({
            lesson: lesson.slug,
          })}
        >
          <Value name="apps.web.src.playlists.components.playlistitem.bekijk_les" version="1">
            Open les
          </Value>
        </RoundedButton>
        {contextMenuItems ? (
          <ContextMenu
            trigger={
              <RoundedButton
                left={<TbDots size={20} style={{ flexShrink: 0 }} />}
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Value name="apps.web.src.playlists.components.playlistitem.opties">
                  Opties
                </Value>
              </RoundedButton>
            }
            items={contextMenuItems}
          />
        ) : null}
      </ButtonGroup>
    </Root>
  )
}

export default PlaylistListItem
