// New colors
export const ORANGE_COLOR = "#f16636"
export const DARK_ORANGE_COLOR = "#C8320C"
export const LIGHT_ORANGE_COLOR = "#FEECE6"

export const DARK_BLUE_COLOR = "#03004E"
export const LIGHT_BLUE_COLOR = "#3AAFF9"
export const DIMMED_BLUE_COLOR = "#807EA5"
export const ADD_PLAYLIST_BLUE_COLOR = "#5D5C8D"

export const LIGHTER_GREY_COLOR = "#F6F6F6"
export const LIGHT_GREY_COLOR = "#E8E8E8"
export const DARKER_GREY_COLOR = "#707070"
export const BORDER_GREY_COLOR = "#CCCCDB"

export const DARK_GREEN_COLOR = "#21837B"
export const GREEN_COLOR = "#17A085"
export const LIGHT_GREEN_COLOR = "#E9FFF9"

export const MEDIUM_GREEN_COLOR = "#0F725D"

export const YELLOW_COLOR = "#ED8929"
export const DARK_YELLOW_COLOR = "#D55E0F"

export const RED_COLOR = "#D41A32"
export const BURGUNDY_COLOR = "#D3569F"
export const PURPLE_COLOR = "#9956D3"

export const SYSTEM_BLUE = "#4977BC"
export const BACKGROUND_BLUE = "#DAE3F1"

export const SYSTEM_YELLOW = "#FEBC11"
export const BACKGROUND_YELLOW = "#FFF2CF"

export const LIST_COLORS = [
  GREEN_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
  LIGHT_BLUE_COLOR,
  BURGUNDY_COLOR,
  PURPLE_COLOR,
]
