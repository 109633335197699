import styled from "@emotion/styled"
import { DARK_BLUE_COLOR, LIGHT_GREY_COLOR } from "theme/colors"
import { RADIUS } from "./Modal"
import { PX12, PX14, PX16 } from "theme/sizes"
import * as Separator from "@radix-ui/react-separator"
import Stack from "./Stack"
import { TbChevronDown } from "@react-icons/all-files/tb/TbChevronDown"
import Button from "./Button"
import { css } from "@emotion/css"


export const Root = styled.div`
  width: 279px;
  background-color: ${DARK_BLUE_COLOR};
  color: white;
  border-top-left-radius: ${RADIUS};
  border-bottom-left-radius: ${RADIUS};
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`


export const Header = styled(Stack)`
  padding: 24px;
  gap: 29px;
`

export const Footer = styled(Stack)`
  padding: 20px;
  font-size: ${PX16};
  gap: 12px;
`


export const SeparatorRoot = styled(Separator.Root)`
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-color: white;
`

export const AccordionChevron = styled(TbChevronDown)`
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
`

export const Item = styled(Button)<{ isActive?: boolean }>`
  background: ${p => p.isActive ? "#ffffff1a" : DARK_BLUE_COLOR};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: none;
  padding: 12px 24px;
  border-bottom: #ffffff1a 2px solid;
  font-size: ${PX16};

  /* &[data-state="open"] {
    background: #ffffff1a;
  } */

  &[data-state="open"] > ${AccordionChevron} {
    transform: rotate(180deg);
  }
`

export const SubItems = styled.div`
  padding: 22px 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${LIGHT_GREY_COLOR}33;
  /* gap: 12px; */
`

export const SubItem = styled.button<{ isActive: boolean }>`
  background: ${(p) => (p.isActive ? "rgba(255, 255, 255, 0.3);" : "none")};
  border-radius: 18px;
  border: none;
  padding: 8px;
  font-size: ${PX14};
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  text-align: left;
`
