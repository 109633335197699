import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Value } from "@stringtale/react"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import hasOnlyOneLearningLine from "app/subscriptions/hasOnlyOneLearningLine"
import Link from "next/link"
import { useState } from "react"
import { ORANGE_COLOR } from "theme/colors"
import Text from "ui/Text"
import * as Menu from "./Menu"

const LEFT_WIDTH = "230px"

const Root = styled(Menu.Root)`
  width: ${LEFT_WIDTH};
`

const Content = styled(Menu.Content)`
  flex: 1 0 ${LEFT_WIDTH};
  width: ${LEFT_WIDTH};
`

const Chevron = styled.div`
  font-weight: bold;
  color: ${ORANGE_COLOR};
`

const MenuItem = styled(Menu.Item)`
  &:hover {
    text-decoration: none;
    ${Text} {
      text-decoration: underline;
    }
  }
`

export default function LearninglineMenu({
  trigger,
}: {
  trigger: React.ReactNode
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const currentSchool = useCurrentOrganization()
  return (
    <DropdownMenu.Root modal open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content asChild align="start" alignOffset={-70}>
        <Root>
          <DropdownMenu.Arrow
            style={{ fill: "white" }}
            width={30}
            height={15}
          />
          <Content>
            <Menu.Title>
              {hasOnlyOneLearningLine(currentSchool?.subscription) ? (
                <Value name="apps.web.src.header.components.learninglinemenu.leerlijn">
                  Leerlijn
                </Value>
              ) : (
                <Value name="apps.web.src.header.components.learninglinemenu.leerlijnen">
                  Leerlijnen
                </Value>
              )}
            </Menu.Title>

            <DropdownMenu.Item>
              <MenuItem
                as={Link}
                {...Routes.LearningLineIndexOverview({
                  vak: "muziek",
                })}
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>
                  <Value name="apps.web.src.header.components.learninglinemenu.muziek_leerlijn">
                    Leerlijn muziek
                  </Value>
                </Text>
              </MenuItem>
            </DropdownMenu.Item>
            {!currentSchool?.subscription.isDanceHidden && (
              <DropdownMenu.Item>
                <MenuItem
                  as={Link}
                  {...Routes.LearningLineIndexOverview({
                    vak: "dans",
                  })}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Text>
                    <Value name="apps.web.src.header.components.learninglinemenu.dans_leerlijn">
                      {`Leerlijn dans`}
                    </Value>
                  </Text>
                </MenuItem>
              </DropdownMenu.Item>
            )}
            {!currentSchool?.subscription.isDramaHidden && (
              <DropdownMenu.Item>
                <MenuItem
                  as={Link}
                  {...Routes.LearningLineIndexOverview({
                    vak: "drama",
                  })}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Text>
                    <Value name="apps.web.src.header.components.learninglinemenu.drama_leerlijn">
                      {`Leerlijn drama`}
                    </Value>
                  </Text>
                </MenuItem>
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Item>
              <MenuItem
                as={Link}
                {...Routes.LearningLineOverview({
                  vak: "muziek",
                  learningLine: "peuter-en-kleuterthemas",
                })}
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>
                  <Value name="apps.web.src.header.components.learninglinemenu.peuter_en_kleuterthema_s">
                    {`Peuter- en kleuterthema's`}
                  </Value>
                </Text>
              </MenuItem>
            </DropdownMenu.Item>
          </Content>
        </Root>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
