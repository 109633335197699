import styled from "@emotion/styled"
import * as Select from "@radix-ui/react-select"
import React, {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { TbCaretDownFilled } from "@react-icons/all-files/tb/TbCaretDownFilled"
import { TbCaretUpFilled } from "@react-icons/all-files/tb/TbCaretUpFilled"
import { poppins } from "theme/fonts"
import {
  DARK_BLUE_COLOR,
  DIMMED_BLUE_COLOR,
  LIGHTER_GREY_COLOR,
  BORDER_GREY_COLOR,
} from "theme/colors"
import { css } from "@emotion/react"
import Group from "./Group"
import * as ScrollArea from "@radix-ui/react-scroll-area"

const SelectTrigger = styled(Select.SelectTrigger)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 36px;
  gap: 5px;
  background-color: white;
  color: ${DARK_BLUE_COLOR};
  box-shadow: inset 0px 3px 6px #00000029;
  cursor: pointer;

  &:hover {
    background-color: ${LIGHTER_GREY_COLOR};
  }

  &:focus {
    /*box-shadow: 0 0 0 2px black;*/
  }

  &[data-placeholder] {
    color: #918ac2;
  }

  &:disabled,
  &[data-disabled="true"] {
    background-color: #dedede;
    box-shadow: unset;
  }
`

const SelectIcon = styled(Select.SelectIcon)<{ disabled: boolean }>`
  color: #5f4b8b;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #aaaaaa;
    `}
`

const SelectContent = styled(Select.Content)`
  font-family: ${poppins.style.fontFamily};
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 10000;
  width: inherit;
`

const SelectViewport = styled(Select.Viewport)<{ viewportMaxHeight?: string }>`
  padding: 4px;
  max-height: ${(p) => (p.viewportMaxHeight ? p.viewportMaxHeight : "auto")};
`

export const StyledItem = styled(Select.Item)`
  font-size: 13px;
  line-height: 1;
  color: #5f4b8b; /* Replace with actual color value of violet.violet11 */
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 36px 0 25px;
  position: relative;
  user-select: none;

  &[data-highlighted],
  &:hover {
    outline: none;
    background-color: ${DARK_BLUE_COLOR};
    color: white;
  }
`

export const SelectLabel = styled(Select.Label)`
  display: flex;
  align-items: center;
  padding: 0 36px 0 20px;
  position: relative;
  user-select: none;
  height: 36px;

  font-size: 12px;
  line-height: 25px;
  color: #7a789f; /* Replace with actual color value of mauve.mauve11 */
`

export const SelectSeparator = styled(Select.Separator)`
  height: 1px;
  background-color: ${DIMMED_BLUE_COLOR};
  opacity: 0.2;
  margin: 0 12px;
`

export const StyledItemIndicator = styled(Select.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  padding: 5px;
`

const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  padding: 5px;
`

type Props = {
  value: string
  onValueChange: Dispatch<SetStateAction<string>>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  placeholder?: string | JSX.Element
  children?: React.ReactNode
  viewportMaxHeight?: string
}

const SelectInput = ({
  value,
  onValueChange,
  open,
  setOpen,
  placeholder,
  children,
  disabled,
  viewportMaxHeight,
}: Props & ComponentProps<typeof Select.Root>) => {
  return (
    <Select.Root
      open={open}
      onOpenChange={setOpen}
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
    >
      <SelectTrigger>
        <Select.Value placeholder={placeholder ? placeholder : ""} />
        <SelectIcon disabled={!!disabled}>
          <TbCaretDownFilled size={20} />
        </SelectIcon>
      </SelectTrigger>
      <Select.Portal>
        <SelectContent position={"popper"} sideOffset={4} align={"start"}>
          <ScrollArea.Root>
            <SelectScrollUpButton>
              <Group align="center" justify="center">
                <TbCaretUpFilled size={20} />
              </Group>
            </SelectScrollUpButton>
            <SelectViewport viewportMaxHeight={viewportMaxHeight}>
              {children}
            </SelectViewport>
            <SelectScrollDownButton>
              <Group align="center" justify="center">
                <TbCaretDownFilled size={20} />
              </Group>
            </SelectScrollDownButton>
          </ScrollArea.Root>
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  )
}

export default SelectInput
