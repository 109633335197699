import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import { BulletinBoardItemType } from "db"
import { PX12, PX16 } from "theme/sizes"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import { Content, DescriptionWrapper, Root } from "./BulletinAddListItem"
import BulletinBoardLLCard from "./BulletinBoardLLCard"

const PreviewSize = styled.div`
  min-width: 200px;
  height: 200px;
  pointer-events: none;
`

const BulletinAddModalWidgetItem = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const currentBulletinBoardLLWidget = bulletinBoardItems.find(
    (item) => item.type === "LL"
  )
  return (
    <Root>
      <Group gap="20px">
        <PreviewSize>
          <BulletinBoardLLCard
            preview
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </PreviewSize>
        <Content>
          <Stack gap="24px">
            <Stack gap="4px">
              <Text size={PX12}>
                <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.widget">
                  Widget
                </Value>
              </Text>
              <Stack gap="12px">
                <Text size={PX16} bold>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.leerlijnen_snelle_link">
                    Leerlijnen snelle link
                  </Value>
                </Text>
              </Stack>
              <DescriptionWrapper>
                <Text size={PX12}>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.volg_je_de_leerlijn">
                    Volg je de leerlijn? Dan is deze widget super handig! Zet de
                    de widget op je prikbord. Je kunt daarna snel naar de
                    leerlijn die je volgt.
                  </Value>
                </Text>
              </DescriptionWrapper>
            </Stack>
            <BulletinAddButton
              addProps={{
                type: BulletinBoardItemType.LL,
              }}
              deleteProps={
                currentBulletinBoardLLWidget && {
                  id: currentBulletinBoardLLWidget.id,
                }
              }
              currentBulletinBoardItem={currentBulletinBoardLLWidget}
            />
          </Stack>
        </Content>
      </Group>
    </Root>
  )
}

export default BulletinAddModalWidgetItem
