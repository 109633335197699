import { useAtomValue } from "jotai"
import { beginAtom, currentDurationAtom, durationAtom, endAtom } from "../state"
import getFormattedTime from "../utils/getFormattedTime"


export default function useDuration() {
  const duration = useAtomValue(durationAtom)
  if (duration) {
    return getFormattedTime(duration)
  }
  return {
    minutes: 0,
    seconds: 0,
  }
}