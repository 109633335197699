import { Grade, Group, UserPlaylist } from "db"
import { UserPlaylistType } from "../queries/getAddableLists"


const filterListsOnGrade = ({ lists, currentGrades }: { lists: UserPlaylistType[], currentGrades: number[] }) => {
  return currentGrades.length < 1
    ? lists
    : lists.filter((list) =>
      !list.group
        ? list
        : currentGrades.includes(list.group.id)
    )
}

export default filterListsOnGrade