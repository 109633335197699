import styled from "@emotion/styled"


const Center = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${p => p.grow && "flex-grow: 1;"}
`

export default Center