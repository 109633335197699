import { css } from "@emotion/css"
import styled from "@emotion/styled"


export const Root = styled.button<{ size?: string }>`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  font-size: ${p => p.size || "inherit"};
  text-align: left;

  &:-webkit-any-link {
    text-decoration: none;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled, &[data-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }
`

Root.defaultProps = {
  type: "button"
}

const Button = Root

export default Button