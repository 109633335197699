import { createContext, useContext, useEffect, useMemo } from "react"
import type { GetFilePath, GetFileVariantUrl, Prefixes } from "./types"
import { getFilePath, getFileVariantUrl } from "./getFilePath"

export const ImageContext = createContext<{ getFilePath: GetFilePath, getFileVariantUrl: GetFileVariantUrl } | null>(null)

const useFilePathContext = () => {
  const ctx = useContext(ImageContext)
  if (!ctx) {
    throw new Error("Image context not found")
  }
  return ctx.getFilePath
}

export const useFileVariantPathContext = () => {
  const ctx = useContext(ImageContext)
  if (!ctx) {
    throw new Error("Image context not found")
  }
  return ctx.getFileVariantUrl
}

export default useFilePathContext