import { useQuery } from "@blitzjs/rpc"
import { useCallback } from "react"
import getFileUrlPrefix from "../queries/getFileUrlPrefix"
import { getFilePath } from "utils/files/getFilePath"
import { File, Options } from "utils/files/types"

export default function useFilePathCallback() {
  const [prefix] = useQuery(getFileUrlPrefix, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  })

  return useCallback(
    (file: File, size?: Options, upload: boolean = false) => {
      return getFilePath(prefix)(file, size, upload)
    },
    [prefix]
  )
}
