
// import { OrganizationUserRole } from "@prisma/client"
import { GeneratedBy } from "db"
import prismaEnumToZod from "utils/types/prismaEnumToZod"
import { z } from "zod"

export const OrganizationUserRole = {
  MANAGER: 'MANAGER',
  INITIATOR: 'INITIATOR',
  MEMBER: 'MEMBER'
} as const

export const AddUserInput = z.object({
  firstName: z.string().min(1, { message: 'Voornaam is verplicht' }),
  insertion: z.string().optional(),
  lastName: z.string().min(1, { message: 'Achternaam is verplicht' }),
  email: z.string().min(1, { message: 'Email is verplicht' }).trim().email({ message: 'Email is ongeldig' }).transform((value) => value.toLowerCase()),
  role: prismaEnumToZod(OrganizationUserRole, { required_error: "Rol is verplicht" }),
  groups: z.array(z.coerce.number()).optional()
})

export const DeleteUserInput = z.object({
  id: z.number(),
})

export const UpdateUserInput = z.object({
  firstName: z.string().min(1, { message: 'Voornaam is verplicht' }).optional(),
  insertion: z.string().optional().optional(),
  lastName: z.string().min(1, { message: 'Achternaam is verplicht' }).optional(),
  email: z.string().min(1, { message: 'Achternaam is verplicht' }).trim().transform((value) => value.toLowerCase()).optional(),
  role: prismaEnumToZod(OrganizationUserRole).optional(),
  isArchived: z.boolean().optional(),
  id: z.number(),
  groups: z.array(z.coerce.number()).optional()
})

export const AddSchoolListInput = z.object({
  grades: z.array(z.coerce.number()),
  displayTitle: z.string(),
  description: z.string().optional(),
  color: z.string().optional()
})

export const PublishStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  READY_TO_PUBLISH: 'READY_TO_PUBLISH'
} as const

export const UpdateSchoolListInput = z.object({
  id: z.number(),
  displayTitle: z.string().optional(),
  description: z.string().optional(),
  color: z.string().optional(),
  status: prismaEnumToZod(PublishStatus).optional(),
  grades: z.array(z.coerce.number()).optional(),
})


export const AddGroupInput = z.object({
  displayTitle: z.string().min(1),
  alternativeTitle: z.string().optional(),
  grades: z.array(z.coerce.number()),
  generatedBy: prismaEnumToZod(GeneratedBy).optional(),
})

export const UpdateGroupInput = z.object({
  id: z.number(),
  displayTitle: z.string().min(1).optional(),
  alternativeTitle: z.string().optional(),
  grades: z.array(z.coerce.number()).optional(),
  isArchived: z.boolean().optional()
})

export const DeleteGroupInput = z.object({
  id: z.number(),
})
