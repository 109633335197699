import styled from "@emotion/styled"
import { ComponentProps, LegacyRef, ReactNode, RefObject, forwardRef } from "react"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX16 } from "theme/sizes"

export const Root = styled.div`
  border: 1px solid #b2b1c9;
  border-radius: 8px;
  font-size: ${PX16};
  font-family: inherit;
  line-height: 0;
  color: ${DARK_BLUE_COLOR};
  display: flex;
  align-items: center;
  &:focus, &:focus-within {
    outline: rgb(94, 158, 214) auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }
`

export const Control = styled.input`
  appearance: none;
  padding: 11px 16px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  border-radius: inherit;
  border: none;
  flex: 1;
  outline: 0px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${DARK_BLUE_COLOR};
    opacity: 0.5;
  }
`

export const Left = styled.div`
  margin-left: 11px;
`
export const Right = styled.div`
  margin-right: 11px;
`

type Props = ComponentProps<typeof Control> & {
  left?: ReactNode
  right?: ReactNode
  inputRef?: LegacyRef<HTMLInputElement>
}

const Input = forwardRef(({ children, inputRef, right, left, className, ...props }: Props, ref: RefObject<HTMLDivElement>) => {
  return (
    <Root className={className} ref={ref}>
      {left && <Left>{left}</Left>}
      <Control ref={inputRef} {...props} />
      {right && <Right>{right}</Right>}
    </Root>
  )
})
Input.displayName = "Input"

export default Input
