import { useCallback, useEffect } from "react"
import { useSetAtom } from "jotai"
import { useToasts } from "./useToast"


export function useHideToast(info: { id: string }, autoHide = true) {
  const [_, setToasts] = useToasts()

  useEffect(() => {
    if (!autoHide) return
    const timeout = setTimeout(() => {
      setToasts((toasts) => toasts.filter((t) => t.id !== info.id))
    }, 5000)
    return () => clearTimeout(timeout)
  }, [autoHide, info.id, setToasts])

  return useCallback(() => {
    setToasts((toasts) => toasts.filter((t) => t.id !== info.id))
  }, [info.id, setToasts])
}