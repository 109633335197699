import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { InternalLinkNode } from "admin-lexical/plugins/internal-link/InternalLinkNode"
import { ImageNode } from "./images/ImageNode"
import { HeadingNode, QuoteNode } from "@lexical/rich-text";


export const NODES = [
  HeadingNode,
  LinkNode,
  AutoLinkNode,
  InternalLinkNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  ListNode,
  ListItemNode,
  ImageNode,
]
