import Image from "app/files/components/Image"
import getLessonTypeTitle from "app/lessons/utils/getLessonTypeTitle"
import { SearchElement } from "app/search/types"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import ButtonGroup from "ui/ButtonGroup"
import Stack from "ui/Stack"
import { TParticipantsIcons } from "web/src/learning-lines/types"
import ParticipationIcons from "web/src/lessons/components/ParticipationIcons"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import BulletinAddListItem from "./BulletinAddListItem"

const BulletinAddLessonItem = ({
  lesson,
  bulletinBoardItems,
}: {
  lesson: SearchElement
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const currentBulletinBoardLesson = bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.LESSON &&
      item.lesson?.uuid === lesson.uuid
  )

  const participantsIcons = useMemo(() => {
    if (!lesson || !lesson.subjects) return
    const map = new Map<number, TParticipantsIcons>()
    lesson.subjects.forEach((headSubjectGroup) => {
      headSubjectGroup.subjects.forEach((headSubject) => {
        headSubject.participationIcons.forEach((icon) => {
          map.set(icon.id, icon)
        })
      })
    })
    return [...map.values()]
  }, [lesson])

  return (
    <div>
      <BulletinAddListItem
        heading={getLessonTypeTitle(lesson.lessonType)}
        title={lesson.displayTitle}
        description={lesson.searchDescription}
        activeGrades={lesson.grades}
        image={
          <>
            {lesson.cardImage ? (
              <Image
                alt=""
                file={lesson.cardImage}
                key={lesson.uuid}
                width={230}
                height={130}
              />
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between" gap="8px">
              <Stack gap="8px">
                {participantsIcons && participantsIcons.length > 0 && (
                  <ParticipationIcons lesson={lesson} />
                )}
              </Stack>
              <ButtonGroup>
                <BulletinAddButton
                  addProps={{
                    lessonUuid: lesson.uuid,
                    type: BulletinBoardItemType.LESSON,
                  }}
                  deleteProps={
                    currentBulletinBoardLesson && {
                      id: currentBulletinBoardLesson.id,
                    }
                  }
                  currentBulletinBoardItem={currentBulletinBoardLesson}
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )

  // return (
  //   <RootWithBorder>
  //     <Group gap="40px">
  //       {lesson.cardImage && (
  //         <PreviewLink
  //           {...Routes.LessonPage({
  //             lesson: lesson.slug,
  //           })}
  //         >
  //           <LessonPreview
  //             previewId={`search-${lesson.uuid}`}
  //             isPlayVisible={
  //               !!lesson?.previewVideo ||
  //               !!lesson?.previewAudio ||
  //               !!lesson?.rehearse?.video ||
  //               !!lesson?.radioSong
  //             }
  //             image={lesson.cardImage}
  //             previewBegin={
  //               lesson.previewBegin
  //                 ? parseStringTime(lesson.previewBegin)
  //                 : undefined
  //             }
  //             previewEnd={
  //               lesson.previewEnd
  //                 ? parseStringTime(lesson.previewEnd)
  //                 : undefined
  //             }
  //             file={
  //               lesson?.previewVideo ||
  //               lesson?.previewAudio ||
  //               lesson?.rehearse?.video ||
  //               lesson?.radioSong
  //             }
  //           />
  //         </PreviewLink>
  //       )}
  //       <Content>
  //         <Stack gap="8px">
  //           {lesson.displayTitle && (
  //             <Link
  //               {...Routes.LessonPage({
  //                 lesson: lesson.slug,
  //               })}
  //             >
  //               <LessonListItemTitle
  //                 title={lesson.displayTitle}
  //                 contentSubject={lesson.contentSubject}
  //               />
  //             </Link>
  //           )}
  //           <LessonSubTitle size={PX12} lesson={lesson} />
  //           <Group gap="4px" justify="left">
  //             <GradeIcons
  //               allGrades={grades}
  //               activeGrades={lesson.grades}
  //               contentSubjectType={lesson.contentSubject}
  //             />
  //             {lesson.isLearningLineLesson ? (
  //               <LeaningLineLessonLabel
  //                 color={getContentSubjectColor(lesson.contentSubject)}
  //               />
  //             ) : null}
  //           </Group>
  //           <Text size={PX12}>{lesson.searchDescription}</Text>
  //         </Stack>
  //         {/* <Group gap="8px" justify="left">
  //           <LikeLessonButton lesson={lesson} />
  //           <RoundedButton
  //             size="small"
  //             as={Link}
  //             {...Routes.LessonPage({
  //               lesson: lesson.slug,
  //             })}
  //           >
  //             <Value name="apps.web.src.search.components.searchlistitem.bekijk_les">
  //               Bekijk les
  //             </Value>
  //           </RoundedButton>
  //         </Group> */}
  //       </Content>
  //     </Group>
  //     <ParticipationIcons lesson={lesson} />
  //   </RootWithBorder>
  // )
}

export default BulletinAddLessonItem
