import { useQuery } from "@blitzjs/rpc"
import getContentSubjects from "../queries/getContentSubjects"
import Group from "ui/Group"
import getContentSubjectColor from "../getContentSubjectColor"
import toggleArray from "utils/global/toggleArray"
import ContentSubjectIcon from "./ContentSubjectIcon"
import Tab from "ui/Tab"
import { Dispatch } from "react"
import { SetStateAction } from "jotai"
import { ContentSubjectType } from "db"
import { learningLineSubscriptionProps } from "app/subscriptions/hasOnlyOneLearningLine"

export const useToggleButtons = () => {}

export default function ToggleButtons({
  size,
  vakken,
  setVakken,
  subscription,
}: {
  size?: "small" | "large"
  vakken: ContentSubjectType[]
  setVakken: Dispatch<SetStateAction<ContentSubjectType[]>>
  subscription: learningLineSubscriptionProps
}) {
  const [contentSubjects] = useQuery(getContentSubjects, undefined)
  return (
    <>
      {contentSubjects.map((contentSubject) => {
        const color = getContentSubjectColor(contentSubject.type)
        const lightColor = getContentSubjectColor(contentSubject.type, "light")
        const borderColor = getContentSubjectColor(contentSubject.type, "border")
        const isActive = vakken.includes(contentSubject.type)
        if(contentSubject.type === "DRAMA" && subscription?.isDramaHidden) return null
        if(contentSubject.type === "DANCE" && subscription?.isDanceHidden) return null
        return (
          <Tab
            size={size}
            colors={{
              background: isActive ? color : lightColor,
              border: isActive ? color : borderColor,
              text: isActive ? "white" : color,
            }}
            left={
              size === "small" ? undefined : <ContentSubjectIcon
                type={contentSubject.type}
                color={isActive ? "white" : color}
              />
            }
            isActive={isActive}
            onClick={() => {
              setVakken(toggleArray(contentSubject.type))
            }}
          >
            {contentSubject.displayTitle}
          </Tab>
        )
      })}
    </>
  )
}
