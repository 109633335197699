export enum TABS {
  LESSONS = "lessen",
  PLAYLISTS = "lijsten",
  THEMES = "themas",
  LEARNINGLINE = "leerlijnen",
  SCHOOLLIST = "schoollijsten",
  WIDGET = "widgets",
}

export enum LL_SUB_TABS {
  THEME = "theme",
  SUBJECT = "subject",
  SUBJECT_SELECTION = "subjectSelection"
}

export enum THEME_SUB_TABS {
  CATEGORY = "category",
  THEME = "theme",
  THEME_GROUP = "themeGroup",
}