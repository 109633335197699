import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { ReactNode } from "react"
import styled from "@emotion/styled"
import { Root as ContentRoot } from "ui/ContextMenu"
import { RADIO_PLAYER_Z_INDEX } from "theme/consts"

const Root = styled(ContentRoot)`
  overflow-y: unset;
  width: 220px;
  gap: 4px;
  z-index: ${RADIO_PLAYER_Z_INDEX};
`

const RadioBoxContextMenu = ({
  trigger,
  items,
  ...props
}: {
  trigger: ReactNode
  items: ReactNode
} & DropdownMenu.DropdownMenuProps) => {
  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side="top"
          sideOffset={4}
          align="end"
          asChild
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Root>{items}</Root>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default RadioBoxContextMenu
