import { useEffect } from "react"

export const FADE_IN_DURATION = 3
export const FADE_OUT_DURATION = 2

function easeInCurve(time: number, duration: number): number {
  const progress = time / duration
  return Math.pow(progress, 3)
}

function easeOutCurve(time: number, duration: number): number {
  const progress = time / duration
  return Math.min(1, Math.max(0, 1 - Math.pow(1 - progress, 3)))
}

type Props = {
  mediaRef: React.RefObject<HTMLAudioElement | HTMLVideoElement | undefined>,
  volume: number,
  isPlaying: boolean,
  currentSegment: {
    end: number | undefined
    begin: number | undefined
  }
}

const useFade = ({ mediaRef, currentSegment, volume, isPlaying }: Props) => {
  // Fade in/out
  useEffect(() => {
    if (!isPlaying) return
    const audio = mediaRef.current

    const int = setInterval(() => {
      if (!audio) return
      if (!currentSegment) return
      const time = audio.currentTime
      if (currentSegment.begin && time < currentSegment.begin) {
        audio.volume =
          easeOutCurve(
            FADE_IN_DURATION - (currentSegment.begin - time),
            FADE_IN_DURATION
            ) * volume
      } else if (currentSegment.end && time > currentSegment?.end) {
        audio.volume =
          easeOutCurve(
            FADE_OUT_DURATION - (time - currentSegment.end),
            FADE_OUT_DURATION
            ) * volume
      } else {
        audio.volume = volume
      }
    }, 1000 / 60)

    return () => {
      clearInterval(int)
    }
  }, [mediaRef, currentSegment, isPlaying, volume])
}

export default useFade