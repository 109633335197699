import { FileVariantType } from "db"
import { File, FileWithVariants, GetFilePath, GetFileVariantUrl, Options, Prefixes } from "./types"

export const getFilePath =
  (prefixes: Prefixes): GetFilePath =>
    (
      { id, type, url }: File,
      options?: Options,
      isPathForUploading: boolean = false
    ) => {
      if (type === "IMAGE") {
        if (options) {
          return (
            `${prefixes?.[type] || ""}/${url}/w=${options.width}${options.height
              ? `,h=${options.height},fit=${options.fit ? options.fit : "scale-down"
              }`
              : ""
            }` +
            (options.crop
              ? `,trim=${options.crop.top};${options.crop.right};${options.crop.bottom};${options.crop.left}`
              : "")
          )
        }
        return `${prefixes?.[type] || ""}/${url}/quality=90`
      }

      const path = `${constructFilePath(type, id, isPathForUploading)}${url}`

      return `${prefixes[type] || prefixes.OTHER}${path}`
    }

export function constructFilePath(
  type: string,
  id: number,
  isPathForUploading: boolean
) {
  let path = `uploads/${type}/${id}/`
  return isPathForUploading ? path : `/${encodeURIComponent(path)}`
}

export function getFileVariantPath(
  file: FileWithVariants,
  variantType: FileVariantType,
  isPathForUploading: boolean = false
) {
  const { variants } = file
  const variant = variants?.find((variant) => variant.type === variantType)
  if (!variant) return null //throw new Error("Variant not found: Video variant bestaat niet, wordt waarschijnlijk nog verwerkt. Probeer het later opnieuw.")
  const url = variant.url

  const path = `${constructFilePath(file.type, file.id, isPathForUploading)}${url}`

  return path
}

export const getFileVariantUrl =
  (prefixes: Prefixes): GetFileVariantUrl =>
    (
      file: FileWithVariants,
      variantType: FileVariantType,
      isPathForUploading = false
    ) => {
      const path = getFileVariantPath(file, variantType, isPathForUploading)
      if (!path) return null
      return `${prefixes.OTHER}${path}`
    }
