import { Organization, Subscription } from "db"

export function organizationHasSchoolyears(
  organization: Organization & { subscription: Subscription } | null
) {
  // Can't have schoolyears if you have no organization
  if (!organization) return false

  // Trial accounts don't get schoolyears, regular schools can have them surpressed
  if (organization.trialAccount || organization.hideSchoolyear) return false

  // If you're not a trial account and they're not hidden, check the subscription
  // If that has schoolyears, the school has schoolyears, otherwise they don't
  if (organization.subscription.hasSchoolyears) return true

  return false
}
