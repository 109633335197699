import { useAtomValue } from "jotai"
import { RefObject, useCallback, useEffect } from "react"
import { beginAtom } from "../state"
import { isPlayButton } from "../components/TogglePlayButton"
import { isInsideToolbar } from "@stringtale/react"

export default function usePlaybackToggle(
  elementRef: RefObject<HTMLVideoElement | HTMLAudioElement>,
) {
  const begin = useAtomValue(beginAtom)

  const toggle = useCallback((replayOrEvent?: "REPLAY" | any) => {
    if (!elementRef) return
    const video = elementRef.current
    if (!video) return
    if (video.paused) {
      if (begin && (!video.currentTime || replayOrEvent === "REPLAY")) {
        video.currentTime = begin
      }
      video.play().catch()
    } else {
      video.pause()
    }
  }, [begin, elementRef])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (isInsideToolbar(e)) return
      if (e.key !== " ") return
      if (e.target instanceof HTMLInputElement) return
      if (e.target instanceof HTMLTextAreaElement) return
      if (e.target && isPlayButton(e.target)) return
      e.preventDefault()
      toggle()
    }
    // const ref = elementRef.current
    document?.addEventListener("keypress", handler)
    return () => {
      document?.removeEventListener("keypress", handler)
    }
  }, [toggle])

  return toggle
}
