import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Value } from "@stringtale/react"
import setGroupForSession from "app/auth/mutations/setGroupForSession"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { addQueryParams } from "app/core/utils/addQueryParams"
import { usePlay } from "app/radio/components/PlayerContext"
import { displayStateAtom } from "app/radio/state"
import { Group } from "db"
import { useSetAtom } from "jotai"
import Link from "next/link"
import { useRouter } from "next/router"
import { parseAsString, useQueryState } from "nuqs"
import { ReactNode } from "react"
import { PX14 } from "theme/sizes"
import ContextMenu, { Separator } from "ui/ContextMenu"

export default function SwitchGroupMenu({
  trigger,
  groups,
  currentGroup,
  onCompleted,
}: {
  trigger: ReactNode
  groups: Group[]
  currentGroup: Group | null
  onCompleted?: () => void
}) {
  const organization = useCurrentOrganization()
  const [setGroupForSessionMutation] = useMutation(setGroupForSession)
  const router = useRouter()
  const [next] = useQueryState("next", parseAsString)

  const setDisplayState = useSetAtom(displayStateAtom)
  const { pause } = usePlay()

  const setGroupForSessionClickHandler = async (groupId: number) => {
    await setGroupForSessionMutation({ groupId })
    await invalidateQuery()
    await onCompleted?.()
  }

  return (
    <ContextMenu
      trigger={trigger}
      items={
        <>
          <ContextMenu.Label>{organization?.displayTitle}</ContextMenu.Label>
          <Separator />
          {groups.length > 0 &&
            groups.map((group, index) => {
              return (
                <ContextMenu.Item
                  onClick={async () => {
                    await setGroupForSessionClickHandler(group.id)
                    pause()
                    setDisplayState("hidden")
                  }}
                >
                  <span
                    style={{
                      fontWeight: `${
                        group.id === currentGroup?.id ? "bold" : "auto"
                      }`,
                    }}
                  >
                    {group.displayTitle}
                  </span>
                </ContextMenu.Item>
              )
            })}
          {groups.length === 0 && (
            <span style={{ padding: "12px 24px", fontSize: PX14 }}>
              <Value name="apps.web.src.groups.components.switchgroupmenu.er_zijn_geen_groepen_aan_je_gekoppeld">
                Je hebt geen groep(en). Klopt dit niet, neem contact op met je
                beheerder.
              </Value>
            </span>
          )}
          {groups.length > 1 && (
            <>
              <Separator />
              <ContextMenu.Item asChild>
                <Link
                  {...addQueryParams(
                    Routes.GroupPicker(),
                    next
                      ? {
                          next,
                        }
                      : {}
                  )}
                >
                  <Value name="apps.web.src.groups.components.switchgroupmenu.groepskeuzemenu">
                    Groepskeuzemenu
                  </Value>
                </Link>
              </ContextMenu.Item>
            </>
          )}

          <DropdownMenu.Arrow
            style={{ fill: "white" }}
            width={30}
            height={15}
          />
        </>
      }
    ></ContextMenu>
  )
}
