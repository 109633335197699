import { useQuery } from "@blitzjs/rpc"
import { FilterOptionLevel } from "db"
import { ComponentProps, useId } from "react"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import getFilterOptionCategoryWithOptionsBySlug, {
  GetFilterOptionCategoryWithOptionsBySlugResult,
} from "../queries/getFilterOptionCategoryWithOptionsBySlug"
import AccordionItem from "./AccordionItem"
import { useSearchContext } from "./SearchProvider"
import Hr from "ui/Hr"

export default function Filter({
  slug,
  ...props
}: {
  slug: string
} & Omit<
  ComponentProps<typeof AccordionItem>,
  "value" | "title" | "children"
>) {
  let [category] = useQuery(getFilterOptionCategoryWithOptionsBySlug, {
    slug,
  })

  if (!category) {
    return null
  }
  const level = category.level
  return (
    <>
      <AccordionItem title={category.displayTitle} value={slug} {...props}>
        {category.options.map((item) => (
          <Item item={item} level={level} key={item.id} />
        ))}
      </AccordionItem>
      <Hr />
    </>
  )
}

function Item({
  item,
  level,
}: {
  item: NonNullable<GetFilterOptionCategoryWithOptionsBySlugResult>["options"][0]
  level: FilterOptionLevel
}): JSX.Element {
  const { setFilter, filter, facetDistribution } = useSearchContext()
  const id = useId()
  const isActive = filter.includes(item.displayTitle)
  // TODO: Check if level === "HEAD_SUBJECT" or "LESSON"
  const amount =
    (facetDistribution?.["filterOptions.id"]?.[`${item.id}`] ?? 0) +
    (facetDistribution?.["subjects.subjects.filterOptions.id"]?.[
      `${item.id}`
    ] ?? 0)
  return (
    <Group align="center" gap="12px">
      <Checkbox
        checked={isActive}
        id={id}
        onCheckedChange={() => {
          setFilter(toggleArray(item.displayTitle))
        }}
      />
      <Text size={PX14} bold={isActive} lineHeight="1">
        <label htmlFor={id}>
          {item.displayTitle} <Text color={DIMMED_BLUE_COLOR}>({amount})</Text>
        </label>
      </Text>
    </Group>
  )
}
