import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react/dist"
import useToast from "app/toasts/hooks/useToast"
import { useState } from "react"
import { LIST_COLORS } from "theme/colors"
import {
  Title,
  Buttons,
  CancelButton,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
} from "ui/Modal"
import Stack from "ui/Stack"
import addSchoolPlaylist from "../../mutations/addSchoolPlaylist"
import getSchoolLists from "../../queries/getSchoolLists"
import createFormFactory from "utils/form/createForm"
import { AddSchoolListInput } from "app/schoolbeheer/validation"
import Field from "app/core/components/Field"
import FieldArea from "app/core/components/FieldArea"
import Color, { ColorGroup } from "app/playlists/components/Color"
import Radio from "ui/Radio"
import styled from "@emotion/styled"
import Grades, { GradeGroup, Root as GradesCheckbox } from "../groups/Grades"
import { Grade } from "db"
import getAllGrades from "app/grades/queries/getAllGrades"
import getGroupedUserPlaylists from "app/playlists/queries/getGroupedUserPlaylists"
import toggleArray from "utils/global/toggleArray"
import Hr from "ui/Hr"

const form = createFormFactory({
  validationSchema: AddSchoolListInput,
})

const FormLabel = styled.label`
  font-weight: 600;
`

const NewPlaylistModal = ({
  onClose,
}: {
  onClose: (open?: boolean) => void
}) => {
  const [addPlaylistMutation] = useMutation(addSchoolPlaylist)
  const [serverErrors, setServerErrors] = useState()
  const [color, setColor] = useState(LIST_COLORS[0])
  const addToast = useToast()
  const [activeGrades, setActiveGrades] = useState<number[]>([])
  const [grades] = useQuery(getAllGrades, undefined)

  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <form.Form
            noValidate
            onSubmit={async (data) => {
              try {
                await addPlaylistMutation({
                  displayTitle: data.displayTitle as string,
                  description: data.description as string,
                  color,
                  grades: activeGrades,
                })
                await invalidateQuery(getSchoolLists)
                onClose(true)
                addToast({
                  content: (
                    <>
                      <Value name="playlist.nieuw.toegevoegd.pre">
                        Afspeellijst
                      </Value>{" "}
                      &quot;{data.displayTitle}&quot;{" "}
                      <Value name="playlist.nieuw.toegevoegd.post">
                        toegevoegd
                      </Value>
                    </>
                  ),
                })
                await invalidateQuery(getGroupedUserPlaylists)
              } catch (e) {
                setServerErrors(e)
              }
            }}
          >
            <Content>
              <Stack gap="24px">
                <Stack gap="16px">
                  <Dialog.Title asChild>
                    <Title>
                      <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.nieuwe_afspeellijst">
                        Nieuwe schoollijst
                      </Value>
                    </Title>
                  </Dialog.Title>
                  <Hr />
                </Stack>
                <Stack gap={"6px"}>
                  <FormLabel>
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.leerjaar_van_de_schoollijst" version="1">
                      Leerjaren van de schoollijst
                    </Value>
                  </FormLabel>
                  <GradeGroup>
                    {grades.map((grade) => (
                      <GradesCheckbox
                        name="grades[]"
                        key={grade.id}
                        value={`${grade.id}`}
                        checked={activeGrades.includes(grade.id)}
                        onCheckedChange={() =>
                          setActiveGrades(toggleArray(grade.id))
                        }
                      >
                        {grade.number}
                      </GradesCheckbox>
                    ))}
                  </GradeGroup>
                </Stack>
                <Field
                  name="displayTitle"
                  label={
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.naam">
                      Naam
                    </Value>
                  }
                  form={form}
                />
                <FieldArea
                  name="description"
                  label={
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.omschrijving">
                      Omschrijving
                    </Value>
                  }
                  form={form}
                />
                <ColorGroup onValueChange={setColor} value={color}>
                  {LIST_COLORS.map((color) => (
                    <Color value={color} />
                  ))}
                </ColorGroup>
                <Buttons>
                  <CancelButton type="button" onClick={() => onClose()}>
                    <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.annuleren">
                      Annuleren
                    </Value>
                  </CancelButton>
                  <Form.Submit asChild>
                    <SubmitButton type="submit">
                      <Value name="apps.web.src.schoolbeheer.components.schoollists.newschoollistmodal.opslaan">
                        Opslaan
                      </Value>
                    </SubmitButton>
                  </Form.Submit>
                </Buttons>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </form.Form>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default NewPlaylistModal
