import { htmlFormatTags, Value } from "@stringtale/react"
import { BlitzPage, Routes } from "@blitzjs/next"
import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import Group from "ui/Group"
import Stack from "ui/Stack"

import DashboardLayout from "app/core/layouts/DashboardLayout"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  PX28,
  PX18,
  PX16,
} from "theme/consts"
import useFilePathCallback from "app/files/hooks/useFilePathCallback"
import { useMutation } from "@blitzjs/rpc"
import { Router, useRouter } from "next/router"
import getGroupsInSchool from "app/auth/queries/getGroupsInSchool"
import setGroupForSession from "app/auth/mutations/setGroupForSession"
import Grow from "ui/Grow"
import { useCallback, useEffect, useMemo } from "react"
import AvatarBase, { Fallback } from "app/files/components/Avatar"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"
import Button from "ui/Button"
import Center from "ui/Center"
import Link from "next/link"
import { useSetAtom } from "jotai"
import { displayStateAtom } from "app/radio/state"
import { usePlay } from "app/radio/components/PlayerContext"

import Title from "ui/Title"
import { groupCompare } from "../utils/groupCompare"
import { ShowGroupMigrationError } from "app/auth/errors"
import RoundedButton from "ui/RoundedButton"

const Root = styled.div`
  padding: 150px 8px;
  @media (min-width: 900px) {
    padding: 150px 42px;
  }
`

export const Content = styled(Group)<{ itemCount: number }>`
  max-width: 1416px;
  width: 100%;
  box-sizing: border-box;

  flex-wrap: wrap;
  align-items: stretch;
  margin: auto;
  z-index: 1;
  position: relative;
  justify-content: center;

  gap: 16px;

  justify-content: ${(p) => (p.itemCount < 2 ? "center" : "flex-start")};

  @media (min-width: 900px) {
    gap: 24px;
    justify-content: ${(p) => (p.itemCount < 4 ? "center" : "flex-start")};
  }
`

export const GroupCard = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 10px #0000001a;
  :active,
  :hover {
    background-color: transparent;
  }

  width: calc(50% - 8px);

  @media (min-width: 900px) {
    width: calc(25% - 21px);
  }
`

export const GroupImage = styled.img`
  width: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  ::before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }

  ::after {
    display: block;
    content: "";
    clear: both;
  }
`

const GroupImagePlacholder = styled.div`
  background: #707070;
  border-radius: 12px 12px 0 0;
  width: 100%;

  ::before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }

  ::after {
    display: block;
    content: "";
    clear: both;
  }
`

export const GroupName = styled.span`
  font-size: ${PX18};
  font-weight: 600;
  color: ${DARK_BLUE_COLOR};
  line-height: 24px;
`

export const CardContent = styled(Grow)`
  background-color: white;
  border-radius: 0 0 12px 12px;
  text-align: left;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
`

const Avatar = styled(AvatarBase)``

export const TeachersRow = styled(Group)`
  margin-top: -46px;
  margin-bottom: 10px;
  ${Avatar}:not(:first-child) {
    margin-left: -8px;
  }
`

const TeacherIcon = styled.img`
  width: 40px;
  height: 40px;
  border: 2px solid white;
  background-color: #707070;
  border-radius: 24px;
`

const Skip = styled(Center)`
  font-size: ${PX16};
  font-weight: 600;
  color: ${DARK_BLUE_COLOR};
  text-align: center;
  margin-top: 50px;
`

const GroupPicker = ({ onChange }: { onChange: () => Promise<void> }) => {
  const getFilePath = useFilePathCallback()
  const [groups, groupsRes] = useQuery(getGroupsInSchool, {})
  const [organizationRole] = useQuery(getOrganizatorRole, undefined)
  const [setGroupForSessionMutation] = useMutation(setGroupForSession)
  const router = useRouter()
  const setDisplayState = useSetAtom(displayStateAtom)
  const { pause } = usePlay()

  const sortedGroups = useMemo(() => {
    const sortedGroups = groups ? [...groups].sort(groupCompare) : []
    return sortedGroups
  }, [groups])

  useEffect(() => {
    async function fetch() {
      if (
        !(groupsRes.isFetching || groupsRes.isLoading) &&
        groups.length <= 1
      ) {
        if (
          organizationRole &&
          (organizationRole === "MEMBER" || organizationRole === "INITIATOR")
        ) {
          if (groups.length === 1) {
            let result = await setGroupForSessionMutation({
              groupId: groups[0].id,
            })
            await invalidateQuery()
            if (result) {
              await onChange()
            } else {
              await router.push(
                Routes.MigrateGroupPage({ next: router.query.next })
              )
            }
          } else if (groups.length === 0) {
            if (router.query.next && typeof router.query.next === "string") {
              await router.push(decodeURIComponent(router.query.next))
            } else {
              await router.push(Routes.Home())
            }
          }
        }
      }
    }
    fetch()
  }, [
    groups,
    groups.length,
    groupsRes.isFetching,
    groupsRes.isLoading,
    onChange,
    organizationRole,
    router,
    setGroupForSessionMutation,
  ])

  return (
    <>
      <Root>
        <Stack align="center">
          <Title size={PX28} style={{ marginBottom: 40 }}>
            <Value name="apps.web.src.groups.components.grouppicker.kies_je_groep">
              Kies je groep:
            </Value>
          </Title>

          {sortedGroups.length > 0 ? (
            <Content itemCount={sortedGroups.length}>
              {sortedGroups.map((group) => {
                return (
                  <GroupCard
                    style={{ flexWrap: "wrap", cursor: "pointer" }}
                    key={group.id}
                    onClick={async () => {
                      let result = await setGroupForSessionMutation({
                        groupId: group.id,
                      })
                      pause()
                      setDisplayState("hidden")
                      if (result) {
                        await invalidateQuery()
                        await onChange()
                      } else {
                        await invalidateQuery()
                        await router.push(
                          Routes.MigrateGroupPage({ next: router.query.next })
                        )
                      }
                    }}
                    title={group.displayTitle}
                  >
                    <GroupImage
                      src={
                        group.image
                          ? getFilePath(group.image, {
                              width: 326,
                              height: 183,
                              fit: "cover",
                            })
                          : "/images/placeholder-groep.png"
                      }
                      alt={`${group.displayTitle}`}
                    />
                    <CardContent>
                      <TeachersRow>
                        {group.organizationUsers.map((ou, index) => {
                          return (
                            <Avatar
                              key={index}
                              user={ou.user}
                              width={40}
                              height={40}
                              withBorder={true}
                              borderColor="white"
                              style={{
                                zIndex: group.organizationUsers.length - index,
                              }}
                            />
                          )
                        })}
                      </TeachersRow>
                      <GroupName>{group.displayTitle}</GroupName>
                    </CardContent>
                  </GroupCard>
                )
              })}
            </Content>
          ) : (
            <Stack gap="40px">
              <Content itemCount={0}>
                <span style={{ textAlign: "center" }}>
                  <Value
                    format={{
                      ...htmlFormatTags,
                    }}
                    name="apps.web.src.groups.components.grouppicker.er_zijn_geen_groep_en_aan_jou_gekoppeld"
                  >
                    {`Er zijn geen groep(en) aan jou gekoppeld.{br}Klopt dit niet?
                  Neem contact op met je beheerder.`}
                  </Value>
                </span>
              </Content>

              <RoundedButton
                variant="filled"
                onClick={async () => {
                  if (
                    router.query.next &&
                    typeof router.query.next === "string"
                  ) {
                    await router.push(decodeURIComponent(router.query.next))
                  } else {
                    await router.push(Routes.Home())
                  }
                }}
              >
                <Value name="apps.web.src.groups.components.grouppicker.verder_naar_leeromgeving">
                  Verder naar leeromgeving
                </Value>
              </RoundedButton>
            </Stack>
          )}

          {organizationRole && organizationRole !== "MEMBER" ? (
            <Skip>
              <b>
                <Value name="apps.web.src.groups.components.grouppicker.schoolbeheer">
                  Schoolbeheer
                </Value>
              </b>
              <Button
                as={Link}
                style={{ textDecoration: "underline" }}
                {...Routes.SchoolbeheerPage()}
              >
                <Value name="apps.web.src.groups.components.grouppicker.administratie_van_groepen_collega_s_en_schoollijsten">
                  Administratie van groepen, collega’s en schoollijsten.
                </Value>
              </Button>
            </Skip>
          ) : null}
        </Stack>
      </Root>
    </>
  )
}

export default GroupPicker
