import styled from "@emotion/styled"
import * as ToastBase from "@radix-ui/react-toast"
import { motion } from "framer-motion"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { DARK_BLUE_COLOR, GREEN_COLOR } from "theme/colors"
import { poppins } from "theme/fonts"
import { PX14 } from "theme/sizes"
import { useHideToast } from "../hooks/useHideToast"
import { ToastInfo } from "../types"

const Root = styled(motion.div)`
  background: white;
  border-radius: 8px;
  font-family: ${poppins.style.fontFamily};
  position: relative;
  border: 2px solid ${GREEN_COLOR};
  box-shadow: 0px 3px 6px #00000029;
  font-size: ${PX14};
  display: flex;
  align-items: stretch;
  width: 406px;

  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  &[data-state="closed"] {
    opacity: 0;
  }
`

const Bar = styled.div`
  background-color: ${GREEN_COLOR};
  width: 6px;
`

const Content = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`

const Circle = styled.div`
  background-color: ${GREEN_COLOR};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled(ToastBase.Close)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  color: ${DARK_BLUE_COLOR};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Toast = ({ info }: { info: ToastInfo & { id: string } }) => {
  const hideToast = useHideToast(info)
  return (
    <ToastBase.Root
      asChild
      open
      onOpenChange={() => {
        hideToast()
      }}
    >
      <Root
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Bar />
        <Content>
          <Circle>
            <FaCheck />
          </Circle>
          <ToastBase.Description>{info.content}</ToastBase.Description>
          <CloseButton>
            <FaTimes />
          </CloseButton>
        </Content>
      </Root>
    </ToastBase.Root>
  )
}

export default Toast
