import { Value } from "@stringtale/react"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import Image from "app/files/components/Image"
import { BulletinBoardItemType, ContentSubjectType } from "db"
import { PX12 } from "theme/sizes"
import ButtonGroup from "ui/ButtonGroup"
import CardLabel from "ui/CardLabel"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetBulletinAddLearningLineItemsResult } from "../queries/getBulletinAddLearningLineItems"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import BulletinAddListItem from "./BulletinAddListItem"

const BulletinAddLearningLineItem = ({
  contentPage,
  bulletinBoardItems,
}: {
  contentPage: GetBulletinAddLearningLineItemsResult[ContentSubjectType][number]["learningLineThemes"][number]["learningLineContentPages"][number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const currentBulletinBoardLL = bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.LL_PAGE &&
      item.learningLineId === contentPage.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.leerlijn">
            Leerlijn
          </Value>
        }
        title={contentPage.displayTitle}
        image={
          <>
            {contentPage.playlistImage ? (
              <>
                <Image
                  alt=""
                  file={contentPage.playlistImage}
                  key={contentPage.id}
                  width={230}
                  height={130}
                />
                <CardLabel
                  size={PX12}
                  color={getContentSubjectColor(
                    contentPage.learningLineTheme.learningLine.contentSubject
                  )}
                >
                  <Value
                    name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.leerlijn_label"
                    format={{
                      cardLabel: contentPage.hasAlternativeGradesLabelBehaviour
                        ? "4S"
                        : contentPage.cardLabel,
                    }}
                  >
                    {`{cardLabel}`}
                  </Value>
                </CardLabel>
              </>
            ) : (
              <EmptyContainer>
                <EmptyImage />
              </EmptyContainer>
            )}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {contentPage.period && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.periode">
                        Periode
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Text
                          size={PX12}
                          color={getContentSubjectColor(
                            contentPage.learningLineTheme.learningLine
                              .contentSubject,
                            "dark"
                          )}
                          bold
                        >
                          {contentPage.period}
                        </Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <BulletinAddButton
                  addProps={{
                    learningLineId: contentPage.id,
                    type: BulletinBoardItemType.LL_PAGE,
                  }}
                  deleteProps={
                    currentBulletinBoardLL && {
                      id: currentBulletinBoardLL.id,
                    }
                  }
                  currentBulletinBoardItem={currentBulletinBoardLL}
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddLearningLineItem
