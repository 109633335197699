import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Checkbox from "@radix-ui/react-checkbox"
import { Grade } from "db"
import { LIGHT_GREY_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"
import Button from "ui/Button"

export const GradeGroup = styled.div`
  display: flex;
  gap: 2px;
`

export const Root = styled(Button)<{ isActive?: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #03004E80;
  padding: 4px;
  box-sizing: border-box;
  font-size: ${PX14};

  ${(p) =>
    p.isActive
      ? css`
          border: 2px solid ${ORANGE_COLOR};
          background-color: ${ORANGE_COLOR};
          color: white;
          font-weight: 600;
        `
      : ""}
`

const Grades = ({
  grades,
  activeGrades,
  enabledGrades,
  onChange
}: {
  grades: Grade[]
  activeGrades?: number[]
  enabledGrades: number[]
  onChange: (grade: Grade) => void
}) => {
  return (
    <GradeGroup>
      {grades.map((grade) => (
        <Root
          name="grades[]"
          key={grade.id}
          value={`${grade.id}`}
          isActive={activeGrades?.includes(grade.id)}
          disabled={!enabledGrades.includes(grade.id)}
          onClick={() => {
            onChange(grade)
          }}
        >
          {grade.number}
        </Root>
      ))}
    </GradeGroup>
  )
}

export default Grades
