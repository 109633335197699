import { FaPause } from "@react-icons/all-files/fa/FaPause"
import { FaPlay } from "@react-icons/all-files/fa/FaPlay"
import Center from "ui/Center"
import { TbPlayerSkipBackFilled } from "@react-icons/all-files/tb/TbPlayerSkipBackFilled"
import { TbPlayerSkipForwardFilled } from "@react-icons/all-files/tb/TbPlayerSkipForwardFilled"
import ProgressBar from "app/radio/components/ProgressBar"
import styled from "@emotion/styled"
import { DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import Stack from "ui/Stack"
import ButtonBase from "ui/Button"
import { usePlay } from "app/radio/components/PlayerContext"
import { useAtom, useAtomValue } from "jotai"
import {
  displayStateAtom,
  isPlayingAtom,
  nextTrackAtom,
  playingStateAtom,
  previousTrackAtom,
} from "app/radio/state"
import { MutableRefObject } from "react"
import Tooltip from "app/radio/components/Tooltip"
import { PlayButton } from "./RadioBoxContent"
import { css } from "@emotion/react"
import { TRANSITION } from "app/radio/consts";

const Root = styled(Stack)`
  /*width: 270px;*/
  height: 100%;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  /*background-color: ${DARK_BLUE_COLOR};*/
`

const ControlGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const Button = styled(ButtonBase)<{ isActive?: boolean; theme?: "light" | "dark" }>`
  ${TRANSITION};
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-color: ${DARK_BLUE_COLOR};
  color: ${(p) => (p.isActive ? ORANGE_COLOR : "white")};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    /* width: 20px; */
    height: 20px;
    flex: 1 0 20px;
  }
  
  ${(p) =>
          p.isActive &&
          css`
      &:hover {
        color: #c8320d;
      }
    `}

  ${(p) =>
    p.theme === "light" &&
    css`
      background-color: #fff;
      color: ${DARK_BLUE_COLOR};
    `}
`

const PlaylistControls = ({
  containerRef,
  theme,
}: {
  containerRef: MutableRefObject<any>
  theme?: "light" | "dark"
}) => {
  const [displayState, setDisplayState] = useAtom(displayStateAtom)

  const next = useAtomValue(nextTrackAtom)
  const previous = useAtomValue(previousTrackAtom)
  const item = useAtomValue(playingStateAtom)
  const isPlaying = useAtomValue(isPlayingAtom)
  const { togglePlay, set } = usePlay()
  return (
    <Root gap="5px">
      <ControlGrid>
        <div>
          <Center>
            <Tooltip
              text="Vorige"
              trigger={
                <Button
                  disabled={!previous}
                  onClick={() => set(previous)}
                  theme={theme}
                >
                  <TbPlayerSkipBackFilled size={24} />
                </Button>
              }
            />
          </Center>
        </div>
        <div>
          <Center>
            <Tooltip
              text={isPlaying ? "Pauzeer" : "Afspelen"}
              trigger={
                <PlayButton onClick={() => {
                  if (displayState === "closed" || displayState === "hidden") setDisplayState("small")
                  togglePlay()
                }} disabled={!item}>
                  {isPlaying ? (
                    <FaPause />
                  ) : (
                    <FaPlay style={{ marginLeft: 4 }} />
                  )}
                </PlayButton>
              }
            />
          </Center>
        </div>
        <div>
          <Center>
            <Tooltip
              text="Volgende"
              trigger={
                <Button
                  disabled={!next}
                  onClick={() => set(next)}
                  theme={theme}
                >
                  <TbPlayerSkipForwardFilled size={24} />
                </Button>
              }
            />
          </Center>
        </div>
      </ControlGrid>
      {containerRef.current && containerRef.current.offsetWidth > 839 ? (
        <ProgressBar disabled={!item} theme={theme} />
      ) : null}
    </Root>
  )
}

export default PlaylistControls
