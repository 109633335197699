export type AnalayticsDataAttributes = {
  eventAction: "click"
  elementLocation: string
  elementText?: string | null
  groupTabLabel?: string | null
  blockTitle?: string | null
}

function getAnalyticsDataAttributes({
  eventAction,
  elementLocation,
  elementText,
  groupTabLabel,
  blockTitle
}: AnalayticsDataAttributes) {
  return {
    "data-event-action": eventAction,
    "data-element-location": elementLocation,
    "data-element-text": elementText ? elementText: undefined,
    "data-group-tab-label": groupTabLabel ? groupTabLabel : undefined,
    "data-block-title": blockTitle ? blockTitle : undefined,
  }
}

export default getAnalyticsDataAttributes
