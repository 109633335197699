import { Grade, Group } from "db"
import { MinimalBlueprintGroup } from "pages/schoolbeheer/schooljaren/stap-2"

export const groupCompare = (
  a: MinimalBlueprintGroup | Group & {grades: Grade[]},
  b: MinimalBlueprintGroup | Group & {grades: Grade[]}
) => {
  const lowestGradeNumberA = Math.min(...a.grades.map((grade) => grade.number))
  const lowestGradeNumberB = Math.min(...b.grades.map((grade) => grade.number))

  if (lowestGradeNumberA < lowestGradeNumberB) {
    return -1
  } else if (lowestGradeNumberA > lowestGradeNumberB) {
    return 1
  } else {
    // Compare displayTitle in a case-insensitive manner
    const displayTitleA = a.displayTitle.toString().toLowerCase()
    const displayTitleB = b.displayTitle.toString().toLowerCase()

    if (displayTitleA < displayTitleB) {
      return -1
    } else if (displayTitleA > displayTitleB) {
      return 1
    } else {
      return 0
    }
  }
}