
export * from "./colors"
export * from "./sizes"

export const CARD_IMAGE_RATIO = 16 / 9

export const CONTAINER_WIDTH = "1400px"

export const SONGBOX_Z_INDEX = 100
export const SLIDE_MENU_Z_INDEX = 100
export const HEADER_Z_INDEX = 1000
export const HEADER_MENU_Z_INDEX = 1500
export const PLAYLIST_MODAL_OVERLAY_Z_INDEX = 2000
export const PLAYLIST_MODAL_Z_INDEX = 3000
export const RADIO_PLAYER_Z_INDEX = 4500
export const MODAL_OVERLAY_Z_INDEX = 4600
export const MODAL_Z_INDEX = 5000
export const SUBMIT_FEEDBACK_Z_INDEX = 7000

export const TOASTS_Z_INDEX = 10000
export const CONTEXT_MENU_Z_INDEX = 11000

export const ALERT_MODAL_OVERLAY_Z_INDEX = 20000
export const ALERT_MODAL_Z_INDEX = 21000

export const POPOVER_MODAL_Z_INDEX = 30000
export const DRAWER_OVERLAY_Z_INDEX = 40000
export const DRAWER_Z_INDEX = 40001

export const DRAG_ZINDEX = 100000


// Don't use: Legacy composer colors
export const ACTIVE_COLOR = '#ec6829'
export const DARK_TEXT = "#1e2843"

// Don't use: Legacy composer fonts
export const FONT_FAMILY = "Poppins, 'Open Sans', sans-serif"
export const FONT_FAMILY_HEADER = "PoppinsExtraBold"
export const FONT_FAMILY_HEADER_SMALL = "PoppinsBold"
