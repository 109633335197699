import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from "react"
import { ToastInfo } from "../types"


let counter = 1

export default function useToast() {
  const ctx = useContext(ToastContext)

  const setToasts = ctx?.setToasts
  
  return useCallback((info: ToastInfo) => {
    
      if (!setToasts) {
        throw new Error("useToast must be used within ToastProvider")
      }
    counter++

    setToasts((toasts) => ([
      ...toasts,
      {
        ...info,
        id: counter.toString(),
      },
    ]))
  }, [setToasts])
}

export const useToasts = () => {
  const ctx = useContext(ToastContext)

  if (!ctx) {
    throw new Error("useToasts must be used within ToastProvider")
  }
  const { toasts, setToasts } = ctx

  return [toasts, setToasts] as const
}

type ToastInfoWithId = ToastInfo & { id: string }

type ToastContextType = {
  setToasts: Dispatch<SetStateAction<ToastInfoWithId[]>>
  toasts: ToastInfoWithId[]
}

const ToastContext = createContext<ToastContextType | null>(null)

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([] as ToastInfoWithId[])

  // console.log("toasts", toasts)

  return (
    <ToastContext.Provider value={{ setToasts, toasts }}>
      {children}
    </ToastContext.Provider>
  )
}