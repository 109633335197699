import styled from "@emotion/styled"
import { ComponentProps, forwardRef } from "react"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX16 } from "theme/sizes"

export const Root = styled.div`
  border: 1px solid #b2b1c9;
  border-radius: 8px;
  font-size: ${PX16};
  font-family: inherit;
  line-height: 0;
  color: ${DARK_BLUE_COLOR};
  display: flex;
  align-items: center;

  &:focus,
  &:focus-within {
    border: rgb(94, 158, 214) solid 1px;
    outline: rgb(94, 158, 214) auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }
`

export const Control = styled.textarea`
  appearance: none;
  padding: 11px 16px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  border-radius: inherit;
  border: none;
  flex: 1;
  outline: 0px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${DARK_BLUE_COLOR};
    opacity: 0.5;
  }
`

const TextAreaControl = styled(Control)`
  height: 126px;
  width: 100%;
  box-sizing: border-box;
`.withComponent("textarea")

const TextArea = forwardRef<
  HTMLTextAreaElement,
  ComponentProps<typeof TextAreaControl>
>(({ children, value, onChange, ...props }, ref) => {
  return (
    <Root>
      <TextAreaControl value={value} onChange={onChange} ref={ref} {...props} />
    </Root>
  )
})

TextArea.displayName = "TextArea"

export default TextArea
