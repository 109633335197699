import { Grade } from "db"

let contentGroups = {
  contentGroup0: false,
  contentGroup1: false,
  contentGroup2: false,
  contentGroup3: false,
  contentGroup4: false,
  contentGroup5: false,
  contentGroup6: false,
  contentGroup7: false,
  contentGroup8: false,
} as const

// Define the type of the contentGroups
export type ContentGroupsObject = {
  [K in keyof typeof contentGroups]: boolean | undefined
} & {
  contentGroupLabel?: string | undefined
}

export default function getAnalyticsContentGroups<ContentGroupsObject>(
  grades: Grade[]
) {
  let contentContentGroups = {}

  if (grades.length === 0) {
    return { ...contentGroups, contentGroupLabel: "NOT_APPLICABLE" }
  }

  grades.forEach((grade) => {
    const groupKey = `contentGroup${grade.number}`
    contentContentGroups[groupKey] = true
  })

  for (const cG in contentGroups) {
    if (!contentContentGroups[cG]) {
      contentContentGroups[cG] = false
    }
  }

  return {
    ...contentContentGroups,
    contentGroupLabel: `Groep ${grades.map((g) => g.number).join("-")}`,
  }
}
