import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { Value } from "@stringtale/react"
import getGroupsInSchool, {
  GetGroupsInSchoolResult,
} from "app/auth/queries/getGroupsInSchool"
import useToast from "app/toasts/hooks/useToast"
import getCurrentGroup from "app/users/queries/getCurrentGroup"
import { Group as GroupVO } from "db"
import { motion } from "framer-motion"
import { ReactNode, useEffect, useRef, useState } from "react"
import { MODAL_OVERLAY_Z_INDEX, ORANGE_COLOR, PX14 } from "theme/consts"
import Checkbox from "ui/Checkbox"
import {
  Content,
  Header,
  Input,
  InputWrapper,
  Root,
  SelectAllWrapper,
} from "ui/FilterSelect"
import Group from "ui/Group"
import Hr from "ui/Hr"
import TextLink from "ui/TextLink"
import * as Modal from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import getGroupedUserPlaylists from "../../playlists/queries/getGroupedUserPlaylists"
import { GroupSelectItem } from "./GroupSelectItem"
import { useRouter } from "next/router"
import useLinks, { getLink } from "app/core/hooks/useLinks"

export const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${MODAL_OVERLAY_Z_INDEX};
`.withComponent(motion.div)

type RenderItemProps = {
  group: GetGroupsInSchoolResult[number]
  isActive: boolean
  disabled?: boolean
  onChange: () => void
}

type Props = {
  onClose: () => void
  onSubmit: (groupIds: number[]) => Promise<void>
  getIsDisabled?: (group: GroupVO) => boolean
  children?: ReactNode
  onRenderItem?: (group: RenderItemProps) => ReactNode
  allGroups?: boolean
}

export default function SelectGroupModal(props: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [currentGroup] = useQuery(getCurrentGroup, {})
  const [groups] = useQuery(getGroupsInSchool, {
    all: props.allGroups || false,
  })
  const [selectedGroups, setSelectedGroups] = useState<number[]>(
    currentGroup ? [currentGroup.id] : []
  )

  useEffect(() => {
    if (currentGroup) {
      setSelectedGroups(currentGroup ? [currentGroup.id] : [])
    }
  }, [currentGroup])

  const [search, setSearch] = useState("")
  const addToast = useToast()
  const [isLoading, setLoading] = useState(false)

  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content>
        <Modal.Root>
          <Modal.ScrollArea>
            <Modal.Content>
              <Stack gap="24px">
                {props.children || (
                  <>
                    <Stack gap="16px">
                      <Modal.Title>
                        <Value name="apps.web.src.playlists.components.followplaylisttogroupmodal.title">
                          Zichtbaar voor andere groepen
                        </Value>
                      </Modal.Title>
                      <Hr />
                    </Stack>
                    <Text size={PX14}>
                      <Value name="apps.web.src.playlists.components.followplaylisttogroupmodal.body">
                        {`Selecteer één of meerdere groepen waarvoor je deze lijst
                        wilt opslaan. Duo's uit geselecteerde groepen krijgen
                        deze lijst automatisch te zien.`}
                      </Value>
                    </Text>
                  </>
                )}
                <Root>
                  <Header align="center">
                    <SelectAllWrapper>
                      <Checkbox
                        label={
                          <Value
                            name="apps.web.src.themes.components.selectgroupmodal.selecteer_alles"
                            version="1"
                          >
                            Alles
                          </Value>
                        }
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedGroups(groups.map((g) => g.id))
                          } else {
                            setSelectedGroups([])
                          }
                        }}
                      />
                    </SelectAllWrapper>
                    <InputWrapper>
                      <Input
                        // autoFocus
                        inputRef={inputRef}
                        placeholder="Zoek een groep"
                        defaultValue={search || ""}
                        onChange={(e) => {
                          setSearch(e.currentTarget.value)
                        }}
                      />
                    </InputWrapper>
                  </Header>
                  <Content>
                    <Stack>
                      {groups.map((group) => {
                        if (
                          group.displayTitle
                            .toLowerCase()
                            .includes(search.toLowerCase()) === false
                        ) {
                          return null
                        }
                        if (props.onRenderItem) {
                          return props.onRenderItem({
                            group,
                            isActive: selectedGroups.includes(group.id),
                            onChange: () => {
                              setSelectedGroups(toggleArray(group.id))
                            },
                          })
                        }
                        return (
                          <GroupSelectItem
                            isActive={selectedGroups.includes(group.id)}
                            group={group}
                            onChange={() => {
                              setSelectedGroups(toggleArray(group.id))
                            }}
                          />
                        )
                      })}
                    </Stack>
                  </Content>
                </Root>
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton variant="bordered">
                      <Value name="apps.web.src.groups.components.selectgroupsmodal.annuleren">
                        Annuleren
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  <RoundedButton
                    disabled={isLoading}
                    variant="filled"
                    onClick={async () => {
                      setLoading(true)
                      await props.onSubmit(selectedGroups)
                      await invalidateQuery(getGroupedUserPlaylists)
                      setLoading(false)

                      props.onClose()
                    }}
                  >
                    <Value name="apps.web.src.themes.components.selectgroupmodal.opslaan">
                      Opslaan
                    </Value>
                  </RoundedButton>
                </Group>
              </Stack>
              <Dialog.Close asChild>
                <Modal.Close />
              </Dialog.Close>
            </Modal.Content>
          </Modal.ScrollArea>
        </Modal.Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}
