import styled from "@emotion/styled"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import RoundedButtonBase from "ui/RoundedButton"
import { PX14 } from "theme/sizes"
import { ContentSubjectType } from "app/search/components/ParticipationIconFilter"
import React, { ComponentProps } from "react"

const RoundedButton = styled(RoundedButtonBase)`
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  font-size: ${PX14};
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
`

type ContentSubjectButtonProps = {
  contentType: ContentSubjectType
  isActive?: boolean
  children: React.ReactNode | string | null
} & ComponentProps<typeof RoundedButton>

const StyledButton = styled(RoundedButton)<ContentSubjectButtonProps>`
  background-color: ${({ contentType, isActive }) =>
    getContentSubjectColor(contentType, isActive ? "dark" : "light")};
  border: 2px solid
    ${({ contentType, isActive }) =>
      getContentSubjectColor(contentType, isActive ? "dark" : "border")};
  color: ${({ contentType, isActive }) =>
    isActive ? "white" : getContentSubjectColor(contentType)};
`
const ContentSubjectButton = ({
  contentType,
  isActive,
  children,
  ...props
}: ContentSubjectButtonProps) => {
  return (
    <StyledButton contentType={contentType} isActive={isActive} {...props}>
      {children}
    </StyledButton>
  )
}

export default ContentSubjectButton
