import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import { PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetBulletinAddSchoollistItemsResult } from "../queries/getBulletinAddSchoollistItems"
import { GetBulletinBoardItemsResult } from "../queries/getBulletinBoardItems"
import BulletinAddButton from "./BulletinAddButton"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import BulletinAddListItem from "./BulletinAddListItem"

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const BulletinAddSchoollistItem = ({
  list,
  bulletinBoardItems,
}: {
  list: GetBulletinAddSchoollistItemsResult[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const activeGrades = useMemo(() => {
    const grades: GetAllGradesResult = []
    list.playlist?.lessons.map((lesson) =>
      lesson.lesson.grades.map((grade) =>
        grades.includes(grade) ? null : grades.push(grade)
      )
    )
    return grades
  }, [list])

  const currentBulletinBoardSchoollist = bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.SCHOOL_LIST &&
      item.playlistId === list.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.schoollijst">
            Schoollijst
          </Value>
        }
        title={list.playlist?.displayTitle}
        description={list.playlist?.description}
        activeGrades={activeGrades}
        image={
          <>
            {list.playlist?.lessons ? (
              <ImageGrid>
                {list.playlist?.lessons.map((lesson) =>
                  lesson.lesson.cardImage ? (
                    <Image
                      alt=""
                      file={lesson.lesson.cardImage}
                      key={lesson.lesson.id}
                      width={115}
                      height={65}
                    />
                  ) : (
                    <EmptyContainer>
                      <EmptyImage />
                    </EmptyContainer>
                  )
                )}
                {list.playlist?.lessons &&
                  [...Array(4 - list.playlist?.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {list.owner && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.gemaakt_door">
                        Gemaakt door
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Avatar user={list.owner} width={20} height={20} />
                        <Text size={PX12}>{list.owner.firstName}</Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <BulletinAddButton
                  addProps={{
                    playlistId: list.id,
                    type: BulletinBoardItemType.SCHOOL_LIST,
                  }}
                  deleteProps={
                    currentBulletinBoardSchoollist && {
                      id: currentBulletinBoardSchoollist.id,
                    }
                  }
                  currentBulletinBoardItem={currentBulletinBoardSchoollist}
                />
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddSchoollistItem
