import styled from "@emotion/styled"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR } from "theme/colors"
import { HEADER_SIZES } from "theme/sizes"

const Title = styled.h1<{ size?: string, center?:boolean, weight?: "400" | "600" | "700" | "900", color?:string }>`
  font-size: ${p => p.size || HEADER_SIZES[0]};
  font-weight: ${p => p.weight || "700"};
  line-height: 1.24;
  letter-spacing: 0.20px;
  color: ${p => p.color || 'inherit'};
  ${p => p.center && "text-align: center;"}
`

export default Title


export const DimmedTitle = styled(Title)`
  font-weight: 400;
  color: ${DIMMED_BLUE_COLOR};
`

export const DimmedTitlePart = styled.span`
  font-weight: 400;
  color: ${DIMMED_BLUE_COLOR};
`