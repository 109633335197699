import styled from "@emotion/styled"
import * as Primitive from "@radix-ui/react-avatar"
import { ComponentProps } from "react"
import { DARK_GREEN_COLOR } from "theme/colors"
import { File } from "utils/files/types"
import Image from "./Image"
import getFullname from "utils/users/getFullName"
import { css } from "@emotion/react"

type RootProps = {
  withBorder?: boolean
  borderColor?: string
  width: number
  height: number
}

export const Root = styled(Primitive.Root)<RootProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: white;
`

const InsetBorder = styled.div<RootProps>`
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;

  ${({ borderColor }) =>
    borderColor
      ? css`
          box-shadow: inset 0 0 0 1px ${borderColor};
        `
      : css`
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
        `}
  border-radius: 50%;
  position: absolute;
`

export const Fallback = styled(Primitive.Fallback)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${DARK_GREEN_COLOR};
  color: white;
`

type User = {
  avatar: File | null
  firstName: string | null
  insertion: string | null
  lastName: string | null
}

type Props = {
  user: User
  withBorder?: boolean
  width?: number
  height?: number
  borderColor?: string
} & Omit<ComponentProps<typeof Root>, "width" | "height">

const Avatar = ({
  user,
  withBorder,
  width = 44,
  height = 44,
  borderColor,
  ...props
}: Props) => {
  return (
    <Root
      width={width}
      height={height}
      withBorder={withBorder}
      borderColor={borderColor}
      {...props}
      data-hj-suppress
    >
      {user.avatar ? (
        // <Primitive.Image>
        <Image
          file={user.avatar}
          width={width}
          height={height}
          ratio={1}
          options={{ fit: "cover" }}
          alt={`Avatar ${getFullname(user)}`}
        />
      ) : (
        // </Primitive.Image>
        <Fallback>{user.firstName?.[0]}</Fallback>
      )}
      {withBorder && (
        <InsetBorder
          width={width}
          height={height}
          withBorder={withBorder}
          borderColor={borderColor}
        />
      )}
    </Root>
  )
}

export default Avatar
