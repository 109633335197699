import { SortOption } from "app/playlists/consts"
import { useCallback } from "react"


export default function useSortLessons() {
  return useCallback(
    <
      T extends {
        createdAt: Date
        id: number
        sort: number
        lesson: { displayTitle: string | null }
      }
    >(
      list?: T[],
      sort: SortOption = "CUSTOM"
    ) => {
      if (!list) {
        return []
      }
      return [...list].sort((a, b) => {
        switch (sort) {
          case "CUSTOM":
            return b.sort - a.sort
          case "DATE_ASC":
            return a.createdAt.getTime() - b.createdAt.getTime()
          case "DATE_DESC":
            return b.createdAt.getTime() - a.createdAt.getTime()
          case "TITLE_ASC":
            return (
              a.lesson.displayTitle?.localeCompare(
                b.lesson.displayTitle || ""
              ) || 0
            )
          case "TITLE_DESC":
            return (
              b.lesson.displayTitle?.localeCompare(
                a.lesson.displayTitle || ""
              ) || 0
            )
        }
        return 0
      })
    },
    []
  )

}