import { usePlay } from "./PlayerContext"
import { useSetAtom } from "jotai"
import { audioElementRefAtom } from "../state"
import { ClassAttributes, AudioHTMLAttributes } from "react"

const Audio = (props: JSX.IntrinsicAttributes & ClassAttributes<HTMLAudioElement> & AudioHTMLAttributes<HTMLAudioElement>) => {
  const { onFinish } = usePlay()
  const setRef = useSetAtom(audioElementRefAtom)
  return <audio ref={setRef} onEnded={onFinish} {...props} />
}

export default Audio
