import { Value } from "@stringtale/react"
import styled from "@emotion/styled"
import Image from "web/src/files/components/Image"
import { File, FileWithVariants } from "utils/files/types"
import StackBase from "ui/Stack"
import ButtonBase from "ui/Button"
import { TbPlayerPlayFilled } from "@react-icons/all-files/tb/TbPlayerPlayFilled"
import { TbPlayerStopFilled } from "@react-icons/all-files/tb/TbPlayerStopFilled"
import { TbVolumeOff } from "@react-icons/all-files/tb/TbVolumeOff"
import { PX12 } from "theme/sizes"
import {
  ComponentProps,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import usePlaybackToggle from "app/player/hooks/usePlaybackToggle"
import useFilePathContext, {
  useFileVariantPathContext,
} from "utils/files/useFilePathContext"
import useProgressValueChange from "app/player/hooks/useProgressValueChange"
import * as Slider from "@radix-ui/react-slider"
import { DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { SliderRoot as SliderRootBase } from "app/player/components/ProgressBar"
import Center from "ui/Center"
import Loader from "ui/Loader"
import { atom, useAtom, useAtomValue } from "jotai"
import { atomFamily } from "jotai/utils"
import useFade, {
  FADE_IN_DURATION,
  FADE_OUT_DURATION,
} from "app/rehearsal/utils/useFade"
import { css } from "@emotion/react"
import * as AspectRatio from "@radix-ui/react-aspect-ratio"
import { CARD_IMAGE_RATIO } from "theme/consts"
import { isPlayingAtom as isRadioPlayingAtom } from "app/radio/state"
import { usePlay } from "app/radio/components/PlayerContext"
import { ImageRoot } from "./LessonListItem"
import Link from "ui/Link"

type StackProps = {
  size?: {
    width: string
    height: string
  }
}

const Stack = styled(StackBase)<StackProps>`
  ${(p) =>
    p.size
      ? css`
          width: ${p.size.width};
        `
      : css`
          width: unset;
          max-height: 240px;
        `};
`

const Root = styled(Stack)`
  @container (max-width: 650px) {
    display: none;
  }
`

export const PreviewLink = styled(Link)`
  @container (max-width: 650px) {
    display: none;
  }
`

const VideoRoot = styled.video`
  width: 100%;
  height: 100%;
`

const SliderRoot = styled(SliderRootBase)`
  position: absolute;
  bottom: 0;
`

const SliderTrack = styled(Slider.Track)`
  background-color: rgba(255, 255, 255, 0.5);
  height: 6px;
  flex-grow: 1;
`

const SliderRange = styled(Slider.Range)`
  background-color: ${ORANGE_COLOR};
  position: absolute;
  height: 100%;
`

const LoaderContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`

type ImagePreviewProps = {
  size?: {
    width: string
    height: string
  }
}

const ImagePreview = styled(Image)<ImagePreviewProps>`
  border-radius: 8px;
  ${(p) =>
    p.size
      ? css`
          width: ${p.size.width};
          height: ${p.size.height};
        `
      : css`
          max-width: 240px;
          max-height: 240px;
        `};
  object-fit: cover;
`

type ButtonProps = {
  alignLabel?: "center" | "left"
}

const Button = styled(ButtonBase)<ButtonProps>`
  gap: 3px;
  color: #f16636;
  display: flex;
  flex-direction: row;
  font-size: ${PX12};
  font-weight: bold;
  height: 30px;

  ${(p) =>
    p.alignLabel
      ? css`
          justify-content: ${p.alignLabel};
        `
      : css`
          justify-content: center;
        `}
`

const MuteButton = styled(ButtonBase)<{ volume: number }>`
  position: absolute;
  bottom: 11px;
  right: 6px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${(p) => (p.volume === 0 ? DARK_BLUE_COLOR : "#fff")};
  color: ${(p) => (p.volume === 0 ? "#fff" : DARK_BLUE_COLOR)};
`

const DEFAULT_START_TIME = 0
const DEFAULT_END_TIME = 30

type Props = {
  previewId: string
  image: File
  file?: File | FileWithVariants | null
  previewBegin?: number
  previewEnd?: number
  isPlayVisible?: boolean
  onPlay?: () => void
  onPause?: () => void
  size?: {
    width: string
    height: string
  }
  alignLabel?: "center" | "left"
  children?: ReactNode
} & ComponentProps<typeof Stack>

const playingIdAtom = atom(null as string | null)
const isPlayingAtom = atomFamily((id: string) =>
  atom(
    (get) => get(playingIdAtom) === id,
    (get, set, value: boolean) => {
      set(playingIdAtom, value ? id : null)
    }
  )
)

const LessonPreview = ({
  previewId,
  image,
  file,
  previewBegin,
  previewEnd,
  isPlayVisible,
  onPlay,
  onPause,
  size,
  alignLabel,
  children,
  ...props
}: Props) => {
  const getFilePath = useFilePathContext()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const rootRef = useRef<HTMLDivElement>(null)

  const mediaElementRef = useRef<HTMLVideoElement | null>(null)
  const toggleVideoPlayback = usePlaybackToggle(mediaElementRef)

  const getFileVariantUrl = useFileVariantPathContext()

  const onProgressValueChange = useProgressValueChange(mediaElementRef)
  const [currentDuration, setCurrentDuration] = useState<number>(0)
  const [volume, setVolume] = useState<number>(1)
  const [currentTime, setCurrentTime] = useState<number>(0)

  const [isPlaying, setPlaying] = useAtom(isPlayingAtom(previewId))
  // const isTouch = useMediaQuery("(pointer: coarse)")

  const isRadioPlaying = useAtomValue(isRadioPlayingAtom)
  const { pause } = usePlay()

  const { begin, end } = useMemo(() => {
    if (previewBegin === undefined || previewEnd === undefined) {
      return {
        begin: Math.max(0, DEFAULT_START_TIME - FADE_IN_DURATION),
        end: Math.min(
          currentDuration || Number.MAX_VALUE,
          DEFAULT_END_TIME + FADE_OUT_DURATION
        ),
      }
    }
    return {
      begin: Math.max(0, previewBegin - FADE_IN_DURATION),
      end: Math.min(
        currentDuration || Number.MAX_VALUE,
        previewEnd + FADE_OUT_DURATION
      ),
    }
  }, [currentDuration, previewBegin, previewEnd])

  useFade({
    mediaRef: mediaElementRef,
    currentSegment: {
      begin: previewBegin || DEFAULT_START_TIME,
      end: previewEnd || DEFAULT_END_TIME,
    },
    volume,
    isPlaying,
  })

  const updatePlayback = (
    element: EventTarget & (HTMLVideoElement | HTMLAudioElement)
  ) => {
    const currentTime = element.currentTime

    if (currentTime < begin) {
      element.currentTime = begin
    } else if (currentTime > end) {
      element.pause()
      setShowPreview(false)
    }
  }

  const VideoControls = (
    <>
      <SliderRoot
        min={begin}
        max={end}
        value={[currentTime]}
        onValueChange={onProgressValueChange}
        onClick={(e) => {
          e.preventDefault()
        }}
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
      </SliderRoot>
      <MuteButton
        onClick={(e) => {
          e.preventDefault()
          if (volume === 0) setVolume(1)
          else setVolume(0)
        }}
        volume={volume}
      >
        <TbVolumeOff style={{ margin: 0 }} />
      </MuteButton>
    </>
  )

  useEffect(() => {
    if (showPreview && !!mediaElementRef.current)
      mediaElementRef.current.volume = volume
  }, [showPreview, volume])

  useEffect(() => {
    if (!mediaElementRef.current) return
    if (!isPlaying) {
      mediaElementRef.current.pause()
      setShowPreview(false)
    }
  }, [isPlaying])

  return (
    <Root ref={rootRef} {...props}>
      <ImageRoot size={size}>
        {showPreview && file && file.type === "VIDEO" ? (
          <>
            {isLoading && (
              <Center>
                <Loader />
              </Center>
            )}
            <AspectRatio.Root ratio={CARD_IMAGE_RATIO}>
              <VideoRoot
                ref={mediaElementRef}
                src={
                  "variants" in file
                    ? getFileVariantUrl(file, "SMALL_360P", false) || ""
                    : ""
                }
                onLoadedMetadata={(e) => {
                  setCurrentDuration(e.currentTarget.duration)
                }}
                onCanPlay={() => setLoading(false)}
                onClick={(e) => {
                  e.preventDefault()
                  toggleVideoPlayback()
                }}
                onTimeUpdate={(e) => {
                  setCurrentDuration(e.currentTarget.duration)
                  updatePlayback(e.currentTarget)
                  setCurrentTime(e.currentTarget.currentTime)
                }}
                onEnded={() => {
                  setPlaying(false)
                  setShowPreview(false)
                }}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
              />
            </AspectRatio.Root>
            <>{VideoControls}</>
          </>
        ) : (
          <>
            <AspectRatio.Root ratio={CARD_IMAGE_RATIO}>
              <ImagePreview
                file={image}
                size={size}
                width={240}
                height={135}
                sizes="350px"
                options={{ fit: "cover" }}
                alt={""}
                onClick={(e) => {
                  if (isPlaying) {
                    e.preventDefault()
                    toggleVideoPlayback()
                  }
                }}
              />
            </AspectRatio.Root>
            {showPreview && file && file.type === "AUDIO" ? (
              <>
                <audio
                  ref={mediaElementRef}
                  src={getFilePath(file)}
                  onLoadedMetadata={(e) => {
                    setCurrentDuration(e.currentTarget.duration)
                  }}
                  onCanPlay={() => setLoading(false)}
                  onTimeUpdate={(e) => {
                    setCurrentDuration(e.currentTarget.duration)
                    updatePlayback(e.currentTarget)
                    setCurrentTime(e.currentTarget.currentTime)
                  }}
                  onEnded={() => {
                    setPlaying(false)
                    setShowPreview(false)
                  }}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                />
                {isLoading && (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )}
                <>{VideoControls}</>
              </>
            ) : null}
          </>
        )}
        {children}
      </ImageRoot>
      {isPlayVisible ? (
        showPreview ? (
          <Button
            type="button"
            onClick={async (e) => {
              e.preventDefault()
              await toggleVideoPlayback()
              await setShowPreview(false)
              onPause?.()
            }}
            alignLabel={alignLabel}
          >
            <TbPlayerStopFilled style={{ margin: 0 }} />
            <span>
              <Value name="apps.web.src.lessons.components.lessonpreview.stop">
                Stop
              </Value>
            </span>
          </Button>
        ) : (
          <Button
            type="button"
            onClick={async (e) => {
              e.preventDefault()
              if (isRadioPlaying) pause()
              await setShowPreview(true)
              await toggleVideoPlayback()
              onPlay?.()
            }}
            alignLabel={alignLabel}
          >
            <TbPlayerPlayFilled style={{ margin: 0 }} />

            <Value name="apps.web.src.lessons.components.lessonpreview.preview">
              Preview
            </Value>
          </Button>
        )
      ) : null}
    </Root>
  )
}

export default LessonPreview
