import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbArrowsMove } from "@react-icons/all-files/tb/TbArrowsMove"
import { TbPinned } from "@react-icons/all-files/tb/TbPinned"
import { TbPinnedOff } from "@react-icons/all-files/tb/TbPinnedOff"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import BulletinBoardCardBase from "app/bulletinboard/components/BulletinBoardCard"
import { GetBulletinBoardItemsResult } from "app/bulletinboard/queries/getBulletinBoardItems"
import useSortableTile from "app/core/hooks/useSortableTile"
import { useAtom } from "jotai"
import { ComponentProps, Dispatch, SetStateAction } from "react"
import { ORANGE_COLOR } from "theme/colors"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import {
  EditButton,
  ItemOverlay,
  Left,
  Right,
} from "../../core/components/TileEdit"
import { isSelectedAtom } from "../state"
import getIsAddableBulletinBoardItem from "../utils/getIsAddableBulletinBoardItem"
import Checkbox from "./Checkbox"

type Props = {
  checked: boolean
  isEditMode: boolean
}

const ItemRoot = styled(BulletinBoardCardBase)<Props>`
  ${({ checked, isEditMode }) =>
    isEditMode && checked
      ? css`
          border: 2px solid ${ORANGE_COLOR};
        `
      : null}
`

export const BulletinBoardCard = ({
  item,
  onChangePin,
  onDelete,
  onEdit,
  onAdd,
  setBulletinItem,
  ...props
}: ComponentProps<typeof BulletinBoardCardBase> & {
  isEditMode: boolean
  onChangePin: (isPinned: boolean) => void
  onDelete: () => void
  onEdit: () => void
  onAdd: () => void
  setBulletinItem: Dispatch<
    SetStateAction<GetBulletinBoardItemsResult[number] | null>
  >
}) => {
  const { isEditMode } = props
  const [isChecked, setChecked] = useAtom(isSelectedAtom(item.id))
  const { dragger, ...dragProps } = useSortableTile(item.id, isEditMode)

  return (
    <ItemRoot {...dragProps} item={item} checked={isChecked} {...props}>
      {isEditMode && (
        <>
          {dragger}
          <ItemOverlay isChecked={isChecked} />
          <Left>
            <Checkbox checked={isChecked} onCheckedChange={setChecked} />
          </Left>
        </>
      )}

      <Right>
        <ContextMenu
          trigger={
            <EditButton>
              <FaEllipsisH />
            </EditButton>
          }
          items={
            <>
              <ContextMenu.Item
                onClick={async () => {
                  onChangePin(!item.isPinned)
                }}
              >
                <Group gap="5px" align="center">
                  {item.isPinned ? (
                    <>
                      <TbPinnedOff size={20} />
                      <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.zet_los_van_bovenkant">
                        Maak los van de bovenkant
                      </Value>
                    </>
                  ) : (
                    <>
                      <TbPinned size={20} />
                      <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.pin_aan_bovenkant">
                        Zet vast aan bovenkant
                      </Value>
                    </>
                  )}
                </Group>
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={async () => {
                  onEdit()
                }}
              >
                <Group gap="5px" align="center">
                  <TbArrowsMove size={20} />
                  <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.verplaatsen">
                    Verplaatsen
                  </Value>
                </Group>
              </ContextMenu.Item>
              {item.type === "LESSON" && (
                <ContextMenu.Item
                  onClick={async () => {
                    onAdd()
                  }}
                >
                  <Group gap="5px" align="center">
                    <TbPlus size={20} />
                    <Value
                      name="apps.web.src.playlists.components.playlistmodalcontent.toevoegen_aan_afspeellijst"
                      version="89"
                    >
                      Opslaan
                    </Value>
                  </Group>
                </ContextMenu.Item>
              )}
              {getIsAddableBulletinBoardItem(item.type) && (
                <ContextMenu.Item
                  onClick={async () => {
                    if (!item) return
                    setBulletinItem(item)
                  }}
                >
                  <Group gap="5px" align="center">
                    <TbPlus size={20} />
                    <Value name="apps.web.src.playlists.components.playlistmodalcontent.lijst_opslaan">
                      Lijst opslaan
                    </Value>
                  </Group>
                </ContextMenu.Item>
              )}
              <ContextMenu.Item
                onClick={() => {
                  onDelete()
                }}
                type="critical"
              >
                <Group gap="5px" align="center">
                  <TbTrash size={20} />
                  <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.verwijder_van_prikbord">
                    Verwijder van prikbord
                  </Value>
                </Group>
              </ContextMenu.Item>
            </>
          }
        />
      </Right>
    </ItemRoot>
  )
}
