import { useQuery } from "@blitzjs/rpc"
import { useMemo } from "react"
import getFileUrlPrefix from "../queries/getFileUrlPrefix"
import { getFilePath } from "utils/files/getFilePath"
import { File, Options } from "utils/files/types"

export default function useFilePath(file?: File | null, size?: Options) {
  const [prefix] = useQuery(getFileUrlPrefix, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  })

  return useMemo(() => {
    if (!file) return null
    return getFilePath(prefix)(file, size, false)
  }, [file, prefix, size])
}
