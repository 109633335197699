import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { DARK_BLUE_COLOR, LIGHT_GREY_COLOR, LIGHT_ORANGE_COLOR } from "theme/colors"
import { PX13, PX14 } from "theme/sizes"
import { BOX_SHADOW } from "theme/styles"
import Button from "ui/Button"

export const Root = styled.div`
  background-color: white;
  /* position: absolute; */
  ${BOX_SHADOW}
  border-radius: 16px;
  gap: 1px;
  font-size: ${PX13};
  box-sizing: border-box;
  width: 260px;

`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 28px;
  box-sizing: border-box;
`


export const Title = styled.div`
  color: ${DARK_BLUE_COLOR};
  font-weight: 600;
  font-size: ${PX14};
  padding: 10px 16px 10px 25px;
  display: block;
`

export const Item = styled(Button)`
  width: 100%;
  padding: 10px 16px 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    text-decoration: underline;
  }
  [data-state="open"] > & {
    background-color: ${LIGHT_ORANGE_COLOR};
  }
`