import { Value } from "@stringtale/react/dist"
import getFullname from "utils/users/getFullName"
import { Dispatch, SetStateAction, useId, useMemo } from "react"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import { PX12, PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import { GetShareUsersResult } from "../queries/getShareUsers"
import { Item as ItemRoot } from "ui/FilterSelect"
import { Groups } from "./ShareModal"

type Props = {
  group: Groups[number]
  users: GetShareUsersResult
  selectedUsers: number[]
  setSelectedUsers: Dispatch<SetStateAction<number[]>>
}

export const GroupItem = ({
  group,
  selectedUsers,
  users,
  setSelectedUsers,
}: Props) => {
  const selectedState = useMemo(() => {
    if (users.every((user) => selectedUsers.includes(user.user.id))) {
      return true
    } 
    // else if (users.some((user) => selectedUsers.includes(user.user.id))) {
    //   return "indeterminate"
    // }
    return false
  }, [users, selectedUsers])

  const shareDate = useMemo(() => {
    // If all users in group have a shareGuest, return the newest date
    if (users.every((user) => user.user.shareGuests.length > 0)) {
      return users.reduce((prev, user) => {
        if (user.user.shareGuests[0].createdAt > prev) {
          return user.user.shareGuests[0].createdAt
        }
        return prev
      }, users[0].user.shareGuests[0].createdAt)
    }
    return null
  }, [users])

  return (
    <ItemRoot
      key={group.id}
      isActive={selectedState === true}
    >
      <Checkbox
        checked={selectedState}
        onCheckedChange={(checked) => {
          if (checked !== "indeterminate") {
            setSelectedUsers((s) =>
              users.reduce(
                (prev, user) => toggleArray(user.user.id, checked)(prev),
                s
              )
            )
          }
        }}
      />
      <Group gap="8px">
        <Stack align="flex-start">
          <Text size={PX14}>
            <Text bold size={PX14}>
              {group.displayTitle}
            </Text>
            {shareDate ? (
              <Text size={PX12} color={DIMMED_BLUE_COLOR}>
                {" • "}
                <Value
                  name="apps.web.src.share.components.sharemodal.gedeeld_op"
                  format={{ date: shareDate }}
                  locales="nl"
                >
                  {`Gedeeld op: {date, date}`}
                </Value>
              </Text>
            ) : null}
          </Text>
          <Text size={PX12}>
            {users.map((user) => getFullname(user.user)).join(", ")}
          </Text>
        </Stack>
      </Group>
    </ItemRoot>
  )
}
