import styled from "@emotion/styled"
import * as RadioBase from "@radix-ui/react-radio-group"
import React, { ComponentProps, useId } from "react"
import { DARK_BLUE_COLOR, DIMMED_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import Text from "./Text"
import { PX14 } from "theme/sizes"

export const Group = styled(RadioBase.Root)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Root = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(p) => p.disabled && `opacity: 0.5;`}
`

const RadioItem = styled(RadioBase.Item)`
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid ${DARK_BLUE_COLOR};
  &[data-state="checked"] {
    border: none;
    background-color: ${ORANGE_COLOR};
  }
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const RadioIndicator = styled(RadioBase.Indicator)`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: white;
  flex: 0 0 auto;
`

const LabelBold = styled.span``

const Label = styled.label`
  font-size: ${PX14};
  input:checked + & ${LabelBold} {
    font-weight: 600;
  }
`

type Props = {
  label?: React.ReactNode
  description?: React.ReactNode
} & ComponentProps<typeof RadioItem>

export default function Radio({
  label,
  description,
  disabled,
  ...props
}: Props) {
  const id = useId()
  return (
    <Root disabled={disabled}>
      <RadioItem
        id={id}
        {...props}
        disabled={disabled}
      >
        <RadioIndicator />
      </RadioItem>
      {label && (
        <Label htmlFor={id}>
          <LabelBold>{label}</LabelBold>{" "}
          {description && <Text color={DIMMED_BLUE_COLOR}>{description}</Text>}
        </Label>
      )}
    </Root>
  )
}

Radio.Group = Group
