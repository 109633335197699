import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import { useMediaQuery } from "@mantine/hooks"
import { Value } from "@stringtale/react/dist"
import Link from "next/link"
import { ComponentProps, useState } from "react"
import { DARK_BLUE_COLOR, PX14 } from "theme/consts"
import upperCase from "app/core/utils/upperCase"

export const Root = styled.span<{ isActive?: boolean }>`
  text-decoration: ${(p) => (p.isActive ? "none" : "underline")};
  color: ${DARK_BLUE_COLOR};
  font-size: ${PX14};
  opacity: ${(p) => (p.isActive ? 1 : 0.5)};
`

const BreadcrumbItem = ({
  children,
  isCollapsable,
  ...props
}: ComponentProps<typeof Root> & { isCollapsable?: boolean }) => {
  if (isCollapsable === undefined) {
    isCollapsable = !props.isActive
  }
  const isCollapsed = useMediaQuery("(max-width: 600px)")
  return (
    <Root as={props.isActive ? undefined : Link} {...props}>
      {isCollapsed && isCollapsable ? "..." : children}
    </Root>
  )
}

export const Home = (props: ComponentProps<typeof Root>) => (
  <BreadcrumbItem isCollapsable={false} {...Routes.Home()} {...props}>
    <Value name="breadcrumb.home">Home</Value>
  </BreadcrumbItem>
)

export const Categories = (props: ComponentProps<typeof Root>) => (
  <BreadcrumbItem isCollapsable={true} {...Routes.CategoriesPage()} {...props}>
    <Value name="breadcrumb.categories">Categorieën</Value>
  </BreadcrumbItem>
)

export const Leerkracht = (props: ComponentProps<typeof Root>) => (
  <BreadcrumbItem
    isCollapsable={true}
    {...Routes.LeerkrachtIndexPage()}
    {...props}
  >
    <Value name="breadcrumb.voor_de_leerkracht">Voor de leerkracht</Value>
  </BreadcrumbItem>
)

export const LearningLines = ({
  vak,
  ...props
}: ComponentProps<typeof Root> & { vak: string }) => (
  <BreadcrumbItem
    isCollapsable={true}
    {...Routes.LearningLineIndexOverview({ vak })}
    {...props}
  >
    <Value
      name="breadcrumb.learningLines"
      format={{ vak }}
    >{`Leerlijn {vak}`}</Value>
  </BreadcrumbItem>
)

export const LearningLineDetail = ({
  vak,
  learningLine,
  theme,
  name,
  ...props
}: ComponentProps<typeof Root> & {
  vak: string
  learningLine: string
  theme: string
  name: string
}) => (
  <BreadcrumbItem
    isCollapsable={true}
    {...Routes.LearningLinePage({ vak, name, learningLine, theme })}
    {...props}
  >
    {upperCase(name)}
  </BreadcrumbItem>
)

BreadcrumbItem.Home = Home
BreadcrumbItem.Categories = Categories
BreadcrumbItem.LearningLines = LearningLines
BreadcrumbItem.LearningLineDetail = LearningLineDetail
BreadcrumbItem.Leerkracht = Leerkracht

export default BreadcrumbItem
