import styled from "@emotion/styled"


const BulletinBoardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @container (max-width: 1279px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @container (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @container (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 14px 23px;
  /* height: 333px; */
  align-self: stretch;
`

export default BulletinBoardGrid