import styled from "@emotion/styled"
import * as Radix from "@radix-ui/react-checkbox"
import { ComponentProps } from "react"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { ORANGE_COLOR } from "theme/colors"

const Root = styled(Radix.Root)`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  border: 2px solid #03004e;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-state="checked"] {
    background: ${ORANGE_COLOR};
    border-width: 0;
    /* width: 21px;
    height: 21px; */
    transition: all 200ms ease-in-out;
  }
  :disabled {
    opacity: 0.5;
  }
`

const Indicator = styled(Radix.Indicator)`
  line-height: 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  &[data-state="checked"] {
    opacity: 1;
  }
`


export default function Checkbox(props: ComponentProps<typeof Root>) {
  return (
    <Root {...props}>
      <Indicator forceMount>
        <FaCheck color="white" size={12} />
      </Indicator>
    </Root>
  )
}
