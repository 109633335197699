import { Grade } from "db"
import { useMemo } from "react"

export type LearningLine = {
  period: string | null
  learningLineTheme: {
    letter: string | null
    learningLine: {
      grades: Grade[]
    }
  }
}

type Props = {
  learningLine: LearningLine
}

export default function LearningLineGradeText({ learningLine }: Props) {
  const grades = learningLine.learningLineTheme.learningLine.grades
  const gradeText = useMemo(
    () => {
      const list = grades?.map((item) => item.number)
      list?.sort()
      return list?.join("-")
    },
    [grades]
  )
  return <>{`Groep ${gradeText}`}</>
}
