import {FormattedTimeType} from "../types"

const getFormattedTime = (timeInSeconds: number): FormattedTimeType => {
  if (!timeInSeconds)
    return {
      minutes: 0,
      seconds: 0,
    }
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds % 60
  return {
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  }
}

export default getFormattedTime