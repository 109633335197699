import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ComponentProps, forwardRef } from "react"
import {
  DARK_BLUE_COLOR,
  DARK_GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
  PX14,
  PX12,
} from "theme/consts"
import Button from "ui/Button"

const COLORS = {
  "orange": ORANGE_COLOR,
  "green": DARK_GREEN_COLOR,
  "blue": DARK_BLUE_COLOR,
  "red": RED_COLOR,
}

type RootProps = {
  variant?: "filled" | "bordered"
  size?: "small" | "large"
  color?: keyof typeof COLORS
}

const Root = styled(Button)<RootProps>`
  ${({ variant, color }) => {
    return variant === "filled"
      ? css`
          background: ${COLORS[color || "orange"]};
          color: white;
        `
      : css`
          color: ${COLORS[color || "orange"]};
          background: white;
          border: 2px solid ${LIGHT_GREY_COLOR};
        `
  }}
  ${({ size }) => {
    if (size === "small") {
      return css`
        padding: 0 20px;
        height: 36px;
      `
    }
    return css`
      padding: 0 24px;
      height: 42px;
    `
  }}
  box-sizing: border-box;
  border-radius: 25px;
  opacity: 1;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: ${p => p.size === "small" ? PX12 : PX14};
`

const Content = styled.div`
  /* font-size: ${PX14}; */
  font-weight: 600;
  font-size: inherit;
`

type Props = ComponentProps<typeof Root> & {
  left?: React.ReactNode
  right?: React.ReactNode
}

export default forwardRef<HTMLButtonElement, Props>(function RoundedButton(
  { left, right, children, ...props },
  ref
) {
  return (
    <Root {...props} ref={ref}>
      {left}
      <Content>{children}</Content>
      {right}
    </Root>
  )
})
