
import SuperJson from "superjson"

export class MoreThanOnePlaylistError extends Error {
  constructor() {
    super("Playlist is not empty")
  }
}

SuperJson.registerClass(MoreThanOnePlaylistError, {
  identifier: "MoreThanOnePlaylistError"
})
