import styled from "@emotion/styled"
import { poppins } from "theme/fonts"
import { ComponentProps, forwardRef } from "react"
import {
  DARK_BLUE_COLOR,
  DARK_ORANGE_COLOR,
  LIGHT_ORANGE_COLOR,
  ORANGE_COLOR,
  PX12,
  PX14,
  PX16,
} from "theme/consts"
import Button from "ui/Button"

type Colors = {
  background?: string
  border?: string
  text?: string
}

type RootProps = {
  isActive?: boolean
  colors?: Colors
  size?: "large" | "small"
}

const Root = styled(Button)<RootProps>`
  background: ${(p) =>
    p.colors?.background ?? (p.isActive ? LIGHT_ORANGE_COLOR : "white")};
  opacity: 1;
  padding: ${(p) => (p.size === "small" ? "4px 8px" : "9px 12px")};
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: ${(p) => (p.size === "small" ? PX12 : PX16)};
  text-decoration: none;
  text-align: center;
  border-radius: 4px 4px 2px 2px;
  /* border: none; */
  border-bottom: 4px solid
    ${(p) => p.colors?.border ?? (p.isActive ? ORANGE_COLOR : LIGHT_ORANGE_COLOR)};
  cursor: pointer;

  svg {
    transition: transform 150ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const Content = styled.div`
  font-size: ${PX14};
`

type Props = ComponentProps<typeof Root> & {
  left?: React.ReactNode
  right?: React.ReactNode
}

export default forwardRef<HTMLButtonElement, Props>(function RoundedButton(
  { left, right, children, ...props },
  ref
) {
  return (
    <Root {...props} ref={ref}>
      {left}
      <Content>{children}</Content>
      {right}
    </Root>
  )
})
