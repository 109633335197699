import {
  AppProps,
  ErrorBoundary as BlitzErrorBoundary,
  ErrorFallbackProps,
  Routes,
} from "@blitzjs/next"
import { invalidateQuery, useQueryErrorResetBoundary } from "@blitzjs/rpc"
import Bugsnag from "@bugsnag/js"
import styled from "@emotion/styled"
import { ModalsProvider } from "@mantine/modals"
import { StringtaleProvider } from "@stringtale/react"
import "@total-typescript/ts-reset"
import {
  ContentNotAvailableError,
  MultipleSessionsError,
  NoGroupSelectedError,
  NoGroupsError,
  NoOrganizationSelectedError,
  ShowGroupMigrationError,
} from "app/auth/errors"
import { withBlitz } from "app/blitz-client"
import { useIsKids } from "app/core/hooks/useIsKids"
import { useIsPublic } from "app/core/hooks/useIsPublic"
import DashboardLayout from "app/core/layouts/DashboardLayout"
import Layout from "app/core/layouts/Layout"
import LayoutWrapper from "app/core/layouts/Layout"
import PublicLayout from "app/core/layouts/PublicLayout"
import { ContentNotAvailable } from "app/errors/ContentNotAvailable"
import { NoGroups } from "app/errors/NoGroups"
import { NotAuthorized } from "app/errors/NotAuthorized"
import { RequestFailed } from "app/errors/RequestFailed"
import FilePathProvider from "app/files/components/FilePathProvider"
import GroupPicker from "app/groups/components/GroupPicker"
import KidsErrorLayout from "app/kids/components/KidsErrorLayout"
import { OrganizationPickerContent } from "app/organizations/components/OrganizationPickerContent"
import { PlayerProvider } from "app/radio/components/PlayerContext"
import RadioBox from "app/radio/components/RadioBox"
import Toasts from "app/toasts/components/Toasts"
import { ToastProvider } from "app/toasts/hooks/useToast"
import { AuthenticationError, AuthorizationError } from "blitz"
import { useRouter } from "next/router"
import { ReactNode, Suspense, useEffect, useRef } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { poppins } from "theme/fonts"

import "@stringtale/react/styles.css"
import "../src/global.css"
import BulletinAddModal from "app/bulletinboard/components/BulletinAddModal"
import MigrateGroupComponent from "app/schooljaren/components/MigrateGroupComponent"
import Stack from "ui/Stack"
import { LIGHT_GREEN_COLOR } from "theme/colors"
import { NuqsAdapter } from "nuqs/adapters/next/pages"
import { LoadingOverlay } from "@mantine/core"
import { parseAsString, useQueryState } from "nuqs"

let ErrorBoundary = BlitzErrorBoundary

// If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
if (
  process.env.NODE_ENV === "production" &&
  process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "",
    appVersion: process.env.NEXT_BUILD_ID || "",
    plugins: [],
  })

  // ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

const StackWithBackground = styled(Stack)`
  background-color: ${LIGHT_GREEN_COLOR};
`

const queryClient = new QueryClient({})

export default withBlitz(function App({ Component, pageProps }: AppProps<any>) {
  const getLayout = Component.getLayout || ((page) => page)
  return (
    <NuqsAdapter>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <ModalsProvider>
            <Suspense
              fallback={
                <Stack>
                  <LoadingOverlay visible />
                </Stack>
              }
            >
              <FilePathProvider>
                <PlayerProvider>
                  <Toasts />
                  <StringtaleProvider locales="nl">
                    <main className={`${poppins.className}`}>
                      <ErrorBoundary
                        FallbackComponent={RootErrorFallback}
                        onReset={useQueryErrorResetBoundary().reset}
                      >
                        <RadioBox />
                        {getLayout(<Component {...pageProps} />)}
                        <BulletinAddModal />
                      </ErrorBoundary>
                    </main>
                  </StringtaleProvider>
                </PlayerProvider>
              </FilePathProvider>
            </Suspense>
          </ModalsProvider>
        </ToastProvider>
      </QueryClientProvider>
    </NuqsAdapter>
  )
})

function DynamicLayout({
  children,
  title,
}: {
  children: ReactNode
  title: string
}) {
  const isPublic = useIsPublic()

  if (isPublic) {
    return <PublicLayout title={title}>{children}</PublicLayout>
  }
  return <DashboardLayout title={title}>{children}</DashboardLayout>
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const router = useRouter()
  const isKidsPage = useIsKids()
  const firstLoad = useRef(true)
  // TODO: Error NoSelectedSchoolError should render school picker
  const [next] = useQueryState("next", parseAsString)

  useEffect(() => {
    if (error instanceof MultipleSessionsError && !isKidsPage) {
      if (firstLoad.current) {
        firstLoad.current = false
        router.push(
          Routes.MultipleSessionsPage({
            next: next?.includes("meerdere-sessies")
              ? undefined
              : next
                ? next
                : router.asPath === "/" || router.asPath === "%2F"
                  ? undefined
                  : router.asPath,
          })
        )
        resetErrorBoundary()
      }
      return
    }
    if (error instanceof AuthenticationError) {
      if (firstLoad.current) {
        firstLoad.current = false
        router.push(
          isKidsPage
            ? Routes.LoginPage({ next: router.asPath })
            : Routes.LoginPage({
                next: next === "meerdere-sessies" ? undefined : next,
                multipleSessions: router.pathname !== "/",
              })
        )
        resetErrorBoundary()
      }
      return
    }
  }, [error, resetErrorBoundary, router, next])

  if (
    error instanceof AuthenticationError ||
    error instanceof MultipleSessionsError
  ) {
    return null
    // if (isKidsPage) {
    //   return (
    //     <LayoutWrapper title="123ZING Kids">
    //       <KidsLoginPage />
    //     </LayoutWrapper>
    //   )
    // }
    // return (
    //   <LoginForm
    //     onSuccess={async (user) => {
    //       // resetErrorBoundary();
    //       if (user.role === "ADMIN") {
    //         await router.push(Routes.AdminLessonPage())
    //       } else {
    //         if (!user.onboardingComplete) {
    //           await router.push(Routes.OnboardingGroup())
    //         } else {
    //           // await router.push(Routes.GroupPicker().pathname)
    //           resetErrorBoundary()
    //         }
    //       }
    //     }}
    //   />
    // )
  }
  if (isKidsPage) {
    return (
      <LayoutWrapper title="123ZING Kids">
        <KidsErrorLayout />
      </LayoutWrapper>
    )
  }

  if (error instanceof ShowGroupMigrationError) {
    return (
      <MigrateGroupComponent
        onChange={async () => {
          await invalidateQuery()
          resetErrorBoundary()
        }}
      />
    )
  }

  if (error instanceof NoOrganizationSelectedError) {
    return (
      <DynamicLayout title="Schoolkeuze">
        <OrganizationPickerContent
          onChange={async () => {
            await invalidateQuery()
            resetErrorBoundary()
          }}
        />
      </DynamicLayout>
    )
  } else if (error instanceof NoGroupSelectedError) {
    return (
      <Layout title={"Groepskeuze"}>
        <StackWithBackground justify="center" align="stretch">
          <GroupPicker
            onChange={async () => {
              await invalidateQuery()
              resetErrorBoundary()
            }}
          />
        </StackWithBackground>
      </Layout>
    )
  } else if (error instanceof NoGroupsError) {
    return (
      <DynamicLayout title="Geen groepen">
        <NoGroups />
      </DynamicLayout>
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <DynamicLayout title="Foutcode 403">
        <NotAuthorized />
      </DynamicLayout>
    )
  } else if (error instanceof ContentNotAvailableError) {
    return (
      <DynamicLayout title="Content niet beschikbaar">
        <ContentNotAvailable />
      </DynamicLayout>
    )
  } else {
    return (
      <DynamicLayout title={`Foutcode ${error.statusCode || 400}`}>
        <RequestFailed
          code={error.statusCode || 400}
          message={error.message || error.name}
        />
      </DynamicLayout>
    )
  }
}
