import styled from "@emotion/styled"
import Group from "./Group"

const ButtonGroup = styled(Group)`
  & > *:first-child:not(:only-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  & > *:last-child:not(:only-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &:has(:not(:only-child)) {
    gap: 1px;
  }
`

export default ButtonGroup