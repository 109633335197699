import { Routes } from "@blitzjs/next"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as AspectRatio from "@radix-ui/react-aspect-ratio"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import useSortableTile from "app/core/hooks/useSortableTile"
import LessonDescriptionPreview from "app/lessons/components/LessonDescriptionPreview"
import LessonPreview from "app/lessons/components/LessonPreview"
import LessonSubTitle from "app/lessons/components/LessonSubTitle"
import {
  TLesson,
  TParticipantsIcons,
} from "app/playlists/components/PlaylistItem"
import parseStringTime from "app/rehearsal/utils/parseStringTime"
import { Grade, HeadSubject, HeadSubjectGroup, SubSubject } from "db"
import { useAtom } from "jotai"
import { CARD_IMAGE_RATIO, ORANGE_COLOR } from "theme/consts"
import ContextMenu from "ui/ContextMenu"
import GradeIcons from "ui/Grades"
import Group from "ui/Group"
import Link from "ui/Link"
import Stack from "ui/Stack"
import Title from "ui/Title"
import * as TileEdit from "../../core/components/TileEdit"
import * as TileLesson from "../../lessons/components/TileLesson"
import { isSelectedAtom } from "../state"
import Checkbox from "./Checkbox"
import { ReactNode } from "react"

type ImageContainerProps = {
  checked: boolean
  isEditMode: boolean
}

const ImageContainer = styled(TileLesson.ImageContainer)<ImageContainerProps>`
  position: relative;
  ${({ checked, isEditMode }) =>
    isEditMode
      ? checked
        ? css`
            border: 2px solid ${ORANGE_COLOR};
          `
        : css`
            img {
              opacity: 0.5;
            }
          `
      : null}

  img {
    border-radius: 0 !important;
  }
`

const WrapBorder = styled(TileLesson.WrapBorder)<{ isDragging: boolean }>`
  ${({ isDragging }) =>
    isDragging
      ? css`
          background-color: white;
          z-index: 1;
          box-shadow: 0px 3px 10px #000000e6;
          border-radius: 4px;
        `
      : ""}
`

type Props = {
  item: {
    id: number
    lesson: Omit<TLesson, "subjects"> & {
      subjects: (HeadSubjectGroup & {
        subjects: (HeadSubject & {
          subjects: (SubSubject & { isSubmittable: boolean })[]
          participationIcons: TParticipantsIcons[]
        })[]
      })[]
    }
  }
  grades: Grade[]
  isEditMode: boolean
  canOrderItem: boolean
  contextMenuItems?: ReactNode
}

const PlaylistTileLesson = ({
  item,
  grades,
  isEditMode,
  canOrderItem,
  contextMenuItems,
}: Props) => {
  const [isChecked, setChecked] = useAtom(isSelectedAtom(item.id))

  const { lesson } = item
  const { dragger, ...dragProps } = useSortableTile(
    item.id,
    isEditMode && canOrderItem
  )

  if (!lesson) return null
  const hasPreview =
    !!lesson?.previewVideo ||
    !!lesson?.previewAudio ||
    !!lesson?.rehearse?.video ||
    !!lesson?.radioSong

  return (
    <WrapBorder {...dragProps}>
      <TileLesson.Root>
        <Stack gap={hasPreview ? undefined : "12px"}>
          <AspectRatio.Root ratio={hasPreview ? 240 / 164 : CARD_IMAGE_RATIO}>
            {lesson.cardImage && (
              <a
                style={{ textDecoration: "none" }}
                {...(isEditMode
                  ? {}
                  : {
                      ...Routes.LessonPage({
                        lesson: lesson.slug,
                      }),
                      as: Link,
                    })}
              >
                <ImageContainer checked={isChecked} isEditMode={isEditMode}>
                  {isEditMode && (
                    <>
                      {canOrderItem && dragger}
                      <TileEdit.Left>
                        <Checkbox
                          checked={isChecked}
                          onCheckedChange={setChecked}
                        />
                      </TileEdit.Left>
                      <TileEdit.ItemOverlay isChecked={isChecked} />
                    </>
                  )}
                  {contextMenuItems && (
                    <TileEdit.Right>
                      <ContextMenu
                        trigger={
                          <TileEdit.EditButton
                            onClick={(e) => e.stopPropagation()}
                          >
                            <FaEllipsisH />
                          </TileEdit.EditButton>
                        }
                        items={<>{contextMenuItems}</>}
                      />
                    </TileEdit.Right>
                  )}
                  <LessonPreview
                    previewId={`playlist-${lesson.id}`}
                    isPlayVisible={hasPreview}
                    image={lesson.cardImage}
                    previewBegin={
                      lesson?.previewBegin
                        ? parseStringTime(lesson.previewBegin)
                        : undefined
                    }
                    previewEnd={
                      lesson?.previewEnd
                        ? parseStringTime(lesson.previewEnd)
                        : undefined
                    }
                    file={
                      lesson?.previewVideo ||
                      lesson?.previewAudio ||
                      lesson?.rehearse?.video ||
                      lesson?.radioSong
                    }
                    size={{ width: "100%", height: "100%" }}
                    alignLabel="left"
                  />
                </ImageContainer>
              </a>
            )}
          </AspectRatio.Root>
          <TileLesson.Content>
            <Stack gap="12px">
              <Stack gap="6px">
                <Stack gap="4px">
                  <Link
                    {...Routes.LessonPage({
                      lesson: lesson.slug,
                    })}
                  >
                    <Title size={"16px"}>{lesson.displayTitle}</Title>
                  </Link>
                  <LessonSubTitle lesson={lesson} size="10px" />
                </Stack>
                <GradeIcons allGrades={grades} activeGrades={lesson.grades} />
              </Stack>
              <LessonDescriptionPreview
                description={lesson.description}
                size="14px"
                hideLink
              />
            </Stack>
          </TileLesson.Content>
        </Stack>
      </TileLesson.Root>
    </WrapBorder>
  )
}

export default PlaylistTileLesson
