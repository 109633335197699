// import { enhancePrisma } from "blitz"
import { PrismaClient } from "@prisma/client"

// const EnhancedPrisma = enhancePrisma(PrismaClient)

export * from "@prisma/client"

let client: PrismaClient
if (globalThis._prismaClient) {
  client = globalThis._prismaClient
} else {
  client = new PrismaClient({
    // log: [
    //   {
    //     emit: 'event',
    //     level: 'query',
    //   },
    //   {
    //     emit: 'stdout',
    //     level: 'error',
    //   },
    //   {
    //     emit: 'stdout',
    //     level: 'info',
    //   },
    //   {
    //     emit: 'stdout',
    //     level: 'warn',
    //   },
    // ],
  })
  // client.$on<'query'>('query', (e) => {
  //   // if (e.duration > 300) {
  //     console.log('Query: ' + e.query)
  //     console.log('Params: ' + e.params)
  //     console.log('Duration: ' + e.duration + 'ms')
  //   // }
  // })
  globalThis._prismaClient = client
}

export default client

export async function resetConnection() {
  // console.log("process.env.NODE_ENV", process.env.NODE_ENV)
  // if (process.env.NODE_ENV === "test") {
    console.log("Resetting Prisma Client")
    await client.$disconnect()
    await client.$connect()
  // }
}