import { UrlTarget } from "db"
import Link from "ui/Link"


const getLinkProps = (url?: string | null, target?: UrlTarget | null) => {
  if (!url) return null
  if (url?.indexOf("/") === 0) {
    return {
      as: Link,
      href: url,
      target: target === "BLANK" ? "_blank" : "_self",
    }
  }
  return {
    as: "a",
    href: url,
    target: target === "SELF" ? "_self" : "_blank",
  } as const
}


export default getLinkProps