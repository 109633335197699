import styled from "@emotion/styled"
import { ComponentProps, RefObject, forwardRef } from "react"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREY_COLOR,
  LIGHT_ORANGE_COLOR,
  ORANGE_COLOR,
  PX12,
} from "theme/consts"
import Button from "ui/Button"

export const Container = styled(Button)`
  opacity: 1;
  padding: 12px 20px;
  color: ${DARK_BLUE_COLOR};
  line-height: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  text-align: center;
  border-left: ${LIGHT_GREY_COLOR} 1px solid;
  cursor: pointer;
  :hover {
    background-color: ${LIGHT_ORANGE_COLOR};
  }
  stroke: ${ORANGE_COLOR};
  &[data-state="open"] {
    color: white;
    background-color: ${ORANGE_COLOR};
    stroke: white;
  }
`

export const Content = styled.div`
  font-size: ${PX12};
  font-weight: 600;
`

type Props = ComponentProps<typeof Container> & {
  top?: React.ReactNode
}

function LineButton(
  { top, children, ...props }: Props,
  ref: RefObject<HTMLButtonElement>
) {
  return (
    <Container {...props} ref={ref}>
      {top}
      <Content>{children}</Content>
    </Container>
  )
}

export default forwardRef(LineButton)
