import { BulletinBoardItemType } from "db"
import { PersonalItem } from "../components/AddListModalContent"
import { GetListsByGroupsResult } from "../queries/getListsByGroups"

export const getUsedBulletinGroups = ({ getListsByGroupsResult, playlistToAddId, type }:
  { getListsByGroupsResult: GetListsByGroupsResult, playlistToAddId: number, type: BulletinBoardItemType }) => {
  if (!getListsByGroupsResult) return []
  if (getListsByGroupsResult.userGroups.length > 0) {
    return getListsByGroupsResult.userGroups
      .filter((group) =>
        group.bulletinBoardItems.some((item) => {
          switch (type) {
            case BulletinBoardItemType.THEME_GROUP:
              return item.themeGroupId === playlistToAddId
            case BulletinBoardItemType.LL_PAGE:
              return item.learningLineId === playlistToAddId
            case BulletinBoardItemType.SCHOOL_LIST:
            case BulletinBoardItemType.GROUP_LIST:
              return item.playlistId === playlistToAddId
            case BulletinBoardItemType.KIDS_LIST:
              return item.playlistId === playlistToAddId
            default:
              return true
          }
        })
      )
      .map((group) => ({
        id: group.id,
      }))
  } else if (
    getListsByGroupsResult &&
    getListsByGroupsResult.bulletinBoardItems &&
    getListsByGroupsResult.userGroups.length === 0
  ) {
    const bBI = getListsByGroupsResult.bulletinBoardItems.find((bBI) => {
      switch (type) {
        case BulletinBoardItemType.THEME_GROUP:
          return bBI.themeGroupId === playlistToAddId
        case BulletinBoardItemType.LL_PAGE:
          return bBI.learningLineId === playlistToAddId
        case BulletinBoardItemType.PERSONAL_LIST:
        case BulletinBoardItemType.SCHOOL_LIST:
        case BulletinBoardItemType.GROUP_LIST:
          return bBI.playlistId === playlistToAddId
        case BulletinBoardItemType.KIDS_LIST:
          return bBI.playlistId === playlistToAddId
        default:
          return true
      }
    })
    if (bBI) {
      return [{ id: PersonalItem } as const]
    }
  }
  return []
}

export const getUsedLibraryGroups = ({ getListsByGroupsResult, playlistToAddId, type }:
  { getListsByGroupsResult: GetListsByGroupsResult, playlistToAddId: number, type: BulletinBoardItemType }) => {
  if (!getListsByGroupsResult) return []
  if (getListsByGroupsResult.userGroups.length > 0) {
    return getListsByGroupsResult.userGroups
      .filter((group) =>
        group.playlists.some((playlist) => {
          switch (type) {
            case BulletinBoardItemType.THEME_GROUP:
              return playlist.themeGroupId === playlistToAddId
            case BulletinBoardItemType.LL_PAGE:
              return playlist.learningLineId === playlistToAddId
            case BulletinBoardItemType.GROUP_LIST:
            case BulletinBoardItemType.KIDS_LIST:
              return playlist.playlistId === playlistToAddId
            default:
              return true
          }
        })
      )
      .map((group) => ({
        id: group.id,
      }))
  } else if (
    getListsByGroupsResult &&
    getListsByGroupsResult.libraryListItems &&
    getListsByGroupsResult.userGroups.length === 0
  ) {
    const hasLibraryItem = getListsByGroupsResult.libraryListItems.find(
      (libraryItem) => {
        switch (type) {
          case BulletinBoardItemType.THEME_GROUP:
            return libraryItem.themeGroupId === playlistToAddId
          case BulletinBoardItemType.LL_PAGE:
            return libraryItem.learningLineId === playlistToAddId
          case BulletinBoardItemType.GROUP_LIST:
          case BulletinBoardItemType.KIDS_LIST:
            return libraryItem.playlistId === playlistToAddId
          default:
            return true
        }
      }
    )
    return hasLibraryItem ? [{ id: PersonalItem } as const] : []
  } else {
    return []
  }
}