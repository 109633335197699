import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import { Value, htmlFormatTags } from "@stringtale/react"
import getGroupsInSchool, {
  GetGroupsInSchoolResult,
} from "app/auth/queries/getGroupsInSchool"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import { DIMMED_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX12, PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import { Item } from "ui/FilterSelect"
import Group from "ui/Group"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getFullname from "utils/users/getFullName"
import migratePlaylist from "../mutations/migratePlaylist"
import getAlreadyMigratedPlaylists from "../queries/getAlreadyMigratedPlaylists"
import AlertModal from "ui/AlertModal"
import React, { ReactNode, useMemo, useState } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import RoundedButton from "ui/RoundedButton"
import { UserListAccessLevel } from "db"
import getKidsListCount from "../queries/getKidsListCount"
import TextLink from "ui/TextLink"
import useToast from "app/toasts/hooks/useToast"
import { useRouter } from "next/router"
import useLinks from "app/core/hooks/useLinks"
import getGroupedUserPlaylists from "app/playlists/queries/getGroupedUserPlaylists"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { isManagerOrInitiator } from "app/users/utils/isManagerOrInitiator"

export const GroupSelectItem = ({
  group,
  isActive,
  onChange,
  alreadyMigrated,
}: {
  group: GetGroupsInSchoolResult[number]
  isActive: boolean
  alreadyMigrated: boolean
  onChange: () => void
}) => {
  return (
    <Item isActive={isActive && !alreadyMigrated}>
      <Checkbox
        checked={isActive || !!alreadyMigrated}
        disabled={!!alreadyMigrated}
        onCheckedChange={onChange}
        label={
          <Stack>
            <Group align="center" gap="5px">
              <Text size={PX14} bold>
                {group.displayTitle}
              </Text>
              {alreadyMigrated ? (
                <Text size="11px" color={DIMMED_BLUE_COLOR}>
                  <Value name="apps.web.src.schooljaren.components.migrateplaylistmodal.al_gemigreerd">
                    De lijst bestaat al
                  </Value>
                </Text>
              ) : null}
            </Group>
            <Text size={PX12}>
              {group.organizationUsers
                .map((u) => getFullname(u.user))
                .join(", ")}
            </Text>
          </Stack>
        }
      />
    </Item>
  )
}

type Props = {
  playlistId: number
  accessLevel: UserListAccessLevel
}

function InnerMigratePlaylistModal({
  playlistId,
  accessLevel,
  isEmpty,
  ...props
}: Props & { isEmpty: boolean }) {
  const [isMigrateDialogOpen, setIsMigrateDialogOpen] = useState(false)
  const [migratePlaylistMutation] = useMutation(migratePlaylist)
  const [alreadyMigrated, { refetch }] = useQuery(getAlreadyMigratedPlaylists, {
    id: playlistId,
  })
  const currentUser = useCurrentUser()
  const currentOrganization = useCurrentOrganization()
  const isManagerOrInitiatorOfSchool = useMemo(() => {
    return isManagerOrInitiator({ currentUser, organizationId: currentOrganization?.id })
  }, [currentOrganization, currentUser])

  const [groups] = useQuery(getGroupsInSchool, { all: isManagerOrInitiatorOfSchool })

  const hasGroup = (groupId: number) => {
    return !!alreadyMigrated.find((g) => g.id === groupId)
  }

  const addToast = useToast()

  const router = useRouter()
  const getLink = useLinks()

  // Used to migrate a single playlist to 1 or multiple groups
  const submit = async (groupIds: number[]) => {
    const result = await migratePlaylistMutation({
      groupIds,
      userPlaylistId: playlistId,
    })
    await invalidateQuery(getGroupedUserPlaylists)
    if (!result) {
      addToast({
        content: (
          <Value
            format={{
              link: (c) => (
                <Text
                  underline
                  bold
                  color={ORANGE_COLOR}
                  onClick={async () => {
                    let query = {
                      ...router.query,
                      bibliotheek: "",
                    }
                    delete query["archief"]
                    router.push(
                      getLink({
                        pathname: router.pathname,
                        query,
                      })
                    )
                  }}
                >
                  {c}
                </Text>
              ),
            }}
            name="apps.web.src.themes.components.migrateplaylistmodal.lijst_is_gekopieerd"
          >
            {`Lijst is gekopieerd naar de <link>bibliotheek</link>.`}
          </Value>
        ),
      })
    } else {
      addToast({
        content: (
          <Value name="apps.web.src.themes.components.migrateplaylistmodal.lijst_is_gekopieerd_naar_geselecteerde_groepen">
            {`Lijst is gekopieerd naar de geselecteerde groep(en).`}
          </Value>
        ),
      })
    }
    refetch()
    setIsMigrateDialogOpen(false)
  }

  // If you have a single group, use this
  if (groups.length === 1) {
    const groupAlreadyHasList = hasGroup(groups[0].id)
    return (
      <AlertDialog.Root
        open={isMigrateDialogOpen}
        onOpenChange={setIsMigrateDialogOpen}
      >
        <AlertDialog.Trigger asChild>
          <RoundedButton
            variant={"filled"}
            color="blue"
            disabled={groupAlreadyHasList}
          >
            {groupAlreadyHasList ? (
              <Value
                name="apps.web.src.schooljaren.components.playlistmodalheader.lijst_al_gekopieerd"
                version="1"
              >
                Deze lijst is al gekopieerd
              </Value>
            ) : (
              <Value
                name="apps.web.src.schooljaren.components.playlistmodalheader.kopieer_lijst_naar_huidig_schooljaar"
                version="1"
              >
                Kopieer naar huidig schooljaar
              </Value>
            )}
          </RoundedButton>
        </AlertDialog.Trigger>
        <AlertModal
          title={
            <Modal.Title>
              {accessLevel === "KIDS" ? (
                isEmpty ? (
                  <Value
                    name="apps.web.src.schooljaren.components.migrateplaylistmodal.kopieer_kids_empty_lijst_naar_bibliotheek_van_group"
                    format={{ groupName: groups[0].displayTitle }}
                  >
                    {`Kopieer kidslijst naar huidige schooljaar`}
                  </Value>
                ) : (
                  <Value
                    name="apps.web.src.schooljaren.components.migrateplaylistmodal.kopieer_kids_lijst_naar_bibliotheek_van_group"
                    format={{ groupName: groups[0].displayTitle }}
                  >
                    {`Kopieer en overschrijf kidslijst naar huidig schooljaar`}
                  </Value>
                )
              ) : (
                <Value
                  name="apps.web.src.schooljaren.components.migrateplaylistmodal.kopieer_lijst_naar_bibliotheek_van_group"
                  format={{ groupName: groups[0].displayTitle }}
                >
                  {`Kopieer lijst naar bibliotheek van huidig schooljaar`}
                </Value>
              )}
            </Modal.Title>
          }
          body={
            accessLevel === "KIDS" ? (
              isEmpty ? (
                <Value
                  name="apps.web.src.schooljaren.components.migrateplaylistmodal.kids_empty_body"
                  format={{
                    groupName: groups[0].displayTitle,
                    ...htmlFormatTags,
                  }}
                >
                  {`Wil je de kidslijst kopiëren naar de bibliotheek van het huidige schooljaar van {groupName}?
                `}
                </Value>
              ) : (
                <Value
                  name="apps.web.src.schooljaren.components.migrateplaylistmodal.kids_body"
                  format={{
                    groupName: groups[0].displayTitle,
                    ...htmlFormatTags,
                  }}
                >
                  {`Wil je de kidslijst kopiëren naar de bibliotheek van het huidige schooljaar van {groupName}?{br}{br}
                  <b>Let op:</b> Als je de kidslijst kopieert, wordt de kidslijst in het huidige schooljaar overschreven.
                `}
                </Value>
              )
            ) : (
              <Value
                name="apps.web.src.schooljaren.components.migrateplaylistmodal.single_body"
                version="1"
                format={{
                  groupName: groups[0].displayTitle,
                  ...htmlFormatTags,
                }}
              >
                {`Wil je deze lijst kopiëren naar de bibliotheek van {groupName}?`}
              </Value>
            )
          }
          submit={
            <Modal.SubmitButton
              onClick={async () => {
                await submit([groups[0].id])
              }}
            >
              <Value name="apps.web.src.schooljaren.components.migrateplaylistmodal.bevestig">
                Bevestig
              </Value>
            </Modal.SubmitButton>
          }
          cancel={
            <Modal.CancelButton>
              <Value name="apps.web.src.schooljaren.components.migrateplaylistmodal.annuleer">
                Annuleer
              </Value>
            </Modal.CancelButton>
          }
        />
      </AlertDialog.Root>
    )
  }
  // If you have multiple groups, use this
  const groupAlreadyHasList = groups.every((g) => hasGroup(g.id))

  return (
    <Dialog.Root
      open={isMigrateDialogOpen}
      onOpenChange={setIsMigrateDialogOpen}
    >
      <Dialog.Trigger asChild>
        <RoundedButton
          variant={"filled"}
          color="blue"
          disabled={groupAlreadyHasList}
          // onClick={() => {}}
        >
          {groupAlreadyHasList ? (
            <Value
              name="apps.web.src.schooljaren.components.playlistmodalheader.lijst_al_gekopieerd"
              version="1"
            >
              Deze lijst is al gekopieerd
            </Value>
          ) : (
            <Value
              name="apps.web.src.schooljaren.components.playlistmodalheader.kopieer_lijst_naar_huidig_schooljaar"
              version="1"
            >
              Kopieer naar huidig schooljaar
            </Value>
          )}
        </RoundedButton>
      </Dialog.Trigger>
      <SelectGroupModal
        {...props}
        allGroups={isManagerOrInitiatorOfSchool}
        onClose={() => setIsMigrateDialogOpen(false)}
        // Passes on submit here
        onSubmit={async (groupIds) => {
          await submit(groupIds)
        }}
        onRenderItem={({ group, isActive, onChange }) => (
          <GroupSelectItem
            isActive={isActive}
            group={group}
            onChange={onChange}
            alreadyMigrated={hasGroup(group.id)}
          />
        )}
      >
        <Stack gap="16px">
          <Modal.Title>
            {accessLevel === "KIDS" ? (
              <Value name="apps.web.src.schooljaren.components.migrateplaylistmodal.kids.title">
                Kopieer en overschrijf kidslijst naar huidig schooljaar
              </Value>
            ) : (
              <Value name="apps.web.src.schooljaren.components.migrateplaylistmodal.title">
                Kopieer lijst naar bibliotheek van huidig schooljaar
              </Value>
            )}
          </Modal.Title>
          <Hr />
        </Stack>
        <Text size={PX14}>
          {accessLevel === "KIDS" ? (
            <Value
              name="apps.web.src.schooljaren.components.migrateplaylistmodal.kids_body_multiple"
              format={htmlFormatTags}
            >
              {`Selecteer de groep(en) waarvoor je de kidslijst wilt kopiëren naar het huidige schooljaar. {br}{br}
                <b>Let op:</b> Als je de kidslijst kopieert, wordt de kidslijst in het huidige schooljaar overschreven.
              `}
            </Value>
          ) : (
            <Value
              name="apps.web.src.schooljaren.components.migrateplaylistmodal.body"
              format={{ groupName: groups[0].displayTitle }}
              version="1"
            >
              {`Selecteer de groep(en) waarvoor je deze lijst wilt kopiëren naar
              de bibliotheek van het huidige schooljaar. Collega's die aan
              dezelfde groep gekoppeld zijn, zien deze lijst ook in hun
              bibliotheek.`}
            </Value>
          )}
        </Text>
      </SelectGroupModal>
    </Dialog.Root>
  )
}

const KidsListMigratePlaylistModal = (props: Props & { groupId: number }) => {
  const [list] = useQuery(getKidsListCount, undefined)
  return <InnerMigratePlaylistModal isEmpty={list === 0} {...props} />
}

export default function MigratePlaylistModal({ ...props }: Props) {
  const [groups] = useQuery(getGroupsInSchool, {})
  if (props.accessLevel === "KIDS" && groups.length === 1) {
    return <KidsListMigratePlaylistModal {...props} groupId={groups[0].id} />
  }
  return <InnerMigratePlaylistModal {...props} isEmpty={false} />
}
