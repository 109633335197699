import styled from "@emotion/styled"
import * as Accordion from "@radix-ui/react-accordion"
import * as Dialog from "@radix-ui/react-dialog"
import { TbBookmark } from "@react-icons/all-files/tb/TbBookmark"
import { TbChevronRight } from "@react-icons/all-files/tb/TbChevronRight"
import { Value } from "@stringtale/react/dist"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { Dispatch, SetStateAction } from "react"
import { GREEN_COLOR, PX10, PX12, PX14, PX20 } from "theme/consts"
import Button from "ui/Button"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { GetPlaylistsResults } from "../queries/getGroupedUserPlaylists"

import { useQuery } from "@blitzjs/rpc"
import { ScrollArea as ScrollAreaBase } from "@mantine/core"
import getGroupsInSchool from "app/auth/queries/getGroupsInSchool"
import { parseAsBoolean, parseAsString, useQueryState } from "nuqs"
import SwitchGroupMenu from "app/groups/components/SwitchGroupMenu"
import Group from "ui/Group"
import {
  AccordionChevron,
  Footer,
  Header,
  Item,
  Root,
  SeparatorRoot,
  SubItem,
  SubItems,
} from "ui/ModalSideBar"
import {
  MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE,
  MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE,
  MODAL_QUERY_KEY,
  MODAL_QUERY_KIDS_TYPE_VALUE,
  MODAL_QUERY_SHARE_TYPE_VALUE,
} from "../consts"
import getUserPlaylistColor from "../utils/getUserPlaylistColor"
import getUserPlaylistTitle from "../utils/getUserPlaylistTitle"
import getIsMigrationVisible from "../utils/getIsMigrationVisible"
import PlaylistMenuCircle from "./PlaylistMenuCircle"
import { useHasArchiveYears } from "app/schooljaren/hooks/useHasArchiveYears"
import { useRouter } from "next/router"
import useLinks from "app/core/hooks/useLinks"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { TbLayoutList } from "@react-icons/all-files/tb/TbLayoutList"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"

const ScrollArea = styled(ScrollAreaBase)`
  flex: 1;
  /* overflow: hidden;
  overflow-y: auto; */
`

const CurrentGroup = styled(Text)`
  font-size: ${PX12};
`

const DialogTitle = styled(Dialog.Title)`
  font-size: ${PX12};
`

const BookmarkTitle = styled.div`
  font-weight: bold;
  font-size: ${PX20};
  display: flex;
  align-items: center;
`

const RoundedButton = styled(Button)`
  color: white;
  display: flex;
  align-items: center;
  border: 2px solid white;
  border-radius: 21px;
  height: 42px;
  width: 100%;
`

const PlaylistModalSideBar = ({
  playlists,
  playlistId,
  setIsNewPlaylistOpen,
}: {
  playlists: GetPlaylistsResults
  playlistId: number | null
  setIsNewPlaylistOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { kidsPlaylist, otherPlaylists, schoolPlaylists } = playlists
  const group = useCurrentGroup()
  const router = useRouter()
  const getLink = useLinks()
  const [groups] = useQuery(getGroupsInSchool, {})
  const [queryParam, setOpen] = useQueryState(MODAL_QUERY_KEY, parseAsString)
  const [_, setOpenSchoollist] = useQueryState(
    MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE
  )
  const [organizationRole] = useQuery(getOrganizatorRole, undefined)
  const currentUser = useCurrentUser()

  const hasArchiveYears = useHasArchiveYears()

  return (
    <>
      <Root>
        <Header>
          <Group gap="8px">
            <TbBookmark size={40} />
            <Stack>
              <BookmarkTitle>
                <Value name="apps.web.src.playlists.components.playlistmodalsidebar.bibliotheek">
                  BIBLIOTHEEK
                </Value>
              </BookmarkTitle>
              {group && (
                <Stack>
                  <Text size={PX14}>{group.displayTitle}</Text>
                  <Group gap="8px">
                    {groups.length > 1 && (
                      <SwitchGroupMenu
                        trigger={
                          <Text underline pointer size={PX10}>
                            <Value name="apps.web.src.playlists.components.playlistmodalsidebar.wissel_groep">
                              Wissel groep
                            </Value>
                          </Text>
                        }
                        currentGroup={group}
                        groups={groups}
                        onCompleted={() => {
                          setOpen("")
                        }}
                      />
                    )}
                    {hasArchiveYears && (
                      <Text
                        underline
                        pointer
                        size={PX10}
                        onClick={async () => {
                          let query = { ...router.query, archief: "" }
                          delete query[MODAL_QUERY_KEY]
                          router.push(
                            getLink({
                              pathname: router.pathname,
                              query,
                            })
                          )
                        }}
                      >
                        <Value name="apps.web.src.playlists.components.playlistmodalsidebar.archief">
                          Archief
                        </Value>
                      </Text>
                    )}
                  </Group>
                </Stack>
              )}
            </Stack>
          </Group>
        </Header>
        <SeparatorRoot />
        <ScrollArea>
          <Accordion.Root type="multiple" defaultValue={["lijsten", "school"]}>
            {currentUser && currentUser.role !== "ADMIN" && (
              <Accordion.Item value="gedeeld">
                <Accordion.Header>
                  <Item
                    onClick={() => {
                      setOpen(MODAL_QUERY_SHARE_TYPE_VALUE)
                      // setPlaylistId(kidsPlaylist.id)
                    }}
                    isActive={queryParam === MODAL_QUERY_SHARE_TYPE_VALUE}
                  >
                    <Group align="center" gap="9px">
                      <Value name="playlists.sidebar.gedeeld_met_mij">
                        Gedeeld met mij
                      </Value>
                      {/* <SoonBadge /> */}
                    </Group>
                    <TbChevronRight size={18} />
                  </Item>
                </Accordion.Header>
                <Accordion.Content />
              </Accordion.Item>
            )}
            <Accordion.Item value="prikbord">
              <Accordion.Header>
                <Item
                  onClick={() => {
                    setOpen(MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE)
                  }}
                  isActive={
                    queryParam === MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE
                  }
                >
                  <Group align="center" gap="9px">
                    <Value name="apps.web.src.playlists.components.playlistmodalsidebar.prikbord">
                      Prikbord
                    </Value>
                    {/* <SoonBadge /> */}
                  </Group>
                  <TbChevronRight size={18} />
                </Item>
              </Accordion.Header>
              <Accordion.Content />
            </Accordion.Item>
            <Accordion.Item value="kids">
              <Accordion.Header>
                {kidsPlaylist ? (
                  <Item
                    onClick={() => {
                      setOpen(MODAL_QUERY_KIDS_TYPE_VALUE)
                      // setPlaylistId(kidsPlaylist.id)
                    }}
                    isActive={playlistId === kidsPlaylist.id}
                  >
                    <Group align="center" gap="9px">
                      <Value name="playlists.sidebar.kidspagina">
                        Kidslijst
                      </Value>
                      {/* <SoonBadge /> */}
                    </Group>
                    <TbChevronRight size={18} />
                  </Item>
                ) : null}
              </Accordion.Header>
              <Accordion.Content />
            </Accordion.Item>

            {/* {kidsPlaylist ? (
        ) : null} */}
            <Accordion.Item value="lijsten">
              <Accordion.Header>
                <Accordion.Trigger asChild>
                  <Item>
                    <Value name="playlists.sidebar.lijsten" version="1">
                      Lijsten
                    </Value>
                    <AccordionChevron size={18} />
                  </Item>
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <SubItems>
                  <Stack>
                    {otherPlaylists.map((playlist) => {
                      return (
                        <SubItem
                          isActive={playlist.id === playlistId}
                          onClick={() => {
                            setOpen(`${playlist.id}`)
                            // setPlaylistId(playlist.id)
                          }}
                        >
                          <PlaylistMenuCircle
                            color={getUserPlaylistColor(playlist)}
                            accessLevel={playlist.accessLevel}
                            type={playlist.type}
                          />
                          <Group justify="space-between" grow>
                            {getUserPlaylistTitle(playlist)}
                            {getIsMigrationVisible(playlist, group) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.998"
                                height="15.003"
                                viewBox="0 0 15.998 15.003"
                              >
                                <g
                                  id="Group_71078"
                                  data-name="Group 71078"
                                  transform="translate(0.003 -0.003)"
                                >
                                  <path
                                    id="Path_12421"
                                    data-name="Path 12421"
                                    d="M.831,9.714c-1.916-3.049.077-6.5.077-6.5S2.6-.563,8.077.07c4.351.5,8.4,2.47,7.549,8.331-.651,4.458-6.145,5.215-7.588,4.908a.2.2,0,0,0-.2.066,4.371,4.371,0,0,1-2.382,1.349.2.2,0,0,1-.2-.324c.527-.578,1.049-1.644-.891-2.232A5.891,5.891,0,0,1,.831,9.714"
                                    transform="translate(0.255 0.004) rotate(1)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_20478"
                                    data-name="Path 20478"
                                    d="M2.442,0,2.187,4.649H.324L.069,0ZM1.255,7.484a1.325,1.325,0,0,1-.912-.3A.979.979,0,0,1,0,6.415a1,1,0,0,1,.343-.78,1.312,1.312,0,0,1,.912-.309,1.3,1.3,0,0,1,.9.309,1,1,0,0,1,.343.78.979.979,0,0,1-.343.765A1.311,1.311,0,0,1,1.255,7.484Z"
                                    transform="translate(7.321 2.898) rotate(8)"
                                  />
                                </g>
                              </svg>
                            ) : null}
                          </Group>
                        </SubItem>
                      )
                    })}
                  </Stack>
                </SubItems>
              </Accordion.Content>
            </Accordion.Item>
            {!!schoolPlaylists?.length ||
            (currentUser &&
              currentUser.role !== "ADMIN" &&
              organizationRole !== "MEMBER") ? (
              <Accordion.Item value="school">
                <Accordion.Header>
                  <Accordion.Trigger asChild>
                    <Item>
                      <Value name="playlists.sidebar.schoollijsten">
                        Schoollijsten
                      </Value>
                      <AccordionChevron size={18} />
                    </Item>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <SubItems>
                    <Stack>
                      {currentUser &&
                        currentUser.role !== "ADMIN" &&
                        organizationRole !== "MEMBER" && (
                          <SubItem
                            onClick={() => {
                              setOpen(MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE)
                            }}
                            isActive={
                              queryParam ===
                              MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE
                            }
                          >
                            <TbLayoutList size={18} />
                            <Value name="playlists.sidebar.beheer_schoollijsten">
                              Beheer alle schoollijsten
                            </Value>
                          </SubItem>
                        )}
                      {schoolPlaylists.map((playlist) => {
                        return (
                          <SubItem
                            isActive={playlist.id === playlistId}
                            onClick={() => {
                              setOpen(`${playlist.id}`)
                              // setPlaylistId(playlist.id)
                            }}
                          >
                            <PlaylistMenuCircle
                              color={playlist.playlist?.color || GREEN_COLOR}
                              accessLevel={playlist.accessLevel}
                              type={playlist.type}
                            />
                            {playlist.playlist?.displayTitle}
                          </SubItem>
                        )
                      })}
                    </Stack>
                  </SubItems>
                </Accordion.Content>
              </Accordion.Item>
            ) : null}
          </Accordion.Root>
        </ScrollArea>
        <Footer>
          <RoundedButton onClick={() => setIsNewPlaylistOpen(true)}>
            <Value name="playlists.sidebar.nieuw">Nieuwe afspeellijst</Value>
          </RoundedButton>
        </Footer>
        {/* <SeparatorRoot /> */}
        {/* <SideBarFooter>
        <SupportCircle>
        <TbQuestionMark />
        </SupportCircle>
        <Value name="playlists.sidebar.support">Support nodig?</Value>
      </SideBarFooter> */}
      </Root>
    </>
  )
}

export default PlaylistModalSideBar
