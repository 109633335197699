import { UserListAccessLevel, UserListType } from "@prisma/client"
import { Value } from "@stringtale/react"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import getFullname from "utils/users/getFullName"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"

export type UserPlaylistTypeOwner =
  | ({ id: number } & Parameters<typeof getFullname>[0])
  | null

export default function UserPlaylistType({
  type,
  accessLevel,
  sender,
  isSharedWithMe,
}: {
  type: UserListType
  accessLevel: UserListAccessLevel
  sender: UserPlaylistTypeOwner
  isSharedWithMe?: boolean
}) {
  const currentUser = useCurrentUser()

  return (
    <>
      {getListTypeDisplayTitle(accessLevel, type)}{" "}
      {sender &&
        accessLevel !== "SCHOOL" &&
        (isSharedWithMe ? (
          <Value
            name="apps.web.src.playlists.components.playlistmodalheader.gedeeld_door"
            format={{ user: getFullname(sender) }}
          >
            {`gedeeld door {user}`}
          </Value>
        ) : sender.id === currentUser?.id ? (
          ""
        ) : (
          <Value
            name="apps.web.src.playlists.components.playlistmodalheader.toegevoegd_door"
            format={{ user: getFullname(sender) }}
          >
            {`toegevoegd door {user}`}
          </Value>
        ))}
    </>
  )
}
