import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import { Value } from "@stringtale/react"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import getAlreadyFollowing from "app/playlists/queries/getAlreadyFollowing"
import useToast from "app/toasts/hooks/useToast"
import getGroupedUserPlaylists from "../../playlists/queries/getGroupedUserPlaylists"
import { GetGroupsInSchoolResult } from "app/auth/queries/getGroupsInSchool"
import { UserPlaylist } from "db"
import { SafeUser } from "app/auth/utils/safeUserInclude"
import { Item } from "ui/FilterSelect"
import Checkbox from "ui/Checkbox"
import Stack from "ui/Stack"
import Group from "ui/Group"
import Text from "ui/Text"
import { PX12, PX14 } from "theme/sizes"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import getFullname from "utils/users/getFullName"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<
  {
    onClose: () => void
    onSubmit: (groupIds: number[]) => Promise<void>
  } & (
    | {
        learningLineId: number
      }
    | {
        themeGroupId: number
      }
    | {
        id: number
      }
  )
>
export const GroupSelectItem = ({
  group,
  isActive,
  onChange,
  alreadyFollowing,
}: {
  group: GetGroupsInSchoolResult[number]
  isActive: boolean
  alreadyFollowing?: (UserPlaylist & { owner: SafeUser | null }) | null
  onChange: () => void
}) => {
  return (
    <Item isActive={isActive && !alreadyFollowing}>
      <Checkbox
        checked={isActive || !!alreadyFollowing}
        disabled={!!alreadyFollowing}
        onCheckedChange={onChange}
        label={
          <Stack>
            <Group align="center" gap="5px">
              <Text size={PX14} bold>
                {group.displayTitle}
              </Text>
              {alreadyFollowing?.owner ? (
                <Text size="11px" color={DIMMED_BLUE_COLOR}>
                  <Value
                    name="apps.web.src.themes.components.followplaylisttogroupsmodal.al_gedeeld"
                    format={{ userName: getFullname(alreadyFollowing.owner) }}
                    version="1"
                  >
                    {`Opgeslagen door: {userName}`}
                  </Value>
                </Text>
              ) : null}
            </Group>
            <Text size={PX12}>
              {group.organizationUsers
                .map((u) => getFullname(u.user))
                .join(", ")}
            </Text>
          </Stack>
        }
      />
    </Item>
  )
}

export default function FollowPlaylistToGroupModal({
  onSubmit,
  onClose,
  children,
  ...props
}: Props) {
  const addToast = useToast()
  const [alreadyFollowing] = useQuery(getAlreadyFollowing, props)

  return (
    <SelectGroupModal
      {...props}
      onClose={onClose}
      onSubmit={async (selectedGroups) => {
        await onSubmit(selectedGroups)
        await invalidateQuery(getGroupedUserPlaylists)
        addToast({
          content: (
            <Value name="apps.web.src.themes.components.followplaylisttogroupmodal.lijst_is_gekoppeld_aan_groepen">
              {`Lijst is gekoppeld aan groep(en).`}
            </Value>
          ),
        })
        onClose()
      }}
      onRenderItem={(props) => (
        <GroupSelectItem
          {...props}
          alreadyFollowing={alreadyFollowing.find(
            (playlist) => playlist.groupId === props.group.id
          )}
        />
      )}
    >
      {children}
    </SelectGroupModal>
  )
}
