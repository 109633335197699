import styled from "@emotion/styled"
import Image from "app/files/components/Image"
import { File } from "db"

const Root = styled.div`
  flex: 0 0 240px;
  position: relative;
`

const BlurOverlay = styled.div`
  filter: blur(3px);
  width: 240px;
  height: 240px;
  transform: scale(1.1);
`

const SquareImage = ({ image }: { image: File }) => {
  return (
    <Root>
      <BlurOverlay>
        <Image alt="" file={image} width={240} height={240} />
      </BlurOverlay>
      <div
        style={{
          width: "135px",
          height: "135px",
          position: "absolute",
          left: "50%",
          top: "0",
          transform: "translate(-50%)",
        }}
      >
        <Image alt="" file={image} width={130} height={130} />
      </div>
    </Root>
  )
}

export default SquareImage
