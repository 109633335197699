import { Routes } from "@blitzjs/next"
import { useMutation, useQuery } from "@blitzjs/rpc"
import { Value, htmlEmptyTags } from "@stringtale/react"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { PX14, PX32 } from "theme/sizes"
import Container from "ui/Container"
import Group from "ui/Group"
import Hr from "ui/Hr"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextLink from "ui/TextLink"
import Title from "ui/Title"
import Layout from "../Layout"
import { useCurrentYear } from "../hooks/useCurrentYear"
import migrateGroup from "../mutations/migrateGroup"
import Loader from "ui/Loader"
import doNotMigrateGroup from "../mutations/doNotMigrateGroup"

import getMigrationGroup from "../queries/getMigrationGroup"

export default function MigrateGroupComponent({
  onChange,
}: {
  onChange: () => void
}) {
  const currentYear = useCurrentYear()
  const [migrateGroupMutation, migrateGroupMutationRes] =
    useMutation(migrateGroup)
  const [doNotMigrateGroupMutation] = useMutation(doNotMigrateGroup)

  const [originGroup] = useQuery(getMigrationGroup, {})
  const currentgroup = useCurrentGroup()

  return (
    <Layout
      blueprint={currentYear}
      title="Kopieer groepslijsten"
      hideCloseButton
    >
      <Container width="596px">
        <Stack gap="16px">
          <Title size={PX32} weight="900">
            <Value name="apps.web.src.schooljaren.components.migrategroupcomponent.title">
              De groepslijsten zijn overgezet naar het nieuwe schooljaar
            </Value>
          </Title>
          <Hr />
          <Text size={PX14}>
            {originGroup.displayTitle.toLowerCase() ===
            currentgroup?.displayTitle.toLowerCase() ? (
              <Value
                name="apps.web.src.schooljaren.components.migrategroupcomponent.body"
                format={{
                  ...htmlEmptyTags,
                  currentgroup: currentgroup?.displayTitle,
                }}
              >
                {`We hebben alle groepslijsten van {currentgroup} overgezet naar het huidige schooljaar.
                Je vindt al je lijsten terug in de bibliotheek.{br}{br}
                Wil je liever met een schone lei beginnen? Klik dan
                op ‘Groepslijsten niet overzetten’. Je kunt oude groepslijsten
                altijd terugvinden in het groepsarchief in de bibliotheek.`}
              </Value>
            ) : (
              <Value
                name="apps.web.src.schooljaren.components.migrategroupcomponent.body"
                format={{
                  ...htmlEmptyTags,

                  originGroup: `${originGroup.displayTitle}${
                    originGroup.archiveYear
                      ? ` (${originGroup.archiveYear.displayTitle})`
                      : ""
                  }`,
                  currentgroup: currentgroup?.displayTitle,
                }}
              >
                {`We hebben alle groepslijsten van {originGroup} overgezet naar {currentgroup} in het
                huidige schooljaar. Je vindt al je lijsten terug in de
                bibliotheek.{br}{br}Wil je liever met een schone lei beginnen? Klik dan
                op ‘Groepslijsten niet overzetten’. Je kunt oude groepslijsten
                altijd terugvinden in het groepsarchief in de bibliotheek.`}
              </Value>
            )}
          </Text>
          <Group align="center" justify="space-between">
            <TextLink
              bold
              onClick={async () => {
                await doNotMigrateGroupMutation()
                onChange()
              }}
            >
              <Value name="apps.web.src.schooljaren.components.migrategroupcomponent.ik_wil_alles_weggooien_en_opnieuw_starten">
                Groepslijsten niet overzetten
              </Value>
            </TextLink>
            <RoundedButton
              variant="filled"
              onClick={async () => {
                if (migrateGroupMutationRes) {
                  if (
                    !migrateGroupMutationRes.isSuccess &&
                    !migrateGroupMutationRes.isError
                  ) {
                    await migrateGroupMutation()
                  }
                  onChange()
                }
              }}
              disabled={migrateGroupMutationRes?.isLoading}
            >
              {migrateGroupMutationRes?.isLoading ? (
                <Loader color="#FFFFFF" />
              ) : (
                <Value name="apps.web.src.schooljaren.components.migrategroupcomponent.verder">
                  Verder
                </Value>
              )}
            </RoundedButton>
          </Group>
        </Stack>
      </Container>
    </Layout>
  )
}
