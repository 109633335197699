import { IntlMessageFormat } from "intl-messageformat"
import { useContext, useMemo } from "react"

export function FormatValue({
  format,
  locales,
  children,
}: {
  children: string
  locales?: string | string[]
  format: any
}) {
  return (
    <>
      {useMemo(() => {
        try {
          return new IntlMessageFormat(
            children,
            locales
          ).format(format)
        } catch (e) {
          if (e instanceof Error) {
            return e.toString()
          }
        }
      }, [children, format, locales])}
    </>
  )
}
